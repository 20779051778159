import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'

const BillingDashboard = () => {
  return (
    
    <div>
        <InventoryHeader />
        
       <div>Billing Dashboard</div>
        </div>
  )
}

export default BillingDashboard