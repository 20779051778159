import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ItemstoReorderBlock from '../../Blocks/ItemstoReorderBlock'

const ItemtoReorderPage = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
        <ItemstoReorderBlock/>
        </div>
    </div>
  )
}

export default ItemtoReorderPage