import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import { Dialog } from 'primereact/dialog';
import AddSTOItems from './AddSTOItems';
import { AutoComplete } from 'primereact/autocomplete';

const AddStockTransferOrder = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [Items, setItems] = useState([]);
    const [SearchItems, setSearchItems] = useState([]);
    const [MySelectedItem, setMySelectedItem] = useState([]);
    const [Dealers, setDealers] = useState([]); 
    const [Warehouses, setWarehouses] = useState([]); 
    const [Taxes, setTaxes] = useState([]); 
    const [Message, setMessage] = useState();
    const [ConfirmVisible, setConfirmVisible] = useState(false);
    const [SelectFWHID, setSelectFWHID] = useState("");
    const [Index, setIndex] = useState(0);
    const navigate = useNavigate();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        sortField: null,
        sortOrder: 0,
    });
    const [state, setState] = useState ({
        Name: "",
        STONo : "",
        STODate : new Date(),
        TimeZone: "",
        CompanyId : Company.id,
        FromWHId: "",
        ToWHId: "",
        Heading : "To Whomsoever it may concern",
        Subject : "Stock Transfer Order",
        WelcomeText : "Please transfer following Items",
        TermsandConditions : "",
        SigningAuthority : "",
        STOItemsRequest : [{
            ItemId: "",
            ItemName: "",
            Index: 0,
            Qty: "",
            RemarksIfany: "",
            UnitId: "",
            UnitName: "",
            SpecialRemarks: "",
            AvailableQty: "",
            InvId: "",
        }]

    });

    const getTimeZone = () => {
                        
        const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setState((prevProps) => ({
            ...prevProps,
            "TimeZone": MytimeZone
          }));
    };

    const accept = () => {
        setState((prevProps) => ({
            ...prevProps,
            FromWHId: SelectFWHID,
            STOItemsRequest: [{"ItemId": "", "Index": 0, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", "AvailableQty": 0, "InvId": "" }],
          }));
        
        setMySelectedItem([]);
   }

    const reject = () => {
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(state.STOItemsRequest);
        if(name=== "FromWHId" && (state.STOItemsRequest.length > 1 || state.STOItemsRequest[0]["ItemId"] !== "")){
            setSelectFWHID(value);
            setConfirmVisible(true);
            
        }
        else{
            setState((prevProps) => ({
                ...prevProps,
                [name]: value
              }));
        }
       
      };
      const search = (event, index) => {
            setSearchItems(Items.filter(x => x.name.toLowerCase().startsWith(event.query.toLowerCase())));
        }
        
        const AutoCompleteChange = (e, index) => {
            const values = [...state.STOItemsRequest];
            const selectedItem = e.value;
            console.log(index);
            const valueSelectedItem = [...MySelectedItem];
            console.log(MySelectedItem);
            console.log(valueSelectedItem);
            // valueSelectedItem.splice(index, 0, selectedItem)
            valueSelectedItem[index] = selectedItem;
            setMySelectedItem(valueSelectedItem)
            
        
            if (selectedItem) {
                values[index] = {
                    ...values[index],
                    ItemId: selectedItem.id,
                    ItemName: selectedItem.name,
                    UnitName: selectedItem.inventoryUnitName,
                    UnitId: selectedItem.inventoryUnit,
                    HSNCode: selectedItem.hsnCode,
                    SpecialRemarks: selectedItem.description
                };

                console.log(selectedItem.listInventories);

                if(selectedItem.listInventories && selectedItem.listInventories.length > 0)
                    {
                        var MyList = selectedItem.listInventories.find(x => x.warehouseId == state.FromWHId);
                        if(MyList !== null && MyList !== undefined){
                            values[index]["AvailableQty"] = MyList.availableQty;
                            values[index]["InvId"] = MyList.id;
                        }
                        else{
                            values[index]["AvailableQty"] = 0;
                        }
                    }
                    else{
                        values[index]["AvailableQty"] = 0;
                    }
                
                setState(prevState => ({
                    ...prevState,
                    STOItemsRequest: values
                }));
                
            }
        }
    const handleWlcomeEditorChange = (event) => {
        setState((prevProps) => ({
          ...prevProps,
          "WelcomeText": event.htmlValue,
        }));
      };
    const handleTndCChange = (event) => {
        setState((prevProps) => ({
          ...prevProps,
          "TermsandConditions": event.htmlValue,
        }));
      };

      const handleL2InputChange = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.STOItemsRequest]      
        values[index][name] = value;
        if (name === "ItemId" && value !== null && value !== "")
        {
            let Myitem = Items.find(x => x.id === value);
            values[index]["ItemName"] = Myitem.name;
            values[index]["UnitName"] = Myitem.invItemUnit.unitShortCode;
            values[index]["UnitId"] = Myitem.invItemUnit.id;
            values[index]["HSNCode"] = Myitem.hsnCode;
            values[index]["SpecialRemarks"] = Myitem.description;
            console.log(Myitem.inventories);
            if(Myitem.inventories.length > 0)
            {
                var MyList = Myitem.inventories.find(x => x.warehouseId == state.FromWHId);
                if(MyList !== null && MyList !== undefined){
                    values[index]["AvailableQty"] = MyList.availableQty;
                    values[index]["InvId"] = MyList.id;
                }
                else{
                    values[index]["AvailableQty"] = 0;
                }
            }
            else{
                values[index]["AvailableQty"] = 0;
            }
        };
        if(name === "Qty" && value > state.STOItemsRequest[index]["AvailableQty"]){
            values[index][name] = state.STOItemsRequest[index]["AvailableQty"];
            alert("Qty can't be more than available quantity.")
        }
        setState((prevProps) => ({
            ...prevProps,
            STOItemsRequest: values
        }));
        
      };

      
      const handleAddMoreItem = (index) => {
        console.log(index);
        index && setIndex (index + 1);
        const values = [...state.STOItemsRequest]
        values.splice(index + 1, 0, {"ItemId": "", "Index": index + 1, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", "AvailableQty": 0 } )
        setState((prevProps) => ({
            ...prevProps,
            STOItemsRequest: values
          }));
          
        let IValues = [...MySelectedItem];
        IValues.splice(index + 1, 0, "")
        setMySelectedItem(IValues);

      }

      const handleremoveitem = (index) => {
             
        const values = [...state.STOItemsRequest]
        let IValues = [...MySelectedItem];
        
        if(values.length > 1){
            values.splice(index, 1)
            setState((prevProps) => ({
                ...prevProps,
                STOItemsRequest: values
              }));

            IValues.splice(index, 1)
            setMySelectedItem(IValues);
        }
      }


        const GetWareHouseDD = async () => {
            var MyRes = await GetAPICallAsync("GetListCompanyWarehouses", { lazyEvent: lazyState }, "");
            if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
            }
            else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setWarehouses(MyData.data);
            }
        };


        const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/ListItemMasters?' + new URLSearchParams({
            CompanyId: Company && Company.id,
            }), {
            method: 'Get',
            headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + tokenString
            },
          })
          .then(res => {
              
              if(res.status === 401){ navigate("/login"); }
              return res.json()
          } ))
        .then(data => {
            console.log(data.data)
            setItems(data.data);
        })
        .catch((err) => {
        console.log(err.message);
        })

        useEffect (() => {   
            calltpAPI();
            getTimeZone();
            GetWareHouseDD();
        }, []);
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        let MyRes = await  POSTAPICallAsync("AddStocktransferorder", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success === true ){
                console.log(MyData);
                navigate("/inventory/stocktransferorders");
            }
            else{
                setMessage(MyData.message);
            }
            
        }
        
        };
  return (
    <>
    
    
   <div>
     <form onSubmit={handleSubmit}>

     <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="Heading" className="form-label">Document Name<span className='color-red'>*</span></label>
                 <input type="text" required name="Name" className="form-control" id="Name" value={state.Name} onChange={handleInputChange} placeholder="Enter document name"/>
             </div>
         </div>
         <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="Heading" className="form-label">Heading<span className='color-red'>*</span></label>
                 <input type="text" required name="Heading" className="form-control" id="Heading" value={state.Heading} onChange={handleInputChange} placeholder="Enter Heading here"/>
             </div>
         </div>
         <div className='row'>
            {/* <div className="col-3 mb-3">
                <label htmlFor="PONo" className="form-label">PO No<span className='color-red'>*</span></label>
                <input type="text" required name="PONo" className="form-control" id="PONo" value={state.PONo} onChange={handleInputChange} placeholder="Enter company name"/>
            </div> */}
             <div className="col-3 mb-3">
                 <label htmlFor="STODate" className="form-label">STO Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="STODate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={state.STODate} onChange={handleInputChange} showButtonBar ></Calendar>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="FromWHId" className="form-label">Transfer From<span className='color-red'>*</span></label>
                 
                 <select name="FromWHId" className="form-select" value={state.FromWHId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Warehouses.filter(x => x.id !== state.ToWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
                </select>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="DealerId" className="form-label">Transfer To<span className='color-red'>*</span></label>
                 
                 <select name="ToWHId" className="form-select" value={state.ToWHId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Warehouses.filter(x => x.id !== state.FromWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
                </select>

             </div>

             

         </div>
         <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="Subject" className="form-label">Subject<span className='color-red'>*</span></label>
                 <input type="text" required name="Subject" className="form-control" id="Subject" value={state.Subject} onChange={handleInputChange} placeholder="Enter company name"/>
             </div>
         </div>
        
         
         <div className="mb-3">
             <label htmlFor="WelcomeText" className="form-label">Welcome Text<span className='color-red'>*</span></label>
             <Editor value={state.WelcomeText} onTextChange={handleWlcomeEditorChange} style={{ height: '100px' }} />
         </div>
        
         <table className='table table-bordered'>
             <thead>
                 <tr>
                     <th> Item</th>
                     <th>HSN</th>
                     <th> Qty</th>
                     <th>Remarks If any</th>
                     <th></th>
                 </tr>
                
             </thead>
             <tbody>
         {state.STOItemsRequest.map((Item, index) => (
             <tr key={index}>
                 <td>
                    {state.FromWHId ? <>
                        <AutoComplete 
                            value={MySelectedItem[index]} 
                            suggestions={SearchItems} 
                            forceSelection 
                            field="name" 
                            completeMethod={(e) => search(e, index)} 
                            onChange={(e) => AutoCompleteChange(e, index)} 
                            style={{ width: '100%' }} 
                            placeholder='Type Item Name to search..' 
                        />
                 {/* <select name="ItemId" className="" value={Item.ItemId} onChange={event => handleL2InputChange(index, event)} >
                     <option value="">Select Item</option>
                  {Items.map((item) => (<option key={item.id} value={item.id}>{item.name}</option>))} 
                 </select><br/> */}
                    {Item.SpecialRemarks}
                    </>
                    : <span style={{fontSize: "12px", color: "red"}}>Please select "Transfer From" Field.</span>}
                 </td>
                 <td>
                    {Item.HSNCode}
                 </td>
                 <td>
                 <input type="text"  name="Qty" className="" id="Qty" value={Item.Qty} onChange={event => handleL2InputChange(index, event)} placeholder="Qty"/>
                 &nbsp;{Item.UnitName} Available :  {Item.AvailableQty} {Item.UnitName}
                 </td>
                 <td>
                 <input type="text"  name="RemarksIfany" className="" id="RemarksIfany" value={Item.RemarksIfany} onChange={event => handleL2InputChange(index, event)} placeholder="Remarks"/>
                    
                 </td>
                 
                 <td>
                     <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index)}></i>
                     <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
                 </td>
             </tr>

         ))}
     

         {/* <tr>
             <td colSpan={3}>
                 <button className='btn btn-primary' onClick={() => AddMoreItem()}>Add</button>
             </td>
         </tr> */}
         </tbody>
         </table>

         <div className="mb-3">
             <label htmlFor="TermsandConditions" className="form-label">Terms and Conditions<span className='color-red'>*</span></label>
             <Editor value={state.TermsandConditions} onTextChange={handleTndCChange} style={{ height: '150px' }} />
         </div>
         <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="SigningAuthority" className="form-label">SignIn Authority Name<span className='color-red'>*</span></label>
                 <input type="text" required name="SigningAuthority" className="form-control" id="Subject" value={state.SigningAuthority} onChange={handleInputChange} placeholder="Enter Signin Authority Name"/>
             </div>
         </div>
         
         

         <div className='mt-4'>
             <button type="submit" className="btn btn-primary">Submit</button>
         </div>
     </form>
     <ConfirmDialog group="declarative"  visible={ConfirmVisible} onHide={() => setConfirmVisible(false)} message="This will reset the items in list. Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
 </div>
 <span>{Message}</span>
        {/* <Dialog header="Add New Item" visible={visible} style={{ width: '70vw' }} onHide={() => setVisible(false)}>
         <AddSTOItems MyItems = {Items} MyTaxes= {Taxes} AddmoreItems= {AddmoreItems}/>
        </Dialog> */}
 </>

  )
}

export default AddStockTransferOrder
