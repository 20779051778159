import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import DetailSTO from '../../Blocks/DetailSTO'

const STODetails = () => {
    
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
           <DetailSTO/>
     </div>
   </>
  )
}

export default STODetails