import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import Importdatablock from '../../Blocks/Importdatablock'

const Bulkupload = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
        <Importdatablock/>
     </div>
   </>
  )
}

export default Bulkupload