import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddNewBankAccount = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [state, setState] = useState({
        CompanyId : Company.id,
        AccountName : "",
        BankName : "",
        AccountNumber : "",
        IFSCode : "",
        SwiftCode : "",
        Branch : "",
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetch(process.env.REACT_APP_API_URL + 'setup/addnewbankaccount', {
         method: 'POST',
         body: JSON.stringify(state),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + tokenString
          },
        })
        .then((res) => 
        { if(res.status === 401){ navigate("/login"); }        
        return res.json()})
        .then((data) => {
            setMessage("Account Added Successfully")
        })
        .catch((err) => {
            console.log(err.message);
        });
    }
  return (
    <div>
       {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible(false)}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label for="AccountName" className="form-label">Account Name<span className='color-red'>*</span></label>
                <input type="text" required name="AccountName" className="form-control" id="AccountName" value={state.AccountName} onChange={handleInputChange} placeholder="Enter account name"/>
            </div>
            <div className="mb-3">
                <label for="BankName" className="form-label">Bank Name<span className='color-red'>*</span></label>
                <input type="text" required name="BankName" className="form-control" id="BankName" value={state.BankName} onChange={handleInputChange} placeholder="Enter bank name"/>
            </div>
            <div className="mb-3">
                <label for="AccountNumber" className="form-label">Account Number<span className='color-red'>*</span></label>
                <input type="number" required name="AccountNumber" className="form-control" id="AccountNumber" value={state.AccountNumber} onChange={handleInputChange} placeholder="Enter account number"/>
            </div>
            <div className="mb-3">
                <label for="Branch" className="form-label">Branch<span className='color-red'>*</span></label>
                <input type="text" required name="Branch" className="form-control" id="Branch" value={state.Branch} onChange={handleInputChange} placeholder="Enter branch name"/>
            </div>
            <div className="mb-3">
                <label for="IFSCode" className="form-label">IFSC<span className='color-red'>*</span></label>
                <input type="text" required name="IFSCode" className="form-control" id="IFSCode" value={state.IFSCode} onChange={handleInputChange} placeholder="Enter IFSC"/>
            </div>
            <div className="mb-3">
                <label for="SwiftCode" className="form-label">SWIFT<span className='color-red'>*</span></label>
                <input type="text" required name="SwiftCode" className="form-control" id="SwiftCode" value={state.SwiftCode} onChange={handleInputChange} placeholder="Enter SWIFT"/>
            </div>
           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </div>
  )
}

export default AddNewBankAccount
