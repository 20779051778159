import moment from 'moment';
export const GetAPICallAsync = async (url, Lazydata, Params) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    console.log(Params.MyFilters);
    function GetUrl(Myurl) {
        switch (Myurl){
            case "GetRFQs" : return process.env.REACT_APP_API_URL + 'inventory/ListRFQs?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows }) ;
            case "GetPOs" : return process.env.REACT_APP_API_URL + 'inventory/ListPurchaseOrder?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder,
                                                                                                                        PONo : Params.Filters.PONO, DocumentName : Params.Filters.DocumentName, PODateFrom : Params.Filters.PODate ?  moment(Params.Filters.PODate[0]).format("DD-MM-YYYY"): "", PODateTo : Params.Filters.PODate ?  moment(Params.Filters.PODate[1]).format("DD-MM-YYYY"): "", SupplierId: Params.Filters.Supplier }) ;
            case "GetStock" : return process.env.REACT_APP_API_URL + 'inventory/GetAllStockInfo?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters: Params.Filters }) ;
            case "GetOrderReceivings" : return process.env.REACT_APP_API_URL + 'inventory/ListOrderReceiving?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filter: Params.MyFilters}) ;
            case "GetListAPInvoices" : return process.env.REACT_APP_API_URL + 'inventory/ListAPInvoices?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters: Params.MyFilters }) ;
            case "GetListSuppliers" : return process.env.REACT_APP_API_URL + 'setup/ListSuppliers?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, supplierName : Params.Filters.SupplierName, BusinessType : Params.Filters.BusinessType, CPerson : Params.Filters.ContactPerson,
                                                                                                                             Designation : Params.Filters.Designation, phone : Params.Filters.Phone, Email : Params.Filters.Email, CountryId : Params.Filters.CountryId, StateId : Params.Filters.StateId, CityId : Params.Filters.CityId}) ;
            case "GetListDealers" : return process.env.REACT_APP_API_URL + 'inventory/Listdealers?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters: Params.MyFilters }) ;
            case "GetListCurrencies" : return process.env.REACT_APP_API_URL + 'setup/ListCurrencies?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows }) ;
            case "GetListSuppliersDD" : return process.env.REACT_APP_API_URL + 'setup/ListSuppliers?' + new URLSearchParams({CompanyId : Company.id, Offset: 0, Limit: 99999 }) ;
            case "GetListItemCategories" : return process.env.REACT_APP_API_URL + 'setup/ListItemCategories?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows
                                                                                                                ,SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, PCatId: Params.PCatID , FirstNodeOnly: true }) ;
            case "GetListItemallCategories" : return process.env.REACT_APP_API_URL + 'setup/ListItemCategories?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows
                                                                                                                    ,SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, PCatId: "", FirstNodeOnly: Params.MYFirstNodeOnly }) ;
            case "GetListTaxes" : return process.env.REACT_APP_API_URL + 'setup/ListTaxes?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows }) ;
            case "GetListCompanyWarehouses" : return process.env.REACT_APP_API_URL + 'setup/CompanyWarehouses?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows
                                                                                                                    ,SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder }) ;
            case "GetListUnits" : return process.env.REACT_APP_API_URL + 'setup/ListUnits?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows
                                                                                                                    ,SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder }) ;
            case "GetWHbyId" : return process.env.REACT_APP_API_URL + 'setup/GetWHbyId?' + new URLSearchParams({Id: Params.Id }) ;
            case "GetUnitbyId" : return process.env.REACT_APP_API_URL + 'setup/GetUnitbyId?' + new URLSearchParams({Id: Params.ID }) ;
            case "GetTaxbyId" : return process.env.REACT_APP_API_URL + 'setup/GetTaxbyId?' + new URLSearchParams({Id: Params.ID }) ;
            case "GetCurrencybyId" : return process.env.REACT_APP_API_URL + 'setup/GetCurrencybyId?' + new URLSearchParams({Id: Params.ID }) ;
            case "GetSupplierbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetSupplierbyId?' + new URLSearchParams({Id: Params.ID }) ;
            case "GetDealerbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetDealerbyId?' + new URLSearchParams({Id: Params.ID }) ;
            case "GetItemCatbyId" : return process.env.REACT_APP_API_URL + 'setup/GetItemCatbyId?' + new URLSearchParams({Id: Params.ID }) ;
            case "GetPobyno" : return process.env.REACT_APP_API_URL + 'inventory/GetPObyNo?' + new URLSearchParams({CompanyId : Company.id, PONo: Params.PONo }) ;
            case "GetPurchaseOrderById" : return process.env.REACT_APP_API_URL + 'inventory/GetPurchaseOrderById?' + new URLSearchParams({CompanyId : Company.id, POId: Params.POId }) ;
            case "GetSalesOrderbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetSalesOrderbyId?' + new URLSearchParams({CompanyId : Company.id, SOId: Params.SOID }) ;
            case "GetOrderReceivingById" : return process.env.REACT_APP_API_URL + 'inventory/GetOrderReceivingById?' + new URLSearchParams({CompanyId : Company.id, ORId: Params.ORId }) ;
            case "GetPowithPendingItemsbyno" : return process.env.REACT_APP_API_URL + 'inventory/GetPOwithpendingItemsbyNo?' + new URLSearchParams({CompanyId : Company.id, PONo: Params.PONo }) ;
            case "GetSobyno" : return process.env.REACT_APP_API_URL + 'inventory/GetSObyNo?' + new URLSearchParams({CompanyId : Company.id, SONo: Params.SONo }) ;
            case "GetSrNosVerify" : return process.env.REACT_APP_API_URL + 'inventory/GetSrNosVerify?' + new URLSearchParams({CompanyId : Company.id, SrNos: Params.SrNos, ItemId: Params.ItemID}) ;
            case "GetInventorybyItemIds" : return process.env.REACT_APP_API_URL + 'inventory/GetInventorybyItemIds?' + new URLSearchParams({ItemIds : Params.TheItemIds }) ;
            case "GetORbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetORbyId?' + new URLSearchParams({ORID : Params.ORId }) ;
            case "GetListProfiles" : return process.env.REACT_APP_API_URL + 'setup/ListProfiles?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder }) ;
            case "GetListStockTransferOrders" : return process.env.REACT_APP_API_URL + 'inventory/ListStockTransferOrders?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters : Params.MyFilters }) ;
            case "GetListOutTransferRequests" : return process.env.REACT_APP_API_URL + 'inventory/TransferRequestsbyFromWHId?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, WHID : Params.WHId, Filters : Params.Filters }) ;
            case "GetListInTransferRequests" : return process.env.REACT_APP_API_URL + 'inventory/TransferRequestsbyToWHId?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, WHID : Params.WHId, Filters : Params.Filters }) ;
            case "ListCompanywiseAllUsers" : return process.env.REACT_APP_API_URL + 'setup/ListCompanywiseAllUsers?' + new URLSearchParams({CompanyId : Company.id }) ;
            case "GetUserbyEmailId" : return process.env.REACT_APP_API_URL + 'setup/GetUserbyEmailId?' + new URLSearchParams({CompanyId: Company.id, EmailId : Params.Email }) ;
            case "GetCompanyUserById" : return process.env.REACT_APP_API_URL + 'setup/GetCompanyUserById?' + new URLSearchParams({CompanyId: Company.id, Id : Params.Id }) ;
            case "GetMyWarehouses" : return process.env.REACT_APP_API_URL + 'inventory/ListMyWarehouses?' + new URLSearchParams({CompanyId: Company.id});
            case "GetSTObyId" : return process.env.REACT_APP_API_URL + 'inventory/GetSTObyId?' + new URLSearchParams({Id : Params.Id }) ;
            case "GetStockIssueReqbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetStockIssueReqbyId?' + new URLSearchParams({Id : Params.Id }) ;
            case "GetStockSubmitReqbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetStockSubmitReqbyId?' + new URLSearchParams({Id : Params.Id }) ;
            case "GetStockAdjReqbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetStockAdjReqbyId?' + new URLSearchParams({Id : Params.Id }) ;
            case "GetProdOrderbyId" : return process.env.REACT_APP_API_URL + 'inventory/GetProdOrderbyId?' + new URLSearchParams({Id : Params.Id }) ;
            case "VerifySrNo" : return process.env.REACT_APP_API_URL + 'inventory/VerifySrNo?' + new URLSearchParams({STOItemId: Params.STOIID, SrNos : Params.SrNOS, WHID: Params.WHId, ItemId : Params.ItemID }) ;
            case "GetBatchDetails" : return process.env.REACT_APP_API_URL + 'inventory/GetBatchByBatchNo?' + new URLSearchParams({CompanyId: Company.id, BatchNO : Params.MyBatchNo, ItemId : Params.ItemID }) ;
            case "FindItem" : return process.env.REACT_APP_API_URL + 'inventory/FindItem?' + new URLSearchParams({CompanyId: Company.id, Req : Params.MyReq, Parameter: Params.Parameter }) ;
            case "GetListSO" : return process.env.REACT_APP_API_URL + 'inventory/ListSalesOrders?' + new URLSearchParams({CompanyId: Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters : Params.Filters }) ;
            case "GetListARInvoices" : return process.env.REACT_APP_API_URL + 'inventory/ListARInvoices?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, }) ;
            case "GetDealerAddressesById" : return process.env.REACT_APP_API_URL + 'setup/GetDealerAddressesById?' + new URLSearchParams({CompanyId : Company.id, DealerId: Params.MyDealerId }) ;
            case "GetDynamicFields" : return process.env.REACT_APP_API_URL + 'setup/Listdynamicfields?' + new URLSearchParams({CompanyId : Company.id, form: Params.FormName }) ;
            case "GetItemDDOptions" : return process.env.REACT_APP_API_URL + 'setup/GetItemMasterForm?' + new URLSearchParams({CompanyId : Company.id}) ;
            case "GetListItems" : return process.env.REACT_APP_API_URL + 'setup/ListItems?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder,});
            case "GetListProductionOrders" : return process.env.REACT_APP_API_URL + 'inventory/ListProductionOrders?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder,});
            case "GetListBOMs" : return process.env.REACT_APP_API_URL + 'inventory/ListBOMs?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder,});
            case "ListStockIssueRequests" : return process.env.REACT_APP_API_URL + 'inventory/ListStockIssueRequests?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters : Params.MyFilters });
            case "ListStockIssueRequestsByUser" : return process.env.REACT_APP_API_URL + 'inventory/ListStockIssueRequestsByUser?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters : Params.MyFilters });
            case "ListSubmitStockRequests" : return process.env.REACT_APP_API_URL + 'inventory/ListSubmitStockRequests?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters : Params.MyFilters});
            case "ListSubmitStockRequestsByUser" : return process.env.REACT_APP_API_URL + 'inventory/ListSubmitStockRequestsByUser?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters : Params.MyFilters});
            case "ListStockAdjustmentRequests" : return process.env.REACT_APP_API_URL + 'inventory/ListStockAdjustmentRequests?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder,Filters : Params.MyFilters});
            case "ListStockAdjustmentRequestsByUser" : return process.env.REACT_APP_API_URL + 'inventory/ListStockAdjustmentRequestsByUser?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder,Filters : Params.MyFilters});
            case "ListApprovalRequests" : return process.env.REACT_APP_API_URL + 'inventory/ListApprovalRequests?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder,});
            case "ListPemissionsbyProfileId" : return process.env.REACT_APP_API_URL + 'setup/ListPemissionsbyProfileId?' + new URLSearchParams({CompanyId : Company.id, ProfileId: Params.MyProfileId });
            case "ListAllPermissions" : return process.env.REACT_APP_API_URL + 'setup/ListAllPermissions';
            case "GetPermissionbyCompanyId" : return process.env.REACT_APP_API_URL + 'setup/GetPermissionbyUserIdandCompanyId?' + new URLSearchParams({CompanyId : Company.id });
            case "GetCompanyAndPartyAddress" : return process.env.REACT_APP_API_URL + 'setup/GetCompanyAndPartyAddress?' + new URLSearchParams({CompanyId : Company.id, PartyId: Params.MyPartyId, PartyType: Params.MyPartyType });
            case "GetAPInvoicebyId" : return process.env.REACT_APP_API_URL + 'inventory/GetAPInvoicebyId?' + new URLSearchParams({CompanyId : Company.id, APInvoiceId : Params.Id }) ;
            case "GetListCategories" : return process.env.REACT_APP_API_URL + 'setup/GetItemMasterForm?' + new URLSearchParams({CompanyId : Company.id, LazyData: Lazydata }) ;
            case "GetApprovalSetupLevels" : return process.env.REACT_APP_API_URL + 'setup/GetApprovalSetupLevels?' + new URLSearchParams({CompanyId : Company.id, ProcessName: Params.PName }) ;
            case "GetApprovalSetupLevelbyId" : return process.env.REACT_APP_API_URL + 'setup/GetApprovalSetupLevelbyId?' + new URLSearchParams({CompanyId : Company.id, Id: Params.Id }) ;
            case "GetCompanybyId" : return process.env.REACT_APP_API_URL + 'setup/GetCompanybyId?' + new URLSearchParams({CompanyId : Params.CompanyId }) ;
            case "ListCompanywiseAllUserInvites" : return process.env.REACT_APP_API_URL + 'setup/ListCompanywiseAllUserInvites?' + new URLSearchParams({CompanyId : Company.id }) ;
            case "InviteCheck" : return process.env.REACT_APP_API_URL + 'auth/InviteCheck?' + new URLSearchParams({InviteToken : Params.MyInviteToken }) ;
            //Reports
            case "AccumulatedandMonthlySales" : return process.env.REACT_APP_API_URL + 'reports/AccumulatedandMonthlySales?' + new URLSearchParams({CompanyId : Company.id, Filters: Params.MyFilters }) ;
            case "AccumulatedandMonthlyPurchase" : return process.env.REACT_APP_API_URL + 'reports/AccumulatedandMonthlyPurchase?' + new URLSearchParams({CompanyId : Company.id, Filters: Params.MyFilters }) ;
            case "StockValuation" : return process.env.REACT_APP_API_URL + 'reports/StockValuation?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters: Params.MyFilters }) ;
            case "PurchaseRegister" : return process.env.REACT_APP_API_URL + 'reports/PurchaseRegister?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters: Params.MyFilters }) ;
            case "ItemisedPurchaseRegister" : return process.env.REACT_APP_API_URL + 'reports/ItemisedPurchaseRegister?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters: Params.MyFilters }) ;
            case "PurchaseSnapshot" : return process.env.REACT_APP_API_URL + 'reports/PurchaseSnapshot?' + new URLSearchParams({CompanyId : Company.id, Offset: Lazydata.lazyEvent.first, Limit: Lazydata.lazyEvent.rows, SortBy: Lazydata.lazyEvent.sortField, SortOrder: Lazydata.lazyEvent.sortOrder, Filters: Params.MyFilters }) ;
        
        
        }
    }
    
    var CallUrl = GetUrl (url);
    return await fetch(CallUrl,
    {
        method: 'Get',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + tokenString.toString()
        },
    })
    .then(res => {
        return res;

    })
}