import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import DynamicFormList from '../../Blocks/DynamicFormList'

const DynamicForms = () => {
  return (
    <>
        <InventoryHeader />
        <div className='container'>
            <DynamicFormList />
        </div>
      
    </>
  )
}

export default DynamicForms
