import React, { useEffect, useState } from 'react'


const WarehouseBInfo = (props) => {

  
  return (
    <div>{props.WH.Name}</div>
  )
}

export default WarehouseBInfo