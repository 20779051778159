import React from 'react'
import ListTaxes from '../../Blocks/ListTaxes'
import InventoryHeader from '../../Blocks/InventoryHeader'

const Taxes = () => {
  return (
    <>
        <InventoryHeader />
        <div className='p-3'>
            <ListTaxes QuckSetupMode={false}/>
        </div>
    </>
  )
}

export default Taxes
