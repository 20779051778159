import React, { useRef } from 'react'
import Logoimage from './logoimage'
import {Link, useNavigate} from 'react-router-dom'
import PermissionGrp from '../StaticHelpers/PermissionGroups'
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';

const InvTopNav = () => {
    const navigate = useNavigate();
    const UserObj = JSON.parse(localStorage.getItem('User_Obj'));
    const CompanyObj = JSON.parse(localStorage.getItem('Company'));
    const PermissionGroup = JSON.parse(localStorage.getItem('gsxkjm'));
    const CompanyName = CompanyObj && CompanyObj.companyName.split(" ")[0];

    const menuRight = useRef(null);
    const menuComp= useRef(null);
    const items = [
        {
            template: () => {
                return (
                <div className='UserName'>
                <span>{UserObj.yourName}</span>
                </div>
                )
            }
        },
        {
            label: 'My Companies',
            icon: 'pi pi-user',
            url: "/setup/companies" 
        },
        {
            label: 'Profile',
            icon: 'pi pi-user',
            url: "/setup/companies" 
        },
        {
            label: 'Billing',
            icon: 'pi pi-dollar',
            url: "/admin/billingdashboard"
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => { Logout() }
        },
    ];
    const items1 = [
        {
            label: 'Change Company',
            url: "/setup/companies" 
        },
    ];

    const Logout = () => {
        localStorage.removeItem('User_Obj');
        localStorage.removeItem('token');
        localStorage.removeItem('Company');
        navigate("/login");
    }
  return (
    <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{borderBottom: "1px solid #e7e7e7"}}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/"><Logoimage height="25px"/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link" to="/inventory/dashboard"><i className="pi pi-home"></i> Dashboard</Link>
                    </li>
                    <li className="nav-item dropdown">
                        {PermissionGroup.includes(PermissionGrp.PURSHASE) && <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                         <i className="pi pi-shopping-cart"></i>  Purchase
                        </Link>}
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {/* <li><Link className="dropdown-item" to="/purchase/rfqs">RFQ</Link></li>
                            <li><Link className="dropdown-item" to="/purchase/rfqs">Quotes</Link></li> */}
                            <li><Link className="dropdown-item" to="/purchase/Suppliers">Suppliers</Link></li>
                            <li><Link className="dropdown-item" to="/purchase/purchaseorders">Purchase Orders</Link></li>
                            <li><Link className="dropdown-item" to="/purchase/receivings">Receiving</Link></li>
                            <li><Link className="dropdown-item" to="/purchase/apinvoices">AP Invoices</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                    {PermissionGroup.includes(PermissionGrp.INVENTORY) &&<Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="pi pi-box"></i> Inventory
                        </Link>}
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/inventory/stock">Stock</Link></li>
                            <li><Link className="dropdown-item" to="/inventory/stocktransferorders">Stock Transfer Orders</Link></li>
                            <li><Link className="dropdown-item" to="/inventory/issuerequests">Issue Stock</Link></li>
                            <li><Link className="dropdown-item" to="/inventory/submitstockrequest">Submit Stock</Link></li>
                            <li><Link className="dropdown-item" to="/inventory/stockadjustments">Stock Adjustments</Link></li>
                            {/* <li><Link className="dropdown-item" to="/inventory/itemstoreorder">Items to Reorder </Link></li> */}
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                    {PermissionGroup.includes(PermissionGrp.WAREHOUSE) && <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="pi pi-warehouse"></i> Warehouse
                        </Link>}
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/inventory/finditem">Find Item</Link></li>
                            <li><Link className="dropdown-item" to="/inventory/stock">My Warehouse</Link></li>
                            <li><Link className="dropdown-item" to="/warehouse/transferrequests">Transfer Requests</Link></li>
                            <li><Link className="dropdown-item" to="/warehouse/issuerequests">Stock Issue Requests</Link></li>
                            <li><Link className="dropdown-item" to="/warehouse/submitstockrequest">Submit Stock Request</Link></li>
                            <li><Link className="dropdown-item" to="/warehouse/stockadjustments">Stock Adjustments Request</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                    {PermissionGroup.includes(PermissionGrp.PRODUCTION) && <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="pi pi-building-columns"></i> Production
                        </Link>}
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/inventory/stock">Processes</Link></li>
                            <li><Link className="dropdown-item" to="/production/bom">BOM</Link></li>
                            <li><Link className="dropdown-item" to="/production/productionorder">Production Orders</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                    {PermissionGroup.includes(PermissionGrp.SALES) && <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="pi pi-dollar"></i>  Sales
                        </Link>}
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/sales/dealers">Dealers</Link></li>
                            <li><Link className="dropdown-item" to="/sales/salesorders">Sales Orders</Link></li>
                            <li><Link className="dropdown-item" to="/sales/arinvoices">AR Invoices</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/reports/snapshot"><i className="pi pi-chart-bar"></i> Reports</Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="pi pi-chart-bar"></i> Reports
                        </Link>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/inventory/stock">Reordered Itemss</Link></li>
                            <li><Link className="dropdown-item" to="/purchase/rfqs">BOM</Link></li>
                            <li><Link className="dropdown-item" to="/production/productionorder">Production Orders</Link></li>
                        </ul>
                    </li> */}
                    
                
                    <li className="nav-item dropdown">
                    {PermissionGroup.includes(PermissionGrp.SETUP) && <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="pi pi-cog"></i>  Setup
                        </Link>}
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {/* <li><Link className="dropdown-item" to="/setup/bankaccounts">Bank Accounts</Link></li> */}
                            <li><Link className="dropdown-item" to="/setup/quicksetup"><i className="pi pi-bolt"></i> Quick Setup</Link></li>
                            <li><Link className="dropdown-item" to="/setup/dynamicforms"><i className="pi pi-bolt"></i> Dynamic Fields</Link></li>
                            <li><Link className="dropdown-item" to="/setup/warehouses"> <i className="pi pi-building"></i>   Warehouses</Link></li>
                            <li><Link className="dropdown-item" to="/Setup/itemcategory"> <i className="pi pi-sitemap"></i>  Item Categories</Link></li>
                            <li><Link className="dropdown-item" to="/setup/itemunits"> <i className="pi pi-ticket"></i> Item Units</Link></li>
                            <li><Link className="dropdown-item" to="/setup/itemmaster"><i className="pi pi-box"></i> Item Master</Link></li>
                            <li><Link className="dropdown-item" to="/setup/taxes"><i className="pi pi-money-bill"></i> Taxes</Link></li>
                            <li><Link className="dropdown-item" to="/setup/currencies"><i className="pi pi-money-bill"></i> Currencies</Link></li>
                            <li><Link className="dropdown-item" to="/setup/users"><i className="pi pi-user"></i> Users</Link></li>
                            <li><Link className="dropdown-item" to="/setup/profiles"><i className="pi pi-wrench"></i> Profiles</Link></li>
                            <li><Link className="dropdown-item" to="/setup/approvalprocesses"><i className="pi pi-wrench"></i> Approvals</Link></li>
                            <li><Link className="dropdown-item" to="/setup/bulkupload"><i className="pi pi-upload"></i> Import Data</Link></li>
                        </ul>
                    </li>  
                    <li className="nav-item dropdown">
                    {PermissionGroup.includes(PermissionGrp.SETUP) && <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="pi pi-bars"></i>  More
                        </Link>}
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {/* <li><Link className="dropdown-item" to="/setup/bankaccounts">Bank Accounts</Link></li> */}
                            <li><Link className="dropdown-item" to="/others/approvalrequests"><i className="pi pi-file-check"></i> Approvals</Link></li>
                            
                        </ul>
                    </li> 
                </ul>
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0 g-0">
                   {CompanyName && <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" onClick={(event) => menuComp.current.toggle(event)}>
                            {CompanyName}
                        </Link>
                        <Menu model={items1} popup ref={menuComp} id="popup_menu_right" popupAlignment="right" />
                        {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/setup/companies">Change Company</Link></li>
                        </ul> */}
                    </li> }
                    { UserObj && <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button"  onClick={(event) => menuRight.current.toggle(event)}>
                        
                        <Avatar label={UserObj.yourName.substring(0,1)} style={{ backgroundColor: '#2196F3', color: '#ffffff' }}  shape="circle" />
                        </Link>
                        <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                        {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to="/setup/companies">Profile</Link></li>
                            <li><Link className="dropdown-item" to="/setup/companies">Settings</Link></li>
                            <li><Link className="dropdown-item" to="/admin/billingdashboard">Billing Dashboard</Link></li>
                            <li><button className="dropdown-item" onClick={Logout} >Logout</button></li>
                        </ul> */}
                    </li> }
     
                </ul>
                {/* <form class="d-flex">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                    <button class="btn btn-outline-success" type="submit">Search</button>
                </form> */}
                </div>
            </div>
        </nav>
    </>
  )
}

export default InvTopNav
