import React from 'react'
import QuickSetupBlock from '../../Blocks/QuickSetupBlock'

const QuickSetupPage = () => {
  return (
    <>
    <div className='p-3'>
        <div className='row mb-3'>
            <div className='col-12 text-center'>
            <h1 className='ListMainHeading'><span className='LogoText' style={{fontSize: "20px"}}>AQLOB </span> (Quick Setup)</h1>
            </div>
        </div>
    <QuickSetupBlock/>
     </div>
   </>
  )
}

export default QuickSetupPage