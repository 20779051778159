import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const AddNewApprovalLevelBlock = (props) => {
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [totalRecords, setTotalRecords] = useState(0);
    const [CompanyUsers, setCompanyUsers] = useState([]);
    const [ErrorMessage, setErrorMessage] = useState([])
    const navigate = useNavigate();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
    });

    const [state, setState] = useState({
        CompanyId: Company.id,
        ProcessName: props.ProcessName,
        Level: props.Level,
        AnyOne: true,
        LevelUsers: [],
    });

    const GetAllUsers = async() => {
        var MyRes = await GetAPICallAsync("ListCompanywiseAllUsers", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            let Data = MyData.data.map((item) =>(
                {...item, "is_Checked" : false}
             ) )
            setTotalRecords(MyData.total);
            setCompanyUsers(Data);
        }
    }

    const handleInputChange = (value) => {
        setState((prevProps) => ({
            ...prevProps,
            AnyOne : value
        }))
    }

    const handleUserSelect = (e, index) => {
        let Myvalues = {...state}
        let MyUsers = [...CompanyUsers]
        let MyAllUserValue = MyUsers[index];
        if(CompanyUsers[index]["is_Checked"]){
            let MyLevelUsers = [...Myvalues.LevelUsers]
            MyLevelUsers = MyLevelUsers.filter(x => x.UserId !== MyAllUserValue.userId);
            Myvalues.LevelUsers = MyLevelUsers;
            setState(Myvalues);
        }
        else{
            let MyLevelUsers = [...Myvalues.LevelUsers, {"UserId": e.target.value}]
            Myvalues.LevelUsers = MyLevelUsers;
            setState(Myvalues);
      
        }
        MyUsers[index]["is_Checked"] = !MyUsers[index]["is_Checked"]
        console.log(MyUsers);
        setCompanyUsers(MyUsers);
        console.log(state);

    }

    const GetProcessName = () => {
        switch (state.ProcessName)
        {
            case "purchaseorder" : return <span>Purchase Order</span>
            case "salesorder" : return <span>Sales Order</span>
            case "apinvoice" : return <span>AP Invoice</span>
            case "arinvoice" : return <span>AR Invoice</span>

        }
    }

    useEffect(()=> {
        GetAllUsers();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        if(state.LevelUsers.length === 0 ){
            setErrorMessage((prevProps) => [...prevProps, "Please select atleast one approver."])
            return;
        }
        console.log(state);
        let MyRes = await  POSTAPICallAsync("AddApprovalProcessLevel", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success == true ){
                props.setVisible({open: false})
            }
            else{
                setErrorMessage(MyData.message);
            }
            
        }
    }

  return (
    <>
        {ErrorMessage.length > 0 ? 
            <ul>
                {ErrorMessage.map((item, index) => (
                    <li key={index} className='Error'>{item}</li>
                ))}
            </ul>
        : ""}
        <div className='row'>
            <div className='col'>
                <span><strong>Process Name:</strong></span> <span>{GetProcessName()}</span>
            </div>
            <div className='col'>
                <span><strong>Level:</strong></span> <span>{state.Level}</span>
            </div>
        </div>
        <div className="mt-3">

            <span>Please select the approval criteria.</span>
          
                <div className="form-check form-check-inline ms-3">
                    <input className="form-check-input" inputId="ingredient1" name="AnyOne" type="radio" value={true} onChange={() => handleInputChange(true)} checked={state.AnyOne === true} />
                    <label htmlFor="ingredient1" className="form-check-label"> Any one</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" inputId="ingredient2" name="AnyOne" type="radio" value={false} onChange={() => handleInputChange(false)} checked={state.AnyOne === false} />
                    <label htmlFor="ingredient2" className="form-check-label"> All</label>
                </div>
            
        </div>
        <div className='row mt-3'>
            <h5>Select Approvers to Level {state.Level}</h5>
            <div className='col-12 p-3'>
                {CompanyUsers.map((item, index) => (
                    
                    <div className="form-check form-check-inline">
                        <input required type="checkbox" name="UserId" value={item.user.id} is_Checked={item.is_Checked} className="form-check-input" onChange={event => handleUserSelect(event, index)} id={index}/>
                        <label className="form-check-label" for={index}>{item.user.name} ({item.user.userName})</label>
                    </div>
                ))}
            </div>
            <div className='col-12'>
                <button type='submit' className='btn btn-primary' onClick={handleSubmit}>Submit</button>
            </div>
         
        </div>

    </>
  )
}

export default AddNewApprovalLevelBlock