import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import WHStockAdjustmentRequestBlock from '../../Blocks/WHStockAdjustmentRequestBlock'

const StockAdjustmentRequests = () => {
  return (
    <>
    <InventoryHeader />
        <div className='p-3'>
            <WHStockAdjustmentRequestBlock />
        </div>
   </>
  )
}

export default StockAdjustmentRequests