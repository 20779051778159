import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import SAdjReqDetailsBlock from '../../Blocks/SAdjReqDetailsBlock'

const SAdjReqDetailPage = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
        <SAdjReqDetailsBlock/>
    </div>
</>
  )
}

export default SAdjReqDetailPage