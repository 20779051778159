import React from 'react'
import TopNavigation from '../../Components/TopNavigation'
import EditCompanyFormBlock from '../../Blocks/EditCompanyFormBlock'

const EditCompanyPage = () => {
  return (
    <>
     <TopNavigation/>
        <div className=''>
            <div className='col-12 p-3'>
                <h1 className='ListMainHeading'>Edit Company</h1>
            </div>
            <div className='col-12 p-3'>
                <EditCompanyFormBlock />
            </div>
        </div> 
    </>
  )
}

export default EditCompanyPage