import React from 'react'
import InvTopNav from '../Components/InvTopNav'

const InventoryHeader = () => {
  return (
    <div>
        <InvTopNav />
    </div>
  )
}

export default InventoryHeader
