import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import { Dialog } from 'primereact/dialog';
import AddPOItems from './AddPOItems';

const AddSalesOrder = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [Items, setItems] = useState([]);
    const [Dealers, setDealers] = useState([]); 
    const [Currencies, setCurrencies] = useState([]); 
    const [Taxes, setTaxes] = useState([]); 
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const [visible, setVisible] = useState(false);
    const [TotalRate, setTotalRate] = useState(0);
    const [TotalTax, setTotalTax] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [TotalDiscount, setTotalDiscount] = useState(0);
    const [Index, setIndex] = useState(0);
    const navigate = useNavigate();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
    });
    const [Filters, setFilters] = useState({
        DealerName: "",
        BusinessType: "",
        ContactPerson: "",
        Designation: "",
        Phone: "",
        Email: "",
        CountryId: 0,
        StateId: 0,
        CityId: 0,
    });
    const [state, setState] = useState ({
        SOName: "",
        SONo : "",
        SODate : new Date(),
        TimeZone: "",
        CompanyId : Company.id,
        DealerId: "",
        CurrencyId: "",
        Heading : "To Whomsoever it may concern",
        Subject : "Sales Order",
        WelcomeText : "",
        TermsandConditions : "",
        SigningAuthority : "",
        SalesOrderItemRequest : []

    });

    const getTimeZone = () => {
                        
        const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setState((prevProps) => ({
            ...prevProps,
            "TimeZone": MytimeZone
          }));

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(value);
        console.log(event.target)
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
    const handleWlcomeEditorChange = (event) => {
        setState((prevProps) => ({
          ...prevProps,
          "WelcomeText": event.htmlValue,
        }));
      };
    const handleTndCChange = (event) => {
        setState((prevProps) => ({
          ...prevProps,
          "TermsandConditions": event.htmlValue,
        }));
      };

      const AddmoreItems = (MyObj) => {
        const values = [...state.SalesOrderItemRequest, ]
        console.log(MyObj);
        MyObj.Index = Index;
        values.splice(Index, 0, MyObj )
        setState((prevProps) => ({
            ...prevProps,
            SalesOrderItemRequest: values
          }));

          let MyTotalRate = TotalRate + (MyObj.Qty * MyObj.UnitPrice);
          setTotalRate(MyTotalRate);
          let TotalAmt = 0;
            let AMtWOD = 0;
            let Discount = 0;
            let TTotal = 0;
            MyObj.ItemTaxRequest.map((MyItem) => {
                TTotal += MyItem.TaxAmount;
            })
            AMtWOD = MyObj.Qty * MyObj.UnitPrice;
            Discount = AMtWOD * MyObj.Discount /100;
            TotalAmt = AMtWOD - Discount + TTotal;
            let MyTotalAmount = TotalAmount + TotalAmt;
            let MyTaxes = TotalTax + TTotal;
            let MyDiscount = TotalDiscount + Discount;
            setTotalTax(MyTaxes);
            setTotalAmount(MyTotalAmount);
            setTotalDiscount(MyDiscount);
          setVisible(false);
      }

      const handleAddMoreItem = (index) => {
        console.log(index);
        index && setIndex (index + 1);
        setVisible(true);
        // const values = [...state.PurchaseOrderItems, ]
        // values.splice(index + 1, 0, {"ItemId": "", "SpecialRemarks": "", "Qty": "", "UnitId": "", "UnitName": "", "UnitPrice": "", "Index": "",} )
        // setState((prevProps) => ({
        //     ...prevProps,
        //     PurchaseOrderItems: values
        //   }));
      }

      const handleremoveitem = (index) => {
             
        const values = [...state.SalesOrderItemRequest]
        if(values.length > 1){
            values.splice(index, 1)
            setState((prevProps) => ({
                ...prevProps,
                SalesOrderItemRequest: values
              }));
        }
      }

      const GetDealersDD = async () => {
            var MyRes = await GetAPICallAsync("GetListDealers", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(Filters)});
            if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
            }
            else{
            var MyData = await MyRes.json();
            console.log(MyData.data);
            setDealers(MyData.data);
            }
        };

        const GetCurrencyDD = async () => {
            var MyRes = await GetAPICallAsync("GetListCurrencies", { lazyEvent: lazyState }, "");
            if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
            }
            else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setCurrencies(MyData.data);
            }
        };

        const GetTaxesDD = async () => {
            var MyRes = await GetAPICallAsync("GetListTaxes", {lazyEvent: lazyState} , 10);
            if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
            }
            else{
            var MyData = await MyRes.json();
            setTaxes(MyData.data);
            }
        };


        const GetTotalAmount = (Item) => {
            let TotalAmt = 0;
            let AMtWOD = 0;
            let Discount = 0;
            let TTotal = 0;
            Item.ItemTaxRequest.map((MyItem) => {
                TTotal += MyItem.TaxAmount;
            })
            AMtWOD = Item.Qty * Item.UnitPrice;
            Discount = AMtWOD * Item.Discount /100;
            TotalAmt = AMtWOD - Discount + TTotal;
            return TotalAmt;
        }
      
   
        const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/ListItemMasters?' + new URLSearchParams({
            CompanyId: Company && Company.id,
            }), {
            method: 'Get',
            headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + tokenString
            },
          })
          .then(res => {
              
              if(res.status === 401){ navigate("/login"); }
              return res.json()
          } ))
        .then(data => {
            setItems(data.data);
        })
        .catch((err) => {
        console.log(err.message);
        })

        useEffect (() => {   
            calltpAPI();
            GetDealersDD();
            GetTaxesDD();
            getTimeZone();
            GetCurrencyDD();
        }, []);
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        let MyRes = await  POSTAPICallAsync("CreateSO", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success === true ){
                navigate("/sales/salesorders");
                
            }
            else{
                setMessage(MyData.message);
            }
            
        }
        
        };
  return (
    <>
    
    
   <div className=''>
     <form onSubmit={handleSubmit}>

     <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="Heading" className="form-label">Document Name<span className='color-red'>*</span></label>
                 <input type="text" required name="SOName" className="form-control" id="SOName" value={state.SOName} onChange={handleInputChange} placeholder="Enter document name"/>
             </div>
         </div>
         <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="Heading" className="form-label">Heading<span className='color-red'>*</span></label>
                 <input type="text" required name="Heading" className="form-control" id="Heading" value={state.Heading} onChange={handleInputChange} placeholder="Enter company name"/>
             </div>
         </div>
         <div className='row'>
            {/* <div className="col-3 mb-3">
                <label htmlFor="PONo" className="form-label">PO No<span className='color-red'>*</span></label>
                <input type="text" required name="PONo" className="form-control" id="PONo" value={state.PONo} onChange={handleInputChange} placeholder="Enter company name"/>
            </div> */}
             <div className="col-3 mb-3">
                 <label htmlFor="SODate" className="form-label">SO Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="SODate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={state.SODate} onChange={handleInputChange} showButtonBar></Calendar>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="DealerId" className="form-label">Sold To<span className='color-red'>*</span></label>
                 
                 <select name="DealerId" className="form-select" value={state.DealerId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Dealers.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.dealerName}</option>))}
                </select>

             </div>
             <div className='col-3 mb-3'>
                <label htmlFor="CurrencyId" className="form-label">Currency<span className='color-red'>*</span></label>
                <select name="CurrencyId" className="form-select" value={state.CurrencyId} onChange={handleInputChange} >
                        <option value="">Select Item</option>
                    {Currencies.map((item) => (<option key={item.id} value={item.id}>{item.shortCode}</option>))} 
                </select>
            </div>

             

         </div>
         <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="Subject" className="form-label">Subject<span className='color-red'>*</span></label>
                 <input type="text" required name="Subject" className="form-control" id="Subject" value={state.Subject} onChange={handleInputChange} placeholder="Enter company name"/>
             </div>
         </div>
        
         
         <div className="mb-3">
             <label htmlFor="WelcomeText" className="form-label">Welcome Text<span className='color-red'>*</span></label>
             <Editor value={state.WelcomeText} onTextChange={handleWlcomeEditorChange} style={{ height: '100px' }} />
         </div>
         <table className='table table-bordered'>
             <thead>
                 <tr>
                     <th> Item</th>
                     <th>HSNC</th>
                     <th > Qty</th>
                     <th className='text-end'> Unit Price</th>
                     <th className='text-end'> Total Rate</th>
                     <th colSpan={2} className='text-center'> Discount</th>
                     <th className='text-center'> Tax</th>
                     <th className='text-end'> Total Amount</th>
                     <th><i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem()}></i></th>
                 </tr>
                 <tr>
                     <th></th>
                     <th></th>
                     <th ></th>
                     <th></th>
                     <th></th>
                     <th className='text-end'>%</th>
                     <th className='text-end'>Amt</th>
                     <th></th>
                     <th></th>
                     <th></th>
                 </tr>
             </thead>
             <tbody>
         {state.SalesOrderItemRequest.map((Item, index) => (
             <tr key={index}>
                 <td>
                    {Item.ItemName} <br/>
                    {Item.SpecialRemarks}
                 </td>
                 <td>
                    {Item.HSNCode}
                 </td>
                 <td>
                  {Item.Qty} 
                 <span> {Item.SUnitName}</span></td>
                 <td className='text-end'>
                     {Item.UnitPrice}  
                 </td>
                 <td className='text-end'>
                     {(Item.Qty * Item.UnitPrice).toFixed(2)} 
                 </td>
                 <td className='text-end'>{Item.Discount}</td>
                 <td className='text-end'>{(Item.Qty * Item.UnitPrice * Item.Discount/ 100).toFixed(2) }</td>
                 <td>
                 <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className='text-end'>%</th>
                            <th className='text-end'>Amt</th>
                        </tr>
                    </thead>
                    <tbody>

                    {Item.ItemTaxRequest.map((NewItem, index) => (
                        
                            <tr key={index}>
                                <td>{NewItem.TaxName}</td>
                                <td className='text-end'>{NewItem.TaxPer}</td>
                                <td className='text-end'>{NewItem.TaxAmount.toFixed(2)}</td>
                            </tr>
                        
                    ))}
                    </tbody>
                    </table>
                 </td>
                
                 <td className='text-end'>
                     {GetTotalAmount(Item).toFixed(2)}  
                 </td>
                 <td>
                     <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index)}></i>
                     <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
                 </td>
             </tr>

         ))}
     
        <tr>
            <td colSpan={10}></td>
        </tr>
        <tr>
            <td colSpan={8} className='text-end'>
                <span>Sub Total (A)</span>
            </td>
            <td className='text-end'>
                {TotalRate.toFixed(2)}
            </td>
            <td>

            </td>
         </tr>
         <tr>
            <td colSpan={8} className='text-end'>
                <span>Total Discount (B)</span>
            </td>
            <td className='text-end'>
                {TotalDiscount.toFixed(2)}
            </td>
            <td>

            </td>
         </tr>
         <tr>
            <td colSpan={8} className='text-end'>
                <span>Taxable Amount (C = A - B)</span>
            </td>
            <td className='text-end'>
                {(TotalRate - TotalDiscount).toFixed(2)}
            </td>
            <td>

            </td>
         </tr>
         <tr>
            <td colSpan={8} className='text-end'>
                <span>Total Tax (D)</span>
            </td>
            <td className='text-end'>
                    {TotalTax.toFixed(2)}
            </td>
            <td>

            </td>
         </tr>
         <tr>
            <td colSpan={8} className='text-end'>
                <span>Total Amount (C + D)</span>
            </td>
            <td className='text-end'>
                    {TotalAmount.toFixed(2)}
            </td>
            <td>

            </td>
         </tr>
         {/* <tr>
             <td colSpan={3}>
                 <button className='btn btn-primary' onClick={() => AddMoreItem()}>Add</button>
             </td>
         </tr> */}
         </tbody>
         </table>

         <div className="mb-3">
             <label htmlFor="TermsandConditions" className="form-label">Terms and Conditions<span className='color-red'>*</span></label>
             <Editor value={state.TermsandConditions} onTextChange={handleTndCChange} style={{ height: '150px' }} />
         </div>
         <div className='row'>
             <div className="col mb-3">
                 <label htmlFor="SigningAuthority" className="form-label">SignIn Authority Name<span className='color-red'>*</span></label>
                 <input type="text" required name="SigningAuthority" className="form-control" id="Subject" value={state.SigningAuthority} onChange={handleInputChange} placeholder="Enter Signin Authority Name"/>
             </div>
         </div>
         
         

         <div className='mt-4'>
             <button type="submit" className="btn btn-primary">Submit</button>
         </div>
     </form>
 </div>
 <span>{Message}</span>
        <Dialog header="Add New Item" visible={visible} style={{ width: '70vw' }} onHide={() => setVisible(false)}>
         <AddPOItems OFrom= "SO" MyItems = {Items} MyTaxes= {Taxes} AddmoreItems= {AddmoreItems}/>
        </Dialog>
 </>

  )
}

export default AddSalesOrder
