import React, { useState } from 'react'
import * as XLSX from 'xlsx'

const ExcelFileReader = () => {
    const [excelData, setExcelData] = useState(null);

  const handleFileRead = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        let MyjsonData = [...jsonData];
        MyjsonData.map ((item, MyIndex) => {
            let MyItem = [...item];
            let MyHeading = ["ItemName", "Rate", "Qty", "Color", "Size"]
            var result = MyIndex > 0 &&  MyItem.reduce((a, v, index) => ({ 
                
                ...a, [MyHeading[index]]: v
            
            }), {})
            if (MyIndex > 0){
                MyjsonData[MyIndex - 1] = result;
            }
            console.log(result);
            
        })
        MyjsonData.pop();
      setExcelData(MyjsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const HandleClick = () => {
    console.log(excelData);
  }
  return (
    <div>
    <input type="file" onChange={handleFileRead} />
    <button onClick={HandleClick}>Submit</button>
    {/* {excelData && (
      <div>
        <h3>Excel Data:</h3>
        <pre>{excelData}</pre>
        <pre>{JSON.stringify(excelData, null, 2)}</pre>
        
      </div>
    )} */}
  </div>
  )
}



export default ExcelFileReader