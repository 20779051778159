import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListStockTOrder from '../../Blocks/ListStockTOrder'

const StockTransferOrder = () => {
  return (
    <>
     <InventoryHeader />
     <div className='p-3'>
            <ListStockTOrder/>
      </div>
    </>
  )
}

export default StockTransferOrder