import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import CompanyBanksList from '../../Blocks/CompanyBanksList'

const BankAccounts = () => {
  return (
    <>
        <InventoryHeader/>
        <div className='p-3'>
            <CompanyBanksList/>
        </div>
      
    </>
  )
}

export default BankAccounts
