import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ReceivingDetailBlock from '../../Blocks/ReceivingDetailBlock'

const ReceivingDetailPage = () => {
  return (
    <div>
        <InventoryHeader/>
        <div className='p-3'>
            <ReceivingDetailBlock />
        </div>
    </div>
  )
}

export default ReceivingDetailPage