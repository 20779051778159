import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ApprovalProcessBlock from '../../Blocks/ApprovalProcessBlock'

const ApprovalProcessPage = () => {
  return (
    <>
        <InventoryHeader />
        <div className='p-3'>
            <ApprovalProcessBlock/>
        </div>
    </>
  )
}

export default ApprovalProcessPage