
import './App.css';
import { BrowserRouter} from 'react-router-dom'

import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css"; 
import 'primeicons/primeicons.css';
import RouteList from './Others/RouteList';


function App() {
 
  
 
  return (
    <BrowserRouter>
     
      <div className="App">
        <RouteList/>
      </div>
    </BrowserRouter>
  );
}

export default App;
