import React, { useRef } from 'react'
import Logoimage from './logoimage'
import {Link, useNavigate} from 'react-router-dom'
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';

const TopNavigation = () => {
    const UserObj = JSON.parse(localStorage.getItem('User_Obj'));
    const navigate = useNavigate();
    const menuRight = useRef(null);
    const items = [
        {
            template: () => {
                return (
                <div className='UserName'>
                <span>{UserObj.yourName}</span>
                </div>

            )
            }
        },
        {
            label: 'My Companies',
            icon: 'pi pi-dollar',
            url: "/setup/companies"
        },
        {
            label: 'Profile',
            icon: 'pi pi-user',
            url: "/setup/companies" 
        },
        {
            label: 'Billing',
            icon: 'pi pi-dollar',
            url: "/admin/billingdashboard"
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => { Logout() }
        },
    ];
    const Logout = () => {
        localStorage.removeItem('User_Obj');
        localStorage.removeItem('token');
        localStorage.removeItem('Company');
        navigate("/login");
    }
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light custOuterNavbar" style={{borderBottom: "1px solid #e7e7e7"}}>
        <div class="container">
            <a className="navbar-brand" href="#"><Logoimage height="50px"/></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto mt-2">
                <li className="nav-item">
                    <Link className="nav-link active" to="/">Home</Link>
                </li>
                
               
                </ul>
                <ul className=" ms-auto mt-2">
                    {UserObj ?  <ul className='navbar-nav'>
                        <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle"  id="navbarDropdown" role="button"  onClick={(event) => menuRight.current.toggle(event)}>
                        
                        <Avatar label={UserObj.yourName.substring(0,1)} style={{ backgroundColor: '#2196F3', color: '#ffffff' }}  shape="circle" />
                        </Link>
                        <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                        
                    </li> </ul> : <ul className='navbar-nav pt-3'>
                        
                    <li className="nav-item">
                        <Link to="/login" className='nav-link'>Login</Link>
                    </li>
                    <li className="nav-item ms-2">
                        <Link className='btn btn-outline-primary' to="/signup">Signup Now</Link>
                    </li> 
                     
                    </ul>
                    }
                </ul>
                {/* <form class="form-inline my-2 my-lg-0">
                <input class="form-control mr-sm-2" type="search" placeholder="Search"/>
                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                </form> */}
            </div>
            </div>
            </nav>
    </div>
  )
}

export default TopNavigation
