import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const EditSetupTax = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [Message, setMessage] = useState();
  const [ShowForm, setShowForm] = useState(true);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 0,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {
    }
  });
  const [state, setState] = useState({
    CompanyId : Company.id,
    Id: props.Id,
    TaxName : "",
    TaxDescription : "",
    TaxType : "",
  });
  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
  };
  const GetTaxbyId = async() => {
    var MyRes = await GetAPICallAsync("GetTaxbyId", { lazyEvent: lazyState }, {ID: props.Id});
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setState((prevProps) => ({
            ...prevProps,
            TaxName : MyData.data.taxName,
            TaxType : MyData.data.taxType,    
            TaxDescription : MyData.data.taxDescription,
        }))
    }
  };

  useEffect (() => {  
    GetTaxbyId();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
            console.log(state);
            let MyRes = await  POSTAPICallAsync("EditTax", state);
            console.log(MyRes);
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                var MyData = await MyRes.json();
                console.log(MyData);
                if (MyData.success == true ){
                    setMessage(MyData.message);
                    setShowForm(false);
                }
                else{
                    setMessage(MyData.message);
                }
                
            }
  }
  return (
    <>
      {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible({"open" : false})}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Tax Name<span className='color-red'>*</span></label>
                <input type="text" required name="TaxName" className="form-control" id="TaxName" value={state.TaxName} onChange={handleInputChange} placeholder="Enter Tax name"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Tax Type<span className='color-red'>*</span></label>
                <input type="text" required name="TaxType" className="form-control" id="TaxType" value={state.TaxType} onChange={handleInputChange} placeholder="Enter Tax Type"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea type="text" rows="4" name="TaxDescription" className="form-control" id="TaxDescription" value={state.TaxDescription} onChange={handleInputChange} placeholder="Enter Description"/>
            </div>           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </>
  )
}

export default EditSetupTax
