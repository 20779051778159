import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import AddBOMBlock from '../../Blocks/AddBOMBlock'

const AddBOMPage = () => {
  return (
    <div>
      <InventoryHeader/>
        <div className='p-3'>
          <AddBOMBlock/>
        </div>
    </div>
  )
}

export default AddBOMPage