

export const POSTAPICallAsync = async (url, body) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  console.log(url);
  const GetUrl = (Myurl) => {
      switch (Myurl){
          case "CreateRFQ" : return process.env.REACT_APP_API_URL + 'inventory/addnewrfq';
          case "CreatePO" : return process.env.REACT_APP_API_URL + 'inventory/addnewpurchaseorder';
          case "CreateSO" : return process.env.REACT_APP_API_URL + 'inventory/addnewsalesorder';
          case "CreateSupplier" : return process.env.REACT_APP_API_URL + 'setup/addnewsupplier';
          case "EditSupplier" : return process.env.REACT_APP_API_URL + 'inventory/editsupplier';
          case "CreateDealer" : return process.env.REACT_APP_API_URL + 'inventory/addnewdealer';
          case "CreateTax" : return process.env.REACT_APP_API_URL + 'setup/addnewtax';
          case "CreateProfile" : return process.env.REACT_APP_API_URL + 'setup/AddNewProfile';
          case "EditTax" : return process.env.REACT_APP_API_URL + 'setup/EditTax';
          case "CreateCurrency" : return process.env.REACT_APP_API_URL + 'setup/addnewcurrency';
          case "EditCurrency" : return process.env.REACT_APP_API_URL + 'setup/EditCurrency';
          case "CreateOrderReceiving" : return process.env.REACT_APP_API_URL + 'inventory/AddNewOrderReceiving';
          case "Addnewapinvoice" : return process.env.REACT_APP_API_URL + 'inventory/AddNewapinvoice';
          case "Addnewarinvoice" : return process.env.REACT_APP_API_URL + 'inventory/AddNewarinvoice';
          case "GetInventorybyItemIds" : return process.env.REACT_APP_API_URL + 'inventory/GetInventorybyItemIds';
          case "AddStocktransferorder" : return process.env.REACT_APP_API_URL + 'inventory/AddStocktransferorder';
          case "AddCompanyUser" : return process.env.REACT_APP_API_URL + 'setup/AddNewCompanyUser';
          case "AddNewUserInvite" : return process.env.REACT_APP_API_URL + 'setup/AddNewUserInvite';
          case "VerifyandAddSTOSrNo" : return process.env.REACT_APP_API_URL + 'inventory/VerifyandAddSTOSrNo';
          case "VerifyandAddIRSrNo" : return process.env.REACT_APP_API_URL + 'inventory/VerifyandAddIRSrNo';
          case "VerifyandAddSSRSrNo" : return process.env.REACT_APP_API_URL + 'inventory/VerifyandAddSSRSrNo';
          case "VerifyandAddSARSrNo" : return process.env.REACT_APP_API_URL + 'inventory/VerifyandAddSARSrNo';
          case "VerifyandAddSTOBatch" : return process.env.REACT_APP_API_URL + 'inventory/VerifyandAddSTOBatches';
          case "VerifyandAddIRBatches" : return process.env.REACT_APP_API_URL + 'inventory/VerifyandAddIRBatches';
          case "VerifyandAddSSRBatches" : return process.env.REACT_APP_API_URL + 'inventory/VerifyandAddSSRBatches';
          case "UpdateSTOStatus" : return process.env.REACT_APP_API_URL + 'inventory/UpdateSTOStatus';
          case "BulkItemMaster" : return process.env.REACT_APP_API_URL + 'setup/AddBulkItemMaster';
          case "AddBOM" : return process.env.REACT_APP_API_URL + 'inventory/AddNewBOM';
          case "AddProductionOrder" : return process.env.REACT_APP_API_URL + 'inventory/AddNewProductionOrder';
          case "AddIssueStockRequest" : return process.env.REACT_APP_API_URL + 'inventory/AddNewStockIssueRequest';
          case "AddSubmitStockRequest" : return process.env.REACT_APP_API_URL + 'inventory/AddNewSubmitStockRequest';
          case "IssueStock" : return process.env.REACT_APP_API_URL + 'inventory/IssueStock';
          case "SubmitStock" : return process.env.REACT_APP_API_URL + 'inventory/IssueStock';
          case "AdjustStock" : return process.env.REACT_APP_API_URL + 'inventory/IssueStock';
          case "AddNewStockAdjustmentRequest" : return process.env.REACT_APP_API_URL + 'inventory/AddNewStockAdjustmentRequest';
          case "GSTTaxSetup" : return process.env.REACT_APP_API_URL + 'setup/GSTTaxSetup';
          case "MarkSetupCompleted" : return process.env.REACT_APP_API_URL + 'setup/MarkSetupCompleted';
          case "EditCompanyUser" : return process.env.REACT_APP_API_URL + 'setup/EditCompanyUser';
          case "DeleteCompanyUser" : return process.env.REACT_APP_API_URL + 'setup/DeleteCompanyUser';
          case "AddApprovalProcessLevel" : return process.env.REACT_APP_API_URL + 'setup/AddApprovalSetupLevel';
          case "EditApprovalSetupLevel" : return process.env.REACT_APP_API_URL + 'setup/EditApprovalSetupLevel';
          case "ResendVerifyCode" : return process.env.REACT_APP_API_URL + 'Auth/ResendVerifyCode';
          case "VerifyCode" : return process.env.REACT_APP_API_URL + 'Auth/VerifyCode';
          case "DeleteUserInvite" : return process.env.REACT_APP_API_URL + 'setup/DeleteUserInvite';
          case "DeleteApprovalSetupLevel" : return process.env.REACT_APP_API_URL + 'setup/DeleteApprovalSetupLevel';
          case "ApproveApprovalRequest" : return process.env.REACT_APP_API_URL + 'inventory/ApproveApprovalRequest';
          case "UpdatePermissions" : return process.env.REACT_APP_API_URL + 'setup/UpdatePermissions';
          case "RemoveWHIncharge" : return process.env.REACT_APP_API_URL + 'setup/RemoveWHIncharge';
          case "EditWHIncharge" : return process.env.REACT_APP_API_URL + 'setup/EditWHIncharge';
      }
  }
  
  var CallUrl = GetUrl(url);
  console.log(CallUrl);
  return await fetch(CallUrl ,
  {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
       'Content-type': 'application/json; charset=UTF-8',
       'Authorization': 'Bearer ' + tokenString.toString()
     },
  })
  .then(res => {
   
      return res;

  })
}