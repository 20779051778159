import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import AddNewReceiveItems from '../../Blocks/AddNewReceiveItems'

const ReceiveItems = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
        <AddNewReceiveItems />
      </div>
    </div>
  )
}

export default ReceiveItems