import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddNewApprovalLevelBlock from './AddNewApprovalLevelBlock';
import { GetAPICallAsync } from '../ServiceLayer/GetAPICall';
import { Accordion, AccordionTab } from 'primereact/accordion';
import EditApprovalLevelBlock from './EditApprovalLevelBlock';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const ApprovalSetupBlock = () => {
    const [ApprovalLevels, setApprovalLevels] = useState([]);
    const [Reload, setReload] = useState(false);
    const Company = JSON.parse(localStorage.getItem('Company'));

    const [loading, setLoading] = useState(false);
    let Params = useParams();
    let networkTimeout = null;
    const navigate = useNavigate();
    const [visible, setVisible] = useState({
        open: false,
        mode: "",
        id: ""
      });

      const loadLazyData = async () => {
        setLoading(true);
        if (networkTimeout) {
          clearTimeout(networkTimeout);
        }
    
        //imitate delay of a backend call
        networkTimeout = setTimeout(async () => {
          const MyRes = await GetAPICallAsync("GetApprovalSetupLevels", { lazyEvent: "" }, {PName: Params.PName});
          
          if (MyRes.status === 401) {
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          } else {
            const MyData = await MyRes.json();
            console.log(MyData);
            setApprovalLevels(MyData.data);
          }
          setLoading(false);
        }, Math.random() * 1000 + 250);
    };

    const GetProcessName = () => {
        switch (Params.PName)
        {
            case "purchaseorder" : return <span>Purchase Order</span>
            case "salesorder" : return <span>Sales Order</span>
            case "apinvoice" : return <span>AP Invoice</span>
            case "arinvoice" : return <span>AR Invoice</span>

        }
    }

    useEffect(() => {
        loadLazyData();
      }, [visible.open, Reload]);
    
      useEffect(() => {
        loadLazyData();
      }, []);

      const handleDelete = async (MyId) => {
        let MyRes = await  POSTAPICallAsync("DeleteApprovalSetupLevel", {CompanyId: Company.id, Id : MyId});
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success == true ){
                alert(MyData.message);
                setReload(!Reload);
            }
            else{
                
            }
            
        }
      }
    

  return (

    <>
        <div className='row'>
            <div className='col-6'>
            <h1 className='ListMainHeading'>Approval Setup for {GetProcessName()}</h1>
            </div>
            <div className='col-6 text-end'>
                {/* <Link className='btn btn-outline-primary' to="/purchase/addnewpo"><i className="pi pi-plus"></i> Add New</Link> */}
            </div>
        </div>
        <div className='row mt-3'>
        <Accordion multiple activeIndex={[0]}>
            {ApprovalLevels.map((item, index) => (
                <AccordionTab key={index} header={"Level " + item.level}>
                  <div className='row'>
                    <div className='col-12 col-sm-6 '>
                      <h5>Approvers</h5> 
                    </div>
                    <div className='col-12 col-sm-6 text-end'>
                    <button className='btn btn-outline-primary'  onClick={() => setVisible({"open": true, "mode": "edit", "id": item.id})}>Edit</button>
                      <button className='btn btn-outline-primary ms-2' onClick={() => handleDelete(item.id)}>Remove</button>
                    </div>
                  </div>
                    
                    {item.anyOne ? <span>Anyone from following can approve.</span> : <span>All approvers need to approve.</span> }<span></span>
                     
                    <div className='row mt-2'>
                    {item.approvalLevelSetupUsers.map((item1, index1) => (
                        <div key={index1} className='col-3'>
                                <span>{item1.name} ({item1.userName})</span>
                        </div>
                    ))
                    }
                    </div>
          
            </AccordionTab>
            ))}
            
        </Accordion>
        </div>
        <div className='row mt-3'>
            <div className='col-12 text-center'>
            <button className='btn btn-primary' onClick={() => setVisible({open: true, mode: "add"})}> <i className='pi pi-plus'  ></i> Add Approval Level</button>
            </div>
        </div>

        <Dialog header={visible.mode === "edit"? "Edit Approval Level":"Add New Approval Level"}  visible={visible.open} style={{ width: '70vw' }} onHide={() => setVisible({"open": false})}>
           {visible.mode === "edit" 
           ? <EditApprovalLevelBlock ProcessName={Params.PName} Level={ApprovalLevels.length} setVisible={setVisible} Id={visible.id} />
           : <AddNewApprovalLevelBlock ProcessName={Params.PName} Level={ApprovalLevels.length + 1} setVisible={setVisible}/>}
        
        </Dialog>
            
    </>
  )
}

export default ApprovalSetupBlock