import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import AddSupplier from './AddSupplier';
import EditSupplier from './EditSupplier';
import AddProfile from './AddProfile';

const ProfileBlock = () => {
    let params = useParams();
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [visible, setVisible] = useState({
      open: false,
      mode: "",
      id: ""
    });
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [Profiles, setProfiles] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const [filterHeight, setfilterHeight] = useState("0px");
    const [Filters, setFilters] = useState({
        SupplierName: "",
        BusinessType: "",
        ContactPerson: "",
        Designation: "",
        Phone: "",
        Email: "",
        CountryId: 0,
        StateId: 0,
        CityId: 0,
    });

    const [AppliedFilters, setAppliedFilters] = useState([]);

    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {}
    });

  const items = [
    {
      label: 'Details',
      icon: 'pi pi-refresh',
      url: "/setup/setpermissions/" + selectedRow.id + ""
  },
  {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: (row) => {
        console.log(selectedRow);
        setVisible({open: true, mode: "edit", id : selectedRow.id})
          // toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
      }
  },
  {
    label: 'React Website',
    icon: 'pi pi-external-link',
    url: 'https://reactjs.org/'
  },
  ]; 
    
  
    useEffect(() => {
      loadLazyData();
    }, [lazyState, visible.open]);
    useEffect (() => {
      loadLazyData();
    }, [])


    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }

      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        var MyRes = await GetAPICallAsync("GetListProfiles", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setProfiles(MyData.data);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };

  const onSort = (event) => {
      setlazyState(event);
  };

  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };

  const onSelectionChange = (event) => {
      const value = event.value;

      setSelectedCustomers(value);
      setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = async (event) => {
      const selectAll = event.checked;

      if (selectAll) {
              setSelectAll(true);
              setSelectedCustomers(Profiles);
        
      } else {
          setSelectAll(false);
          setSelectedCustomers([]);
      }
  };

  const handleFiltersChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleFilterSubmit = async (event) => {
    event.preventDefault();
    FilterSubmitMethod();
  }

  const FilterSubmitMethod = async () => {

    //   let MyFilters = [];
      
    //   if(Filters.SupplierName != null && Filters.SupplierName != ""){
    //     MyFilters = [...MyFilters, {name: "SupplierName", Filter: "Supplier Name", Value: Filters.SupplierName}] 
    //   }
    //   if (Filters.BusinessType != null && Filters.BusinessType != ""){
    //     MyFilters = [...MyFilters, {name: "BusinessType", Filter: "Business Type", Value: Filters.BusinessType}] 
    //   }
    //   if(Filters.ContactPerson != null && Filters.ContactPerson != ""){
    //     MyFilters = [...MyFilters, {name: "ContactPerson", Filter: "Contact Person", Value: Filters.ContactPerson}] 
    //   }
    //   if(Filters.Designation != null && Filters.Designation != ""){
    //     MyFilters = [...MyFilters, {name: "Designation", Filter: "Designation", Value: Filters.Designation}] 
    //   }
    //   if(Filters.Phone != null && Filters.Phone != ""){
    //     MyFilters = [...MyFilters, {name: "Phone", Filter: "Phone", Value: Filters.Phone}] 
    //   }
    //   if(Filters.Email != null && Filters.Email != ""){
    //     MyFilters = [...MyFilters, {name: "Email",Filter: "Email", Value: Filters.Email}] 
    //   }
    //   if(Filters.CountryId > 0 && Filters.CountryId != ""){
    //     MyFilters = [...MyFilters, {name: "Country",Filter: "Country", Value: Filters.CountryId}] 
    //   }
    //   if(Filters.StateId > 0 && Filters.StateId != ""){
    //     MyFilters = [...MyFilters, {name: "State",Filter: "State", Value: Filters.StateId}] 
    //   }
    //   if(Filters.CityId != 0 && Filters.CityId != ""){
    //     MyFilters = [...MyFilters, {name: "City", Filter: "City", Value: Filters.CityId}] 
    //   }
    //   setAppliedFilters(MyFilters);

    var MyRes = await GetAPICallAsync("GetListProfiles", { lazyEvent: lazyState }, {Filters: Filters});
    if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
    }
    else{
      var MyData = await MyRes.json();
      console.log(MyData);
      setTotalRecords(MyData.total);
      setProfiles(MyData.data);
      setfilterHeight("0px")
    }

  }

  const RemoveFilter = (item, index) => {
    let MyAppliedFilter = [...AppliedFilters];
    MyAppliedFilter.splice(index, 1)
    setAppliedFilters(MyAppliedFilter);
    const name = item.name;
    setFilters((prevProps) => ({
      ...prevProps,
      [name] : "",
    }) )
    FilterSubmitMethod();
  }

  const Reset = () => {
    setFilters({SupplierName: "",
    BusinessType: "",
    ContactPerson: "",
    Designation: "",
    Phone: "",
    Email: "",
    CountryId: 0,
    StateId: 0,
    CityId: 0,})
  }


    const ActiveTemplate = (prop) => {
        return prop.status === true ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Inactive</span>
    }
    const Phonetemplate = (prop) => {
      return <span>+{prop.phone}</span>
    }
    const ActionTemplate = (row) => {
       return<>
       <div className="flex justify-content-center">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
            {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
       </div>
       </> 
    }
  return (
    <div>
        <div className='mb-3 row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Profiles ({totalRecords} { (totalRecords > 1) ?  "records"  : "record"} )</h1>
        </div>
        <div className='col-6 d-flex justify-content-end text-end'>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
       <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "300px" : "0px")}></i>
        <button className='btn btn-outline-primary' onClick={() => setVisible({"open": true, "mode": "add"})} ><i className="pi pi-plus" ></i> Add New</button>
        </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12'>
            {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter}: {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}} onClick={() => RemoveFilter(item, index) }></i></span>))}
          </div>
        </div>
        <div className='filterContainer' style={{ height: filterHeight, transition: "height 1s"}}>
        <form onSubmit={handleFilterSubmit} style={{height: filterHeight, transition: "height 1s", overflow: "hidden" }}> 
          <div class="row g-2">
            <h2 className='FiltersHeading'>Filters</h2>
            <div class="col-3 mb-3">
              <label htmlFor="SupplierName" class="form-label">Supplier Name</label>
              <input type="text" className="form-control" name="SupplierName" id="SupplierName" value={Filters.SupplierName} onChange={handleFiltersChange} placeholder="Supplier name."/>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="BusinessType" class="form-label">Business Type</label>
              <select name="BusinessType" id="BusinessType" className="form-select" value={Filters.BusinessType} onChange={handleFiltersChange} aria-label="">
                        <option value="">Select Any</option>
                        <option value="manufacturer">Manufacturer</option>
                        <option value="trader">Trader</option>
                        <option value="wholesaler">Wholesaler</option>
                        <option value="retailer">Retailer</option>
                    </select>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="ContactPerson" class="form-label">Contact Person</label>
              <input type="text" className="form-control" name="ContactPerson" id="ContactPerson" value={Filters.ContactPerson} onChange={handleFiltersChange} placeholder="Contact Person"/>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="Designation" class="form-label">Designation</label>
              <input type="text" className="form-control" name='Designation' id="Designation" value={Filters.Designation} onChange={handleFiltersChange} placeholder="Designation"/>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="Phone" class="form-label">Phone</label>
              <input type="text" className="form-control" name='Phone' id="Phone" value={Filters.Phone} onChange={handleFiltersChange} placeholder="Phone"/>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="Email" class="form-label">Email Id</label>
              <input type="email" className="form-control" name="Email" id="Email" value={Filters.Email} onChange={handleFiltersChange} placeholder="Email"/>
            </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <button type='submit' className='btn btn-outline-primary mt-4'>Apply <i className="pi pi-angle-double-right"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => Reset()}>Reset <i className="pi pi-undo"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => setfilterHeight("0px")}>Cancel <i className="pi pi-times"></i></button>
                
                </div>
            </div>
          </form>
        </div>
        <div className='row' style={{ marginTop: filterHeight === "0px" ? "-15px": "" }}>
            <DataTable value={Profiles} lazy removableSort dataKey="id" paginator selectionMode="checkbox"
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                    selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column field="name" header="Profile Name" sortable />
            <Column field="description" header="Description"></Column>
            <Column field="status" header="Status"></Column>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
            </DataTable>
        </div>
        <Dialog header={visible.mode === "add" ? "Add New Profile" : "Edit Profile"} visible={visible.open} style={{ width: '70vw' }} onHide={() => setVisible({"open": false})}>
            {visible.mode == "add" && <AddProfile setVisible={setVisible} />}
            {visible.mode == "edit" &&  <EditSupplier setVisible={setVisible} Id={visible.id}/> }
        </Dialog>
    </div>
  )
}

export default ProfileBlock