import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const ReceiveItemsbypo = () => {
    const [PONo, setPONo] = useState("");
    const navigate = useNavigate();
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [Suppliers, setSuppliers] = useState([]);
    const [Warehouses, setWarehouses] = useState([]);
    const [Message, setMessage] = useState();
    const [FetchbyPOMessage, setFetchbyPOMessage] = useState("");
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
    });
    const [itemMatadata, setItemMetaData] = useState({
      visible : "",
      itemIndex: "",
      trackBy: "",
    });

    const [state, setState] = useState ({
        POId : "",
        Date : new Date(),
        TimeZone: "",
        CompanyId : Company.id,
        SupplierId: "",
        WarehouseId: "",
        PurchaseOrderItems : []

    });



    const handleInputChange = (event) => {
      const { name, value } = event.target;
    
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
    };

    const handleL2InputChange = (event, Index) => {
      const { name, value } = event.target;
      let POItems = [...state.PurchaseOrderItems];
      POItems[Index][name] = value;
      POItems[Index]["invQty"] = value * POItems[Index]["ConvRate"];
      console.log();
      setState((prevProps) => ({
        ...prevProps,
        PurchaseOrderItems: POItems
      }));

    }

    const GetSuppliersDD = async () => {
      var MyRes = await GetAPICallAsync("GetListSuppliersDD", "", "");
      if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
      }
      else{
      var MyData = await MyRes.json();
      setSuppliers(MyData.data);
      }
  };
    const GetWareHouses = async () => {
      var MyRes = await GetAPICallAsync("GetListCompanyWarehouses",{ lazyEvent: lazyState }, "");
      if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
      }
      else{
      var MyData = await MyRes.json();
      console.log(MyData);
      setWarehouses(MyData.data);
      }
  };


  const getTimeZone = () => {
                        
    const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setState((prevProps) => ({
        ...prevProps,
        "TimeZone": MytimeZone
      }));

  };


    const handlePOSearch = (event) => {
        const { value } = event.target;
        setPONo(value);
    }

    useEffect (() => {   
      
      GetSuppliersDD();
      getTimeZone();
      GetWareHouses();
    }, []);
    

    const GetPODetails = async (event) => {
        event.preventDefault();
        var MyRes = await GetAPICallAsync("GetPowithPendingItemsbyno", "", {"PONo" : PONo});
        console.log(MyRes);
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setFetchbyPOMessage("");
        let POItems = []
        if(MyData.success === true){
        MyData.data.purchaseOrderItems.map((item, index) => {
          console.log(item);
          POItems = [...POItems, {"POItemId": item.id, "ItemId": item.item.id,"itemName": item.item.name, "hsnCode" : item.item.hsnCode, 
          "orderedQty": item.qty, "oQtyUnit": item.itemUnit.unitName, "rQty": 0, "rQtyUnit": item.itemUnit.unitName, }];
         })

         setState((prevProps) => ({
          ...prevProps,
          "POId" : MyData.data.id,
          "SupplierId": MyData.data.supplierId,
          "PurchaseOrderItems": POItems
        }));
      }
      else
      {
        setFetchbyPOMessage(MyData.message);
      }
        
      }
      
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        let NewState = {...state};
        let MyNewItems = NewState.PurchaseOrderItems;
        MyNewItems = MyNewItems.filter(x => x.rQty > 0);
        NewState.PurchaseOrderItems = MyNewItems;
        console.log(NewState);
        let MyRes = await  POSTAPICallAsync("CreateOrderReceiving", NewState);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            if (MyData.success === true ){
                navigate("/purchase/receivings");
            }
            else{
                setMessage(MyData.message);
            }
            
        }
      
      
      }
  return (
    <>
    
      <span>{Message}</span>
      <div className='row'>
      <label className="form-label">PO No.<span className='color-red'>*</span></label>
      </div>
      <div className='row'>
      {FetchbyPOMessage && <span className='Error'>{FetchbyPOMessage}</span>}
          <div className="mb-3 col-3">
              
              <input type="text" required name="PurchaseOrder" className="form-control" id="PurchaseOrder" value={PONo} onChange={handlePOSearch} placeholder="Enter Purchase Number"/>
          </div>          
          <div className='col-3'>
              <button className="btn btn-primary" onClick={GetPODetails}>Fetch Details</button>
          </div>
          </div>
      <div className='row'>
      <form onSubmit={handleSubmit}>
        
         <div className='row'>
            {/* <div className="col-3 mb-3">
                <label htmlFor="PONo" className="form-label">PO No<span className='color-red'>*</span></label>
                <input type="text" required name="PONo" className="form-control" id="PONo" value={state.PONo} onChange={handleInputChange} placeholder="Enter company name"/>
            </div> */}
             <div className="col-3 mb-3">
                 <label htmlFor="Date" className="form-label">Receiving Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="Date"  dateFormat="dd/mm/yy" className="form-control custdate"  value={state.Date} onChange={handleInputChange} showButtonBar ></Calendar>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="SupplierId" className="form-label">Supplier<span className='color-red'>*</span></label>
                 
                 <select name="SupplierId" className="form-select" value={state.SupplierId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Suppliers.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.supName}</option>))}
                </select>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="WarehouseId" className="form-label">Warehouse<span className='color-red'>*</span></label>
                 
                 <select name="WarehouseId" className="form-select" value={state.WarehouseId} onChange={handleInputChange} aria-label="Select Warehouse">
                    <option value={""}>Select Any</option>
                    {Warehouses.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
                </select>
             </div>
         </div>
      <div className='row mt-5'>
        <div className='col-12'>
          <span> Only Entries with Received Qty greater than 0 will be saved.</span>
          <table className='table table-bordered'>
            <thead>
            <tr>
              <th>Item Name</th>
              <th>HSN Code</th>
              <th>Ordered Qty</th>
              <th>Received Qty</th>
            </tr>
            </thead>
            <tbody>            
          
              {state.PurchaseOrderItems && state.PurchaseOrderItems.map ((item, index) => (
                <tr key={index}>
                  <td>{item.itemName} </td>
                  <td>{item.hsnCode}</td>
                  <td>{item.orderedQty} {item.oQtyUnit}</td>
                  <td><input type='text' id="" name="rQty" value={item.rQty} onChange={(event) => handleL2InputChange(event, index)}/> {item.rQtyUnit}
                  </td>
                  </tr> 
                  
              ))}
          </tbody>
          </table>
         
        </div>

        <div className='mt-4'>
             <button type="submit" className="btn btn-primary">Submit</button>
         </div>
      </div>
      
      </form>
      </div>

  </>
    
  )
}

export default ReceiveItemsbypo