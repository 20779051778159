import React from 'react'
import SubmitStockReqBlock from '../../Blocks/SubmitStockReqBlock'
import InventoryHeader from '../../Blocks/InventoryHeader'

const SubmitStockReqPage = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
           <SubmitStockReqBlock/>
     </div>
   </>
  )
}

export default SubmitStockReqPage