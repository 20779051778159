import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const AddWareHouse = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Cities, setCitiess] = useState([]);
    const [WareHouseList, setWareHouseList] = useState([]);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
        filters: {
          warehouseName: { value: '', matchMode: 'contains' },
          cityName: {value: '', matchMode: 'contains'},
          countryName : {value: '', matchMode: 'contains'},
          status: {value: '', matchMode: 'equals'}
        }
    });
    
    const [state, setState] = useState({
        CompanyId : Company.id,
        PWarehouseId : null,
        WarehouseName : "",
        StreetAddress : "",
        CityId : "",
        StateId : "",    
        CountryId : "",
        ZipCode : "",
    });
    const UpdateStates = (event) => {
        handleInputChange(event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/states?' + new URLSearchParams({
                CountryId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                setStates(data.lstStates);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      };
      const UpdateCities= (event) => {
        handleInputChange(event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/Cities?' + new URLSearchParams({
                StateId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                console.log(data);
                setCitiess(data.lstCities);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      }
    const calltpAPI = () => fetch(process.env.REACT_APP_API_URL + 'setup/countries', {
        method: 'Get',
        headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + tokenString.toString()
        },
    })
    .then(res => {
        if(res.status === 401){ navigate("/login"); }
        return res.json()
    } )
    .then(data => {
        setCountries(data.lstCountries);
    })
    .catch((err) => {
       console.log(err.message);
    })

    useEffect (() => {  
        calltpAPI();
        GetWHList();
    }, []);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };

    const GetWHList= async() => {
        var MyRes = await GetAPICallAsync("GetListCompanyWarehouses", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            setWareHouseList(MyData.data);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        await fetch(process.env.REACT_APP_API_URL + 'setup/addnewwarehouse', {
         method: 'POST',
         body: JSON.stringify(state),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + tokenString.toString()
          },
        })
        .then((res) => 
        { if(res.status === 401){ navigate("/login"); console.log(res); }        
        return res.json()})
        .then((data) => {
            setMessage("Account Added Successfully")
        })
        .catch((err) => {
            console.log(err.message);
        });
    }
  return (
    <div>
       {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible(false)}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label for="WarehouseName" className="form-label">Warehouse Name<span className='color-red'>*</span></label>
                <input type="text" required name="WarehouseName" className="form-control" id="WarehouseName" value={state.WarehouseName} onChange={handleInputChange} placeholder="Enter Warehouse name"/>
            </div>
            { props.WareHouseList &&
            <div className="mb-3">
                <label className="form-label">Parent WareHouse Name (if Substore)</label>
                <select name="PWarehouseId" className="form-select" value={state.PWarehouseId} onChange={handleInputChange} >
                        <option value="">Select WareHouse</option>
                     {WareHouseList.map((item) => (<option key={item.id} value={item.id}>{item.warehouseName}</option>))} 
                    </select>
            </div> }
            <div className='row mb-3'>
                <div className="col">
                    <label for="CountryId" className="form-label">Country<span className='color-red'>*</span></label>
                    <select name="CountryId" required className="form-select" value={state.CountryId} onChange={event => UpdateStates(event)} >
                        <option value="">Select Country</option>
                        {Countries.map((item) => (<option key={item.id} value={item.id}>{item.countryName}</option>))}
                    </select>
                </div>
                <div className="col">
                    <label for="StateId"  className="form-label">State<span className='color-red'>*</span></label>
                    <select name="StateId" required id="StateId" className="form-select" value={state.StateId} onChange={event => UpdateCities(event)}>
                        <option value="">Select State</option>
                        {States.map((item) => (<option key={item.id} value={item.id}>{item.stateName}</option>))}
                    </select>
                </div>
                <div className="col">
                    <label for="CityId" className="form-label">City<span className='color-red'>*</span></label>
                    <select name="CityId" required id="CityId" className="form-select" value={state.CityId} onChange={handleInputChange}>
                        <option value="">Select City</option>
                        {Cities.map((item) => (<option key={item.id} value={item.id}>{item.cityName}</option>))}
                    </select>
                </div>
            </div>
            <div className='row mb-3'>
                <div className="col">
                    <label for="StreetAddress" className="form-label">Local Address<span className='color-red'>*</span></label>
                    <input type="text" required  name="StreetAddress" className="form-control" id="StreetAddress" value={state.StreetAddress} onChange={handleInputChange} placeholder="Enter Local Address"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col">
                    <label for="ZipCode" className="form-label">Pin code<span className='color-red'>*</span></label>
                    <input type="text" required  name="ZipCode" className="form-control" id="ZipCode" value={state.ZipCode} onChange={handleInputChange} placeholder="Enter ZipCode"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
            </div>
           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </div>
  )
}

export default AddWareHouse
