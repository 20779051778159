import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListItemMaster from '../../Blocks/ListItemMaster'

const ItemMasterList = () => {
  return (
    <>
     <InventoryHeader />
     <div className='p-3'>
        <ListItemMaster QuckSetupMode={false} />
      </div>
    </>
  )
}

export default ItemMasterList
