import React from 'react'
import * as XLSX from 'xlsx';
import FileSaver from "file-saver";

const ExcelDownload = () => {
    var data = [
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"}
      ];
      let header = ["Name", "City"];
      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [header]);
      XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: false });
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles:true });
      const finalData = new Blob([excelBuffer], { type: 'xlsx' });
      FileSaver.saveAs(finalData, "Data.xlsx");

  return (
    <div>ExcelDownload</div>
  )
}

export default ExcelDownload