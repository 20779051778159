import React from 'react'
import InvSideMenu from '../../Components/InvSideMenu'
import InventoryHeader from '../../Blocks/InventoryHeader'

const ReportSnapshot = () => {
  return (
    <div>
        <InventoryHeader/>
        <div className='row g-0'>
            <div className='w-200 Sidebar'>
                <InvSideMenu/>
            </div>
            <div className='ReportArea'>

            </div>
        </div>
            
    </div>

  )
}

export default ReportSnapshot