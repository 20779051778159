import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import moment from 'moment';

const ApprovalListBlock = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [Dealers, setDealers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const [Approval, setApproval] = useState({
      RequestId: "",
      Remarks: "",
    });

    const menuRight = useRef(null);
    const [visible, setVisible] = useState({
      open: false,
      mode: "",
      id: ""
    });
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
        supName: { value: '', matchMode: 'contains' },

      }
  });

  const items = [
  {
      label: 'Details',
      icon: 'pi pi-info-circle',
      command: (row) => {
        console.log(selectedRow);
        switch (selectedRow.documentType) {
            case "arinvoice":
                navigate("/sales/arinvoice/" + selectedRow.documentId + "");
                break;
            case "purchaseorder":
                navigate("/purchase/purchaseorder/"+ selectedRow.documentId + "");
                break;
            case "apinvoice":
                navigate("/purchase/apinvoice/"+ selectedRow.documentId + "");
                break;
            case "salesorder":
                navigate("/purchase/purchaseorder/"+ selectedRow.documentId + "");
                break;
            default:
                break;
        }
      }
  },
  {
    label: 'Approve',
      icon: 'pi pi-check-square',
      command: (row) => {
        setApproval((prevProp) => ({...prevProp, RequestId : selectedRow.id}))
        setVisible({open: true, mode: "approve"})
      }
  },
  {
    label: 'Reject',
      icon: 'pi pi-ban',
      command: (row) => {
        console.log(row);
        setApproval((prevProp) => ({...prevProp, RequestId : selectedRow.id}))
        setVisible({open: true, mode: "reject"})
      }
  },
  ]; 

    useEffect(() => {
      loadLazyData();
    }, [lazyState, visible]);
    useEffect (() => {
      loadLazyData();
    }, [])


    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }

      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        var MyRes = await GetAPICallAsync("ListApprovalRequests", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setDealers(MyData.data);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };

  const onSort = (event) => {
      setlazyState(event);
  };

  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };

  const handleDialogChange = (event)=> {
    const { name, value } = event.target;
    console.log(name);
    setApproval((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  }

  const handleApproveRequest = async (event) => {
    event.preventDefault();
    console.log(Approval);
    let MyRes = await  POSTAPICallAsync("ApproveApprovalRequest", Approval);
    console.log(MyRes);
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        if (MyData.success == true ){
            setMessage(MyData.message);
            setShowForm(false);
        }
        else{
            setMessage(MyData.message);
        }
        
    }
  }

    const ActiveTemplate = (prop) => {
        return prop.status === true ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Inactive</span>
    }
    const DocumentType = (props) => {
        switch (props.documentType){
            case "arinvoice" : return <span>AR Invoice</span>
            case "apinvoice" : return <span>AP Invoice</span>
            case "salesorder" : return <span>Sales Order</span>
            case "purchaseorder" : return <span>Purchase Order</span>
        }
    }
    const DateTemplate = (prop) => {
        return moment(prop.createdOn).format("DD/MM/yyyy");
      }
    const ActionTemplate = (row) => {
       return<>
       <div className="flex justify-content-center">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
            {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
       </div>
       </> 
    }

  return (
    <div>
    <div className='row'>
    <div className='col-6'>
    <h1 className='ListMainHeading'>Approvals</h1>
    </div>
    <div className='col-6 text-end'>
     </div>
    </div>
    <div className='row'>
      <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
      </div>
    <div className='row'>
        <DataTable value={Dealers} showGridlines  lazy removableSort dataKey="id"
                first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={loading}
                selection={selectedCustomers}  selectAll={selectAll} >
        <Column field="createdOn" header="Date" body={DateTemplate}></Column>
        <Column field="requestId" header="Document Id" />
        <Column field="documentType" header="Document Type" body={DocumentType} />
        <Column field="approvalStatus" header="Approval Status"></Column>
        <Column field="remarks" header="Remarks"></Column>
        <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
        </DataTable>
    </div>
    <Dialog header={visible.mode === "approve"? "Approve" : "Reject"} visible={visible.open} style={{ width: '70vw' }} onHide={() => setVisible({"open": false})}>
      {Message && <span>{Message}</span>}
      {ShowForm && <form>
      <div className="mb-3">
        <label for="WarehouseName" className="form-label">Remarks/ Reason<span className='color-red'>*</span></label>
        <textarea className='form-control' name="Remarks" value={Approval.Remarks} onChange={handleDialogChange} rows="4"  />
       
      </div>
      <div className='mb-3'>
        {visible.mode === "approve" && <button className='btn btn-outline-primary' onClick={handleApproveRequest}>Approve</button>}
        {visible.mode === "reject" && <button className='btn btn-outline-primary'>Reject</button>}
      </div>
      </form>}

    </Dialog>
</div>
  )
}

export default ApprovalListBlock