import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';
import { TabPanel, TabView } from 'primereact/tabview';
import ListPurchaseOrders from './ListPurchaseOrders';
import ListReceivings from './ListReceivings';
import ListAPInvoices from './ListAPInvoices';

const SupplierDetailBlock = () => {
  const Params = useParams();
  const [supplier, setSupplier] = useState();
  console.log(Params);
  const navigate = useNavigate();
  const [POFilters, setPOFilters] = useState({
    PONO: "",
    PODate: "",
    DocumentName: "",
    Supplier: "",
});
const [ORFilters, setORFilters] = useState({
  ORNo: "",
  PONO: "",
  Date: "",
  Warehouse: "",
  SupplierId: ""
});
const [APInvFilters, setAPInvFilters] = useState({
  ORNo: "",
  PONO: "",
  Date: "",
  Warehouse: "",
  SupplierId: ""
});
  const GetSupplier = async () => {
      var MyRes = await GetAPICallAsync("GetSupplierbyId", { lazyEvent: "" }, {ID : Params.Id});
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData.data);
        setSupplier(MyData.data);
        setPOFilters((prevProps) => ({
          ...prevProps,
          "Supplier" : MyData.data.id

        }))
        setORFilters((prevProps) => ({
          ...prevProps,
          "SupplierId" : MyData.data.id

        }))
        setAPInvFilters((prevProps) => ({
          ...prevProps,
          "SupplierId" : MyData.data.id

        }))
      }
  }
  useEffect(()=> {
    GetSupplier();
  }, [])

  
  return (
    <>
    {supplier && <div className="supplier-detail-container">
      <div className='row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Supplier Detail</h1>
        </div>
        <div className='col-6 d-flex justify-content-end text-end'>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
       {/* <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "300px" : "0px")}></i> */}
        {/* <button className='btn btn-outline-primary' onClick={() => setVisible({"open": true, "mode": "add"})} ><i className="pi pi-plus" ></i> Add New</button> */}
        </div>
        </div>
      <div className="table-responsive mt-3">
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Supplier Name</th>
              <th>Business Type</th>
              <th>Contact Person</th>
              <th>Email</th>
              <th>Phone</th>
              <th>GST</th>
              <th>PAN</th>
              <th>TAN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{supplier.supName}</td>
              <td>{supplier.businessType}</td>
              <td>{supplier.contactPersonName}</td>
              <td>{supplier.email}</td>
              <td>{supplier.phone}</td>
              <td>{supplier.gst}</td>
              <td>{supplier.pan}</td>
              <td>{supplier.tan}</td>
             
            </tr>
          </tbody>
        </table>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TabView>
            {/* <TabPanel header="Analytics">

            </TabPanel> */}
            <TabPanel header="Addresses">
              {supplier.supplierAddresses && 
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Address Type</th>
                    <th>Local Address</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Country</th>
                    <th>Pin Code</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    supplier.supplierAddresses.map((item, index) => (
                      <tr key={index}>
                        <td>{item.addressType}</td>
                        <td>{item.streetAddress}</td>
                        <td>{item.city.cityName}</td>
                        <td>{item.state.stateName}</td>
                        <td>{item.country.countryName}</td>
                        <td>{item.zipCode}</td>
                      </tr>

                    ))
                  }
                </tbody>
              </table>
              
              
                
              }
            </TabPanel>
            <TabPanel header="Purchase Orders">
              <ListPurchaseOrders Filters={POFilters} HideButtons={true}/>
            </TabPanel>
            <TabPanel header="Receivings">
              <ListReceivings Filters={ORFilters} HideButtons={true}/>
             
            </TabPanel>
            <TabPanel header="AP Invoices">
              <ListAPInvoices Filters={APInvFilters} HideButtons={true} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  }
  </>
  )
}

export default SupplierDetailBlock