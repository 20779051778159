import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import StockAdjNewReqBlock from '../../Blocks/StockAdjNewReqBlock'

const StockAdjNewReqPage = () => {
    return (
        <>
        <InventoryHeader />
        <div className='p-3'>
          <StockAdjNewReqBlock/>
         </div>
       </>
    )
}

export default StockAdjNewReqPage