import React from 'react'
import DetailWarehouse from '../../Blocks/DetailWarehouse'
import InventoryHeader from '../../Blocks/InventoryHeader'

const Warehousedetails = () => {
  return (
    <>
        <InventoryHeader/>
        <div className='p-3'>
            <DetailWarehouse />
        </div>
    </>
  )
}

export default Warehousedetails