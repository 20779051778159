import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListItemCategories from '../../Blocks/ListItemCategories'

const ItemCategory = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
        <ListItemCategories QuckSetupMode={false}/>
      </div>
    </div>
  )
}

export default ItemCategory
