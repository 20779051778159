import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const DynamicFormList = () => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [FormsList, setFormList] = useState([{
        Id: "item-master",
        FormName: "Item Master"
    }]);
    const ManageFields = (props) => {
        navigate('/setup/managefields/' + props.Id)
    }

    const ActionTemplate = (form) => {
        return <button className='btn btn-primary' onClick={() => ManageFields(form)}>Manage Fields</button>
    }
  return (
    <>
      <div className='mb-3 row'>
        <div className='col-6'>
          <span>All Forms</span>
        </div>
       </div>
       <div className='row'>
          <DataTable value={FormsList} tableStyle={{ minWidth: '50rem' }}>
            <Column field="FormName" header="Form Name"></Column>
            <Column field="" header="Actions" body={ActionTemplate}></Column>
          </DataTable>
       </div>
       <Dialog header="Add New Bank Account" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
          {/* <AddNewBankAccount CompId = {props.CompId} setVisible={setVisible} /> */}
      </Dialog>
    </>
  )
}

export default DynamicFormList
