import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddNewItemCategory from './AddNewItemCategory';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import EditItemCategory from './EditItemCategory';

const ListItemCategories = (props) => {
  let params = useParams();
  const[PCatId, setPCatId ]= useState("");
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [CategoryList, setCategoryList] = useState([]);
  const [visible, setVisible] = useState({
    open: false,
    mode: "",
    id: "", 

  });
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedrows] = useState(null);
  const [selectedRow, setselectedRow] = useState([]);
  const [SelectedCat, setSelectedCat] = useState();
  const menuRight = useRef(null);
  //const router = useRouter();
  const toast = useRef(null);
  let networkTimeout = null;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {
      warehouseName: { value: '', matchMode: 'contains' },
      cityName: {value: '', matchMode: 'contains'},
      countryName : {value: '', matchMode: 'contains'},
      status: {value: '', matchMode: 'equals'}
    }
  });

  const loadLazyData = () => {
    setLoading(true);
    if (networkTimeout) {
        clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(async () => {
      console.log(lazyState);
      var MyRes = await GetAPICallAsync("GetListItemCategories", { lazyEvent: lazyState }, {PCatID: PCatId ? PCatId : ""});
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setTotalRecords(MyData.total);
        setCategoryList(MyData.data);
        props.QuckSetupMode && props.MyItemTotalCallBack(MyData.total);
      }
      
    setLoading(false);
    }, Math.random() * 1000 + 250);
};

const GetParentCat = async(Id) => {
  var MyRes = await GetAPICallAsync("GetItemCatbyId", { lazyEvent: "" }, {ID: Id});
  if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
  }
  else{
      var MyData = await MyRes.json();
      console.log(MyData);
      setSelectedCat(MyData.data)
  }
};

  const onPage = (event) => {
    setlazyState(event);
};

const onSort = (event) => {
  
    setlazyState(event);
};

const onFilter = (event) => {
  console.log(event);
    event['first'] = 0;
    setlazyState(event);
};

const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedrows(value);
    setSelectAll(value.length === totalRecords);
};

const onSelectAllChange = async (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      //var MyRes = await GetAPICallAsync("GetListItemCategories", { lazyEvent: lazyState }, {PCatID: params.PCatId ? params.PCatId : ""});
            setSelectAll(true);
            // var MyData = await MyRes.json();
            setSelectedrows(CategoryList);
      
    } else {
        setSelectAll(false);
        setSelectedrows([]);
    }
};

useEffect(() => {
  loadLazyData();
}, [lazyState, visible.open, PCatId]);

useEffect (() => {
  loadLazyData();
}, [])

  const ActionTemplate = (cat) =>{
    let url = '/setup/itemcategory/' + cat.id;
    console.log(SelectedCat);
    return <>
      <span className="pi pi-sitemap ms-3 ActionIcon" data-toggle="tooltip" title='Subcategories' onClick={() => {setSelectedCat(cat); setPCatId(cat.id)}}></span>
      <i className="pi pi-info-circle ms-3 ActionIcon" data-toggle="tooltip" title='Info'></i>
      <i className="pi pi-pencil ms-3 ActionIcon" data-toggle="tooltip" title='Edit' onClick={() => setVisible({open: true, mode: "edit", id : cat.id})}></i>
      <i className="pi pi-times ms-3 ActionIcon" data-toggle="tooltip" title='Status'></i>
    </>
  }
  return (
    <div>
      <div className='mb-3 row g-0'>
        <div className='col-6'>
          {PCatId ? <h1 className='ListMainHeading'>Subcategories of   {SelectedCat.catName} </h1>  : <><h1 className='ListMainHeading'>Categories </h1> </>}
        </div>
        <div className='col-6 d-flex justify-content-end'>
         {PCatId && <button className='btn btn-outline-primary' onClick={() => (SelectedCat.pCatId !== "00000000-0000-0000-0000-000000000000" ? GetParentCat(SelectedCat.pCatId): "", setPCatId(SelectedCat.pCatId !== "00000000-0000-0000-0000-000000000000" ? SelectedCat.pCatId : ""))}><i className="pi pi-left" ></i> Back</button>}
          <button className='btn btn-outline-primary ms-2' onClick={() => setVisible({open: true, mode: "add"})}><i className="pi pi-plus" ></i> Add New</button>
        </div>
       </div>
       {PCatId === "" &&  <div className='row mb-2 g-0'>
        <div className='col-12'>
        <span>Just add first level categories. You may add subcategories by click on <i className="pi pi-sitemap mx-1" data-toggle="tooltip" title='Subcategories'></i> under each category.</span>
        </div>
       </div> }
       <div className='row g-0'>
          <DataTable value={CategoryList} removableSort lazy dataKey="id" paginator 
                  first={lazyState.first} rows={5} selectionMode="checkbox" totalRecords={totalRecords} onPage={onPage}
                  onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                  onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                  selection={selectedRows} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column field="catName" header="Category" sortable></Column>
            <Column field="description" header="Description"></Column>
            <Column field="" header="Action" body={ActionTemplate}></Column>
          </DataTable>
       </div>
       <Dialog header={visible.mode === "add" ? "Add New Category" : "Edit Category"} visible={visible.open} style={{ width: '50vw' }} onHide={() => setVisible({open: false})}>
       {visible.mode === "add" && <AddNewItemCategory CompId = {Company.id} PCatId = {PCatId} setVisible={setVisible} />}
       {visible.mode === "edit"&& <EditItemCategory CompId = {Company.id} Id={visible.id} PCatId = {PCatId} setVisible={setVisible} />}
      </Dialog>
    </div>
  )
}

export default ListItemCategories
