import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';

const AddPOItems = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [SearchItems, setSearchItems] = useState([]);
    const [SelectedItem, setSelectedItem] = useState();
    const [state, setState] = useState({
            ItemId: "",
            ItemName: "",
            HSNCode: "",
            SpecialRemarks: "",
            Qty: "",
            UnitId: "",
            UnitName: "",
            SUnitName: "",
            UnitPrice: "",
            Discount: 0,
            Index: "",
            ItemTaxRequest: [{
                TaxId: "",
                TaxName: "",
                TaxPer: "",
                TaxAmount: "",
            }]
           
        }
    );
    
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
   
        if (name === "ItemId" && value !== null && value !== ""){
            let Myitem = props.MyItems.find(x => x.id === value);
            setState((prevProps) => ({
                ...prevProps,
                [name]: value,
                ["ItemName"] : Myitem.name,
                ["UnitName"] : Myitem.itemUnit.unitName,
                ["SUnitName"] : Myitem.sItemUnit.unitShortCode,
                ["UnitId"] : Myitem.itemUnit.id,
                ["HSNCode"] : Myitem.hsnCode,
                ["SpecialRemarks"] : Myitem.description,
            }));
        }
        else {
            setState((prevProps) => ({
                ...prevProps,
                [name]: value
              }));
        }

        if( name === "Qty" || name === "UnitPrice" || name === "Discount"){
            const Taxes = [...state.ItemTaxRequest]
            let MyQty = name === "Qty" ? value : state.Qty;
            let MyUP = name === "UnitPrice" ? value : state.UnitPrice;
            let MyDiscount = name === "Discount" ? value : state.Discount;
            Taxes.map((Item, index) => {
                let ItemT1 = (MyQty * MyUP);
                let Discount = ItemT1 * MyDiscount / 100;
                let ItemT2 = ItemT1 - Discount;
                let TaxAmt = ItemT2 * Item.TaxPer / 100;
                Taxes[index]["TaxAmount"] = TaxAmt != null ? TaxAmt : 0;
                
            })
        }
        
      };

      const handleInputL2Change = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.ItemTaxRequest]
        
        if(name === "TaxId" && value !== null && value !== "") {
            const Tax = props.MyTaxes.find(x => x.id == value);      
            values[index]["TaxName"] = Tax.taxName;
            console.log(Tax.taxValue);
            values[index]["TaxPer"] = Tax.taxValue;
            let ItemT1 = (state.Qty * state.UnitPrice);
            let Discount = ItemT1 * state.Discount / 100;
            let ItemT2 = ItemT1 - Discount;
            let TaxAmt = ItemT2 * Tax.taxValue / 100;
            values[index]["TaxAmount"] = TaxAmt != null ? TaxAmt : 0;
        }
        
        if(name === "TaxPer"){
            let ItemT1 = (state.Qty * state.UnitPrice);
            let Discount = ItemT1 * state.Discount / 100;
            let ItemT2 = ItemT1 - Discount;
            let TaxAmt = ItemT2 * value / 100;
            values[index]["TaxAmount"] = TaxAmt != null ? TaxAmt : 0;
        }
        else if (name === "TaxAmount"){
            let ItemT1 = (state.Qty * state.UnitPrice);
            let Discount = ItemT1 * state.Discount / 100;
            let ItemT2 = ItemT1 - Discount;
            let TaxPer = (value / ItemT2) * 100;
            values[index]["TaxPer"] = TaxPer != null ? TaxPer : 0;
        }
        
        values[index][name] = value;
        setState((prevProps) => ({
            ...prevProps,
            ItemTaxRequest: values
          }));
      };

      const handleAddMoreItem = (index) => {
       
        const values = [...state.ItemTaxRequest ]
        values.splice(index + 1, 0, {"TaxId": "","TaxPer": "", "TaxAmount": "",} )
        setState((prevProps) => ({
            ...prevProps,
            ItemTaxRequest: values
          }));
      }

      const handleremoveitem = (index) => {
             
        const values = [...state.ItemTaxRequest]
        if(values.length > 1){
            values.splice(index, 1)
            setState((prevProps) => ({
                ...prevProps,
                ItemTaxRequest: values
              }));    
        }
      }
    
    const search = (event) => {
        console.log(props.MyItems)
        setSearchItems(props.MyItems.filter(x => x.name.toLowerCase().startsWith(event.query.toLowerCase()) ));
    }
    const AutoCompleteChange = (e)=> {
        console.log(e.value);
        setSelectedItem(e.value);
        if (e.value != null){
            setState((prevProps) => ({
            ...prevProps,
            ["ItemId"] : e.value.id,
            ["ItemName"] : e.value.name,
            ["UnitName"] : e.value.buyingUnitName,
            ["SUnitName"] : e.value.sellingUnitName,
            ["UnitId"] :    e.value.buyingUnit,
            ["HSNCode"] : e.value.hsnCode,
            ["SpecialRemarks"] : e.value.description,
          }));
        }
        

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        props.AddmoreItems(state);
    }


  return (
    <div>
    {Message ? <div className='col'>
         <span>{Message}</span>
         <button onClick={() => props.setVisible(false)}>Close</button>
     </div> :
   <form onSubmit={handleSubmit}>
         <div className="col-12 mb-3">
         
            <label className="form-label">Select Item<span className='color-red'>*</span></label>
            <AutoComplete value={SelectedItem} suggestions={SearchItems} forceSelection field="name" completeMethod={search} onChange={AutoCompleteChange} style={{ width: '100%' }} 
            placeholder='Type Item Name to search..' />
            {/* <select name="ItemId" className="form-select" value={state.ItemId} onChange={handleInputChange} >
                <option value="">Select Item</option>
                {props.MyItems.map((item) => (<option key={item.id} value={item.id}>{item.name}</option>))} 
            </select> */}
         </div>
         <div className='mb-3'>
            <label htmlFor="SpecialRemarks" className="form-label">Details</label>
            <input type="text"  name="SpecialRemarks" className="form-control" id="SpecialRemarks" value={state.SpecialRemarks} onChange={handleInputChange} placeholder="Details"/>
        </div>
        <div className='row'>

        
         <div className="col-3 mb-3">
             <label className="form-label">Qty<span className='color-red'>*</span></label>
              <input type="text"  name="Qty" className="form-control" id="Qty" value={state.Qty} onChange={handleInputChange} placeholder="Qty"/>
              {props.OFrom === "SO" ? state.SUnitName : state.UnitName }
         </div>
         <div className='col-3 mb-3'>
            <label htmlFor="UnitPrice" className="form-label">Unit Price</label>
            
            <input type="text"  name="UnitPrice" className="form-control" id="UnitPrice" value={state.UnitPrice} onChange={handleInputChange} placeholder="Unit Price"/>
    
        </div>
       
        <div className='col-3 mb-3'>
            <label htmlFor="Discount" className="form-label">Discount (% percentage)</label>
            <input type="text"  name="Discount" className="form-control" id="Discount" value={state.Discount} onChange={handleInputChange} placeholder="Discount"/>
        </div>
        <div className='col-3 mb-3'>
            <label htmlFor="ItemTotal" className="form-label">Item Total</label>
            <input type="text" readOnly  name="ItemTotal" className="form-control" id="ItemTotal" value={(state.Qty * state.UnitPrice) - (state.Qty * state.UnitPrice * state.Discount / 100)} onChange={handleInputChange} placeholder="Sub Total"/>
        </div>
        </div>
        <table className='table '>
            <thead>
            <tr>
                <th>Tax</th>
                <th>Tax Percentage</th>
                <th>Tax Amount</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            
        {state.ItemTaxRequest.map((Item, index) => (
            <tr>
                <td>
                    <select name="TaxId" id="TaxId" className="form-select" value={Item.TaxId} onChange={event => handleInputL2Change(index, event)}>
                        <option value="">Select Tax</option>
                        {props.MyTaxes.map((item) => (<option key={item.id} value={item.id}>{item.taxName}</option>))} 
                    </select>
                </td>
                <td>
                    <input type="text"  name="TaxPer" className="form-control" id="TaxPer" value={Item.TaxPer} onChange={event => handleInputL2Change(index, event)} placeholder="Tax Percentage"/>
                </td>
                <td>
                    <input type="text"  name="TaxAmount" className="form-control" id="TaxAmount" value={Item.TaxAmount} onChange={event => handleInputL2Change(index, event)} placeholder="Tax Amount"/>
                </td>
                <td>
                    <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index)}></i>
                    <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
                </td>
            </tr>
        ))}
        </tbody>
        </table>
         <div className='mt-4'>
             <button type="submit" className="btn btn-primary">Submit</button>
         </div>
     </form>}
 </div>
  )
}

export default AddPOItems
