import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';

const ReceivingDetailBlock = () => {
    const Params = useParams();
    const [OrderReceiving, setOrderReceiving] = useState();
    console.log(Params);
    const navigate = useNavigate();
    const GetOrderReceiving = async () => {
        var MyRes = await GetAPICallAsync("GetOrderReceivingById", { lazyEvent: "" }, {ORId : Params.Id});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setOrderReceiving(MyData.data);
        }
    }
    useEffect(()=> {
        GetOrderReceiving();
    }, [])

  return (
    OrderReceiving && <>
    <div className='row mt-3'>
    <div className='col-6'><h1 className='ListMainHeading'>Order Receiving: {OrderReceiving.orNo}</h1></div>
       {/* Action Buttons */}
       <div className="col-6 text-end">
        {/* <button className='btn btn-outline-primary me-2'>Edit Invoice</button>
        <button className='btn btn-outline-primary me-2'>Approve</button>
        <button className='btn btn-outline-primary me-2'>Reject</button> */}
        <button className='btn btn-outline-primary me-2'>Print</button>
        {/* <button className='btn btn-outline-primary me-2'>Make Payment</button> */}
      </div>
    </div>
    

      <div className='row mt-3'>

      {/* Vendor Information */}
    
      <div className="col-6" style={{padding: "30px 30px", border: "1px solid #e7e7e7" }}>
        <h2 className='ListMainHeading text-center'>Vendor Information</h2>
        {/* <p className='mt-2'><strong>Vendor ID:</strong> V001</p> */}
        <p className='mt-3'><strong>Vendor Name:</strong> {OrderReceiving.vendorInfo.supName}</p>
        <p><strong>Vendor Address:</strong> {OrderReceiving.vendorInfo.localAddress} {OrderReceiving.vendorInfo.cityName} {OrderReceiving.vendorInfo.stateName} {OrderReceiving.vendorInfo.countryName} {OrderReceiving.vendorInfo.zipCode}</p>
        {/* <p><strong>Payment Terms:</strong> Net 30</p> */}
        <p><strong>Contact Person:</strong> {OrderReceiving.vendorInfo.contactPersonName} ({OrderReceiving.vendorInfo.email})</p>
        <p><strong>GST:</strong> {OrderReceiving.vendorInfo.gst}</p>
        <p><strong>TAN:</strong> {OrderReceiving.vendorInfo.tan}</p>
      </div>


      {/* Invoice Details */}
      <div className="col-6" style={{ padding: "30px 30px", border: "1px solid #e7e7e7", marginLeft: "-1px" }}>
      <h2 className='ListMainHeading text-center'>Details</h2>
      <div className="row">
        <div className='col-12 mt-2'>
        <p><strong>OR No:</strong>  {OrderReceiving.orNo}</p>
        <p><strong>PO No:</strong>  {OrderReceiving.poNo}</p>
        <p><strong>Receiving Date:</strong>  {moment(OrderReceiving.date).format("DD/MM/yyyy")}</p>
        <p><strong>WareHouse:</strong>  {OrderReceiving.warehouseName}</p>

        {/* <p><strong>Due Date:</strong> 31/10/2024</p> */}
        {/* <p><strong>PO No:</strong> {OrderReceiving.poNo}</p> */}
        </div>
       
        </div>
      </div>


      {/* Itemized Details */}
      <div className="col-12 mt-5">
        <h2 className='ListMainHeading'>Itemized Details</h2>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Name</th>
              <th className='text-end'>Ordered Quantity</th>
              <th className='text-end'>Received Quantity</th>
              <th className='text-end'>Invoiced Quantity</th>
            </tr>
          </thead>
          <tbody>
            {OrderReceiving.items.map((item, index) => (
              <tr key={index}>
              <td>{item.itemCode}</td>
              <td>{item.foreignName}</td>
              <td className='text-end'>{item.orderQty} {item.unitShortName}</td>
              <td className='text-end'>{item.rQty} {item.unitShortName}</td>
              <td className='text-end'>{item.apInvQty} {item.unitShortName}</td>
            </tr>
            ))}
            
     
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>

    </div>

    </>
  )
}

export default ReceivingDetailBlock