import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import { AutoComplete } from 'primereact/autocomplete';

const SubmitStockNewReqBlock = () => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const [Items, setItems] = useState([]);
  const [SearchItems, setSearchItems] = useState([]);
  const [myFGACItem, setmyFGACItem] = useState([]);
  const [Warehouses, setWarehouses] = useState([]); 
  const [Message, setMessage] = useState();
  const [ConfirmVisible, setConfirmVisible] = useState(false);
  const [SelectFWHID, setSelectFWHID] = useState("");
  const [Index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 0,
      sortField: null,
      sortOrder: 0,
  });
  const [state, setState] = useState ({
      SubmitTo: "",
      SubmitFrom: "",
      ReferenceType: "",
      ReferenceNumber: "",
      CompanyId : Company.id,
      Comments : "Please submit following items.",
      SubmitItems : [{
          ItemId: "",
          ItemName: "",
          Index: 0,
          Qty: "",
          RemarksIfany: "",
          UnitId: "",
          UnitName: "",
          SpecialRemarks: "",
          AvailableQty: 0,
          InvId: "",
      }],

  });

  const getTimeZone = () => {
                      
      const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setState((prevProps) => ({
          ...prevProps,
          "TimeZone": MytimeZone
        }));
  };

//     const accept = () => {
//         setState((prevProps) => ({
//             ...prevProps,
//             FromWHId: SelectFWHID,
//             STOItemsRequest: [{"ItemId": "", "Index": 0, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", "AvailableQty": 0, "InvId": "" }],
//           }));
      
//         setMySelectedItem([]);
//    }

//     const reject = () => {
//     }

  const handleInputChange = (event) => {
      const { name, value } = event.target;
      if(name=== "FromWHId" && (state.STOItemsRequest.length > 1 || state.STOItemsRequest[0]["ItemId"] !== "")){
          setSelectFWHID(value);
          setConfirmVisible(true);
          
      }
      else{
          setState((prevProps) => ({
              ...prevProps,
              [name]: value
            }));
      }
     
    };
    const search = (event, index) => {
          setSearchItems(Items.filter(x => x.name.toLowerCase().startsWith(event.query.toLowerCase())));
      }
      
      const AutoCompleteChange = (e, index) => {
          let values = [];
          let valueSelectedItem = [];

          
          values = [...state.SubmitItems];
          valueSelectedItem = [...myFGACItem];
        
        
          const selectedItem = e.value;
          // valueSelectedItem.splice(index, 0, selectedItem)
          valueSelectedItem[index] = selectedItem;        
          
      
          if (selectedItem) {
              values[index] = {
                  ...values[index],
                  ItemId: selectedItem.id,
                  ItemName: selectedItem.name,
                  UnitName: selectedItem.inventoryUnitName,
                  UnitId: selectedItem.inventoryUnit,
                  HSNCode: selectedItem.hsnCode,
                  SpecialRemarks: selectedItem.description
              };

              console.log(selectedItem.listInventories);

              if(selectedItem.listInventories && selectedItem.listInventories.length > 0)
                  {
                      var MyList = selectedItem.listInventories.find(x => x.warehouseId == state.SubmitTo);
                      if(MyList !== null && MyList !== undefined){
                          console.log(MyList.id);
                          values[index]["AvailableQty"] = MyList.availableQty;
                          values[index]["InvId"] = MyList.id;
                      }
                      else{
                          values[index]["AvailableQty"] = 0;
                      }
                  }
                  else{
                      values[index]["AvailableQty"] = 0;
                  }


                  
                  setmyFGACItem(valueSelectedItem)
                  setState(prevState => ({
                      ...prevState,
                      SubmitItems: values
                  }));
                 
   
          }
      }


  const handleWlcomeEditorChange = (event) => {
      setState((prevProps) => ({
        ...prevProps,
        "Comments": event.htmlValue,
      }));
    };

    const handleL2InputChange = ( index, event) => {
      const { name, value } = event.target;
      let values = []
          values = [...state.SubmitItems];
          values[index][name] = value;
        //   if(name === "Qty" && value > state.SubmitItems[index]["AvailableQty"]){
        //       values[index][name] = state.SubmitItems[index]["AvailableQty"];
        //       alert("Qty can't be more than available quantity.")
        //   }
      
          setState((prevProps) => ({
              ...prevProps,
              SubmitItems: values
          }));
      
        
      
      
    };

    
    const handleAddMoreItem = (index) => {
      console.log(index);
      index && setIndex (index + 1);
      
      const values = [...state.SubmitItems]
      values.splice(index + 1, 0, {"ItemId": "", "Index": index + 1, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", "AvailableQty": 0 } )
      setState((prevProps) => ({
          ...prevProps,
          SubmitItems: values
          }));

      let IValues = [...myFGACItem];
      IValues.splice(index + 1, 0, "")
      setmyFGACItem(IValues);
      
      
      
    }

  const handleremoveitem = (index) => {
          
      const values = [...state.SubmitItems]
      if(values.length > 1){
          values.splice(index, 1)
          setState((prevProps) => ({
              ...prevProps,
              SubmitItems: values
          }));

          let IValues = [...myFGACItem];
          IValues.splice(index, 1)
          setmyFGACItem(IValues);
      } 
    }


      const GetWareHouseDD = async () => {
          var MyRes = await GetAPICallAsync("GetListCompanyWarehouses", { lazyEvent: lazyState }, "");
          if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
          }
          else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setWarehouses(MyData.data);
          }
      };


      const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/ListItemMasters?' + new URLSearchParams({
          CompanyId: Company && Company.id,
          }), {
          method: 'Get',
          headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer ' + tokenString
          },
        })
        .then(res => {
            
            if(res.status === 401){ navigate("/login"); }
            return res.json()
        } ))
      .then(data => {
          console.log(data.data)
          setItems(data.data);
      })
      .catch((err) => {
      console.log(err.message);
      })

      useEffect (() => {   
          calltpAPI();
          getTimeZone();
          GetWareHouseDD();
      }, []);
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      console.log(state);
      let MyRes = await  POSTAPICallAsync("AddSubmitStockRequest", state);
      console.log(MyRes);
      if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
      }
      else{
          var MyData = await MyRes.json();
          console.log(MyData);
          if (MyData.success === true ){
              navigate("/inventory/submitstockrequest");
          }
          else{
              setMessage(MyData.message);
          }
          
      }
      
      };
return (
  <>
  <div className='mb-3 row'>
      <div className='col-6'>
          <h1 className='ListMainHeading'>New Submit Request</h1>
      </div>
      <div className='col-6 d-flex justify-content-end'>
         
      </div>
      </div>
      <div className='row'>
  
 <div>
   <form onSubmit={handleSubmit}>

   <div className='row'>
           {/* <div className="col-6 mb-3">
               <label htmlFor="Heading" className="form-label">Document Name<span className='color-red'>*</span></label>
               <input type="text" required name="Name" className="form-control" id="Name" value={state.Name} onChange={handleInputChange} placeholder="Enter document name"/>
           </div> */}
           <div className="col-3 mb-3">
               <label htmlFor="SubmitTo" className="form-label">Submit To<span className='color-red'>*</span></label>
               
               <select name="SubmitTo" required className="form-select" value={state.SubmitTo} onChange={handleInputChange} aria-label="Default select example">
                  <option value={""}>Select Any</option>
                  {Warehouses.filter(x => x.id !== state.ToWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
              </select>

           </div>
           <div className="col-3 mb-3">
               <label htmlFor="SubmitFrom" className="form-label">Submit From<span className='color-red'>*</span></label>
                <select name="SubmitFrom" required className="form-select" value={state.SubmitFrom} onChange={handleInputChange} aria-label="Default select example">
                  <option value={""}>Select Any</option>
                  <option value={"production"}>Production</option> 
                </select>
           </div>
           <div className="col-3 mb-3">
               <label htmlFor="ReferenceType" className="form-label">Reference<span className='color-red'>*</span></label>
               <select name="ReferenceType" required className="form-select" value={state.ReferenceType} onChange={handleInputChange} aria-label="Default select example">
                  <option value={""}>Select Any</option>
                  <option value={"prodono"}>Production Order Number</option>
                  
              </select>

           </div>
           <div className="col-3 mb-3">
               <label htmlFor="ReferenceNumber" className="form-label">Ref. Number<span className='color-red'>*</span></label>
               <input type="text" required name="ReferenceNumber" className="form-control" id="Name" value={state.ReferenceNumber} onChange={handleInputChange} placeholder="Enter document name"/>
           </div>
           {/* <div className="col-6 mb-3">
               <label htmlFor="Heading" className="form-label">Heading<span className='color-red'>*</span></label>
               <input type="text" required name="Heading" className="form-control" id="Heading" value={state.Heading} onChange={handleInputChange} placeholder="Enter Heading here"/>
           </div> */}
       </div>
       <div className='row'>
          {/* <div className="col-3 mb-3">
              <label htmlFor="PONo" className="form-label">PO No<span className='color-red'>*</span></label>
              <input type="text" required name="PONo" className="form-control" id="PONo" value={state.PONo} onChange={handleInputChange} placeholder="Enter company name"/>
          </div> */}
           {/* <div className="col-3 mb-3">
               <label htmlFor="STODate" className="form-label">STO Date<span className='color-red'>*</span></label>
               
               <Calendar required name="STODate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={state.STODate} onChange={handleInputChange} showButtonBar showIcon ></Calendar>

           </div> */}
           {/* <div className="col-3 mb-3">
               <label htmlFor="FromWHId" className="form-label">FG Store<span className='color-red'>*</span></label>
               
               <select name="FromWHId" className="form-select" value={state.FromWHId} onChange={handleInputChange} aria-label="Default select example">
                  <option value={""}>Select Any</option>
                  {Warehouses.filter(x => x.id !== state.ToWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
              </select>

           </div>
           <div className="col-3 mb-3">
               <label htmlFor="DealerId" className="form-label">RM Store<span className='color-red'>*</span></label>
               
               <select name="ToWHId" className="form-select" value={state.ToWHId} onChange={handleInputChange} aria-label="Default select example">
                  <option value={""}>Select Any</option>
                  {Warehouses.filter(x => x.id !== state.FromWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
              </select>

           </div>
           <div className="col-3 mb-3">
               <label htmlFor="FromWHId" className="form-label">Scrap/ Reject Store<span className='color-red'>*</span></label>
               
               <select name="FromWHId" className="form-select" value={state.FromWHId} onChange={handleInputChange} aria-label="Default select example">
                  <option value={""}>Select Any</option>
                  {Warehouses.filter(x => x.id !== state.ToWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
              </select>

           </div> */}

           

       </div>
       
      
       
       <div className="mb-3">
           <label htmlFor="Comments" className="form-label">Comments (if any)</label>
           <Editor id="Comments" value={state.Comments} onTextChange={handleWlcomeEditorChange} style={{ height: '100px' }} />
       </div>

       <div className='row'>
          <div className='col-12'>
              <h5>Submit following Items</h5>
          </div>
       </div>
      
       <table className='table table-bordered'>
           <thead>
               <tr>
                   <th> Item</th>
                   <th>HSN</th>
                   <th> Qty</th>
                   <th>Remarks If any</th>
                   <th></th>
               </tr>
              
           </thead>
           <tbody>
       {state.SubmitItems.map((Item, index) => (
           <tr key={index}>
               <td>
             
                      <AutoComplete 
                          value={myFGACItem[index]} 
                          suggestions={SearchItems} 
                          forceSelection 
                          field="name" 
                          completeMethod={(e) => search(e, index)} 
                          onChange={(e) => AutoCompleteChange(e, index)} 
                          style={{ width: '100%' }} 
                          placeholder='Type Item Name to search..' 
                      />
                  {Item.SpecialRemarks}
            
                  
               </td>
               <td>
                  {Item.HSNCode}
               </td>
               <td>
                    <input type="text"  name="Qty" className="" id="Qty" value={Item.Qty} onChange={event => handleL2InputChange(index, event, "fg")} placeholder="Qty"/>
                    &nbsp;{Item.UnitName}
               </td>
               <td>
                    <input type="text"  name="RemarksIfany" className="" id="RemarksIfany" value={Item.RemarksIfany} onChange={event => handleL2InputChange(index, event, "fg")} placeholder="Remarks"/>
               </td>
               <td>
                   <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index, "fg")}></i>
                   <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index, "fg")}></i>
               </td>
           </tr>

       ))}
   

       {/* <tr>
           <td colSpan={3}>
               <button className='btn btn-primary' onClick={() => AddMoreItem()}>Add</button>
           </td>
       </tr> */}
       </tbody>
       </table>

      
       
       

       <div className='mt-4'>
           <button type="submit" className="btn btn-primary">Submit</button>
       </div>
   </form>
   {/* <ConfirmDialog group="declarative"  visible={ConfirmVisible} onHide={() => setConfirmVisible(false)} message="This will reset the items in list. Are you sure you want to proceed?" 
              header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} /> */}
</div>
</div>
<span>{Message}</span>
      {/* <Dialog header="Add New Item" visible={visible} style={{ width: '70vw' }} onHide={() => setVisible(false)}>
       <AddSTOItems MyItems = {Items} MyTaxes= {Taxes} AddmoreItems= {AddmoreItems}/>
      </Dialog> */}
</>
)
}

export default SubmitStockNewReqBlock