import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const EditCompanyUser = (props) => {
    const Company = JSON.parse(localStorage.getItem('Company'));
const navigate = useNavigate();
const [Message, setMessage] = useState();
const [ShowForm, setShowForm] = useState(true);
const [UserRegistered, setUserRegistered] = useState(false);
const [AlreadyUser, setAlreadyUser] = useState(true);
const [EmailId, setEmailId] = useState();
const [AllProfiles, setAllProfiles] = useState([]);
const [state, setState] = useState({
    Id : "",
    CompanyId : Company.id,
    UserId : "",
    ProfileId : "",
    Name : "",
    UserName : ""
});
const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 0,
    page: 1,
    sortField: "",
    sortOrder: 0,
});

const GetCompanyUserById = async () => {
    var MyRes = await GetAPICallAsync("GetCompanyUserById", { lazyEvent: lazyState }, {Id : props.MyVisible.id});
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setState((prevProps) => ({
            ...prevProps,
            Id : MyData.data.id,
            UserId : MyData.data.userId,
            ProfileId : MyData.data.profileId,
            Name : MyData.data.name,
            UserName : MyData.data.userName
        }))
    }
}

const GetAllprofiles = async () => {
    var MyRes = await GetAPICallAsync("GetListProfiles", { lazyEvent: lazyState }, "");
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setAllProfiles(MyData.data);
    }
}

useEffect(() => {
    GetAllprofiles();
    GetCompanyUserById();

}, [])


const handleInputchange = (event) => {
    const {name, value} = event.target;
    setState((prevProps) => ({
        ...prevProps,
        [name] : value,
    }))

}


const handleSubmit = async (event) => {
event.preventDefault();
let MyRes = await  POSTAPICallAsync("EditCompanyUser", state);
console.log(MyRes);
if (MyRes.status === 401){
    localStorage.removeItem("User_Obj");
    localStorage.removeItem("Company");
    localStorage.removeItem("token");
    navigate("/login");
}
else{
    var MyData = await MyRes.json();
    console.log(MyData);
    if (MyData.success == true ){
        setMessage(MyData.message);
        setShowForm(false);
        props.setVisible({open: false});
    }
    else if (MyData.success == false){
        setMessage(MyData.message);
    }
    
}
}
  return ( 
        <form className='mt-3' onSubmit={handleSubmit }>
            <div className='row'>
                <div className='col-12'>
                <label htmlFor="ProfileId" className="form-label">Select Profile</label>
                <select name='ProfileId' className='form-select' required value={state.ProfileId} onChange={handleInputchange}>
                    <option value="">Select One</option>
                    {AllProfiles.map((item, index) => (<option key={index} value={item.id}>{item.name}</option>))}
                </select>
                
                </div>
                <div className='col-12 mt-3'>
                    <button type="submit" className="btn btn-primary">Edit</button>
                </div>
            </div>
            
          
        </form>
  )
}

export default EditCompanyUser