import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import DealerDetailBlock from '../../Blocks/DealerDetailBlock'

const DealerDetailPage = () => {
  return (
    <div>
    <InventoryHeader/>
    <div className='p-3'>
        <DealerDetailBlock />
      </div>
  </div>
  )
}

export default DealerDetailPage