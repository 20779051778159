import React, { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom'

const LoginForm = () => {
    const navigate = useNavigate();
    const [ErrorMsg, setErrorMsg] = useState();
    const [state, setState] = useState ({
        Email:"",
        Password: "",
        RememberMe: false,
       
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
      const handleSubmit = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_API_URL + 'Auth/login', {
         method: 'POST',
         body: JSON.stringify(state),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
         },
      })
         .then((res) => res.json())
         .then((data) => {
            if(data.succeed){
                console.log(data.user);
                localStorage.setItem("token", JSON.stringify(data.token) );
                if(data.user.emailConfirmed){
                    localStorage.setItem("User_Obj", JSON.stringify(data.user) );
                    navigate("/setup/companies");
                }
                else{
                    navigate("/completesignup/verifyotp")
                }
                
            }
            else{
                console.log(data);
                setErrorMsg(data.errorMessage);
            }
         })
         .catch((err) => {
            console.log(err.message);
         });
      };
     
  return (
    <div className='ps-lg-5'>
        <h1 className='MainHeading mb-2'>Login</h1>
        <span className='Error'>{ErrorMsg}</span>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Email address</label>
                <input required type="email" className="form-control" id="Email" name="Email" value={state.Email} onChange={handleInputChange}  placeholder="Enter email"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Password</label>
                <input required type="password" className="form-control" name="Password" value={state.Password} onChange={handleInputChange}  id="Password" placeholder="Password"/>
            </div>
            <div>
             <span>Don't have an account?</span> <Link to='/signup'>Signup</Link>
            </div>
           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
           
        </form>
    </div>
  )
}

export default LoginForm
