import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';
import { TabPanel, TabView } from 'primereact/tabview';
import ListPurchaseOrders from './ListPurchaseOrders';
import ListReceivings from './ListReceivings';
import ListAPInvoices from './ListAPInvoices';
import ListSalesOrders from './ListSalesOrders';
import ListARInvoices from './ListARInvoices';

const DealerDetailBlock = () => {
    const Params = useParams();
  const [dealer, setDealer] = useState();
  console.log(Params);
  const navigate = useNavigate();
  const [SOFilters, setSOFilters] = useState({
    PONO: "",
    PODate: "",
    DocumentName: "",
    DealerId: "",
});

const [ARInvFilters, setARInvFilters] = useState({
  ORNo: "",
  PONO: "",
  Date: "",
  Warehouse: "",
  DealerId: ""
});
  const GetDealer = async () => {
      var MyRes = await GetAPICallAsync("GetDealerbyId", { lazyEvent: "" }, {ID : Params.Id});
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData.data);
        setDealer(MyData.data);
        setSOFilters((prevProps) => ({
          ...prevProps,
          "DealerId" : MyData.data.id

        }))
        setARInvFilters((prevProps) => ({
          ...prevProps,
          "DealerId" : MyData.data.id

        }))
      }
  }
  useEffect(()=> {
    GetDealer();
  }, [])

  return (
    <>
    {dealer && <div className="supplier-detail-container">
      <div className='row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Dealer Detail</h1>
        </div>
        <div className='col-6 d-flex justify-content-end text-end'>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
       {/* <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "300px" : "0px")}></i> */}
        {/* <button className='btn btn-outline-primary' onClick={() => setVisible({"open": true, "mode": "add"})} ><i className="pi pi-plus" ></i> Add New</button> */}
        </div>
        </div>
      <div className="table-responsive mt-3">
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Dealer Name</th>
              <th>Business Type</th>
              <th>Contact Person</th>
              <th>Email</th>
              <th>Phone</th>
              <th>GST</th>
              <th>PAN</th>
              <th>TAN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{dealer.dealerName}</td>
              <td>{dealer.businessType}</td>
              <td>{dealer.contactPersonName}</td>
              <td>{dealer.email}</td>
              <td>{dealer.phone}</td>
              <td>{dealer.gst}</td>
              <td>{dealer.pan}</td>
              <td>{dealer.tan}</td>
             
            </tr>
          </tbody>
        </table>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TabView>
            {/* <TabPanel header="Analytics">

            </TabPanel> */}
            <TabPanel header="Addresses">
              {dealer.dealerAddresses && 
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Address Type</th>
                    <th>Local Address</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Country</th>
                    <th>Pin Code</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dealer.dealerAddresses.map((item, index) => (
                      <tr key={index}>
                        <td>{item.addressType}</td>
                        <td>{item.streetAddress}</td>
                        <td>{item.city.cityName}</td>
                        <td>{item.state.stateName}</td>
                        <td>{item.country.countryName}</td>
                        <td>{item.zipCode}</td>
                      </tr>

                    ))
                  }
                </tbody>
              </table>
              
              
                
              }
            </TabPanel>
            <TabPanel header="Sales Orders">
              <ListSalesOrders Filters={SOFilters} HideButtons={true}/>
            </TabPanel>
            <TabPanel header="AR Invoices">
              <ListARInvoices Filters={ARInvFilters} HideButtons={true} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  }
  </>
  )
}

export default DealerDetailBlock