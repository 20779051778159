import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';

const Addnewapinvoice = () => {
    const [PONo, setPONo] = useState("");
    const navigate = useNavigate();
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [Suppliers, setSuppliers] = useState([]);
    const [Receivings, setReceivings] = useState([]);
    const [PO, setPO] = useState();
    const [Message, setMessage] = useState();
    const [Currency, setCurrency] = useState();
    const [FetchbyPOMessage, setFetchbyPOMessage] = useState("");
    const [MySrNos, setMySrNos] = useState("");
    const [AllUniqueTaxes , setAllUniqueTaxes] = useState([]);
    const [PItems, setPItems] = useState([]);
    const [GSTTaxType, SetGSTTaxType] = useState("");
    const [SelectedSupplier, setSelectSupplier] = useState() 
    const [SearchSupplier, setSearchSupplier] = useState([]);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: "",
    });
    const [itemMatadata, setItemMetaData] = useState({
      visible : "",
      itemIndex: "",
      trackBy: "",
    });
    const [Batches, setBatches] = useState ([{
      BatchNo: "",
      ManufBNo : "",
      ManufacturingDate: "",
      ExpiryDate:"",
      Qty: "",

    }])
    const [state, setState] = useState ({
        POId : "",
        ORIds: [],
        Date : new Date(),
        TimeZone: "",
        CompanyId : Company.id,
        SupplierId: "",
        InvNo: "",
        CurrencyId: "",
        TermsandConditions : "",
        APInvoiceItems : []
    });
    const AddItemMetadata = (Index) => {
      let MyPOItem = state.APInvoiceItems[Index]; 
      console.log(MyPOItem);
      setItemMetaData((prevProps) => ({
        ...prevProps,
        visible : true,
        itemIndex : Index,
        trackBy: MyPOItem.trackBy
      }))
      if(MyPOItem.trackBy === "batch"){
        
        MyPOItem.Batches.length > 0 && setBatches(MyPOItem.Batches);
      }
      else if(MyPOItem.trackBy === "serialno"){
        setMySrNos(MyPOItem.SrNos);
      }
      
    }

    const handleInputChange = (event) => {
      const { name, value } = event.target;
    
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
    };

    const handleL2InputChange = (event, Index) => {
      let { name, value } = event.target;
      let POItems = [...state.APInvoiceItems];
      POItems[Index][name] = value;
      POItems[Index]["invQty"] = value * POItems[Index]["ConvRate"];
      if(name === "rQty"){
        value = value === "" ? 0 : value;
        let MyCQty = parseFloat(POItems[Index]["CrQty"]);
        let MyInvoicedQty = parseFloat(POItems[Index]["invoicedQty"]);
        console.log(MyCQty + "--- " + MyInvoicedQty + "---" + value)
        if((parseFloat(value) + parseFloat(MyInvoicedQty)) <= parseFloat(MyCQty)){
          setState((prevProps) => ({
            ...prevProps,
            APInvoiceItems: POItems
          }));
          CalculateAllTaxes(POItems);
        }
        else{
          alert("This value is not allowed.")
        }
      }
      else {
        setState((prevProps) => ({
          ...prevProps,
          APInvoiceItems: POItems
        }));
        CalculateAllTaxes(POItems);
      }
      

    }

    const handleBatchInputChange = (Index, event) => {
      const { name, value } = event.target;
     let values = [...Batches];
     values[Index][name] = value;
      setBatches(values);
    };

    const GetSuppliersDD = async () => {
      var MyRes = await GetAPICallAsync("GetListSuppliersDD", "", "");
      if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
      }
      else{
      var MyData = await MyRes.json();
      setSuppliers(MyData.data);
      }
  };


  const handleAddMoreItem = (index) => {
    const values = [...Batches ]
    values.splice(index + 1, 0, {"BatchNo": "", "ManfBatchNo" : "", "ManfDate": "", "ExpiryDate":"", "Qty": ""} )
    setBatches(values);
  }

  const handleremoveitem = (index) => {
         
    const values = [...Batches]
    if(values.length > 1){
        values.splice(index, 1)
        setBatches(values);
    }
  }

  const getTimeZone = () => {
                        
    const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setState((prevProps) => ({
        ...prevProps,
        "TimeZone": MytimeZone
      }));

  };


    const handlePOSearch = (event) => {
        const { value } = event.target;
        setPONo(value);
    }

    useEffect (() => {   
      GetSuppliersDD();
      getTimeZone();
    }, []);
    

    const GetPODetails = async (event) => {
        event.preventDefault();
        var MyRes = await GetAPICallAsync("GetPobyno", "", {"PONo" : PONo});
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        setFetchbyPOMessage("");
        var MyData = await MyRes.json();
        console.log(MyData.data)
        setPO(MyData.data);
        setCurrency(MyData.data.currency);
        let MyReceivings = [];
        if(MyData.success === true){
          if(MyData.data.orderReceivings.length > 0){
            MyData.data.orderReceivings.map((item) => {
            return MyReceivings = [...MyReceivings, {"id": item.id, "Rec_no": item.orNo, "isChecked": false}]
            })
          }
          else{
            setFetchbyPOMessage("No receiving found for this PO No.")
          }
        }
        else
        {
          setFetchbyPOMessage(MyData.message);
        }
        setReceivings(MyReceivings);
        
      }
      
    }

    const search = (event) => {
      setSearchSupplier(Suppliers.filter(x => x.supName.toLowerCase().startsWith(event.query.toLowerCase()) ));
    }
    const AutoCompleteChange = (e)=> {
      console.log(e);
      setSelectSupplier(e.value);
      if (e.value != null){
          setState((prevProps) => ({
          ...prevProps,
          ["SupplierId"] : e.value.id,
        }));
      }
  }

    const GetTaxType = async () => {
      var MyRes = await GetAPICallAsync("GetCompanyAndPartyAddress", "", {MyPartyId : PO.supplierId, MyPartyType : "supplier"});
                if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
                }
                else{
                var MyData = await MyRes.json();
                  console.log(MyData.data);
                  let ApplicableGSTTax = "";
                  if(MyData.data.companyAddress.stateId == MyData.data.supplierAddress.stateId ){
                    ApplicableGSTTax = "intrastate";
                  }
                  else{
                    ApplicableGSTTax = "interstate";
                  }
                  SetGSTTaxType(ApplicableGSTTax);
                  return ApplicableGSTTax
                }
    }

    const GetTaxslabs = async (ItemTaxes, AppTaxType) => {
      let NewItemTaxes = [];
    
      // Iterate through the ItemTaxes array
      ItemTaxes.forEach((MyItem) => {
        if (MyItem.taxes.taxType === "GST") {
          if (AppTaxType === "interstate") {
            // Find IGST tax slab for interstate
            let MyGSTTaxSlab = MyItem.taxes.taxGSTSlabs.find(x => x.taxSystem === "IGST");
            if (MyGSTTaxSlab) {
              MyItem = { ...MyItem, "taxGSTId": MyGSTTaxSlab.id, "taxAmount": MyItem.taxAmount  };
              NewItemTaxes.push(MyItem);
            }
          } else if (AppTaxType === "intrastate") {
            // Find CGST and SGST tax slabs for intrastate
            let MySGSTTaxSlab = MyItem.taxes.taxGSTSlabs.find(x => x.taxSystem === "SGST");
            let MyCGSTTaxSlab = MyItem.taxes.taxGSTSlabs.find(x => x.taxSystem === "CGST");
            
            if (MySGSTTaxSlab && MyCGSTTaxSlab) {
               // Split the existing taxAmount equally for CGST and SGST
              let splitTaxAmount = MyItem.taxAmount / 2;
              let splitTaxPercentage = MyItem.taxPercentage / 2;
              // Add SGST
              let SGSTItem = { ...MyItem, "taxGSTId": MySGSTTaxSlab.id, "taxAmount": splitTaxAmount, "taxPercentage" : splitTaxPercentage };
              NewItemTaxes.push(SGSTItem);
    
              // Add CGST as a separate entry
              let CGSTItem = { ...MyItem, "taxGSTId": MyCGSTTaxSlab.id, "taxAmount": splitTaxAmount, "taxPercentage" : splitTaxPercentage  };
              NewItemTaxes.push(CGSTItem);
            }
          }
        } else {
          // If not GST, just push the original item
          NewItemTaxes.push(MyItem);
        }
      });
    
      return NewItemTaxes;
    };
    
    const ReceivingOnChange = async (event, index) => {
      let POItems = [...PItems];
      console.log(POItems);
      let MyRecs = [...Receivings];
      console.log(!MyRecs[index]["isChecked"]);
      MyRecs[index]["isChecked"] = !MyRecs[index]["isChecked"];
      let ORID = MyRecs[index]["id"]
      setReceivings(MyRecs);
        let SupplierId =  ""
        let CurrencyId =  ""
        let POId = ""
        let ORIDs = []
        let Currency = PO.currency;
       
        if(MyRecs[index]["isChecked"]){
          ORIDs = [...state.ORIds, ORID]
          var MyRes = await GetAPICallAsync("GetORbyId","",{ ORId : ORID });
          if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            console.log(MyData.data);
            POItems = await Promise.all(
            MyData.data.orderReceivingItems.map(async (item, index) => {

              if(item.rQty > item.apInvQty){
            
                  let POItem = PO.purchaseOrderItems.find(x => x.itemId === item.itemId);
                  
                  console.log(POItem.poItemTaxes)

                  let ApplicableGSTTax = await GetTaxType();
                  console.log(ApplicableGSTTax);
                    
                  const MyTaxSlabs = await GetTaxslabs(POItem.poItemTaxes, ApplicableGSTTax);

                  console.log(MyTaxSlabs);

                  POItems = {"APInvId": "", "ItemId": item.itemId,"POItemId": POItem.id, "itemName": POItem.item.name, "hsnCode" : POItem.item.hsnCode, "SpecialRemarks": POItem.specialRemarks,
                  "orderedQty": POItem.qty, "oQtyUnit": POItem.itemUnit.unitName, "CrQty": item.rQty,"rQty": (item.rQty - item.apInvQty), "rQtyUnit": POItem.itemUnit.unitName,"UnitId": POItem.unitId,"invQty" : 0, "ORId" : ORID,
                  "invQtyUnit": POItem.item.invItemUnit.unitName, "ConvRate": POItem.item.bToIConversion, "TItemPrice" : "", "Discount": POItem.discount, "APInvItemTaxes" : MyTaxSlabs, 
                  "btoIconvRate": POItem.item.bToIConversion , "unitPrice": POItem.unitPrice, "Trackable": POItem.item.trackItem, "trackBy": POItem.item.trackBy
                  ,"SrNos": "","Batches" : [], "TrackedItemQty" : 0, "Index": index, "CurrencyName": Currency.shortCode, "CurrencyId": Currency.id, "ORItemId" : item.id, "invoicedQty" : item.apInvQty,
                  };
                   return POItems 
              }
              else{
                return null;
              }
            }))

            console.log(POItems);
          }
        }
        else{
          POItems = POItems.filter(x => x.ORId !== ORID)
          ORIDs = state.ORIds.filter(x => x !== ORID)
        }
         CurrencyId = PO.currencyId;
         SupplierId = PO.supplierId;
         POId = PO.id;
         POItems = POItems.filter(x => x != null);
         let MySup = Suppliers.find(x => x.id === SupplierId);
         console.log(MySup);
         setSelectSupplier(MySup);
        setState((prevProps) => ({
          ...prevProps,
          "POId" : POId,
          "SupplierId": SupplierId,
          "APInvoiceItems": POItems,
          "CurrencyId": CurrencyId,
          "ORIds" : ORIDs,
        }));
        console.log(POItems)
        CalculateAllTaxes(POItems);
        setPItems(POItems);

    }

    const SubmitItemMetaData = (e) => {
      e.preventDefault();
      console.log(MySrNos);
      let ItemsNumber = 0
      let values = [...state.APInvoiceItems]
      let TrackBy = values[itemMatadata.itemIndex]["trackBy"];
      let OrderedQty = parseFloat(values[itemMatadata.itemIndex]["orderedQty"])
      let RQty = parseFloat(values[itemMatadata.itemIndex]["rQty"])

      
      if (TrackBy === "batch")
      {
          Batches.map ((item) => (
            ItemsNumber += parseInt(item.Qty)
          ))
        
          if(ItemsNumber <= OrderedQty && ItemsNumber === RQty){

            values[itemMatadata.itemIndex]["Batches"] = Batches;
            values[itemMatadata.itemIndex]["TrackedItemQty"] = ItemsNumber;
            setState((prevProps) => ({
              ...prevProps,
              "APInvoiceItems": values
            }));
            setItemMetaData((prevProps) => ({
              ...prevProps,
              "visible" : false
            }));
            setBatches([{"BatchNo": "", "ManfBatchNo" : "", "ManfDate": "", "ExpiryDate":"", "Qty": ""}])
          }
          else{
            alert("Qty doesn't match with recieved qty.")
          }
      }
      else if (TrackBy === "serialno")
      {
        let TSrNos = MySrNos.split(",");
        ItemsNumber = TSrNos.length;
       
        console.log("SerialNumber");
        if(ItemsNumber <= OrderedQty && ItemsNumber === RQty){
          values[itemMatadata.itemIndex]["SrNos"] = MySrNos;
          values[itemMatadata.itemIndex]["TrackedItemQty"] = ItemsNumber;
          console.log("SrNo");
          setState((prevProps) => ({
            ...prevProps,
            "APInvoiceItems": values
          }));
          setItemMetaData((prevProps) => ({
            ...prevProps,
            "visible" : false
          }));
        }
        else{
          alert("Qty doesn't match with recieved qty.")
        }
      }
    }

    // const GenerateMataDataLink = (TrackBy, index) => {
    //   switch (TrackBy){
    //     case "batch" :
    //       return <a className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>Add New Batch</a>;
    //     case "serialNo" :
    //       return <a className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>Add Serial Nos</a>;
    //     default :
    //      return "";
    //   }
    // }

    const CalculateItemFinalPrice = (Myitem) => {
      let TotalbeforeTax = (Myitem.unitPrice * Myitem.rQty) - (Myitem.unitPrice * Myitem.rQty) * Myitem.Discount/100
      let TTotalTax = 0;
      Myitem.APInvItemTaxes.map((NewItem) => (
        TTotalTax += ((Myitem.unitPrice * Myitem.rQty) - (Myitem.unitPrice * Myitem.rQty) * Myitem.Discount/100) * NewItem.taxPercentage/ 100
      ))
      // setMyTotalAmount(TotalAmountV);
      return TotalbeforeTax + TTotalTax;
    }

    const CalculateTotalDicount = () => {
      let Discount = 0;
      state.APInvoiceItems.forEach((Myitem)=> {
        Discount += (Myitem.unitPrice * Myitem.rQty) * Myitem.Discount/100
      })
      return Discount;
    }

    const CalculateTotalAount = () => {
      let TaxableAmount = 0;
      state.APInvoiceItems.forEach((Myitem)=> {
        TaxableAmount += (Myitem.unitPrice * Myitem.rQty) - (Myitem.unitPrice * Myitem.rQty) * Myitem.Discount/100
      })
      return TaxableAmount;
    }

    const CalculateItemTax = (item, TaxItem) => {
      return ((item.unitPrice * item.rQty) - (item.unitPrice * item.rQty) * item.Discount/100) * TaxItem.taxes.taxGSTSlabs.find(x => x.id === TaxItem.taxGSTId).taxValue/ 100
    }

    const CalculateAllTaxes = (MyItems) => {
      console.log(MyItems);
      let ListAllUniqueTaxes = [];
      MyItems.forEach((item) => {
        console.log(item);
        item.APInvItemTaxes.forEach((TaxItem) => {
          
          let chkTaxItem = "";
          let TaxPercentage = 0;
          let TaxName = "";
          
          
          if(TaxItem.taxGSTId != undefined || TaxItem.taxGSTId != "" ){
            chkTaxItem = ListAllUniqueTaxes.filter(x => x.taxId === TaxItem.taxId && x.taxGSTId === TaxItem.taxGSTId);
            TaxPercentage = TaxItem.taxes.taxGSTSlabs.find(x => x.id === TaxItem.taxGSTId).taxValue;
            TaxName = TaxItem.taxes.taxGSTSlabs.find(x => x.id === TaxItem.taxGSTId).taxName
          }else{
            chkTaxItem = ListAllUniqueTaxes.filter(x => x.taxId === TaxItem.taxId);
            TaxPercentage = TaxItem.taxPercentage;
            TaxName = TaxItem.taxes.taxName
          }
          const CalculatedTax = parseFloat(((item.rQty * item.unitPrice) - (item.rQty * item.unitPrice) * item.Discount/100) * TaxPercentage/100);

          if(item.rQty >= 0 && chkTaxItem.length > 0)
          {
            
            let NewList = [...ListAllUniqueTaxes];
            NewList = NewList.filter(x => x.taxId !== TaxItem.taxId);
            let NewTotal = parseFloat(chkTaxItem[0].taxAmount ) + CalculatedTax;
            chkTaxItem = {...chkTaxItem[0], "taxAmount" : NewTotal}
            NewList = [...NewList, chkTaxItem];
            ListAllUniqueTaxes = NewList;
  
          }
          else if (item.rQty !== ""){
            if(TaxItem.taxGSTId != undefined || TaxItem.taxGSTId != "" ){
              ListAllUniqueTaxes = [...ListAllUniqueTaxes, {"taxId" : TaxItem.taxId, "taxGSTId": TaxItem.taxGSTId, "name" : TaxName, "taxAmount" : CalculatedTax}];
            }else{
              ListAllUniqueTaxes = [...ListAllUniqueTaxes, {"taxId" : TaxItem.taxId, "taxGSTId":"", "name" : TaxName, "taxAmount" : CalculatedTax}];
            }
          }
        })
      })
      setAllUniqueTaxes(ListAllUniqueTaxes)  
    }

    const CalculateTotalPayable = () => {
      let totalPayable = 0;
  
      state.APInvoiceItems.forEach((item) => {
          // Calculate total before tax
          const totalBeforeTax = (item.unitPrice * item.rQty) - ((item.unitPrice * item.rQty) * item.Discount / 100);
  
          // Calculate total tax for this item
          let totalTax = 0;
          item.APInvItemTaxes.forEach((taxItem) => {
              totalTax += totalBeforeTax * (taxItem.taxPercentage / 100);
          });
  
          // Sum up to the total payable
          totalPayable += totalBeforeTax + totalTax;
      });
  
      return totalPayable;
  };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        let MyRes = await  POSTAPICallAsync("Addnewapinvoice", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            if (MyData.success === true ){
                navigate("/purchase/apinvoices");
            }
            else{
                setMessage(MyData.message);
            }
            
        }
      
      
      }
  return (
    <>
      <div className='mb-3 row'>
        <div className='col-6'>
            <h1 className='ListMainHeading'>Add New AP Invoice</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
           
        </div>
        </div>    
      <span>{Message}</span>
      <div className='row'>
      <label className="form-label">PO No.<span className='color-red'>*</span></label>
      </div>
      <div className='row'>
          {FetchbyPOMessage && <span className='Error'>{FetchbyPOMessage}</span>}
          <div className="mb-3 col-3">
              
              <input type="text" required name="PurchaseOrder" className="form-control" id="PurchaseOrder" value={PONo} onChange={handlePOSearch} placeholder="Enter Purchase Number"/>
          </div>          
          <div className='col-3'>
              <button className="btn btn-primary" onClick={GetPODetails}>Fetch Details</button>
          </div>
          </div>
      <div className='row'>
      <form onSubmit={handleSubmit}>
      {Receivings.length > 0 ?
             <>
         <div className='row'>
            {/* <div className="col-3 mb-3">
                <label htmlFor="PONo" className="form-label">PO No<span className='color-red'>*</span></label>
                <input type="text" required name="PONo" className="form-control" id="PONo" value={state.PONo} onChange={handleInputChange} placeholder="Enter company name"/>
            </div> */}
             {/* <div className="col-3 mb-3">
                 <label htmlFor="WarehouseId" className="form-label">Receiving No<span className='color-red'>*</span></label>
                 
                 <select name="ORId" className="form-select" value={state.ORId} onChange={handleInputChange} aria-label="Select Warehouse">
                    <option value={""}>Select Any</option>
                    {Receivings.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.Rec_no}</option>))}
                </select>
             </div> */}
             
             <div className='row'>
              
              <div className="col-12 mb-3">
                 <label htmlFor="WarehouseId" className="form-label me-3">Select Receivings<span className='color-red'>*</span></label>
                 
                  {Receivings.map((Myitem, index) => (
                
                    <div key={index} className="form-check form-check-inline">
                    <input className="form-check-input" name={Myitem.Rec_no} ischecked={Myitem.isChecked} type="checkbox" id={Myitem.id} value={Myitem.id} onChange={event => ReceivingOnChange(event, index)}/>
                    <label className="form-check-label" for="inlineCheckbox1">{Myitem.Rec_no}</label>
                    </div>
                  
                  ))}

             
              </div>
             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="InvNo" className="form-label">AP Invoice No<span className='color-red'>*</span></label>
                 <input type='text' required id="InvNo" name="InvNo" className="form-control" value={state.InvNo}  onChange={handleInputChange}/>
             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="Date" className="form-label">Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="Date"  dateFormat="dd/mm/yy" className="form-control custdate"  value={state.Date} onChange={handleInputChange} showButtonBar ></Calendar>

             </div>
             <div className="col-3 mb-3">
                    <label htmlFor="SupplierId" className="form-label">Supplier<span className='color-red'>*</span></label>
                    <AutoComplete value={SelectedSupplier} suggestions={SearchSupplier} forceSelection field="supName" completeMethod={search} onChange={AutoCompleteChange} style={{ width: '100%' }} 
                    placeholder='Type Supp Name to search..' />

             </div>
            
         </div>
      <div className='row mt-5'>
        <div className='col-12'>
          <table className='table table-bordered'>
            <thead>
            <tr>
              <th>Item Name</th>
              <th>HSN Code</th>
              <th>Ordered Qty</th>
              <th>Non Invoiced Received Qty</th>
              <th>Unit Price ({Currency.shortCode})</th>
              <th>Discount (%)</th>
              <th className='text-end'>T. Amount ({Currency.shortCode})</th>
              <th className='text-center'>Tax</th>
              <th className='text-end'>Final Amount ({Currency.shortCode})</th>
              {/* <th>Qty for Inventory</th> */}
              
            </tr>
            </thead>
            <tbody>            
          
              {state.APInvoiceItems && state.APInvoiceItems.map ((item, index) => (
                <tr key={index}>
                  <td>{item.itemName} </td>
                  <td>{item.hsnCode}</td>
                  <td>{item.orderedQty} {item.oQtyUnit}</td>
                  <td><input required type='text' id="" name="rQty" value={item.rQty} onChange={(event) => handleL2InputChange(event, index)}/> {item.rQtyUnit}
                  {item.rQty > 0 ? <><span className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>{item.Trackable && (item.trackBy === "batch" ?  item.Batches.length + " batches Added for ( " + item.TrackedItemQty + " Qty )": item.TrackedItemQty + " Sr. No Added") }</span>
                   </>: ""}
                  </td>
                  <td><input required type='text' id="" name="unitPrice" value={item.unitPrice}  onChange={(event) => handleL2InputChange(event, index)}/>{item.CurrencyName}</td>
                  
                  <td><input type='text' required id="" name="Discount" value={item.Discount} onChange={(event) => handleL2InputChange(event, index)}/></td>
                  <td className='text-end'>{((item.unitPrice * item.rQty) - (item.unitPrice * item.rQty) * item.Discount/100).toFixed(2)}</td>
                  <td>
                    <table className='table table-bordered'>
                      <tbody>
                    {item.APInvItemTaxes.map((NewItem, index) => (
                        
                            <tr key={index}>
                                <td>{NewItem.taxes.taxGSTSlabs.find(x => x.id === NewItem.taxGSTId).taxName}</td>
                                <td className='text-end'>{NewItem.taxes.taxGSTSlabs.find(x => x.id === NewItem.taxGSTId).taxValue} (%)</td>
                                <td className='text-end'>{CalculateItemTax(item, NewItem).toFixed(2)}</td>
                            </tr>
                    ))}
                    </tbody>
                    </table>
                  </td>
                  <td className='text-end'>{CalculateItemFinalPrice(item).toFixed(2)}</td>
                  {/* <td>{item.invQty} { item.invQtyUnit}</td> */}
                  
                  </tr> 
                  
              ))}
              <tr className='text-end'>
                <td colSpan={8} >Taxable Amount ({Currency.shortCode})</td>
               
                <td>{CalculateTotalAount().toFixed(2)}</td>
              </tr>
              <tr className='text-end'>
                <td colSpan={8} >Discount ({Currency.shortCode})</td>
                <td>{CalculateTotalDicount().toFixed(2)}</td>
              </tr>
              {/* {CalculateAllTaxes()} */}
              {  AllUniqueTaxes.map((AllTaxItem, index) => {
                return <tr key={index} className='text-end'><td colSpan={8}>{AllTaxItem.name} ({Currency.shortCode})</td><td >{AllTaxItem.taxAmount.toFixed(2)}</td></tr>
                })}
                <tr className='text-end'>
                  <td colSpan={8} >Total Payable ({Currency.shortCode})</td>
                  <td>{CalculateTotalPayable().toFixed(2)}</td>
              </tr>
          </tbody>
          </table>
          
         
        </div>

        <div className='mt-4'>
             <button type="submit" className="btn btn-primary">Submit</button>
         </div>
      </div>
      </>: ""}
      </form>
      </div>
      <Dialog header="Add Info" visible={itemMatadata.visible} maximizable  style={{  width: '90vw',}} onHide={() => setItemMetaData({visible: false})}>
      <form onSubmit={SubmitItemMetaData} className='mt-3'>
            {itemMatadata.trackBy === "batch"?
            Batches.map ((item, index) => (          
            <div key={index} className='row mt-3'>
            <div className="col-2 mb-3">
                <label className="form-label">Batch No<span className='color-red'>*</span></label>
                <input type="text" required name="BatchNo" className="form-control" id="TaxName" value={item.BatchNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Batch Number"/>
            </div>
            <div className="col-2 mb-3">
                <label className="form-label">Manufacturer Batch No<span className='color-red'>*</span></label>
                <input type="text" required name="ManufBNo" className="form-control" id="ManufBNo" value={item.ManufBNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Mafacturer B. No. "/>
            </div>
            <div className="col-2 mb-3">
                 <label htmlFor="ManufacturingDate" className="form-label">Manufacturing Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="ManufacturingDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={item.ManufacturingDate} onChange={event => handleBatchInputChange(index, event)} showButtonBar showIcon ></Calendar>

             </div>
             <div className="col-2 mb-3">
                 <label htmlFor="Date" className="form-label">Expiry Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="ExpiryDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={item.ExpiryDate} onChange={event => handleBatchInputChange(index, event)} showButtonBar showIcon ></Calendar>

             </div>
             <div className="col-2 mb-3">
                <label className="form-label">Qty<span className='color-red'>*</span></label>
                <input type="text" required name="Qty" className="form-control" id="Qty" value={item.Qty} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Unit Short Code"/>
            </div>
            <div className='col-2'>
            <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index)}></i>
            <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
            </div>
            
            </div> 
            )):
            <div className="mb-3">
                <label className="form-label">Add Serial Numbers ("," Comma Separated)</label>
                <textarea required type="text" rows="4" name="MySrNos" className="form-control" id="MySrNos" value={MySrNos} onChange={(e) => setMySrNos(e.target.value)} placeholder="Enter Serial Numbers"/>
            </div> }          
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
      </Dialog>

  </>
  )
}

export default Addnewapinvoice