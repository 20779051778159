import React from 'react'
import ListReceivings from '../../Blocks/ListReceivings'
import InventoryHeader from '../../Blocks/InventoryHeader'

const Receivings = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
          <ListReceivings />
        </div>
    </div>
  )
}

export default Receivings