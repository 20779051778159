import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  // Register the components
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const LineChartCummPurchase = ({ purchases }) => {
    console.log(purchases);
  const labels = purchases.map((item) => item.monthName);
  const purchaseData = purchases.map((item) => item.cumulativePurchase);

  const data = {
    labels,
    datasets: [
      {
        label: 'Cumulative Purchases',
        data: purchaseData,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.4,
      }
    ],
  };

  const options = {
    layout: {
      padding: {
          left: 10,
          right: 30,
          top: 10,
          bottom: 10
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChartCummPurchase