import React from 'react'
import ListPurchaseOrders from '../../Blocks/ListPurchaseOrders'
import InventoryHeader from '../../Blocks/InventoryHeader'

const PurchaseOrder = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
          <ListPurchaseOrders />
        </div>
    </div>
  )
}

export default PurchaseOrder
