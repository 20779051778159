import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ProfileBlock from '../../Blocks/ProfileBlock'

const ProfilesPage = () => {
  return (
    <>
     <InventoryHeader />
     <div className='p-3'>
        <ProfileBlock />
      </div>
    </>
  )
}

export default ProfilesPage