import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import AddSetupTax from './AddSetupTax';
import AddCurrency from './AddCurrency';
import EditCurrency from './EditCurrency';
import Importdatablock from './Importdatablock';

const ListCurrencies = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [visible, setVisible] = useState({
      open: false,
      mode: "",
      id: ""
    });
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [Currencies, setCurrencies] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: null,
      filters: {
        supName: { value: '', matchMode: 'contains' },
  
      }
  });
  
  const items = [
    {
      label: 'Details',
      icon: 'pi pi-refresh',
      url: "/customers/details/" + selectedRow.org_id + ""
  },
  {
      label: 'Edit',
      icon: 'pi pi-times',
      command: (row) => {
        console.log(row);
          toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
      }
  },
  {
    label: 'React Website',
    icon: 'pi pi-external-link',
    url: 'https://reactjs.org/'
  },
  ]; 
    
  
    useEffect(() => {
      loadLazyData();
    }, [lazyState, visible.open]);
    useEffect (() => {
      loadLazyData();
    }, [])
  
  
    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }
  
      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        var MyRes = await GetAPICallAsync("GetListCurrencies", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setCurrencies(MyData.data);
          props.QuckSetupMode && props.MyItemTotalCallBack(MyData.total);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };
  
  const onSort = (event) => {
      setlazyState(event);
  };
  
  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };
  
  const onSelectionChange = (event) => {
      const value = event.value;
  
      setSelectedCustomers(value);
      setSelectAll(value.length === totalRecords);
  };
  
  const onSelectAllChange = async (event) => {
      const selectAll = event.checked;
  
      if (selectAll) {
              setSelectAll(true);
              setSelectedCustomers(Currencies);
        
      } else {
          setSelectAll(false);
          setSelectedCustomers([]);
      }
  };

const GetDialogHeader= () => {
  switch(visible.mode){
    case "add" : return <span>Add Currency</span>
    case "edit" : return <span>Edit Currency</span>
    case "import" : return <span>Import Currencies</span>
    default : return <span>Add Currency</span>
  }
}
  
const ActiveTemplate = (prop) => {
    return prop.status === true ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Inactive</span>
}
const ActionTemplate = (row) => {
  return <>
  <i className="pi pi-info-circle ActionIcon"></i>
  <i className="pi pi-pencil ms-3 ActionIcon" onClick={() => setVisible({open: true, mode: "edit", id : row.id})}></i>
  <i className="pi pi-times ms-3 ActionIcon"></i>
</>
}
  return (
    <div>
    <div className='mb-3 row'>
    <div className='col-6'>
    <h1 className='ListMainHeading'>Currencies</h1>
    </div>
    <div className='col-6 d-flex justify-content-end'>
        <button className='btn btn-outline-primary ms-2' onClick={() => setVisible({open: true, mode: "add"})} > <i className="pi pi-plus" ></i> Add New</button>
    </div>
    </div>
    <div className='row'>
        <DataTable value={Currencies} lazy dataKey="id" paginator selectionMode="checkbox" size='small' showGridlines  resizableColumns columnResizeMode="expand"
                first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column field="name" header="Currency Name" />
        <Column field="shortCode" header="Short Code"></Column>
        <Column field="conversionRate" header="Conversion Rate"></Column>
        <Column field="status" header="Status" body={ActiveTemplate}></Column>
        <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
        </DataTable>

    </div>
    <Dialog header={GetDialogHeader()}  visible={visible.open} style={{ width: '70vw' }} onHide={() => setVisible({"open": false})}>
        
        { visible.mode == "add" && <AddCurrency setVisible={setVisible} />}
        {visible.mode == "edit" && <EditCurrency setVisible={setVisible} Id={visible.id} />}
        
    </Dialog>
</div>
  )
}

export default ListCurrencies