import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate, useParams } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import WarehouseBInfo from '../Components/WarehouseBInfo';
import WHIncharges from '../Components/WHIncharges';

const DetailWarehouse = (props) => {
    const navigate = useNavigate();
    const MyParm = useParams();
    console.log(MyParm);
    const [WH, setWH] = useState({
        Name: "",
    });
    const [WhIncharges, setWhIncharges] = useState();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 5,
        page: 1,
        sortField: null,
        sortOrder: 0,
        filters: {
          warehouseName: { value: '', matchMode: 'contains' },
          cityName: {value: '', matchMode: 'contains'},
          countryName : {value: '', matchMode: 'contains'},
          status: {value: '', matchMode: 'equals'}
        }
      });
        const GetWHbyId = async() => {
            var MyRes = await GetAPICallAsync("GetWHbyId", { lazyEvent: lazyState }, {Id: MyParm.Id});
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                var MyData = await MyRes.json();
                console.log(MyData);
                setWhIncharges(MyData.data.warehouseIncharges)
                setWH({
                    "Name": MyData.data.warehouseName
                });
            }
        };
    
        useEffect (() => {  
            GetWHbyId();
        }, []);
  return (
        <TabView>
          <TabPanel header="Basic Info">
              <WarehouseBInfo WH={WH} />
          </TabPanel>
          <TabPanel header="Incharges">
              <WHIncharges WHIncharges={WhIncharges} WHID = {MyParm.Id}/>
          </TabPanel>
          <TabPanel header="Locations">
              <h2>Locations</h2>
          </TabPanel>
        
        </TabView>
  )
}

export default DetailWarehouse