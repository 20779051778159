import React, { useState }  from 'react'
import { useNavigate } from 'react-router-dom';

const AddDynamicTextField = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [state, setState] = useState({
        CompanyId : Company.id,
        FormName : props.FormName,
        FieldName : "",
        FieldType : props.FieldType,
        IsRequired : false,
        FieldOptions : []
      });
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
      const handleInputChangeCheckbox = (event) => {
        console.log(event.target);
        const { name } = event.target;
        console.log(name);
        setState((prevProps) => ({
          ...prevProps,
          [name]: event.target.checked
        }));
      };
      
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        await fetch(process.env.REACT_APP_API_URL + 'setup/addnewdynamicfield', {
         method: 'POST',
         body: JSON.stringify(state),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + tokenString.toString()
          },
        })
        .then((res) => 
        { if(res.status === 401){ navigate("/login"); }        
        return res.json()})
        .then((data) => {
            setMessage("Field Added Successfully")
        })
        .catch((err) => {
            console.log(err.message);
        });
    }
  return (
    <>
      {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible(false)}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Field Name<span className='color-red'>*</span></label>
                <input type="text" required name="FieldName" className="form-control" id="FieldName" value={state.FieldName} onChange={handleInputChange} placeholder="Enter Field Name"/>
            </div>
            <div className='mb-3'>
                
                <input type="checkbox" name="IsRequired" value={state.IsRequired} onChange={handleInputChangeCheckbox} className="form-check-input" id="IsRequired"/>
                <label className="form-check-label ms-2" for="exampleCheck1">Is Required</label>
            </div>
                       
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </>
  )
}

export default AddDynamicTextField
