import React from 'react'
import AddPurchaseOrder from '../Components/AddPurchaseOrder'

const AddNewPurchaseOrder = () => {
  return (
    <>
     <div className='mb-3 row'>
        <div className='col-6'>
            <h1 className='ListMainHeading'>Add New Purchase Order</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
           
        </div>
        </div>
        <div className='row'>
            <AddPurchaseOrder /> 
        </div>
    </>
  )
}

export default AddNewPurchaseOrder
