import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const AddProfile = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const [state, setState] = useState({
      CompanyId : Company.id,
      Name : "",
      Description : "",
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
    };
    const handleSubmit = async (event) => {
      event.preventDefault();
              console.log(state);
              let MyRes = await  POSTAPICallAsync("CreateProfile", state);
              console.log(MyRes);
              if (MyRes.status === 401){
                  localStorage.removeItem("User_Obj");
                  localStorage.removeItem("Company");
                  localStorage.removeItem("token");
                  navigate("/login");
              }
              else{
                  var MyData = await MyRes.json();
                  console.log(MyData);
                  if (MyData.success == true ){
                      setMessage(MyData.message);
                      setShowForm(false);
                  }
                  else{
                      setMessage(MyData.message);
                  }
                  
              }
    }
    return (
      <>
        {Message ? <div className='col'>
              <span>{Message}</span>
              <button onClick={() => props.setVisible({"open" : false})}>Close</button>
          </div> :
        <form onSubmit={handleSubmit}>
              <div className="mb-3">
                  <label className="form-label">Profile Name<span className='color-red'>*</span></label>
                  <input type="text" required name="Name" className="form-control" id="Name" value={state.Name} onChange={handleInputChange} placeholder="Enter profile name"/>
              </div>
              <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea type="text" rows="4" name="Description" className="form-control" id="Description" value={state.Description} onChange={handleInputChange} placeholder="Enter Description"/>
              </div>           
              <div className='mt-4'>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>
          </form>}
      </>
    )
}

export default AddProfile