import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ManagefieldsList from '../../Blocks/ManagefieldsList'

const ManageFields = () => {
  return (
    <div>
      <InventoryHeader />
      <div className='container'>
        <ManagefieldsList />
      </div>
    </div>
  )
}

export default ManageFields
