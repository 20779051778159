import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListCompUsers from '../../Blocks/ListCompUsers'

const UsersList = () => {
  return (
    <>
     <InventoryHeader />
        <div className='p-3'>
            <ListCompUsers/>
        </div>
    </>
  )
}

export default UsersList