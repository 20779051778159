import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
  } from 'chart.js';
import {generateRandomColor } from '../../Helpers/CommonFunctions';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChartpurchasebycategory = ({data}) => {

    const generateColors = () => {
        return data.map(() => generateRandomColor());
    };
  // Prepare the data for the chart
  const chartData = {
    labels: data.map(item => item.categoryName),
    datasets: [
      {
        data: data.map(item => item.totalPurchased),
        backgroundColor:  generateColors(),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      // Enable the datalabels plugin
      datalabels: {
        display: true,
        formatter: (value, context) => {
          return `${value}`; // Display the actual value
        },
        color: '#fff', // Set label color to white
        font: {
          weight: 'bold',
          size: 12,
        },
        align: 'center', // Align the label at the center of the slice
        offset: 0, // No offset, label will be directly in the center
      },
    },
  };

  return <Pie data={chartData} options={options} />;
}

export default PieChartpurchasebycategory
