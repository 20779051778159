import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {GetAPICallAsync} from '../../ServiceLayer/GetAPICall'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';

const ItemisedPurchaseBillsBlock = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [APInvoiceItems, setAPInvoiceItems] = useState([]);
    const getCurrentDate = () => new Date();
    const [AppliedFilters, setAppliedFilters] = useState([]);

    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
          org_name: { value: '', matchMode: 'contains' },
      }
  });
    const getCurrentMonthStartDate = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth()-12, 1); // Start of the current month
    };
    const [Filters, setFilters] = useState({
        DocumentNo: props.Filters ? props.Filters.InvNo: "" ,
        FromDate: props.Filters ? props.Filters.InvNo: getCurrentMonthStartDate(),
        ToDate: props.Filters ? props.Filters.InvNo: getCurrentDate(),
    });
    const loadLazyData = () => {
        setLoading(true);
        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
    
        //imitate delay of a backend call
        networkTimeout = setTimeout(async () => {
          console.log(Filters);
          var MyRes = await GetAPICallAsync("ItemisedPurchaseRegister", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(Filters)});
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setTotalRecords(MyData.total);
            setAPInvoiceItems(MyData.data);
          }
          
        setLoading(false);
        }, Math.random() * 1000 + 250);
    };
    const onPage = (event) => {
      setlazyState(event);
  };
  
  const onSort = (event) => {
      setlazyState(event);
  };
  
  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };
   

    useEffect(() => {
        loadLazyData();
      }, [lazyState]);
      useEffect (() => {
        
        loadLazyData();

      }, [])

      const DateTemplate = (prop) => {
        return moment(prop.apInvDate).format("DD/MM/yyyy");
      }
      const UnitpriceTemplate = (prop) => {
        return (prop.unitPrice).toFixed(2);
      }
      const QtyTemplate = (prop) => {
        return <span> {(prop.qty).toFixed(2)}  {prop.unitShortCode} </span>;
      }
      const DiscountTemplate = (prop) => {
        return (prop.discount).toFixed(2);
      }
  return (
    <div>
        <div className=' row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Itemised Purchase Bills</h1>
        </div>
         <div className='col-6 d-flex justify-content-end text-end'>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
        </div>
        </div>
        <div className='row'>
        <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
        </div>
        {AppliedFilters.length > 0 ? <div className='row mb-3'>
          <div className='col-12'>
            {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter}: {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}}></i></span>))}
          </div>
        </div>: ""}
   
        <div className='row'>
            <DataTable value={APInvoiceItems} lazy dataKey="id" paginator  removableSort size='small' showGridlines  resizableColumns columnResizeMode="expand"
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading}>
            <Column field="apInvDate" header="Date" body={DateTemplate}  sortable/>
            <Column field="itemName" header="Item"></Column>
            <Column field="apInvNo" header="Inv No"></Column>
            <Column field="supplierName" header="Supplier"></Column>
            <Column className='text-end' alignHeader={'right'} field="unitPrice" header="UnitPrice" body={UnitpriceTemplate}></Column>
            <Column className='text-end' alignHeader={'right'} field="qty" header="Qty" body={QtyTemplate}></Column>
            <Column className='text-end' alignHeader={'right'} field="discount" header="Discount" body={DiscountTemplate}></Column>
            </DataTable>
        </div>
        {/* <Dialog header="Create Purchase Order" visible={visible} maximizable  style={{  width: '90vw',}} onHide={() => setVisible(false)}>
            <AddPurchaseOrder setVisible={setVisible} />
        </Dialog> */}
    </div>
  )
}

export default ItemisedPurchaseBillsBlock