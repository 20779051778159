import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import moment from 'moment';

const SAdjReqDetailsBlock = () => {
    const navigate = useNavigate();
    const [MySrNos, setMySrNos] = useState("");
    const [WrongSrNos, setWrongSrNos] = useState([]);
    const [confTracking, setConfirmTracking] = useState([]);
    const Params = useParams();
    const [Issued, setIssued] = useState(false);
    const [state, setState] = useState({
        id : "",
        requestId : "",
        warehouseId : "",
        warehouseName : "",
        companyId : "",
        is_Adjusted : "",
        adjustedBy : "",
        adjustedOn : "",
        createdOn : "",
        createdBy : "",
        adjustItems : [],
    });
    const [itemMatadata, setItemMetaData] = useState({
      visible : "",
      itemIndex: "",
      trackBy: "",
    });
    const [Batches, setBatches] = useState ([
      {
        BatchNo: "",
        ManufBNo : "",
        ManufacturingDate: "",
        ExpiryDate:"",
        Qty: "",
        EditMode: false,
      }
  ])
    const GetSTO = async () => {
        var MyRes = await GetAPICallAsync("GetStockAdjReqbyId", { lazyEvent: "" }, {Id : Params.Id});
        setConfirmTracking([])
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setState(MyData.data);

          MyData.data.adjustItems.map((item) => {
            if(item.itemDetail.trackItem === true){
              let IsTracked = false;
              switch (item.itemDetail.trackBy){
                case "batch"  : {
                  if(item.stockIssueRequestBatches.length > 0 ){
                    IsTracked = true
                  }}
                case "serialno"   : {
                  if(item.stockIssueRequestSrNos.length === item.qty){
                    IsTracked = true
                  }
                }
              }
              setConfirmTracking((prevProps) => ([
                ...prevProps, {"SARItemId": item.id, "Tracked" : IsTracked}
              ]))
            }
          })
        }
    }
    const handleAddMoreItem = (index) => {
      const values = [...Batches ]
      values.splice(index + 1, 0, {"BatchNo": "", "ManufBNo" : "", "ManfDate": "", "ExpiryDate":"", "Qty": "", "EditMode": true} )
      setBatches(values);
    }
  
    const handleremoveitem = (index) => {
      const values = [...Batches]
      if(values.length > 1){
          values.splice(index, 1)
          setBatches(values);
      }
    }

    const handleBatchEditMode = (index) => {
      const values = [...Batches ];
      console.log(index);
      console.log(Batches[index]["EditMode"]);
      values[index]["EditMode"] = !Batches[index]["EditMode"];
      setBatches(values);
    }
  
    const getTimeZone = () => {
                          
      const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // setState((prevProps) => ({
      //     ...prevProps,
      //     "TimeZone": MytimeZone
      //   }));
  
    };

    const handleBatchInputChange = (Index, event) => {
      const { name, value } = event.target;
     let values = [...Batches];
     values[Index][name] = value;
      setBatches(values);
    };
  

    useEffect(() => {
        GetSTO();
    }, [])
    useEffect(() => {
      GetSTO();
    }, [itemMatadata.visible === false, Issued])

    const AddItemMetadata = (Index) => {
      let MyPOItem = state.adjustItems[Index]; 
      setItemMetaData((prevProps) => ({
        ...prevProps,
        visible : true,
        itemIndex : Index,
        trackBy: MyPOItem.itemDetail.trackBy,
      }))
      if(MyPOItem.itemDetail.trackBy === "batch"){
        
        if(MyPOItem.stockIssueRequestBatches.length > 0)
        {
          var Values = [];
          MyPOItem.stockIssueRequestBatches.map((batchitem) => {
            console.log(batchitem); 
            Values = [...Values, {"BatchNo": batchitem.batchNo , "ManufBNo" : batchitem.manufBNo, "ManufacturingDate": batchitem.ManufacturingDate , "ExpiryDate": batchitem.expiryDate, "Qty": batchitem.qty,}]
          })
          setBatches(Values);
        } 
      }
      else if(MyPOItem.itemDetail.trackBy === "serialno"){
        let SrNos = "";
        MyPOItem.stockIssueRequestSrNos.map((Sno, index) => {
          if(index < MyPOItem.stockIssueRequestSrNos.length - 1){
            SrNos += Sno.itemSrNo + ",";
          }
          else
          {
            SrNos += Sno.itemSrNo
          }
          
        })
        setMySrNos(SrNos);
        setWrongSrNos("")
      }
    }

    const SubmitItemMetaData = async (e) => {
      e.preventDefault();
      setWrongSrNos([]);
      let ItemsNumber = 0
      let values = [...state.adjustItems]
      let TrackBy = values[itemMatadata.itemIndex]["itemDetail"]["trackBy"];
      let QtytoIssue = parseFloat(values[itemMatadata.itemIndex]["qty"])
      let ItemId = values[itemMatadata.itemIndex]["itemId"]
      let SARItemId = values[itemMatadata.itemIndex]["id"]
      let MyAction = values[itemMatadata.itemIndex]["action"]
      let TackingValues = [...confTracking]
      if (TrackBy === "batch")
      {
        console.log(Batches);
          Batches.map ((item) => (
            ItemsNumber += parseInt(item.Qty)
          ))
          if(ItemsNumber === QtytoIssue){
          var MyRes = await POSTAPICallAsync("VerifyandAddSSRBatches", {"SSRItemId": SARItemId, "CompanyId": state.companyId, "ItemId": ItemId, Batches: Batches});
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            if(MyData.success === false){
              setWrongSrNos(MyData.data);
            }
            else if(MyData.success === true){
              setItemMetaData((prevProps) => ({
                ...prevProps,
                "visible" : false
              }));
              let StoItemId = values[itemMatadata.itemIndex]["id"];
              let MyValue = TackingValues.find(x => x.SARItemId === SARItemId);
              MyValue.Tracked = true;
              setConfirmTracking(TackingValues);
              setBatches([{"BatchNo": "",  "Qty": ""}])
            }
          }
          }
          else{
            alert("Qty doesn't match with recieved qty.")
          }
      }
      else if (TrackBy === "serialno")
      {
        console.log(state);
        let TSrNos = MySrNos.split(",");
        ItemsNumber = TSrNos.length;
        if(parseInt(ItemsNumber) === parseInt(QtytoIssue)){
          var MyRes = await POSTAPICallAsync("VerifyandAddSARSrNo", {SARItemId: SARItemId, Action: MyAction, SrNos: MySrNos, WHID: state.warehouseId, ItemId : ItemId});
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            if(MyData.success === false){
              setWrongSrNos(MyData.data);
            }
            else if(MyData.success === true){
              setItemMetaData((prevProps) => ({
                ...prevProps,
                "visible" : false
              }));
              let MYSARItemId = values[itemMatadata.itemIndex]["id"];
              let MyValue = TackingValues.find(x => x.SARItemId === MYSARItemId);
              MyValue.Tracked = true;
              console.log(MyValue);
              console.log(TackingValues);
              setConfirmTracking(TackingValues);
            }
          }
          
        }
        else{
          alert("Qty doesn't match with recieved qty.")
        }
      }
        

    }

    const SubmitStock = async () => {
      var MyRes = await POSTAPICallAsync("IssueStock", {SSRId: Params.Id});
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            if(MyData.success){
              alert("Updated")
              setIssued(true);
            }
            }
          
    }

    const Cancel = () => {

    }

    const GetButton = () => {
      let Validation  = true;
      if(state.is_Adjusted === false ){
        confTracking.map((item) => {
          if(item.Tracked === false){
            Validation = false
          }
        })
      }
      
      return (
      <>
      {!state.is_Adjusted ? <button className='btn btn-outline-primary' disabled={!Validation} onClick={() => SubmitStock()}>Adjust Qty</button>
      : <span><button className='btn btn-outline-primary' disabled >Already Adjusted</button></span>}
      <button className='btn btn-outline-primary ms-2' onClick={() => Cancel()}>Cancel</button>
      </>)
      
    }

    const GetTick = (Id) => {
      console.log(confTracking);
      if(confTracking.length> 0){
        let MyTrack = confTracking.find(x => x.SARItemId === Id)
        if(MyTrack != null){
            return MyTrack.Tracked ? <i className="pi pi-verified" style={{ color: 'green' }}></i>  : "" ;
        }
        else{
            return <span>Non Trackable</span>
        }
        
      }
      
    }

  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <h1 className='ListMainHeading'>Stock Adjustment Request</h1>
        </div>
        <div className='col-6 text-end'>
        {GetButton()}
        </div>
      </div>

      <div className='row my-3 g-0'>
        <table className='table table-bordered'>
          <tbody>
          <tr>
            <td className='HeaderTd'>
              Request Id
            </td>
            <td className='ValueTD'>
               {state.requestId}
            </td>
            <td className='HeaderTd'>
              Request Date
            </td>
            <td className='ValueTD'>
               {moment(state.createdOn).format("DD/MM/yyyy")}
            </td>
          </tr>
          <tr>
          <td className='HeaderTd'>
              Status
            </td>
            <td className='ValueTD'>
              {state.is_Adjusted ? "Adjusted" : "Not Adjusted Yet"}
            </td> 
            <td className='HeaderTd'>
              Adjusted On & By
            </td>
            <td className='ValueTD'>
            {state.adjustedOn ? moment(state.adjustedOn).isValid() ? moment(state.adjustedOn).format("DD/MM/yyyy") : "Invalid Date" : ""} - {state.adjustedBy}
            </td>
          </tr>
          <tr>
            <td className='HeaderTd'>
             Warehouse
            </td>
            <td className='ValueTD'>
               {state.warehouseName
               }
            </td>
            <td className='HeaderTd'>
             
            </td>
            <td className='ValueTD'>
               
            </td>
          </tr>
         
         
          </tbody>
        </table>
      </div>
      <h2  className='ListMainHeading'>Items to Adjust</h2>

      <div className='row g-0'>
        <table  className='table table-bordered mt-3'>
          <thead>
            <tr>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Action</th>
            <th>Qty</th>
            <th>Unit</th>
            <th>Action</th>
            </tr>
          </thead>
        <tbody>
          {state.adjustItems.map((item, index ) =>(
            <tr key={index}>
              <td>{item.itemDetail.itemCode}</td>
              <td>{item.itemDetail.name}</td>
              <td>{item.action}</td>
              <td>{item.qty}</td>
              <td>{item.itemDetail.invUnitName}</td>
              <td>{(item.itemDetail.trackItem) ? <span className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>{item.itemDetail.trackBy === "serialno" ? <span>Add Sr. No </span>: <span>Add Batch</span> }</span> : "" }
                {GetTick(item.id)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <Dialog header="Add Info" visible={itemMatadata.visible} maximizable  style={{  width: '90vw',}} onHide={() => setItemMetaData({visible: false})}>
      {WrongSrNos.length > 0 ? <>
      <span className='Error'>Following data is wrong. Please check </span><br/>
      {WrongSrNos.map((item, index) => (
         <span className='Error' key={index}> {item},</span>
      )) }</> : ""
      }
      
      <form onSubmit={SubmitItemMetaData} className='mt-3'>
            {itemMatadata.trackBy === "batch"?
            <>
            {/* <div className='row'>
            <div className="col-6 mb-3">
                <input type="text" required name="BatchNo" className="form-control" id="TaxName" value={FindBatch} onChange={event => setFindBatch(event.target.value)} placeholder="Search by Batch / Manufacturer Batch No Number"/>
            </div>
            <div className='col-2'>
              <button type="button" className="btn btn-primary" onClick={() => GetBatch()}>Add</button>
            </div>
            </div> */}

            {Batches.map ((item, index) => (          
            <div key={index} className='row mt-3'>
            <div className="col-2 mb-3">
                <label className="form-label">Batch No<span className='color-red'>*</span></label>
               {item.EditMode ? <input type="text" required name="BatchNo" className="form-control" id="TaxName" value={item.BatchNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Batch Number"/> : <input disabled type="text" required name="BatchNo" className="form-control" id="TaxName" value={item.BatchNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Batch Number"/>}
            </div>
            <div className="col-2 mb-3">
                <label className="form-label">Manufacturer Batch No<span className='color-red'>*</span></label>
                {item.EditMode ?  <input type="text" required name="ManufBNo" className="form-control" id="ManufBNo" value={item.ManufBNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Mafacturer B. No. "/> :  <input disabled type="text" required name="BatchNo" className="form-control" id="TaxName" value={item.ManufBNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Batch Number"/>}
            </div>
            <div className="col-2 mb-3">
                  <label htmlFor="ManufacturingDate" className="form-label">Manufacturing Date<span className='color-red'>*</span></label>
                  
                  {item.EditMode ?  <Calendar required name="ManufacturingDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={item.ManufacturingDate} onChange={event => handleBatchInputChange(index, event)} showButtonBar showIcon ></Calendar> :  <input disabled type="text" required name="BatchNo" className="form-control" id="TaxName" value={moment(item.ManufacturingDate).format("DD/MM/yy")} onChange={event => handleBatchInputChange(index, event)}/>}

              </div>
              <div className="col-2 mb-3">
                  <label htmlFor="Date" className="form-label">Expiry Date<span className='color-red'>*</span></label>
                  {item.EditMode ?  <Calendar required name="ExpiryDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={item.ExpiryDate} onChange={event => handleBatchInputChange(index, event)} showButtonBar showIcon ></Calendar> : <input disabled type="text" required name="BatchNo" className="form-control" id="TaxName" value={moment(item.ExpiryDate).format("DD/MM/yy")} onChange={event => handleBatchInputChange(index, event)}/>}
              </div>
              <div className="col-2 mb-3">
                <label className="form-label">Qty<span className='color-red'>*</span></label>
                {item.EditMode ?  <input type="text" required name="Qty" className="form-control" id="Qty" value={item.Qty} onChange={event => handleBatchInputChange(index, event)} placeholder="Qty"/> : <input disabled type="text" required name="BatchNo" className="form-control" id="TaxName" value={item.Qty} onChange={event => handleBatchInputChange(index, event)}/>}
            </div>

            <div className='col-2 mt-4'>
            <i className="pi pi-plus ActionIconsDefault ms-1" onClick={() => handleAddMoreItem(index)}></i>
            <i className="pi pi-pencil ActionIconsDefault ms-2" onClick={() => handleBatchEditMode(index)}></i>
            <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
            </div>
            </div> 

            ))}
            </>
            :
            <div className="mb-3">
                <label className="form-label">Add Serial Numbers ("," Comma Separated)</label>
                <textarea type="text" rows="4" name="MySrNos" className="form-control" id="MySrNos" value={MySrNos} onChange={(e) => setMySrNos(e.target.value)} placeholder="Enter Serial Numbers"/>
            </div> }          
            <div className='mt-4'>
                {!state.is_Issued ? <button type="submit" className="btn btn-primary">Submit</button> : ""}
            </div>
        </form>
      </Dialog>
    </div>
  )
}

export default SAdjReqDetailsBlock