import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';

const AddBOMBlock = () => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [Items, setItems] = useState([]);
    const [SearchItems, setSearchItems] = useState([]);
    const [myFGACItem, setmyFGACItem] = useState([]);
    const [myRMACItem, setmyRMACItem] = useState([]);
    const [mySACItem, setmySACItem] = useState([]);
    const [Dealers, setDealers] = useState([]); 
    const [Warehouses, setWarehouses] = useState([]); 
    const [Taxes, setTaxes] = useState([]); 
    const [Message, setMessage] = useState();
    const [ConfirmVisible, setConfirmVisible] = useState(false);
    const [SelectFWHID, setSelectFWHID] = useState("");
    const [Index, setIndex] = useState(0);
    const navigate = useNavigate();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        sortField: null,
        sortOrder: 0,
    });
    const [state, setState] = useState ({
        Name: "",
        CompanyId : Company.id,
        Description : "Please transfer following Items",
        FinishedGoods : [{
            ItemId: "",
            ItemName: "",
            Index: 0,
            Qty: "",
            RemarksIfany: "",
            UnitId: "",
            UnitName: "",
            SpecialRemarks: "",
        }],
        RawMaterial : [{
            ItemId: "",
            ItemName: "",
            Index: 0,
            Qty: "",
            RemarksIfany: "",
            UnitId: "",
            UnitName: "",
            SpecialRemarks: "",
        }],
        Scrap : [{
            ItemId: "",
            ItemName: "",
            Index: 0,
            Qty: "",
            RemarksIfany: "",
            UnitId: "",
            UnitName: "",
            SpecialRemarks: "",
        }]

    });

    const getTimeZone = () => {
                        
        const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setState((prevProps) => ({
            ...prevProps,
            "TimeZone": MytimeZone
          }));
    };

//     const accept = () => {
//         setState((prevProps) => ({
//             ...prevProps,
//             FromWHId: SelectFWHID,
//             STOItemsRequest: [{"ItemId": "", "Index": 0, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", "AvailableQty": 0, "InvId": "" }],
//           }));
        
//         setMySelectedItem([]);
//    }

//     const reject = () => {
//     }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(state.STOItemsRequest);
        if(name=== "FromWHId" && (state.STOItemsRequest.length > 1 || state.STOItemsRequest[0]["ItemId"] !== "")){
            setSelectFWHID(value);
            setConfirmVisible(true);
            
        }
        else{
            setState((prevProps) => ({
                ...prevProps,
                [name]: value
              }));
        }
       
      };
      const search = (event, index) => {
            setSearchItems(Items.filter(x => x.name.toLowerCase().startsWith(event.query.toLowerCase())));
        }
        
        const AutoCompleteChange = (e, index, Type) => {
            let values = [];
            let valueSelectedItem = [];

            if(Type === "fg"){
                values = [...state.FinishedGoods];
                valueSelectedItem = [...myFGACItem];
            }
            else if(Type === "rm"){
                values = [...state.RawMaterial];
                valueSelectedItem = [...myRMACItem];
            } 
            else if(Type === "s"){
                values = [...state.Scrap];
                valueSelectedItem = [...mySACItem];
            }
            const selectedItem = e.value;
            // valueSelectedItem.splice(index, 0, selectedItem)
            valueSelectedItem[index] = selectedItem;        
            
        
            if (selectedItem) {
                values[index] = {
                    ...values[index],
                    ItemId: selectedItem.id,
                    ItemName: selectedItem.name,
                    UnitName: selectedItem.inventoryUnitName,
                    UnitId: selectedItem.inventoryUnit,
                    HSNCode: selectedItem.hsnCode,
                    SpecialRemarks: selectedItem.description
                };

                console.log(selectedItem.listInventories);

                if(selectedItem.listInventories && selectedItem.listInventories.length > 0)
                    {
                        var MyList = selectedItem.listInventories.find(x => x.warehouseId == state.FromWHId);
                        if(MyList !== null && MyList !== undefined){
                            values[index]["AvailableQty"] = MyList.availableQty;
                            values[index]["InvId"] = MyList.id;
                        }
                        else{
                            values[index]["AvailableQty"] = 0;
                        }
                    }
                    else{
                        values[index]["AvailableQty"] = 0;
                    }


                    if(Type === "fg"){
                        setmyFGACItem(valueSelectedItem)
                        setState(prevState => ({
                            ...prevState,
                            FinishedGoods: values
                        }));
                    }
                    else if(Type === "rm"){
                        setmyRMACItem(valueSelectedItem)
                        setState(prevState => ({
                            ...prevState,
                            RawMaterial: values
                        }));
                    } 
                    else if(Type === "s"){
                        setmySACItem(valueSelectedItem)
                        setState(prevState => ({
                            ...prevState,
                            Scrap: values
                        }));
                    }
                
                
                
            }
        }


    const handleWlcomeEditorChange = (event) => {
        setState((prevProps) => ({
          ...prevProps,
          "Description": event.htmlValue,
        }));
      };

      const handleL2InputChange = ( index, event, Type) => {
        const { name, value } = event.target;
        let values = []
        if(Type === "fg"){
            values = [...state.FinishedGoods];
            values[index][name] = value;
        
            setState((prevProps) => ({
                ...prevProps,
                FinishedGoods: values
            }));
        }
        else if(Type === "rm"){
            values = [...state.RawMaterial];
            values[index][name] = value;
        
            setState((prevProps) => ({
                ...prevProps,
                RawMaterial: values
            }));
        } 
        else if(Type === "s"){
            values = [...state.Scrap];
            values[index][name] = value;
        
            setState((prevProps) => ({
                ...prevProps,
                Scrap: values
            }));
        }    
        
        
      };

      
      const handleAddMoreItem = (index, Type) => {
        console.log(index);
        index && setIndex (index + 1);
        if (Type.toLowerCase() === "fg"){
            const values = [...state.FinishedGoods]
            values.splice(index + 1, 0, {"ItemId": "", "Index": index + 1, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", } )
            setState((prevProps) => ({
                ...prevProps,
                FinishedGoods: values
              }));

            let IValues = [...myFGACItem];
            IValues.splice(index + 1, 0, "")
            setmyFGACItem(IValues);
        }
        else if (Type.toLowerCase() === "rm"){
            const values = [...state.RawMaterial]
            values.splice(index + 1, 0, {"ItemId": "", "Index": index + 1, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", } )
            setState((prevProps) => ({
                ...prevProps,
                RawMaterial: values
              }));
            
            let IValues = [...myRMACItem];
            IValues.splice(index + 1, 0, "")
            setmyRMACItem(IValues);
        }
        else if (Type.toLowerCase() === "s"){
            const values = [...state.Scrap]
            values.splice(index + 1, 0, {"ItemId": "", "Index": index + 1, "RemarksIfany": "", "Qty": "", "UnitId": "", "UnitName": "", } )
            setState((prevProps) => ({
                ...prevProps,
                Scrap: values
              }));
            
            let IValues = [...mySACItem];
            IValues.splice(index + 1, 0, "")
            setmySACItem(IValues);
        }
      }

    const handleremoveitem = (index, Type) => {
            if (Type.toLowerCase() === "fg"){
                const values = [...state.FinishedGoods]
                if(values.length > 1){
                    values.splice(index, 1)
                    setState((prevProps) => ({
                        ...prevProps,
                        FinishedGoods: values
                    }));

                    let IValues = [...myFGACItem];
                    IValues.splice(index, 1)
                    setmyFGACItem(IValues);
                }
            }
            else if (Type.toLowerCase() === "rm"){
                const values = [...state.RawMaterial]
                if(values.length > 1){
                    values.splice(index, 1)
                    setState((prevProps) => ({
                        ...prevProps,
                        RawMaterial: values
                    }));
                }

                let IValues = [...myRMACItem];
                IValues.splice(index, 1)
                setmyRMACItem(IValues);

            }
            else if (Type.toLowerCase() === "s"){
                const values = [...state.Scrap]
                
                values.splice(index, 1)
                setState((prevProps) => ({
                    ...prevProps,
                    Scrap: values
                }));
            

                let IValues = [...mySACItem];
                IValues.splice(index, 1)
                setmySACItem(IValues);
            }

            

            
        
      }


        const GetWareHouseDD = async () => {
            var MyRes = await GetAPICallAsync("GetListCompanyWarehouses", { lazyEvent: lazyState }, "");
            if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
            }
            else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setWarehouses(MyData.data);
            }
        };


        const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/ListItemMasters?' + new URLSearchParams({
            CompanyId: Company && Company.id,
            }), {
            method: 'Get',
            headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + tokenString
            },
          })
          .then(res => {
              
              if(res.status === 401){ navigate("/login"); }
              return res.json()
          } ))
        .then(data => {
            console.log(data.data)
            setItems(data.data);
        })
        .catch((err) => {
        console.log(err.message);
        })

        useEffect (() => {   
            calltpAPI();
            getTimeZone();
            GetWareHouseDD();
        }, []);
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        let MyRes = await  POSTAPICallAsync("AddBOM", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success === true ){
                console.log(MyData);
                navigate("/production/bom");
            }
            else{
                setMessage(MyData.message);
            }
            
        }
        
        };
  return (
    <>
    <div className='mb-3 row'>
        <div className='col-6'>
            <h1 className='ListMainHeading'>Add Bill of Material</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
           
        </div>
        </div>
        <div className='row'>
    
   <div>
     <form onSubmit={handleSubmit}>

     <div className='row'>
             <div className="col-6 mb-3">
                 <label htmlFor="Heading" className="form-label">Document Name<span className='color-red'>*</span></label>
                 <input type="text" required name="Name" className="form-control" id="Name" value={state.Name} onChange={handleInputChange} placeholder="Enter document name"/>
             </div>
             {/* <div className="col-6 mb-3">
                 <label htmlFor="Heading" className="form-label">Heading<span className='color-red'>*</span></label>
                 <input type="text" required name="Heading" className="form-control" id="Heading" value={state.Heading} onChange={handleInputChange} placeholder="Enter Heading here"/>
             </div> */}
         </div>
         <div className='row'>
            {/* <div className="col-3 mb-3">
                <label htmlFor="PONo" className="form-label">PO No<span className='color-red'>*</span></label>
                <input type="text" required name="PONo" className="form-control" id="PONo" value={state.PONo} onChange={handleInputChange} placeholder="Enter company name"/>
            </div> */}
             {/* <div className="col-3 mb-3">
                 <label htmlFor="STODate" className="form-label">STO Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="STODate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={state.STODate} onChange={handleInputChange} showButtonBar showIcon ></Calendar>

             </div> */}
             {/* <div className="col-3 mb-3">
                 <label htmlFor="FromWHId" className="form-label">FG Store<span className='color-red'>*</span></label>
                 
                 <select name="FromWHId" className="form-select" value={state.FromWHId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Warehouses.filter(x => x.id !== state.ToWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
                </select>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="DealerId" className="form-label">RM Store<span className='color-red'>*</span></label>
                 
                 <select name="ToWHId" className="form-select" value={state.ToWHId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Warehouses.filter(x => x.id !== state.FromWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
                </select>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="FromWHId" className="form-label">Scrap/ Reject Store<span className='color-red'>*</span></label>
                 
                 <select name="FromWHId" className="form-select" value={state.FromWHId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Warehouses.filter(x => x.id !== state.ToWHId).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
                </select>

             </div> */}

             

         </div>
         
        
         
         <div className="mb-3">
             <label htmlFor="Description" className="form-label">Description<span className='color-red'>*</span></label>
             <Editor id="Description" value={state.Description} onTextChange={handleWlcomeEditorChange} style={{ height: '100px' }} />
         </div>
        

         <div className='row'>
            <div className='col-12'>
                <h5>Finished Good</h5>
            </div>
         </div>
        
         <table className='table table-bordered'>
             <thead>
                 <tr>
                     <th> Item</th>
                     <th>HSN</th>
                     <th> Qty</th>
                     <th>Remarks If any</th>
                     <th></th>
                 </tr>
                
             </thead>
             <tbody>
         {state.FinishedGoods.map((Item, index) => (
             <tr key={index}>
                 <td>
                    
                        <AutoComplete 
                            value={myFGACItem[index]} 
                            suggestions={SearchItems} 
                            forceSelection 
                            field="name" 
                            completeMethod={(e) => search(e, index)} 
                            onChange={(e) => AutoCompleteChange(e, index, "fg")} 
                            style={{ width: '100%' }} 
                            placeholder='Type Item Name to search..' 
                        />
                 {/* <select name="ItemId" className="" value={Item.ItemId} onChange={event => handleL2InputChange(index, event)} >
                     <option value="">Select Item</option>
                  {Items.map((item) => (<option key={item.id} value={item.id}>{item.name}</option>))} 
                 </select><br/> */}
                    {Item.SpecialRemarks}
                   
                    
                 </td>
                 <td>
                    {Item.HSNCode}
                 </td>
                 <td>
                 <input type="text"  name="Qty" className="" id="Qty" value={Item.Qty} onChange={event => handleL2InputChange(index, event, "fg")} placeholder="Qty"/>
                 &nbsp;{Item.UnitName} 
                 </td>
                 <td>
                 <input type="text"  name="RemarksIfany" className="" id="RemarksIfany" value={Item.RemarksIfany} onChange={event => handleL2InputChange(index, event, "fg")} placeholder="Remarks"/>
                    
                 </td>
                 
                 <td>
                     <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index, "fg")}></i>
                     <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index, "fg")}></i>
                 </td>
             </tr>

         ))}
     

         {/* <tr>
             <td colSpan={3}>
                 <button className='btn btn-primary' onClick={() => AddMoreItem()}>Add</button>
             </td>
         </tr> */}
         </tbody>
         </table>

         <div className='row'>
            <div className='col-12'>
                <h5>Raw Material</h5>
            </div>
         </div>
        
         <table className='table table-bordered'>
             <thead>
                 <tr>
                     <th> Item</th>
                     <th>HSN</th>
                     <th> Qty</th>
                     <th>Remarks If any</th>
                     <th></th>
                 </tr>
                
             </thead>
             <tbody>
         {state.RawMaterial.map((Item, index) => (
             <tr key={index}>
                 <td>
                   
                        <AutoComplete 
                            value={myRMACItem[index]} 
                            suggestions={SearchItems} 
                            forceSelection 
                            field="name" 
                            completeMethod={(e) => search(e, index)} 
                            onChange={(e) => AutoCompleteChange(e, index, "rm")} 
                            style={{ width: '100%' }} 
                            placeholder='Type Item Name to search..' 
                        />
                 {/* <select name="ItemId" className="" value={Item.ItemId} onChange={event => handleL2InputChange(index, event)} >
                     <option value="">Select Item</option>
                  {Items.map((item) => (<option key={item.id} value={item.id}>{item.name}</option>))} 
                 </select><br/> */}
                    {Item.SpecialRemarks}
                   
                 </td>
                 <td>
                    {Item.HSNCode}
                 </td>
                 <td>
                 <input type="text"  name="Qty" className="" id="Qty" value={Item.Qty} onChange={event => handleL2InputChange(index, event, "rm")} placeholder="Qty"/>
                 &nbsp;{Item.UnitName}
                 </td>
                 <td>
                 <input type="text"  name="RemarksIfany" className="" id="RemarksIfany" value={Item.RemarksIfany} onChange={event => handleL2InputChange(index, event, "rm")} placeholder="Remarks"/>
                    
                 </td>
                 
                 <td>
                     <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index,"rm")}></i>
                     <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index, "rm")}></i>
                 </td>
             </tr>

         ))}
     

         {/* <tr>
             <td colSpan={3}>
                 <button className='btn btn-primary' onClick={() => AddMoreItem()}>Add</button>
             </td>
         </tr> */}
         </tbody>
         </table>

         <div className='row'>
            <div className='col-12'>
                <h5>Scrap</h5>
            </div>
         </div>
        
         <table className='table table-bordered'>
             <thead>
                 <tr>
                     <th> Item</th>
                     <th>HSN</th>
                     <th> Qty</th>
                     <th>Remarks If any</th>
                     <th></th>
                 </tr>
                
             </thead>
             <tbody>
         {state.Scrap.map((Item, index) => (
             <tr key={index}>
                 <td>
                        <AutoComplete 
                            value={mySACItem[index]} 
                            suggestions={SearchItems} 
                            forceSelection 
                            field="name" 
                            completeMethod={(e) => search(e, index)} 
                            onChange={(e) => AutoCompleteChange(e, index, "s")} 
                            style={{ width: '100%' }} 
                            placeholder='Type Item Name to search..' 
                        />
                 {/* <select name="ItemId" className="" value={Item.ItemId} onChange={event => handleL2InputChange(index, event)} >
                     <option value="">Select Item</option>
                  {Items.map((item) => (<option key={item.id} value={item.id}>{item.name}</option>))} 
                 </select><br/> */}
                    {Item.SpecialRemarks}
                 </td>
                 <td>
                    {Item.HSNCode}
                 </td>
                 <td>
                 <input type="text"  name="Qty" className="" id="Qty" value={Item.Qty} onChange={event => handleL2InputChange(index, event, "s")} placeholder="Qty"/>
                 &nbsp;{Item.UnitName}
                 </td>
                 <td>
                 <input type="text"  name="RemarksIfany" className="" id="RemarksIfany" value={Item.RemarksIfany} onChange={event => handleL2InputChange(index, event, "s")} placeholder="Remarks"/>
                    
                 </td>
                 
                 <td>
                     <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index, "s")}></i>
                     <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index, "s")}></i>
                 </td>
             </tr>

         ))}
     

         {/* <tr>
             <td colSpan={3}>
                 <button className='btn btn-primary' onClick={() => AddMoreItem()}>Add</button>
             </td>
         </tr> */}
         </tbody>
         </table>
         
         

         <div className='mt-4'>
             <button type="submit" className="btn btn-primary">Submit</button>
         </div>
     </form>
     {/* <ConfirmDialog group="declarative"  visible={ConfirmVisible} onHide={() => setConfirmVisible(false)} message="This will reset the items in list. Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} /> */}
 </div>
 </div>
 <span>{Message}</span>
        {/* <Dialog header="Add New Item" visible={visible} style={{ width: '70vw' }} onHide={() => setVisible(false)}>
         <AddSTOItems MyItems = {Items} MyTaxes= {Taxes} AddmoreItems= {AddmoreItems}/>
        </Dialog> */}
 </>
  )
}

export default AddBOMBlock