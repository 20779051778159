import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { Calendar } from 'primereact/calendar';

const AddDealer = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Cities, setCitiess] = useState([]);
    const [Dealers, setDealers] = useState([]);
    const [Currencies, setCurrencies] = useState([]);
    const date = new Date();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
        filters: {
          supName: { value: '', matchMode: 'contains' },
  
        }
    });
    const [Filters, setFilters] = useState({
        DealerName: "",
        BusinessType: "",
        ContactPerson: "",
        Designation: "",
        Phone: "",
        Email: "",
        CountryId: 0,
        StateId: 0,
        CityId: 0,
    });
    const [state, setState] = useState ({
        CompanyId: Company.id,
        PrimaryDealer: "",
        DealerName : "",
        PrimaryDealer: "",
        BusinessType: "",
        ContactPersonName: "",
        ContactDesignation: "",
        Email: "",
        phone: "",
        GST : "",
        TAN : "",
        PAN : "", 
        TimeZone: "",
        DealerAddresses: [{
            AddressName: "",
            AddressType : "",
            StreetAddress : "",
            CityId : "",
            StateId : "",
            CountryId : "",
            ZipCode : "",
            ContactPersonName : "",
            ContactPhone : "",
            Email: ""
        }],
        DealerCreditLimitRequests: [{
            CreditLimit: "",
            CurrencyId: "",
            StartDate: new Date(),
            EndDate: Date.parse(date.setFullYear(date.getFullYear() + 1)),
        }]

    });

    const getTimeZone = () => {
                        
        const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setState((prevProps) => ({
            ...prevProps,
            "TimeZone": MytimeZone
          }));

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
      const handleInputL2Change = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.DealerAddresses]
        values[index][name] = value;
        setState((prevProps) => ({
            ...prevProps,
            DealerAddresses: values
          }));
      };
      const handleInputCLChange = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.DealerCreditLimitRequests]
        values[index][name] = value;
        setState((prevProps) => ({
            ...prevProps,
            DealerCreditLimitRequests: values
          }));
      };
      const handlePhoneL1 = (phone) => {
        setState((prevProps) => ({
            ...prevProps,
            phone: phone
          }));
      };
      const GetListDealers = async () => {
        var MyRes = await GetAPICallAsync("GetListDealers", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(Filters)});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setDealers(MyData.data);
        }
      }

      const GetCurrencyDD = async () => {
        var MyRes = await GetAPICallAsync("GetListCurrencies", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
        }
        else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setCurrencies(MyData.data);
        }
    };

      const UpdateStates = (index, event) => {
        handleInputL2Change(index, event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/states?' + new URLSearchParams({
                CountryId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                setStates(data.lstStates);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      };
      const UpdateCities= (index, event) => {
        handleInputL2Change(index, event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/Cities?' + new URLSearchParams({
                StateId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                console.log(data);
                setCitiess(data.lstCities);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      }
      const calltpAPI = () => fetch(process.env.REACT_APP_API_URL + 'setup/countries', {
        method: 'Get',
        headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + tokenString.toString()
        },
    })
    .then(res => {
        if(res.status === 401){ navigate("/login"); }
        return res.json()
    } )
    .then(data => {
        setCountries(data.lstCountries);
    })
    .catch((err) => {
       console.log(err.message);
    });

    useEffect (() => {
        getTimeZone();
        calltpAPI();
        GetListDealers();
        GetCurrencyDD();
    }, []);
      
      const handleSubmit = async (event) => {
            event.preventDefault();
            console.log(state);
            let MyRes = await  POSTAPICallAsync("CreateDealer", state);
            console.log(MyRes);
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                var MyData = await MyRes.json();
                console.log(MyData);
                if (MyData.success == true ){
                    setMessage(MyData.message);
                    setShowForm(false);
                }
                else{
                    setMessage(MyData.message);
                }
                
            }
        };
  return (
    <>
    {!ShowForm ? <div className='col'>  
    <button onClick={() => props.setVisible(false)}>Close</button>
</div> :
    <div className='p-5'>
        <form onSubmit={handleSubmit}>
        <div className='row mb-3'>
            <div className="col-6 mb-3">
                <label for="TAN" className="form-label">Main Dealer (if any)</label>
                <select name="PrimaryDealer" className="form-select" value={state.PrimaryDealer} onChange={handleInputChange} >
                    <option value="">Select Dealer</option>
                    {Dealers.map((item) => (<option key={item.id} value={item.id}>{item.dealerName}</option>))}
                </select>
            </div>
            <div className="col-6 mb-3">
                <label className="form-label">Dealer Name<span className='color-red'>*</span></label>
                <input type="text" required name="DealerName" className="form-control" id="DealerName" value={state.DealerName} onChange={handleInputChange} placeholder="Enter dealer name"/>
                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            </div>
            <div className='row mb-3'>
                <div className="col-3">
                    <label for="BusinessType" className="form-label">Business Type</label>
                    <select name="BusinessType" className="form-select" value={state.BusinessType} onChange={handleInputChange} aria-label="">
                        <option>Select Any</option>
                        <option value="trader">Trader</option>
                        <option value="wholesaler">Wholesaler</option>
                        <option value="retailer">Retailer</option>
                    </select>
                </div>
              
                <div className="col-3 mb-3">
                    <label htmlFor="ContactPersonName" className="form-label">Business Contact</label>
                    <input type="text"  name="ContactPersonName" className="form-control" id="ContactPersonName" value={state.ContactPersonName} onChange={handleInputChange} placeholder="Enter Person Name"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
     
                <div className="col-3 mb-3">
                    <label htmlFor="ContactDesignation" className="form-label">Designation</label>
                    <input type="text"  name="ContactDesignation" className="form-control" id="ContactDesignation" value={state.ContactDesignation} onChange={handleInputChange} placeholder="Enter Designation"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-3 mb-3">
                    <label htmlFor="Email" className="form-label">Business Email</label>
                    <input type="email"  name="Email" className="form-control" id="Email" value={state.Email} onChange={handleInputChange} placeholder="Enter Email"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-3 mb-3">
                    <label htmlFor="phone" className="form-label">Business Phone</label>
                    <PhoneInput inputProps={{required: true}} country={'in'} inputClass='inputClass' name="phone" value={state.phone} onChange={Phone => handlePhoneL1(Phone)} placeholder="Enter mobile with country code"/>
                </div>
    
                <div className="col-3 mb-3">
                    <label for="GST" className="form-label">GST</label>
                    <input type="text"  name="GST" className="form-control" id="GST" value={state.GST} onChange={handleInputChange} placeholder="Enter GST Number"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-3 mb-3">
                    <label for="TAN" className="form-label">TAN</label>
                    <input type="text"  name="TAN" className="form-control" id="TAN" value={state.TAN} onChange={handleInputChange} placeholder="Enter TAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
     
                <div className="col-3 mb-3">
                    <label for="PAN" className="form-label">PAN</label>
                    <input type="text"  name="PAN" className="form-control" id="PAN" value={state.PAN} onChange={handleInputChange} placeholder="Enter PAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>

            </div>
            <div className='row'>
                <div className='col-12 formheading'>
                    <h3>Credit Limit</h3>
                </div>
            </div>
            {state.DealerCreditLimitRequests.map((CC, index) => (
                <div key={index}>
                    <div className='row'>
                    <div className="col-3 mb-3">
                            <label htmlFor="CreditLimit" className="form-label">Credit Limit</label>
                            <input type="text"  name="CreditLimit" className="form-control" id="CreditLimit" value={CC.CreditLimit} onChange={event => handleInputCLChange(index, event)} placeholder="Enter Credit Limit"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        <div className="col-3 mb-3">
                            <label htmlFor="CurrencyId" className="form-label">Currency<span className='color-red'>*</span></label>
                            <select name="CurrencyId" className="form-select" value={CC.CurrencyId} onChange={event => handleInputCLChange(index, event)} >
                                <option value="">Select Item</option>
                                {Currencies.map((item) => (<option key={item.id} value={item.id}>{item.shortCode}</option>))} 
                            </select>
                        </div>
                        <div className="col-3 mb-3">
                            <label htmlFor="StartDate" className="form-label">Start Date<span className='color-red'>*</span></label>
                            
                            <Calendar required name="StartDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={CC.StartDate} onChange={event => handleInputCLChange(index, event)} showButtonBar ></Calendar>

                        </div>
                        <div className="col-3 mb-3">
                            <label htmlFor="EndDate" className="form-label">Valid Till<span className='color-red'>*</span></label>
                            
                            <Calendar required name="EndDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={CC.EndDate} onChange={event => handleInputCLChange(index, event)} showButtonBar ></Calendar>

                        </div>
                        
                    </div>
                </div>
                ))};
            <div className='row'>
                <div className='col-12 formheading'>
                    <h3>Address</h3>
                </div>
            </div>
            {state.DealerAddresses.map((address, index) => (
                <div key={index}>
                    <div className='row mb-3'>
                    <div className="col-3 mb-3">
                            <label for="AddressName" className="form-label">Address Name</label>
                            <input type="text"  name="AddressName" className="form-control" id="AddressName" value={address.AddressName} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Address Name"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        <div className="col-3 mb-3">
                            <label for="GST" className="form-label">Address Type</label>
                            <select name="AddressType" className="form-select" value={address.AddressType} onChange={event => handleInputL2Change(index, event)} aria-label="Default select example">
                                <option>Select Any</option>
                                <option value="headoffice">Head Office</option>
                                <option value="branchoffice">Branch Office</option>
                            </select>
                        </div>
                        <div className="col-3 mb-3">
                            <label for="TAN" className="form-label">Country</label>
                            <select name="CountryId" className="form-select" value={address.CountryId} onChange={event => UpdateStates(index, event)} >
                                <option value="">Select Country</option>
                                {Countries.map((item) => (<option key={item.id} value={item.id}>{item.countryName}</option>))}
                            </select>
                        </div>
                        <div className="col-3 mb-3">
                            <label for="StateId" className="form-label">States</label>
                            <select name="StateId" id="StateId" className="form-select" value={address.StateId} onChange={event => UpdateCities(index, event)}>
                                <option value="">Select State</option>
                                {States.map((item) => (<option key={item.id} value={item.id}>{item.stateName}</option>))}
                            </select>
                        </div>
                        <div className="col-3 mb-3">
                            <label for="CIN" className="form-label">City</label>
                            <select name="CityId" id="CityId" className="form-select" value={address.CityId} onChange={event => handleInputL2Change(index, event)}>
                                <option value="">Select City</option>
                                {Cities.map((item) => (<option key={item.id} value={item.id}>{item.cityName}</option>))}
                            </select>
                        </div>
                 
                        <div className="col-3 mb-3">
                            <label for="StreetAddress" className="form-label">Local Address</label>
                            <input type="text"  name="StreetAddress" className="form-control" id="StreetAddress" value={address.StreetAddress} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Local Address"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        <div className="col-3 mb-3">
                            <label for="ZipCode" className="form-label">Pin code</label>
                            <input type="text"  name="ZipCode" className="form-control" id="ZipCode" value={address.ZipCode} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Pincode"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                     
                    </div>
            </div>

            ))}

            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>
    }
    <span>{Message}</span>
    </>
  )
}

export default AddDealer