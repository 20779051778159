import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const EditItemUnit = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [Message, setMessage] = useState();
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 0,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {
      warehouseName: { value: '', matchMode: 'contains' },
      cityName: {value: '', matchMode: 'contains'},
      countryName : {value: '', matchMode: 'contains'},
      status: {value: '', matchMode: 'equals'}
    }
  });
  const [state, setState] = useState({
    Id: props.Id,
    CompanyId : Company.id,
    UnitName : "",
    UnitDescription : "",
    UnitShortCode : "",
  });
  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
  };

  const GetUnitbyId = async() => {
    var MyRes = await GetAPICallAsync("GetUnitbyId", { lazyEvent: lazyState }, {ID: props.Id});
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setState((prevProps) => ({
            ...prevProps,
            UnitName : MyData.data.unitName,
            UnitDescription : MyData.data.unitDescription,
            UnitShortCode : MyData.data.unitShortCode,
            
        }))
    }
};

useEffect (() => {  
  GetUnitbyId();
}, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(Company.id)
    console.log(state);
    await fetch(process.env.REACT_APP_API_URL + 'setup/EditUnit', {
     method: 'POST',
     body: JSON.stringify(state),
     headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + tokenString
      },
    })
    .then((res) => 
    { if(res.status === 401){ navigate("/login"); }        
    return res.json()})
    .then((data) => {
        setMessage("Unit Added Successfully")
    })
    .catch((err) => {
        console.log(err.message);
    });
  }
  return (
    <>
      {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible({open: false})}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Unit Name<span className='color-red'>*</span></label>
                <input type="text" required name="UnitName" className="form-control" id="CatName" value={state.UnitName} onChange={handleInputChange} placeholder="Enter Unit name"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Short Code<span className='color-red'>*</span></label>
                <input type="text" required name="UnitShortCode" className="form-control" id="UnitShortCode" value={state.UnitShortCode} onChange={handleInputChange} placeholder="Enter Unit Short Code"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea type="text" rows="4" name="UnitDescription" className="form-control" id="UnitDescription" value={state.UnitDescription} onChange={handleInputChange} placeholder="Enter Description"/>
            </div>           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </>
  )
}

export default EditItemUnit
