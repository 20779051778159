import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddItemUnit from './AddItemUnit';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import moment from 'moment';
import { AutoComplete } from 'primereact/autocomplete';

const ListAPInvoices = (props) => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [OrderReceiving, setOrderReceiving] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const [Suppliers, setSuppliers] = useState([]);
    const [SelectedSupplier, setSelectSupplier] = useState() 
    const [SearchSupplier, setSearchSupplier] = useState([]);

    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
          org_name: { value: '', matchMode: 'contains' },
      }
  });
  const [FilterState, setFilterState] = useState({
    first: 0,
    rows: 0,
    page: 1,
    sortField: null,
    sortOrder: 0,
});


  const [filterHeight, setfilterHeight] = useState("0px");
  const [Filters, setFilters] = useState({
      InvNo: props.Filters ? props.Filters.InvNo: "" ,
      PONO: props.Filters ? props.Filters.InvNo: "",
      Date: props.Filters ? props.Filters.InvNo: "",
      SupplierId: props.Filters ? props.Filters.InvNo: "",
      ApprovalStatus: props.Filters ? props.Filters.InvNo: "",
      FromDate: props.Filters ? props.Filters.InvNo: "",
      ToDate: props.Filters ? props.Filters.InvNo: "",
  });
  const Reset = () => {
    setFilters({
      InvNo: "",
      PONO: "",
      Date: "",
      SupplierId: "",
      ApprovalStatus: "",
      FromDate: "",
      ToDate: "",
  })
  }
  const [AppliedFilters, setAppliedFilters] = useState([]);
  
  const items = [
  {
      label: 'Details',
      icon: 'pi pi-info-circle',
      url: "/purchase/apinvoice/" + selectedRow.id + ""
  },
  {
      label: 'Edit',
      icon: 'pi pi-times',
      command: (row) => {
        console.log(row);
          toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
      }
  },
  {
    label: 'React Website',
    icon: 'pi pi-external-link',
    url: 'https://reactjs.org/'
  },
  ]; 
    
  
    useEffect(() => {
      loadLazyData();
    }, [lazyState, visible]);
    useEffect (() => {
      loadLazyData();
      GetSuppliersDD();
    }, [])

    const GetSuppliersDD = async () => {
      var MyRes = await GetAPICallAsync("GetListSuppliersDD", "", "");
      if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
      }
      else{
      var MyData = await MyRes.json();
      setSuppliers(MyData.data);
      }
  };

  const search = (event) => {
    setSearchSupplier(Suppliers.filter(x => x.supName.toLowerCase().startsWith(event.query.toLowerCase()) ));
  }
  const AutoCompleteChange = (e)=> {
      console.log(e);
      setSelectSupplier(e.value);
      if (e.value != null){
        setFilters((prevProps) => ({
          ...prevProps,
          ["SupplierId"] : e.value.id,
        }));
      }
  }
  
  
    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }
  
      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        console.log(Filters);
        var MyRes = await GetAPICallAsync("GetListAPInvoices", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(Filters)});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setOrderReceiving(MyData.data);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };
  
  const onSort = (event) => {
      setlazyState(event);
  };
  
  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };
  
  const onSelectionChange = (event) => {
      const value = event.value;
  
      setSelectedCustomers(value);
      setSelectAll(value.length === totalRecords);
  };
  
  const onSelectAllChange = async (event) => {
      const selectAll = event.checked;
  
      if (selectAll) {
              setSelectAll(true);
              setSelectedCustomers(OrderReceiving);
        
      } else {
          setSelectAll(false);
          setSelectedCustomers([]);
      }
  };

  const handleFiltersChange = (event) => {
    let { name, value } = event.target;
    setFilters((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  
  const handleFilterSubmit = async (event) => {
    event.preventDefault();
    const FinalFilters = ManageAppliedFilters(Filters);
    FilterSubmitMethod(FinalFilters);
  }
  
  const ManageAppliedFilters = (AppFilters) => {
      let MyFilters = [];
      let ModifiedFilters = {...Filters};
      if(AppFilters.PONO !== null && AppFilters.PONO !== ""){
        MyFilters = [...MyFilters, {name: "PONO", Filter: "PO No", Value: AppFilters.PONO}] 
      }
      if (Filters.Date !== null && Filters.Date !== ""){
        let FromDate = moment(Filters.Date[0]).format("DD/MM/yyyy");
        let ToDate = new moment(Filters.Date[1]).format("DD/MM/yyyy");
        MyFilters = [...MyFilters, {name: "Date", Filter: "Date", Value: FromDate + "-" + ToDate}] 
        ModifiedFilters.FromDate = Filters.Date[0];
        ModifiedFilters.ToDate = Filters.Date[1];
      }
      if(AppFilters.SupplierId !== null && AppFilters.SupplierId !== ""){
        let MySupplier= Suppliers.find(x => x.id == AppFilters.SupplierId);
        console.log(MySupplier);
        MyFilters = [...MyFilters, {name: "SupplierId", Filter: "Supplier", Value: MySupplier.supName}] 
      }
      if(AppFilters.InvNo !== null && AppFilters.InvNo !== ""){
        MyFilters = [...MyFilters, {name: "InvNo", Filter: "Invoice No", Value: AppFilters.InvNo}] 
      }
      if(AppFilters.ApprovalStatus !== null && AppFilters.ApprovalStatus !== ""){
        MyFilters = [...MyFilters, {name: "ApprovalStatus", Filter: "Approval Status", Value: AppFilters.ApprovalStatus}] 
      }
      setAppliedFilters(MyFilters);
      setFilters(ModifiedFilters);
      return ModifiedFilters;
  }
  
  const FilterSubmitMethod = async (AppFilters) => {
    var MyRes = await GetAPICallAsync("GetListAPInvoices", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(AppFilters)});
    if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
    }
    else{
      var MyData = await MyRes.json();
      console.log(MyData);
      setTotalRecords(MyData.total);
      setOrderReceiving(MyData.data);
      setfilterHeight("0px")
    }
  }
  const RemoveFilter = (item, index) => {
    let MyAppliedFilter = [...AppliedFilters];
    MyAppliedFilter.splice(index, 1)
    setAppliedFilters(MyAppliedFilter);
    const name = item.name;
    let MyFilterz ="";
    console.log(MyFilterz);
    if(name === "Date"){
      MyFilterz = {...Filters, [name] : "", ["FromDate"] : "",["ToDate"] : "" }
      setFilters((prevProps) => ({
        ...prevProps,
        [name] : "",
        ["FromDate"] : "",
        ["ToDate"] : "",
      }) )
    }else{
      MyFilterz = {...Filters, [name] : "" }
      setFilters((prevProps) => ({
        ...prevProps,
        [name] : "",
      }) )
    }
   
    FilterSubmitMethod(MyFilterz);
  }
  
  
  
  
    const ActiveTemplate = (prop) => {
        return prop.status === true ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Inactive</span>
    }
    const ApprovedTemplate = (prop) => {
        return prop.isApproved === true ? <span className='ActiveClass'>Approved</span> : <span className='InActiveClass'>Unapproved</span>
    }
    const DateTemplate = (prop) => {
      return moment(prop.date).format("DD/MM/yyyy");
    }
    const ActionTemplate = (row) => {
       return<>
       <div className="flex justify-content-center">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
            {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
       </div>
       </> 
    }
  return (
    <div>
        {!props.HideButtons && <div className=' row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Account Payables</h1>
        </div>
         <div className='col-6 d-flex justify-content-end text-end'>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
       <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "350px" : "0px")}></i>
        
        <Link className='btn btn-outline-primary' to="/purchase/addapinvoice"><i className="pi pi-plus"></i> Add New</Link>
        </div>
        </div>}
        <div className='row'>
        <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
        </div>
        {AppliedFilters.length > 0 ? <div className='row mb-3'>
          <div className='col-12'>
            {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter}: {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}} onClick={() => RemoveFilter(item, index) }></i></span>))}
          </div>
        </div>: ""}
        <div className='filterContainer' style={{ height: filterHeight, transition: "height 1s"}}>
        <form onSubmit={handleFilterSubmit} style={{height: filterHeight, transition: "height 1s", overflow: "hidden" }}> 
          <div className="row g-2">
            <h2 className='FiltersHeading'>Filters</h2>
            <div className="col-3 mb-3">
              <label htmlFor="InvNo" className="form-label">Invoice No</label>
              <input type="text" className="form-control" name="InvNo" id="InvNo" value={Filters.InvNo} onChange={handleFiltersChange} placeholder="Invoice number"/>
            </div>
            <div className="col-3 mb-3">
              <label htmlFor="PONO" className="form-label">PO No</label>
              <input type="text" className="form-control" name="PONO" id="PONO" value={Filters.PONO} onChange={handleFiltersChange} placeholder="Purchase order number"/>
            </div>
            <div className="col-3 mb-3">
                    <label htmlFor="SupplierId" className="form-label">Supplier<span className='color-red'>*</span></label>
                    <AutoComplete value={SelectedSupplier} suggestions={SearchSupplier} forceSelection field="supName" completeMethod={search} onChange={AutoCompleteChange} style={{ width: '100%' }} 
                    placeholder='Type Supp Name to search..' />
            </div>
            <div className="col-3 mb-3">
              <label htmlFor="ApprovalStatus" className="form-label">Approval</label>
              <select name="ApprovalStatus" className="form-select" value={Filters.ApprovalStatus} onChange={handleFiltersChange} aria-label="">
                    <option value={""}>Select Any</option>
                    <option value={true}>Approved</option>
                    <option value={false}>Not Approved</option>
                </select>
            </div>
            <div className="col-3 mb-3">
              <label htmlFor="Date" className="form-label">PO Date</label>
              <Calendar name="Date" id="Date"  dateFormat="dd/mm/yy" className="form-control custdate" selectionMode="range"  value={Filters.Date} onChange={handleFiltersChange} showButtonBar placeholder='Select Date Range' hideOnRangeSelection></Calendar>
            </div>              
            </div>
            <div className='row'>
              <div className='col-12'>
                <button type='submit' className='btn btn-outline-primary mt-4'>Apply <i className="pi pi-angle-double-right"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => Reset()}>Reset <i className="pi pi-undo"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => setfilterHeight("0px")}>Cancel <i className="pi pi-times"></i></button>
                
                </div>
            </div>
          </form>
        </div>
        <div className='row' style={{ marginTop: filterHeight === "0px" ? "-15px": "" }}>
            <DataTable value={OrderReceiving} lazy dataKey="id" paginator  removableSort size='small' showGridlines  resizableColumns columnResizeMode="expand"
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage} selectionMode="checkbox"
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                    selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column field="date" header="Date" body={DateTemplate}  sortable />
            <Column field="invNo" header="Inv No"></Column>
            <Column field="poNo" header="PO No"></Column>
            <Column field="supplierName" header="Supplier"></Column>
            <Column field="isApproved" header="Approval Status" body={ApprovedTemplate}></Column>
            <Column field="totalPrice" header="Payable Amount"></Column>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
            </DataTable>
        </div>
        {/* <Dialog header="Create Purchase Order" visible={visible} maximizable  style={{  width: '90vw',}} onHide={() => setVisible(false)}>
            <AddPurchaseOrder setVisible={setVisible} />
        </Dialog> */}
    </div>
  )
}

export default ListAPInvoices