import React, { useEffect, useRef, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddWHIncharge from '../Blocks/AddWHIncharge';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import EditWarehouseIncharge from '../Blocks/EditWarehouseIncharge';

const WHIncharges = (props) => {
  console.log(props.WHIncharges);
  const navigate = useNavigate();
  const Company = JSON.parse(localStorage.getItem('Company'));
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [selectedRow, setselectedRow] = useState([]);
  const menuRight = useRef(null);
  //const router = useRouter();
  const toast = useRef(null);
  let networkTimeout = null;
  const [visible, setVisible] = useState({
    open: false,
    mode: "",
    row: ""
  });
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {
      warehouseName: { value: '', matchMode: 'contains' },
      cityName: {value: '', matchMode: 'contains'},
      countryName : {value: '', matchMode: 'contains'},
      status: {value: '', matchMode: 'equals'}
    }
  });
  const items = [
    {
      label: 'Disable',
      icon: 'pi pi-info-circle',
      url: "/setup/warehousedetails/" + selectedRow.id + ""
  },
  {
    label: 'Remove',
    icon: 'pi pi-map-marker',
    url: "/setup/warehouselocations/" + selectedRow.id + ""
  },
  ];

const handleRemove = async (row) => {
  console.log(row);
  let MyRes = await  POSTAPICallAsync("RemoveWHIncharge", {CompanyId : Company.id, Id: row.id });
  console.log(MyRes);
  if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
  }
  else{
      var MyData = await MyRes.json();
      console.log(MyData);
      if (MyData.success === true ){
         alert("Incharge has been removed.")
          
      }
      else{
          //setMessage(MyData.message);
      }
      
  }

}

const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedCustomers(value);
    setSelectAll(value.length === totalRecords);
};

const onSelectAllChange = async (event) => {
    const selectAll = event.checked;

    if (selectAll) {
  
            setSelectAll(true);
            setSelectedCustomers(props.WHIncharges);
      
    } else {
        setSelectAll(false);
        setSelectedCustomers([]);
    }
};


  
  const StatusTemplate = (status) => {
    return status.status ? <span>Active</span> : <span>Inactive</span>
  }


  // const statusFilterTemplate = (options) => {
  //   return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)}  placeholder="Select One" className="p-column-filter" showClear />;
  // }


  const ActionTemplate = (Myrow) => {
    console.log(Myrow.id);
    return <>
         <i className="pi pi-pencil ms-3 ActionIcon" onClick={() => setVisible({open: true, mode: "edit", row : Myrow})}></i>
         {Myrow.status && <i className="pi pi-trash ms-3 ActionIcon" onClick={() => handleRemove(Myrow)}></i>}
        {/* <div className="flex justify-content-center">
              <Toast ref={toast}></Toast>
              <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
              <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
              <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> 
        </div> */}
    
    </>
  }
  return (
    <>
    <div className='mb-3 row'>
        <div className='col-6'>
          <h1 className='ListMainHeading'>Active Incharges</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
          <button className='btn btn-outline-primary ms-auto' onClick={() => setVisible({open: true, mode: "add"})} > <i className="pi pi-plus" ></i> Add New</button>
        </div>
       </div>
       <div className='row'>
          <DataTable value={props.WHIncharges.filter(x => x.status)}  lazy dataKey="id"  totalRecords={totalRecords}
                  loading={loading} tableStyle={{ minWidth: '75rem' }}
                  selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column className='Datatablecol' field="inchargeName" header="Name" ></Column>
            <Column className='Datatablecol' field="email" header="email"></Column>
            <Column className='Datatablecol' field="contactPhone" header="Phone" ></Column>
            <Column className='Datatablecol' field="inchargeFrom" header="From"></Column>
            <Column className='Datatablecol' field="inchargeTill" header="Till"></Column>
            <Column className='Datatablecol' field="status" header="Status" body={StatusTemplate} ></Column>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
          </DataTable>
       </div>
       <h2 className='ListMainHeading my-3'>All Previous Incharges</h2>
       <div className='row'>
          <DataTable value={props.WHIncharges.filter(x => !x.status)}  lazy dataKey="id"  totalRecords={totalRecords}
                  loading={loading} tableStyle={{ minWidth: '75rem' }}
                  selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column className='Datatablecol' field="inchargeName" header="Name" ></Column>
            <Column className='Datatablecol' field="email" header="email"></Column>
            <Column className='Datatablecol' field="contactPhone" header="Phone" ></Column>
            <Column className='Datatablecol' field="inchargeFrom" header="From"></Column>
            <Column className='Datatablecol' field="inchargeTill" header="Till"></Column>
            <Column className='Datatablecol' field="status" header="Status" body={StatusTemplate} ></Column>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
          </DataTable>
       </div>
       <Dialog header={visible.mode === "add" ? "Add Incharge" : "Edit Incharge"} visible={visible.open} style={{ width: '50vw' }} onHide={() => setVisible({"open": false, "mode": ""})}>
          {visible.mode === "add" ? <AddWHIncharge CompId = {props.CompId} setVisible={setVisible} WarehouseId = {props.WHID} /> :
                          <EditWarehouseIncharge Row={visible.row} setVisible={setVisible}/>
          }
      </Dialog>
    </>
  )
}

export default WHIncharges