import React from 'react'
import ReceiveItemsbypo from '../Components/ReceiveItemsbypo'

const AddNewReceiveItems = () => {
  return (
    <>
        <div className='mb-3 row'>
        <div className='col-6'>
            <h1 className='ListMainHeading'>Receive Items</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
           
        </div>
        </div>
        <div className='row'>
          <ReceiveItemsbypo/>
        </div>
        
    </>

  )
}

export default AddNewReceiveItems