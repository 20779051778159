import React from 'react'
import ListTransferRequests from '../../Blocks/ListTransferRequests'
import InventoryHeader from '../../Blocks/InventoryHeader'

const TransferRequests = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
           <ListTransferRequests/>
     </div>
   </>
  )
}

export default TransferRequests