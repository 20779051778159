import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import InvSideMenu from '../../Components/InvSideMenu'
import ItemisedPurchaseBillsBlock from '../../Blocks/Reports/ItemisedPurchaseBillsBlock'

const ItemisedPurchaseBillsPage = () => {
  return (
    <div>
    <InventoryHeader/>
    <div className=' g-0'>
        <div className='w-200 Sidebar'>
            <InvSideMenu/>
        </div>
        <div className='ReportArea'>
            <ItemisedPurchaseBillsBlock/>
        </div>
    </div>
    </div>
  )
}

export default ItemisedPurchaseBillsPage