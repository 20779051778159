import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import AddSetupTax from './AddSetupTax';
import EditSetupTax from './EditSetupTax';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const ListTaxes = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [visible, setVisible] = useState({
    open: false,
    mode: "",
    id: ""
  });
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Taxes, setTaxes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState(null);
  const [selectedRow, setselectedRow] = useState([]);
  const [state, setState] = useState({
    CompanyId: Company.id,
  });
  const menuRight = useRef(null);
  //const router = useRouter();
  const toast = useRef(null);
  let networkTimeout = null;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {

    }
});

const items = [
  {
    label: 'Details',
    icon: 'pi pi-refresh',
    url: "/customers/details/" + selectedRow.org_id + ""
},
{
    label: 'Edit',
    icon: 'pi pi-times',
    command: (row) => {
      console.log(row);
        toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
    }
},
{
  label: 'React Website',
  icon: 'pi pi-external-link',
  url: 'https://reactjs.org/'
},
]; 

const accept = async () => {
  //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  console.log(state);
  let MyRes = await  POSTAPICallAsync("GSTTaxSetup", state);
  console.log(MyRes);
  if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
  }
  else{
      var MyData = await MyRes.json();
      console.log(MyData);
      if (MyData.success == true ){
        loadLazyData();
      }
  }

}

const reject = () => {
  //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
}

const confirm1 = () => {
  confirmDialog({
      message: 'Hey! This will create default tax system as per GST. Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept,
      reject
  });
};
  

  useEffect(() => {
    loadLazyData();
  }, [lazyState, visible]);
  useEffect (() => {
    loadLazyData();
  }, [])


  const loadLazyData = () => {
    setLoading(true);
    if (networkTimeout) {
        clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(async () => {
      var MyRes = await GetAPICallAsync("GetListTaxes", { lazyEvent: lazyState }, "");
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setTotalRecords(MyData.total);
        setTaxes(MyData.data);
        props.QuckSetupMode && props.MyItemTotalCallBack(MyData.total);
      }
      
    setLoading(false);
    }, Math.random() * 1000 + 250);
};
  const onPage = (event) => {
    setlazyState(event);
};

const onSort = (event) => {
    setlazyState(event);
};

const onFilter = (event) => {
  console.log(event);
    event['first'] = 0;
    setlazyState(event);
};

const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedTaxes(value);
    setSelectAll(value.length === totalRecords);
};

const onSelectAllChange = async (event) => {
    const selectAll = event.checked;

    if (selectAll) {
            setSelectAll(true);
            setSelectedTaxes(Taxes);
      
    } else {
        setSelectAll(false);
        setSelectedTaxes([]);
    }
};
const ActiveTemplate = (prop) => {
    return prop.status === true ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Inactive</span>
}
const ActionTemplate = (row) => {
  return <>
  <i className="pi pi-info-circle ActionIcon"></i>
  <i className="pi pi-pencil ms-3 ActionIcon" onClick={() => setVisible({open: true, mode: "edit", id : row.id})}></i>
  <i className="pi pi-times ms-3 ActionIcon"></i>
</>
}
return (
  <div>
      <div className='mb-3 row g-0'>
      <div className='col-6'>
      <h1 className='ListMainHeading'>Taxes</h1>
      </div>
      <div className='col-6 d-flex justify-content-end'>
      <ConfirmDialog />
      {props.showGSTSetupbtn ?
          <button className='btn btn-outline-primary' onClick={confirm1} > <i className="pi pi-plus" ></i> One click GST Setup</button>: ""}
          <button className='btn btn-outline-primary ms-2' onClick={() => setVisible({open: true, mode: "add"})} > <i className="pi pi-plus" ></i> Add New</button>
      </div>
      </div>
      <div className='row g-0'>
          <DataTable value={Taxes} lazy dataKey="id" paginator selectionMode="checkbox"
                  first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                  onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                  onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                  selection={selectedTaxes} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
          <Column field="taxName" header="Tax Name" />
          <Column field="taxType" header="Tax Type"></Column>
          <Column field="taxDescription" header="Description"></Column>
          <Column field="status" header="Status" body={ActiveTemplate}></Column>
          <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
          </DataTable>



      </div>
      <Dialog header={visible.mode === "add" ? "Add New Tax" : "Edit Tax"} visible={visible.open} style={{ width: '50vw' }}  onHide={() => setVisible({"open": false})}>
          {visible.mode === "add" && <AddSetupTax setVisible={setVisible} />}
          {visible.mode === "edit" && <EditSetupTax  setVisible={setVisible} Id={visible.id}/>}
      </Dialog>
  </div>
)
}

export default ListTaxes
