import React from 'react'
import TopNavigation from '../Components/TopNavigation'

export default function PublicHeader() {
  return (
    <div>
      <div>
        <TopNavigation />
      </div>
    </div>
  )
}
