import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import SalesOrderDetailBlock from '../../Blocks/SalesOrderDetailBlock'

const SalesOrderDetailPage = () => {
  return (
    <div>
    <InventoryHeader/>
    <div className='p-3'>
        <SalesOrderDetailBlock />
      </div>
  </div>
  )
}

export default SalesOrderDetailPage