import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import InvSideMenu from '../../Components/InvSideMenu'
import DashboardBlock from '../../Blocks/DashboardBlock'

const Dashboard = () => {
  return (
    <div>
      <InventoryHeader />
      <div className='p-3'>
          <DashboardBlock />
      </div>      
    </div>
  )
}

export default Dashboard
