import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import AlluniqueTaxTable from '../Components/AlluniqueTaxTable';
import moment from 'moment';

const Addnewarinvoice = () => {
    const [SONo, setSONo] = useState("");
    const navigate = useNavigate();
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [Dealers, setDealers] = useState([]);
    const [Inventories, setInventories] = useState([]);
    const [SelectedDealer, setSelectedDealer] = useState();
    const [Message, setMessage] = useState();
    const [FetchbyPOMessage, setFetchbyPOMessage] = useState("");
    const [MySrNos, setMySrNos] = useState("");
    const [MetaDataMessage, setMetaDataMessage] = useState("");
    const [AllUniqueTaxes , setAllUniqueTaxes] = useState([]);
    const [ValidationErrors, setValidationErrors] = useState([]);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
    });
    const [DealerFilters, setDealerFilters] = useState({
      DealerName: "",
      BusinessType: "",
      ContactPerson: "",
      Designation: "",
      Phone: "",
      Email: "",
      CountryId: 0,
      StateId: 0,
      CityId: 0,
  });
    const [itemMatadata, setItemMetaData] = useState({
      visible : "",
      itemIndex: "",
      trackBy: "",
    });



    const [Batches, setBatches] = useState ([{
      BatchId : "",
      BatchNo: "",
      ManufBNo : "",
      ManufacturingDate: "",
      ExpiryDate:"",
      Qty: "",
      AvailableQty: "",
      Verified: false,
      QtyDisabled: true

    }])
    const [state, setState] = useState ({
        SOId : "",
        ORId: "",
        Date : new Date(),
        TimeZone: "",
        CompanyId : Company.id,
        DealerId: "",
        BillingAddId: "",
        ShippingAddId: "",
        InvNo: "",
        CurrencyId: "",
        TermsandConditions : "",
        ARInvoiceItems : []

    });

    const AddItemMetadata = (Index) => {
      let MyPOItem = state.ARInvoiceItems[Index]; 
      console.log(MyPOItem);
      setItemMetaData((prevProps) => ({
        ...prevProps,
        visible : true,
        itemIndex : Index,
        trackBy: MyPOItem.trackBy
      }))
      MyPOItem.Batches.length > 0 && setBatches(MyPOItem.Batches);
    }

    const handleInputChange = async (event) => {
      const { name, value } = event.target;
      if(name === "DealerId"){
        var MyRes = await GetAPICallAsync("GetDealerbyId", { lazyEvent: lazyState }, {ID: value});
        if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
        }
        else{
          var MyData =  await MyRes.json();
          console.log(MyData.data);
          setSelectedDealer(MyData.data);
        }
      }
      console.log(value);
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
    };

    const handleL2InputChange = (event, Index) => {
      const { name, value } = event.target;
      let SOItems = [...state.ARInvoiceItems];
      SOItems[Index][name] = value;
      SOItems[Index]["invQty"] = value * SOItems[Index]["ConvRate"];
      console.log("L2Input");
      setState((prevProps) => ({
        ...prevProps,
        ARInvoiceItems: SOItems
      }));
      CalculateAllTaxes(SOItems);

    }

    const handleL3InputChange = (event, index, index1) => {
      const { name, value } = event.target;
      let SOItems = [...state.ARInvoiceItems];
      let MyItem = SOItems[index];
      let MyWarehouseLIst = [...MyItem.WarehouseDetails];
      MyWarehouseLIst[index1][name] = value;
      console.log("L2Input");
      setState((prevProps) => ({
        ...prevProps,
        ARInvoiceItems: SOItems
      }));
    } 

    const handleBatchInputChange = (Index, event) => {
      setFetchbyPOMessage("");
      const { name, value } = event.target;
     let values = [...Batches];
     values[Index][name] = value;
     if(name === "BatchNo"){
      values[Index]["BatchId"] = "";
      values[Index]["Verified"] = false;
      values[Index]["ManufacturingDate"] = "";
      values[Index]["ExpiryDate"] = "";
      values[Index]["ManufBNo"] = "";
      values[Index]["AvailableQty"] = 0;
      values[Index]["Qty"] = 0;
      values[Index]["QtyDisabled"] = true;
     }
     if( name === "Qty"){
        if(value > values[Index]["AvailableQty"]){
          values[Index][name] = 0
          setFetchbyPOMessage("Qty can not be more than available qty. i.e." + values[Index]["AvailableQty"]);
        }
     }
      setBatches(values);
    };

    const GetDealersDD = async () => {
      var MyRes = await GetAPICallAsync("GetListDealers", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(DealerFilters)});
      if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
      }
      else{
      var MyData = await MyRes.json();
      setDealers(MyData.data);
      }
  };


  const handleAddMoreItem = (index) => {
    const values = [...Batches ]
    values.splice(index + 1, 0, {"BatchId": "","BatchNo": "", "ManfBatchNo" : "", "ManfDate": "", "ExpiryDate":"", "Qty": "", "AvailableQty": "",
      "Verified": false, "QtyDisabled": true} )
    setBatches(values);
  }
  const handleremoveitem = (index) => {
         
    const values = [...Batches]
    if(values.length > 1){
        values.splice(index, 1)
        setBatches(values);
    }
  }
  const AddMoreWarehouse = (itemIndex) => {
    const values = [...state.ARInvoiceItems];
    const MyItem = values[itemIndex];
    const MyWarehouseLIst  = [...MyItem.WarehouseDetails, {"WHID": "", "Qty": 0}];
    values[itemIndex].WarehouseDetails = MyWarehouseLIst;
    setState((prevProps) => ({
      ...prevProps,
      "ARInvoiceItems": values, 
    }))
  }

  const removeWareHouse = (itemIndex, index)=> {
    const values = [...state.ARInvoiceItems];
    const MyItem = values[itemIndex];
    const MyWarehouseLIst  = [...MyItem.WarehouseDetails];
    if(MyWarehouseLIst.length > 1){
      MyWarehouseLIst.splice(index, 1);
    }
    values[itemIndex].WarehouseDetails = MyWarehouseLIst;
    setState((prevProps) => ({
      ...prevProps,
      "ARInvoiceItems": values, 
    }))
  }

  const getTimeZone = () => {
                        
    const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setState((prevProps) => ({
        ...prevProps,
        "TimeZone": MytimeZone
      }));

  };


    const handleSOSearch = (event) => {
        const { value } = event.target;
        setSONo(value);
    }

    useEffect (() => {   
      
      GetDealersDD();
      getTimeZone();
    }, []);

    const GetInventoryByItemids = async (MyItemIds) =>{
      console.log(MyItemIds);
      var MyRes = await POSTAPICallAsync("GetInventorybyItemIds", MyItemIds);
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData.data)
        setInventories(MyData.data)
      }
    }

    const GetTaxType = async (SO) => {
      var MyRes = await GetAPICallAsync("GetCompanyAndPartyAddress", "", {MyPartyId : SO.dealerId, MyPartyType : "dealer"});
                if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
                }
                else{
                var MyData = await MyRes.json();
                  console.log(MyData.data);
                  let ApplicableGSTTax = "";
                  if(MyData.data.companyAddress.stateId == MyData.data.dealerAddress.stateId ){
                    ApplicableGSTTax = "intrastate";
                  }
                  else{
                    ApplicableGSTTax = "interstate";
                  }
                  // SetGSTTaxType(ApplicableGSTTax);
                  return ApplicableGSTTax
                }
    }

    const GetTaxslabs = async (ItemTaxes, AppTaxType) => {
      let NewItemTaxes = [];
    
      // Iterate through the ItemTaxes array
      ItemTaxes.forEach((MyItem) => {
        console.log(MyItem);
        if (MyItem.taxes.taxType === "GST") {
          if (AppTaxType === "interstate") {
            // Find IGST tax slab for interstate
            let MyGSTTaxSlab = MyItem.taxes.taxGSTSlabs.find(x => x.taxSystem === "IGST");
            if (MyGSTTaxSlab) {
              MyItem = { ...MyItem, "taxGSTId": MyGSTTaxSlab.id, "taxAmount": MyItem.taxAmount  };
              NewItemTaxes.push(MyItem);
            }
          } else if (AppTaxType === "intrastate") {
            // Find CGST and SGST tax slabs for intrastate
            let MySGSTTaxSlab = MyItem.taxes.taxGSTSlabs.find(x => x.taxSystem === "SGST");
            let MyCGSTTaxSlab = MyItem.taxes.taxGSTSlabs.find(x => x.taxSystem === "CGST");
            
            if (MySGSTTaxSlab && MyCGSTTaxSlab) {
               // Split the existing taxAmount equally for CGST and SGST
              let splitTaxAmount = MyItem.taxAmount / 2;
              let splitTaxPercentage = MyItem.taxPercentage / 2;
              // Add SGST
              let SGSTItem = { ...MyItem, "taxGSTId": MySGSTTaxSlab.id, "taxAmount": splitTaxAmount, "taxPercentage" : splitTaxPercentage };
              NewItemTaxes.push(SGSTItem);
    
              // Add CGST as a separate entry
              let CGSTItem = { ...MyItem, "taxGSTId": MyCGSTTaxSlab.id, "taxAmount": splitTaxAmount, "taxPercentage" : splitTaxPercentage  };
              NewItemTaxes.push(CGSTItem);
            }
          }
        } else {
          // If not GST, just push the original item
          NewItemTaxes.push(MyItem);
        }
      });
    
      return NewItemTaxes;
    };
    

    const GetSODetails = async (event) => {
      try {
        const MyRes = await GetAPICallAsync("GetSobyno", "", { "SONo": SONo });
    
        // Check for unauthorized response
        if (MyRes.status === 401) {
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
          return;
        }
    
        const MyData = await MyRes.json();
        if (!MyData.success) {
          setFetchbyPOMessage(MyData.message);
          return;
        }
    
        const Currency = MyData.data.currency;
        let SOItems = [];
        let ItemIds = [];
        let CurrencyId = MyData.data.currencyId || "";
        let SOId = MyData.data.id || "";
    
        // Using Promise.all to fetch details for each sales order item
        SOItems = await Promise.all(
          MyData.data.saleOrderItems.map(async (item, index) => {
            const ApplicableGSTTax = await GetTaxType(MyData.data);
            const MyTaxSlabs = await GetTaxslabs(item.soItemTaxes, ApplicableGSTTax);
    
            ItemIds.push(item.itemId);
            
            return {
              "ARInvId": "",
              "ItemId": item.itemId,
              "SOItemId": item.item.id,
              "itemName": item.item.name,
              "hsnCode": item.item.hsnCode,
              "SpecialRemarks": item.specialRemarks,
              "Qty": item.qty,
              "QtyUnit": item.item.sItemUnit.unitName,
              "UnitId": item.unitId,
              "rQty": 0,
              "TItemPrice": "",
              "Discount": item.discount,
              "ARInvItemTaxes": MyTaxSlabs,
              "WarehouseDetails": [{ "WHID": "", "Qty": 0 }],
              "unitPrice": item.unitPrice,
              "Trackable": item.item.trackItem,
              "trackBy": item.item.trackBy,
              "SrNo": "",
              "TrackedItemQty": 0,
              "Index": index,
              "CurrencyName": Currency.shortCode,
              "CurrencyId": Currency.id,
              "Batches": [],
            };
          })
        );
    
        // Fetch inventory for the item IDs
        GetInventoryByItemids(ItemIds);
    
        // Update the state with SOId, SOItems, and CurrencyId
        setState(prevState => ({
          ...prevState,
          "SOId": SOId,
          "ARInvoiceItems": SOItems,
          "CurrencyId": CurrencyId
        }));
    
        // Calculate taxes after state update
        CalculateAllTaxes(SOItems);
    
      } catch (error) {
        console.error("An error occurred while fetching SO details:", error);
        setFetchbyPOMessage("Failed to fetch Sales Order details.");
      }
    };
    

    const VerifyBatchNo = async (index) => {
      let values = [...state.ARInvoiceItems];
      let MyItemId = values[itemMatadata.itemIndex]["ItemId"];
      let BatchNo = Batches[index]["BatchNo"];
      console.log(Batches);
      let CHKBatch = Batches.filter( x=> x.BatchNo === BatchNo);
      console.log(CHKBatch.length);
      if(CHKBatch.length > 1){
        setFetchbyPOMessage("Duplicate Batch No:" + BatchNo + "");
      }
      else{
        var MyRes = await GetAPICallAsync("GetBatchDetails", "", {"MyBatchNo" : BatchNo, "ItemID": MyItemId});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          setFetchbyPOMessage("");
          var MyData = await MyRes.json();
          console.log(MyData)
          if(MyData.success === true){
            
            let values = [...Batches];
            let MyValue = values[index]
            MyValue.BatchId = MyData.data.id;
            MyValue.ManufacturingDate = moment(MyData.data.manufacturingDate).format("DD/MM/yyyy") ;
            MyValue.ExpiryDate = moment(MyData.data.expiryDate).format("DD/MM/yyyy");
            MyValue.ManufBNo = MyData.data.manufBNo;
            MyValue.AvailableQty = MyData.data.availableQty;
            MyValue.Verified = true;
            MyValue.QtyDisabled = false;
            setBatches(values);
          }
          else{
            console.log("MyBatch");
            setFetchbyPOMessage("Wrong Batch No:" + BatchNo + "");
          }

           
        }
        
      }
    }

    const SubmitItemMetaData = async (e) => {
      e.preventDefault();
      console.log(MySrNos);
      let ItemsNumber = 0
      setMetaDataMessage("");
     
      let values = [...state.ARInvoiceItems]
      console.log(values);
      let TrackBy = values[itemMatadata.itemIndex]["trackBy"];
      let MyItemId = values[itemMatadata.itemIndex]["ItemId"];
      let RQty = parseFloat(values[itemMatadata.itemIndex]["Qty"])

      
      if (TrackBy === "batch")
      {
          Batches.map ((item) => (
            ItemsNumber += parseInt(item.Qty)
          ))
        
          if(ItemsNumber === RQty){

            values[itemMatadata.itemIndex]["Batches"] = Batches;
            values[itemMatadata.itemIndex]["TrackedItemQty"] = ItemsNumber;
            setState((prevProps) => ({
              ...prevProps,
              "ARInvoiceItems": values
            }));
            setItemMetaData((prevProps) => ({
              ...prevProps,
              "visible" : false
            }));
            setBatches([{"BatchId": "","BatchNo": "", "ManfBatchNo" : "", "ManfDate": "", "ExpiryDate":"", "Qty": "","AvailableQty": "",
      "Verified": false, "QtyDisabled": true}])
          }
          else{
            alert("Qty doesn't match with recieved qty.")
          }
      }
      else if (TrackBy === "serialno")
      {
        if(MySrNos != ""){

        let TSrNos = MySrNos.split(",");
        ItemsNumber = TSrNos.length;
       
        console.log("SerialNumber");
        if(ItemsNumber === RQty){
        var MyRes = await GetAPICallAsync("GetSrNosVerify", "", {"SrNos" : TSrNos, "ItemID": MyItemId});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          setFetchbyPOMessage("");
          var MyData = await MyRes.json();
          console.log(MyData)
          if(MyData.success === true){
            values[itemMatadata.itemIndex]["SrNo"] = MySrNos;
            values[itemMatadata.itemIndex]["TrackedItemQty"] = ItemsNumber;
            console.log("SrNo");
            setState((prevProps) => ({
              ...prevProps,
              "ARInvoiceItems": values
            }));
            setItemMetaData((prevProps) => ({
              ...prevProps,
              "visible" : false
            }));
          }
          else{
            var MyWrongSrNos = MyData.data.map((item) => (
              item += ","
            ))
            console.log("MyBatch");
            setMetaDataMessage("Wrong Sr Nos: " + MyWrongSrNos + "");
            values[itemMatadata.itemIndex]["SrNo"] = "";
            values[itemMatadata.itemIndex]["TrackedItemQty"] = 0;
            console.log("SrNo");
            setState((prevProps) => ({
              ...prevProps,
              "ARInvoiceItems": values
            }));
          }

        }
          
          
        }
        else{
          alert("Qty doesn't match with recieved qty.")
        }
                  
      }
      else{
        values[itemMatadata.itemIndex]["SrNo"] = "";
        values[itemMatadata.itemIndex]["TrackedItemQty"] = 0;
        setItemMetaData((prevProps) => ({
          ...prevProps,
          "visible" : false
        }));
      }
      }
        

    }

    const GenerateMataDataLink = (TrackBy, index) => {
      switch (TrackBy){
        case "batch" :
          return <a className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>Add New Batch</a>;
        case "serialNo" :
          return <a className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>Add Serial Nos</a>;
        default :
         return "";
      }
    }

    const CalculateItemFinalPrice = (Myitem) => {

      let TotalbeforeTax = (Myitem.unitPrice * Myitem.Qty) - (Myitem.unitPrice * Myitem.Qty) * Myitem.Discount/100
      let TTotalTax = 0;
      Myitem.ARInvItemTaxes.map((NewItem, index) => (
        TTotalTax += ((Myitem.unitPrice * Myitem.Qty) - (Myitem.unitPrice * Myitem.Qty) * Myitem.Discount/100) * NewItem.taxPercentage/ 100
      ))

      return parseFloat(TotalbeforeTax + TTotalTax);

    }

    const CalculateTotalDicount = () => {
      let Discount = 0;
      state.ARInvoiceItems.forEach((Myitem)=> {
        Discount += (Myitem.unitPrice * Myitem.Qty) * Myitem.Discount/100
      })
      return Discount;
    }

    const CalculateTotalAount = () => {
      let TaxableAmount = 0;
      state.ARInvoiceItems.forEach((Myitem)=> {
        TaxableAmount += (Myitem.unitPrice * Myitem.Qty) - (Myitem.unitPrice * Myitem.Qty) * Myitem.Discount/100
      })
      return TaxableAmount;
    }

    const CalculateItemTax = (item, TaxItem) => {
      return ((item.unitPrice * item.Qty) - (item.unitPrice * item.Qty) * item.Discount/100) * TaxItem.taxPercentage/ 100
    }

    const CalculateAllTaxes = (MyARInvItems) => {
      console.log(MyARInvItems);
      let ListAllUniqueTaxes = [];
      MyARInvItems.forEach((item) => {
        item.ARInvItemTaxes.forEach((TaxItem) => {
          let chkTaxItem = ListAllUniqueTaxes.filter(x => x.taxId === TaxItem.taxId);
          if(item.Qty >= 0 && chkTaxItem.length > 0)
          {
            let NewList = [...ListAllUniqueTaxes];
            NewList = NewList.filter(x => x.taxId !== TaxItem.taxId);
            let NewTotal = parseFloat(chkTaxItem[0].taxAmount ) + 
                           parseFloat(((item.Qty * item.unitPrice) - (item.Qty * item.unitPrice) * item.Discount/100) * TaxItem.taxPercentage/100);
  
            console.log(chkTaxItem);
            chkTaxItem = {...chkTaxItem[0], "taxAmount" : NewTotal}
            NewList = [...NewList, chkTaxItem];
            ListAllUniqueTaxes = NewList;
  
          }
          else if (item.Qty !== ""){
            ListAllUniqueTaxes = [...ListAllUniqueTaxes, {"taxId" : TaxItem.taxId, "name" : TaxItem.taxes.taxName, "taxAmount" : parseFloat((((item.Qty) * (item.unitPrice)) - ((item.Qty) * (item.unitPrice)) * (item.Discount)/100) * (TaxItem.taxPercentage)/100)}];
          }
          
        })

      })
      setAllUniqueTaxes(ListAllUniqueTaxes)  
    }

    const GetWhOptions = (ARInvItem) => {
      let BatchNos = [];
      let MyInventories = [];
      ARInvItem.Batches.forEach((batch) => {
        BatchNos = [...BatchNos, batch.BatchNo]
      })
      console.log(BatchNos);
      console.log(Inventories);
      if(Inventories.length > 0){
        Inventories.forEach((Inv) => {
          var MyBatches  = Inv.batches.filter(x => BatchNos.includes(x.batchNo))
          if(MyBatches.length > 0){
            MyInventories = [...MyInventories, Inv];
          }
        });
      }
      return (ARInvItem.trackBy === "batch" ? MyInventories.filter(x => x.itemId === ARInvItem.ItemId).map((Myitem) => (<option key={Myitem.warehouse.id} value={Myitem.warehouse.id}>{Myitem.warehouse.warehouseName}</option>))
              : Inventories.filter(x => x.itemId === ARInvItem.ItemId).map((Myitem) => (<option key={Myitem.warehouse.id} value={Myitem.warehouse.id}>{Myitem.warehouse.warehouseName}</option>)))
    }

    function GetAddressInfo(Id) {
      const MyAdd =  SelectedDealer && SelectedDealer.dealerAddresses.find(x => x.id === Id);
      return (<div className='BillingOuter'>
      {MyAdd && <> {MyAdd.streetAddress} <br/>
        {MyAdd.city.cityName}, &nbsp;{MyAdd.state.stateName}, &nbsp; {MyAdd.country.countryName} &nbsp; {MyAdd.zipCode}  <br/>
        </>}
      
      </div>)
    }

    const ValidateState = () => {
      console.log(state);
      setValidationErrors([]);
      let MyValidationErrors = [];
      state.ARInvoiceItems.map((item) => {
        console.log(item)
        if(item.Qty < 1){
          MyValidationErrors = [...MyValidationErrors, "Quantity should be more than 0 for " + item.itemName +  "."]
        }
        if(item.Trackable && item.trackBy === "batch"){
          if(item.Batches.length < 1  ){
            MyValidationErrors = [...MyValidationErrors, "Please add batch for " + item.itemName +  "."]
          }
          let WHTQty = 0;
          item.WarehouseDetails.forEach((myItem) => {
            if(myItem.WHID === ""){
              MyValidationErrors= [...MyValidationErrors, "Please select Warehouse for " + item.itemName +  "."]
            }
            WHTQty += parseFloat(myItem.Qty);
          })
          if(WHTQty != item.Qty){
            console.log(WHTQty);
            MyValidationErrors= [...MyValidationErrors, "Quantity mismatch with warehouse quantity for " + item.itemName +  "."]
          }
          
        }
        else if(item.Trackable && item.trackBy === "serialno"){
          if(item.SrNo === ""  ){
            MyValidationErrors= [...MyValidationErrors, "Please add serial numbers for " + item.itemName +  "."]
          }
        }

      })
      setValidationErrors(MyValidationErrors);
      return MyValidationErrors.length > 0 ? false : true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const Validated = ValidateState();
        console.log(state);
        if(Validated){
          let MyRes = await  POSTAPICallAsync("Addnewarinvoice", state);
          console.log(MyRes);
          if (MyRes.status === 401){
              localStorage.removeItem("User_Obj");
              localStorage.removeItem("Company");
              localStorage.removeItem("token");
              navigate("/login");
          }
          else{

              var MyData = await MyRes.json();
              if (MyData.success === true ){
                  navigate("/sales/arinvoices");
              }
              else{
                  setMessage(MyData.message);
              } 
          }
                  
        }
      }
  return (
    <>
      <div className='mb-3 row'>
        <div className='col-6'>
            <h1 className='ListMainHeading'>Add New AR Invoice</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
           
        </div>
        </div>  
    
      <span>{Message}</span>
      
      <div className='row'>
      <form onSubmit={handleSubmit}>
        
         <div className='row'>
            {/* <div className="col-3 mb-3">
                <label htmlFor="PONo" className="form-label">PO No<span className='color-red'>*</span></label>
                <input type="text" required name="PONo" className="form-control" id="PONo" value={state.PONo} onChange={handleInputChange} placeholder="Enter company name"/>
            </div> */}
             {/* <div className="col-3 mb-3">
                 <label htmlFor="WarehouseId" className="form-label">Receiving No<span className='color-red'>*</span></label>
                 
                 <select name="ORId" className="form-select" value={state.ORId} onChange={handleInputChange} aria-label="Select Warehouse">
                    <option value={""}>Select Any</option>
                    {Receivings.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.Rec_no}</option>))}
                </select>
             </div> */}
             {/* <div className="col-3 mb-3">
                 <label htmlFor="InvNo" className="form-label">AR Invoice No<span className='color-red'>*</span></label>
                 <input type='text' id="InvNo" name="InvNo" className="form-control" value={state.InvNo}  onChange={handleInputChange}/>
             </div> */}
             <div className="col-3 mb-3">
                 <label htmlFor="Date" className="form-label">Date<span className='color-red'>*</span></label>
                 
                 <Calendar required name="Date"  dateFormat="dd/mm/yy" className="form-control custdate"  value={state.Date} onChange={handleInputChange} showButtonBar ></Calendar>

             </div>
             
             </div>
             <div className='row'>
             <div className='col-4'>
             <div className="col-12 mb-3">
                 <label htmlFor="DealerId" className="form-label">Bill To<span className='color-red'>*</span></label>
                 
                 <select name="DealerId" className="form-select" value={state.DealerId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Dealers.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.dealerName}</option>))}
                </select>

             </div>
             <div className='col-12'>
              <div className='BillingOuter'>
                
                {SelectedDealer && <>Company Name: {SelectedDealer && SelectedDealer.dealerName} <br/>
                <span>P Name - {SelectedDealer && SelectedDealer.contactPersonName}</span> <br/>
                <span>Email - {SelectedDealer && SelectedDealer.email}</span> <br/>
                <span>GST - {SelectedDealer && SelectedDealer.gst}</span> <br/></>}

                </div>
             {/* {GetAddressInfo(state.ShippingAddId)} */}
              
             </div>
              </div>
              <div className='col-4'>
              <div className="col-12 mb-3">
                 <label htmlFor="BillingAddId" className="form-label">Billing Address<span className='color-red'>*</span></label>
                 
                 <select name="BillingAddId" className="form-select" value={state.BillingAddId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {SelectedDealer && SelectedDealer.dealerAddresses.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.addressName}</option>))}
                </select>

             </div>
             <div className='col-12'>
                {GetAddressInfo(state.BillingAddId)}
             </div>
              </div>
             
              <div className='col-4'>
              <div className="col-12 mb-3">
                 <label htmlFor="ShippingAddId" className="form-label">Shipping Address<span className='color-red'>*</span></label>
                 
                 <select name="ShippingAddId" className="form-select" value={state.ShippingAddId} onChange={handleInputChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {SelectedDealer && SelectedDealer.dealerAddresses.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.addressName}</option>))}
                </select>

             </div>
             <div className='col-12'>
             {GetAddressInfo(state.ShippingAddId)}
              
             </div>
              </div>
             
            
         </div>
         <div className='row mt-2'>
            <label className="form-label">SO No.<span className='color-red'>*</span></label>
            </div>
            <div className='row'>
                {FetchbyPOMessage && <span className='Error'>{FetchbyPOMessage}</span>}
                <div className="mb-3 col-3">
                    
                    <input type="text" required name="SalesOrder" className="form-control" id="SalesOrder" value={SONo} onChange={handleSOSearch} placeholder="Enter Sales Order Number"/>
                </div>          
                <div className='col-3'>
                    <button type='button' className="btn btn-primary" onClick={() => GetSODetails()}>Fetch Details</button>
                </div>
          </div>

      <div className='row mt-2'>
        <div className='col-12'>
          <table className='table table-bordered custTable'>
            <thead>
            <tr>
              <th>Item Name</th>
              <th>HSN Code</th>
              
              <th className='text-center'>Qty</th>
              <th>Warehouse with Qty</th>
              <th className='text-end'>Unit Price</th>
              <th className='text-end'>Discount (%)</th>
              <th className='text-end'>T. Amount</th>
              <th className='text-center'>Tax</th>
              <th className='text-end'>Final Amount</th>
              {/* <th>Qty for Inventory</th> */}
              
            </tr>
            </thead>
            <tbody>            
          
              {state.ARInvoiceItems && state.ARInvoiceItems.map ((item, index) => (
                <tr key={index}>
                  <td>{item.itemName} </td>
                  <td>{item.hsnCode}</td>
                  <td className='text-end'><input type='text' id="" className='CustTextBox-70' name="Qty" value={item.Qty} onChange={(event) => handleL2InputChange(event, index)}/> {item.QtyUnit}
                  <br/>
                  {item.Qty > 0 && item.Trackable ? <><span className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>{item.trackBy ==="batch" ?  item.Batches.length + " batches Added for ( " + item.TrackedItemQty + " Qty )": item.TrackedItemQty  + " Sr. No Added" }</span></>: ""}
                  </td>
                  <td>
                    {item.trackBy ==="serialno" ? "N/A" :
                    <table className='table table-bordered'>
                      <tbody className= {Inventories.filter(x => x.ItemId === item.itemId).length <= 0 ? 'ErrorTd' : ""}>
                    {item.WarehouseDetails.map((NewItem, index1) => (
                            <tr key={index1}>
                                <td>
                                  <select name="WHID" className="" value={NewItem.WHID} onChange={(event) => handleL3InputChange(event, index, index1)} aria-label="Default select example">
                                      <option value={""}>Select Any</option>
                                      {GetWhOptions(item)}
                                  </select>
                                </td>
                                <td className='text-end'><input type='text' id="" className='CustTextBox-70' name="Qty" value={NewItem.Qty} onChange={(event) => handleL3InputChange(event, index, index1)}/></td>
                                <td onClick={() => removeWareHouse(index, index1)}>x</td>
                            </tr>    
                    ))}
                    {Inventories.filter(x => x.ItemId === item.itemId).length > 0 &&
                    <tr className="text-center">
                      <td colSpan={3} onClick={() => AddMoreWarehouse(index) }>+</td>
                    </tr>
                    }
                    </tbody>
                    </table>}
                  </td>
                  
                  <td className='text-end'><input type='text' id="" name="unitPrice" className='CustTextBox-80' value={item.unitPrice}  onChange={(event) => handleL2InputChange(event, index)}/> {item.CurrencyName}</td>
                  
                  <td className='text-end'><input type='text' id="" name="Discount" className='CustTextBox-60' value={item.Discount} onChange={(event) => handleL2InputChange(event, index)}/></td>
                  <td className='text-end'>{((item.unitPrice * item.Qty) - (item.unitPrice * item.Qty) * item.Discount/100).toFixed(2)}</td>
                  <td>
                    <table className='table table-bordered'>
                      <tbody>
                    {item.ARInvItemTaxes.map((NewItem, index) => (
                            <tr key={index}>
                                <td>{NewItem.taxes.taxName}</td>
                                <td className='text-end'>{NewItem.taxPercentage} (%)</td>
                                <td className='text-end'>{CalculateItemTax(item, NewItem).toFixed(2)}</td>
                            </tr>
                        
                    ))}
                    </tbody>
                    </table>
                  </td>
                  <td className='text-end'>{CalculateItemFinalPrice(item).toFixed(2)}</td>
                  {/* <td>{item.invQty} { item.invQtyUnit}</td> */}
                  </tr> 
                  
              ))}
              <tr className='text-end'>
                <td colSpan={8} >Taxable Amount</td>
                <td>{CalculateTotalAount().toFixed(2)}</td>
              </tr>
              <tr className='text-end'>
                <td colSpan={8} >Discount</td>
                <td>{CalculateTotalDicount().toFixed(2)}</td>
              </tr>
              {/* {CalculateAllTaxes()} */}
              {  AllUniqueTaxes.map((AllTaxItem, index) => {
                return <tr key={index} className='text-end'><td colSpan={8}>{AllTaxItem.name}</td><td >{AllTaxItem.taxAmount.toFixed(2)}</td></tr>
                })}
          </tbody>
          </table>
          
         
        </div>
        <div className=''>
          {ValidationErrors.length > 0 && 
          <>
          <ul>
          {ValidationErrors.map((item, index) => (
            <li key={index} className='Error'>{item}</li>
          ))}
          </ul>
          </>
          }
        </div>

        <div className='mt-4'>
             <button type="submit" className="btn btn-primary">Submit</button>
         </div>
      </div>
      </form>
      </div>
      <Dialog header="Add Info" visible={itemMatadata.visible} maximizable  style={{  width: '90vw',}} onHide={() => setItemMetaData({visible: false})}>
      {itemMatadata.trackBy === "batch"?
          <TabView>
          <TabPanel header="Manual">
          {FetchbyPOMessage !== "" && <span className='Error'>{FetchbyPOMessage}</span> }
            <form onSubmit={SubmitItemMetaData} className='mt-3'>
              <>
              {Batches.map ((item, index) => (        
              <div key={index} className='row mt-3'>
                
              <div className="col-3 mb-3">
                <label className="form-label">Batch No<span className='color-red'>*</span></label>
                <div class="input-group mb-3">
                  <input type="text" required name="BatchNo" className="form-control" id="BatchNo" value={item.BatchNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Batch Number"/>
                  {/* <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2"/> */}
                  {item.Verified === true? <button type="button" class="btn btn-outline-secondary" disabled id="CheckButton"><span className="pi pi-check" style={{color: 'green'}}></span></button> : 
                  <button class="btn btn-outline-secondary"  type="button" id="searchButton" onClick={() => VerifyBatchNo(index)}><span className="pi pi-search" ></span></button>}
                </div>
                  
              </div>
              <div className="col-2 mb-3">
                  <label className="form-label">Qty<span className='color-red'>*</span></label>
                  <input type="text" readOnly={item.QtyDisabled} required name="Qty" className="form-control" id="Qty" value={item.Qty} onChange={event => handleBatchInputChange(index, event)} placeholder="Qty"/>
              </div>
              <div className="col-2 mb-3">
                  <label className="form-label">Manuf. Batch No<span className='color-red'>*</span></label>
                  <input readOnly type="text" required name="ManufBNo" className="form-control" id="ManufBNo" value={item.ManufBNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Manuf. B. No. "/>
              </div>
              <div className="col-2 mb-3">
                  <label htmlFor="ManufacturingDate" className="form-label">Manufacturing Date<span className='color-red'>*</span></label>
                  <input readOnly type="text" required name="ManufacturingDate" className="form-control" id="Qty" value={item.ManufacturingDate} onChange={event => handleBatchInputChange(index, event)} placeholder="Manf. Date"/>
                  {/* <Calendar readOnly required name="ManufacturingDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={item.ManufacturingDate} onChange={event => handleBatchInputChange(index, event)} showButtonBar showIcon ></Calendar> */}

              </div>
              <div className="col-2 mb-3">
                  <label htmlFor="Date" className="form-label">Expiry Date<span className='color-red'>*</span></label>
                  <input readOnly type="text" required name="ExpiryDate" className="form-control" id="Qty" value={item.ExpiryDate} onChange={event => handleBatchInputChange(index, event)} placeholder="Expiry Date"/>
                  {/* <Calendar readOnly required name="ExpiryDate"  dateFormat="dd/mm/yy" className="form-control custdate"  value={item.ExpiryDate} onChange={event => handleBatchInputChange(index, event)} showButtonBar showIcon ></Calendar> */}

              </div>
              
              <div className='col-1'>
              <label htmlFor="ManufacturingDate" className="form-label"></label><br/>
              <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index)}></i>
                      <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
              </div>
              
              </div> 
              ))}
                      
              <div className='mt-4'>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            
              </>
          </form>
          </TabPanel>
          <TabPanel header="FIFO">
              
          </TabPanel>
          <TabPanel header="LIFO">
              <h2>Locations</h2>
          </TabPanel>
          <TabPanel header="Aqlob Recommended">
              <h2>Locations</h2>
          </TabPanel>
        
        </TabView>
     
      
        : 
        <>
        {MetaDataMessage != "" && <span className='Error'>{MetaDataMessage}</span>} 
        <form onSubmit={SubmitItemMetaData} className='mt-3'>
        <div className="mb-3">
                <label className="form-label">Add Serial Numbers ("," Comma Separated)</label>
                <textarea type="text" rows="4" name="SrNo" className="form-control" id="SrNo" value={MySrNos} onChange={(e) => setMySrNos(e.target.value)} placeholder="Enter Description"/>
            </div>         
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
        
        </>
         } 
      </Dialog>

  </>
  )
}

export default Addnewarinvoice