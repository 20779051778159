import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { Calendar } from 'primereact/calendar';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const EditWarehouseIncharge = (props) => {
    console.log(props.Row);
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [CompUsers, setCompUsers] = useState([]);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
        filters: {
          warehouseName: { value: '', matchMode: 'contains' },
          cityName: {value: '', matchMode: 'contains'},
          countryName : {value: '', matchMode: 'contains'},
          status: {value: '', matchMode: 'equals'}
        }
    });
    
    const [state, setState] = useState(
        {
            Id : props.Row ?  props.Row.id : "",
            WarehouseId : props.Row ? props.Row.warehouseId : "",
            UserId : props.Row ?  props.Row.userId : "",
            InchargeName : props.Row ? props.Row.inchargeName : "",
            ContactPhone : props.Row ? props.Row.contactPhone : "",
            Email : props.Row ? props.Row.email : "",
            InchargeFrom : props.Row ? props.Row.inchargeFrom : "",    
        });

    
    
    useEffect (() => {  
        GetUsersList();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "UserId"){
            const MyUser = CompUsers.find(x => x.userId === value)
            console.log(MyUser);
            setState((prevProps) => ({
                ...prevProps,
                "InchargeName": MyUser.user.name,
                "UserId": value,
                "Email": MyUser.user.email,
                "ContactPhone": MyUser.user.phone

              }));
        }
        else{

            setState((prevProps) => ({
                ...prevProps,
                [name]: value
            }));
        }
        
      };


    const GetUsersList= async() => {
        var MyRes = await GetAPICallAsync("ListCompanywiseAllUsers", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData.data);
            setCompUsers(MyData.data);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        let MyRes = await  POSTAPICallAsync("EditWHIncharge", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success === true ){
                setMessage("Incharge Editted Successfully")
                
            }
            else{
                //setMessage(MyData.message);
            }
            
        }
    }

  return (
    <div>
       {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible(false)}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
        <div className='row'>
            <div className="col-6 mb-3">
                <label className="form-label">Users</label>
                <select disabled name="UserId" className="form-select" value={state.UserId} onChange={handleInputChange} >
                        <option value="">Select User</option>
                     {CompUsers.map((item) => (<option key={item.userId} value={item.userId}>{item.user.name}</option>))} 
                    </select>
            </div>

        </div>
            
            <div className='row mb-3'>
                
                <div className="col">
                    <label for="Email" className="form-label">Email Id<span className='color-red'>*</span></label>
                    <input type="email" required  name="Email" className="form-control" id="Email" value={state.Email} onChange={handleInputChange} placeholder="Email Id"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col">
                    <label for="ContactPhone" className="form-label">Phone<span className='color-red'>*</span></label>
                    <input type="number" required  name="ContactPhone" className="form-control" id="ContactPhone" value={state.ContactPhone} onChange={handleInputChange} placeholder="Phone"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
            </div>
           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </div>
  )
}

export default EditWarehouseIncharge