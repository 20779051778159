import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import AddNewBankAccount from './AddNewBankAccount';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const CompanyBanksList = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [BankList, setBankList] = useState([]);

  const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/companybankaccounts?' + new URLSearchParams({
    CompanyId: Company && Company.id,
    }), {
    method: 'Get',
    headers: {
    'Content-type': 'application/json',
    'Authorization': 'Bearer ' + tokenString
    },
  })
  .then(res => {
      console.log(res)
      if(res.status === 401){ navigate("/login"); }
      return res.json()
  } )
  .then(data => {
    console.log(data);
    setBankList(data.listBankAccounts);
  })
  .catch((err) => {
    console.log(err.message);
  }))
  useEffect (() => {
    calltpAPI();
  }, [])
  useEffect (() => {
    calltpAPI();
  }, [visible])
  return (
    <>
      <div className='mb-3 row'>
        <div className='col-6'>
          <span>Company All Banks</span>
        </div>
        <div className='col-6 d-flex justify-content-end'>
          <button className='btn btn-outline-primary ms-auto' onClick={() => setVisible(true)} >Add New</button>
        </div>
       </div>
       <div className='row'>
          <DataTable value={BankList} tableStyle={{ minWidth: '50rem' }}>
            <Column field="accountName" header="AccountName"></Column>
            <Column field="bankName" header="Bank Name"></Column>
            <Column field="accountNumber" header="A/C Number"></Column>
            <Column field="branch" header="Branch"></Column>
            <Column field="ifsCode" header="IFSC"></Column>
            <Column field="swiftCode" header="SWIFT"></Column>
            <Column field="isActive" header="Is Active"></Column>
          </DataTable>
       </div>
       <Dialog header="Add New Bank Account" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
          <AddNewBankAccount CompId = {props.CompId} setVisible={setVisible} />
      </Dialog>
    </>
  )
}

export default CompanyBanksList
