import React from 'react'
import StockIssueNewReqBlock from '../../Blocks/StockIssueNewReqBlock'
import InventoryHeader from '../../Blocks/InventoryHeader'

const StockIssueNewReqPage = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
           <StockIssueNewReqBlock/>
     </div>
   </>
  )
}

export default StockIssueNewReqPage