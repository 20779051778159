import React from 'react'
import logo from '../images/logo.png'

const logoimage = (props) => {
  return (
    <>
      <span className='LogoText'>aqlob</span>
      {/* <img width={props.width} height={props.height} src= {logo} alt='logo'/> */}
    </>
  )
}

export default logoimage
