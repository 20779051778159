import React, { useRef, useState } from "react";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import ListCurrencies from "./ListCurrencies";
import ListTaxes from "./ListTaxes";
import WareHouseList from "./WareHouseList";
import ListItemCategories from "./ListItemCategories";
import ListItemMaster from "./ListItemMaster";
import ItemUnitsList from "./ItemUnitsList";
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const QuickSetupBlock = () => {
    const stepperRef = useRef(null);
    const navigate = useNavigate();
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [ItemTotal, setItemTotal] = useState(0);
    const [state, setState] = useState({
        CompanyId : Company.id,
      });
    const ItemTotalCallBack = (Value) => {
        console.log(Value);
        setItemTotal(parseInt(Value));
    }
    const SetUpCompleted = async() => {
        console.log(state);
        let MyRes = await  POSTAPICallAsync("MarkSetupCompleted", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success == true ){
                navigate("/inventory/dashboard");
            }            
        }
    }

  return (
    <div>
        <Stepper linear ref={stepperRef} style={{ flexBasis: '50rem' }} headerPosition="bottom">
            
            <StepperPanel header="Currencies">
                <div className="d-flex flex-column MyStepperPanelContent">
                    <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto d-flex justify-content-center align-items-center font-medium">
                       
                        <ListCurrencies QuckSetupMode={true} MyItemTotalCallBack = {ItemTotalCallBack}/>
                    </div>
                </div>
                <div className="d-flex pt-4 justify-content-end">
                    {ItemTotal > 0 && <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />}
                </div>
               
            </StepperPanel>
            <StepperPanel header="Taxes">
                <div className="d-flex flex-column MyStepperPanelContent">
                    <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        <ListTaxes QuckSetupMode={true} showGSTSetupbtn = {true} MyItemTotalCallBack = {ItemTotalCallBack}/>
                    </div>
                </div>
                <div className="d-flex pt-4 justify-content-between">
                    <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                    {ItemTotal > 0 && <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />}
                </div>
            </StepperPanel>
            <StepperPanel header="Warehouse">
                <div className="d-flex flex-column MyStepperPanelContent">
                    <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto d-flex justify-content-center align-items-center font-medium">
                        <WareHouseList QuckSetupMode={true} MyItemTotalCallBack = {ItemTotalCallBack}/>
                    </div>
                </div>
                <div className="d-flex pt-4 justify-content-between">
                    <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                    {ItemTotal > 0 && <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />}
                </div>
                
            </StepperPanel>
            <StepperPanel header="Item Categories">
                <div className="d-flex flex-column MyStepperPanelContent">
                    <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        <ListItemCategories QuckSetupMode={true} MyItemTotalCallBack = {ItemTotalCallBack}/>
                    </div>
                </div>
                <div className="d-flex pt-4 justify-content-between">
                    <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                    {ItemTotal > 0 && <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />}
                </div>
            </StepperPanel>
            <StepperPanel header="Item Units">
                <div className="d-flex flex-column MyStepperPanelContent">
                    <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        <ItemUnitsList QuckSetupMode={true} MyItemTotalCallBack = {ItemTotalCallBack}/>
                    </div>
                </div>
                <div className="d-flex pt-4 justify-content-between">
                    <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                    {ItemTotal > 0 && <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />}
                </div>
            </StepperPanel>
            <StepperPanel header="Item Master">
                <div className="d-flex flex-column MyStepperPanelContent" >
                    <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        <ListItemMaster QuckSetupMode={true} MyItemTotalCallBack = {ItemTotalCallBack}/>
                    </div>
                </div>
                <div className="d-flex pt-4 justify-content-between">
                    <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                    <Button label="Finish" disabled={ItemTotal > 0 ? false : true} icon="pi pi-arrow-right" iconPos="right" onClick={() => SetUpCompleted()} />
                </div>
            </StepperPanel>
        </Stepper>
    </div>
  )
}

export default QuickSetupBlock