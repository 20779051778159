import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import {IssueTo, ReferenceType} from '../StaticHelpers/SelectOptions'

const SubmitStockReqBlock = () => {
  let params = useParams();
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [visible, setVisible] = useState({
    open: false,
    mode: "",
    id: ""
  });
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [SubmitStockRequests, setSubmitStockRequests] = useState([]);
  const [Warehouses, setWarehouses] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [selectedRow, setselectedRow] = useState([]);
  const [filterHeight, setfilterHeight] = useState("0px");
  const [Filters, setFilters] = useState({
    Date: "",
    FromDate: "",
    ToDate: "",
    RequestId: "",
    SubmitWHId: "",
    SubmitFrom: "",
    ReferenceType: "",
    ReferenceNo: "",
  });

  const [AppliedFilters, setAppliedFilters] = useState([]);

  const menuRight = useRef(null);
  //const router = useRouter();
  const toast = useRef(null);
  let networkTimeout = null;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {}
  });

const items = [
  {
    label: 'Details',
    icon: 'pi pi-info-circle',
    url: "/warehouse/submitstockreqdetail/" + selectedRow.id + ""
},
{
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: (row) => {
      console.log(selectedRow);
      setVisible({open: true, mode: "edit", id : selectedRow.id})
        // toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
    }
},
]; 
  

  useEffect(() => {
    loadLazyData();
  }, [lazyState, visible.open]);
  useEffect (() => {
    loadLazyData();
    GetWareHouseDD();
  }, [])


  const loadLazyData = () => {
    setLoading(true);
    if (networkTimeout) {
        clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(async () => {
      var MyRes = await GetAPICallAsync("ListSubmitStockRequests", { lazyEvent: lazyState }, {MyFilters : JSON.stringify(Filters)});
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setTotalRecords(MyData.total);
        setSubmitStockRequests(MyData.data);
      }
      
    setLoading(false);
    }, Math.random() * 1000 + 250);
};
  const onPage = (event) => {
    setlazyState(event);
};

const onSort = (event) => {
    setlazyState(event);
};

const onFilter = (event) => {
  console.log(event);
    event['first'] = 0;
    setlazyState(event);
};

const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedCustomers(value);
    setSelectAll(value.length === totalRecords);
};

const onSelectAllChange = async (event) => {
    const selectAll = event.checked;

    if (selectAll) {
            setSelectAll(true);
            setSelectedCustomers(SubmitStockRequests);
      
    } else {
        setSelectAll(false);
        setSelectedCustomers([]);
    }
};

const handleFiltersChange = (event) => {
  const { name, value } = event.target;
  setFilters((prevProps) => ({
    ...prevProps,
    [name]: value
  }));
};

const handleFilterSubmit = async (event) => {
  event.preventDefault();
  const FinalFilters = ManageAppliedFilters(Filters);
  FilterSubmitMethod(FinalFilters);
}





const ManageAppliedFilters = (AppFilters) => {
    let MyFilters = [];
    let ModifiedFilters = {...Filters};
    if (Filters.Date !== null && Filters.Date !== ""){
      console.log(Filters.Date);
      let FromDate = moment(Filters.Date[0]).format("DD/MM/yyyy");
      let ToDate = new moment(Filters.Date[1]).format("DD/MM/yyyy");
      ModifiedFilters.FromDate = Filters.Date[0];
      ModifiedFilters.ToDate = Filters.Date[1];
      MyFilters = [...MyFilters, {name: "Date", Filter: "SIR Date", Value: FromDate + "-" + ToDate}] 
    }
    if(AppFilters.SubmitWHId !== null && AppFilters.SubmitWHId !== ""){
      let MyWarehouse = Warehouses.find(x => x.id === AppFilters.SubmitWHId);
      MyFilters = [...MyFilters, {name: "SubmitWHId", Filter: "Submit To", Value: MyWarehouse.warehouseName}] 
    }
    if(AppFilters.SubmitFrom !== null && AppFilters.SubmitFrom !== ""){
      MyFilters = [...MyFilters, {name: "SubmitFrom", Filter: "Submit From", Value: AppFilters.SubmitFrom}] 
    }
    if(AppFilters.RequestId !== null && AppFilters.RequestId !== ""){
      MyFilters = [...MyFilters, {name: "RequestId", Filter: "Request Id", Value: AppFilters.RequestId}] 
    }
    if(AppFilters.ReferenceType !== null && AppFilters.ReferenceType !== ""){
      MyFilters = [...MyFilters, {name: "ReferenceType", Filter: "Reference Type", Value: AppFilters.ReferenceType}] 
    }
    if(AppFilters.ReferenceNo !== null && AppFilters.ReferenceNo !== ""){
      MyFilters = [...MyFilters, {name: "ReferenceNo", Filter: "Reference Type", Value: AppFilters.ReferenceNo}] 
    }
    setAppliedFilters(MyFilters);
    return ModifiedFilters;
}

const FilterSubmitMethod = async (AppFilters) => {
  console.log(AppFilters);
  var MyRes = await GetAPICallAsync("ListSubmitStockRequests", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(AppFilters)});
  if (MyRes.status === 401){
    localStorage.removeItem("User_Obj");
    localStorage.removeItem("Company");
    localStorage.removeItem("token");
    navigate("/login");
  }
  else{
    var MyData = await MyRes.json();
    console.log(MyData);
    setTotalRecords(MyData.total);
    setSubmitStockRequests(MyData.data);
    setfilterHeight("0px")
  }

}

const RemoveFilter = (item, index) => {
  let MyAppliedFilter = [...AppliedFilters];
  MyAppliedFilter.splice(index, 1)
  setAppliedFilters(MyAppliedFilter);
  const name = item.name;
  let MyFilterz ="";
  console.log(MyFilterz);
  if(name === "Date"){
    MyFilterz = {...Filters, [name] : "", ["FromDate"] : "",["ToDate"] : "" }
    setFilters((prevProps) => ({
      ...prevProps,
      [name] : "",
      ["FromDate"] : "",
      ["ToDate"] : "",
    }) )
  }else{
    MyFilterz = {...Filters, [name] : "" }
    setFilters((prevProps) => ({
      ...prevProps,
      [name] : "",
    }) )
  }
 
  FilterSubmitMethod(MyFilterz);
}

const Reset = () => {
  setFilters({ 
    Date: "",
    FromDate: "",
    ToDate: "",
    RequestId: "",
    SubmitWHId: "",
    SubmitFrom: "",
    ReferenceType: "",
    ReferenceNo: ""})
}

const GetWareHouseDD = async () => {
  var MyRes = await GetAPICallAsync("GetListCompanyWarehouses", { lazyEvent: lazyState }, "");
  if (MyRes.status === 401){
  localStorage.removeItem("User_Obj");
  localStorage.removeItem("Company");
  localStorage.removeItem("token");
  navigate("/login");
  }
  else{
  var MyData = await MyRes.json();
  console.log(MyData);
  setWarehouses(MyData.data);
  }
};

  const ReferenceTypeTemplate = (prop) => {
      return prop.referenceType === "prodono" ? <span className='ActiveClass'>Production Order</span> : <span className='InActiveClass'>Not Known</span>
  }
  const IssuedStatusTemplate = (prop) => {
      return prop.is_Issued === true ? <span className='ActiveClass'>Yes</span> : <span className='InActiveClass'>No</span>
  }
  const Phonetemplate = (prop) => {
    return <span>+{prop.phone}</span>
  }
  const ActionTemplate = (row) => {
     return<>
     <div className="flex justify-content-center">
          <Toast ref={toast}></Toast>
          <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
          <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
          {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
     </div>
     </> 
  }

  const DateTemplate = (prop) => {
    return moment(prop.createdOn).format("DD/MM/yyyy");
  }

  return (
    <div>
       <div className=' row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Submit Stock Requests</h1>
        </div>
        <div className='col-6 d-flex justify-content-end text-end'>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
       <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "300px" : "0px")}></i>
        
        <Link className='btn btn-outline-primary' to="/inventory/submitstocknewrequest"><i className="pi pi-plus"></i> Add New</Link>
        </div>
        </div>
        <div className='row'>
        <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
        </div>
        {AppliedFilters.length > 0 ? <div className='row mb-3'>
          <div className='col-12'>
            {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter}: {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}} onClick={() => RemoveFilter(item, index) }></i></span>))}
          </div>
        </div>: ""}
        <div className='filterContainer' style={{ height: filterHeight, transition: "height 1s"}}>
        <form onSubmit={handleFilterSubmit} style={{height: filterHeight, transition: "height 1s", overflow: "hidden" }}> 
          <div class="row g-2">
            <h2 className='FiltersHeading'>Filters</h2>
            <div className="col-3 mb-3">
              <label htmlFor="RequestId" className="form-label">Request Id</label>
              <input type="text" className="form-control" name="RequestId" id="RequestId" value={Filters.RequestId} onChange={handleFiltersChange} placeholder="Request Id"/>
            </div>
           
            
            <div className="col-3 mb-3">
              <label htmlFor="Date" className="form-label">Date</label>
              <Calendar name="Date" id="Date"  dateFormat="dd/mm/yy" className="form-control custdate" selectionMode="range"  value={Filters.Date} onChange={handleFiltersChange} showButtonBar placeholder='Select Date Range' hideOnRangeSelection ></Calendar>
            </div> 
            <div className="col-3 mb-3">
                 <label htmlFor="SubmitWHId" className="form-label">Submit To</label>
                 
                 <select name="SubmitWHId" className="form-select" value={Filters.SubmitWHId} onChange={handleFiltersChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {Warehouses.filter(x => x.id !== Filters.TransferTo).map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.warehouseName}</option>))}
                </select>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="SubmitFrom" className="form-label">Submit From</label>
                 
                 <select name="SubmitFrom" className="form-select" value={Filters.SubmitFrom} onChange={handleFiltersChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {IssueTo().map((item, index) => (<option key={index} value={item.value}>{item.name}</option>))}
                </select>

             </div>
             <div className="col-3 mb-3">
                 <label htmlFor="ReferenceType" className="form-label">Reference Type</label>
                 
                 <select name="ReferenceType" className="form-select" value={Filters.ReferenceType} onChange={handleFiltersChange} aria-label="Default select example">
                    <option value={""}>Select Any</option>
                    {ReferenceType().map((item, index) => (<option key={index} value={item.value}>{item.name}</option>))}
                </select>

             </div>
             {Filters.ReferenceType != "" &&
             <div className="col-3 mb-3">
              <label htmlFor="ReferenceNo" className="form-label">Reference No</label>
              <input type="text" className="form-control" name="ReferenceNo" id="ReferenceNo" value={Filters.ReferenceNo} onChange={handleFiltersChange} placeholder="Reference Number"/>
            </div> }             
            </div>
            <div className='row'>
              <div className='col-12'>
                <button type='submit' className='btn btn-outline-primary mt-4'>Apply <i className="pi pi-angle-double-right"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => Reset()}>Reset <i className="pi pi-undo"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => setfilterHeight("0px")}>Cancel <i className="pi pi-times"></i></button>
                </div>
            </div>
          </form> 
        </div>
        <div className='row' style={{ marginTop: filterHeight === "0px" ? "-15px": "" }}>
            <DataTable value={SubmitStockRequests} lazy removableSort dataKey="id" paginator size='small' showGridlines  resizableColumns columnResizeMode="expand"
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                    selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            
            <Column field="requestId" header="Request Id" />
            <Column field="createdOn" header="Requested On" body={DateTemplate} />
            <Column field="submitToName" header="Submit To"/>
            <Column field="submitFrom" header="Submit From"/>
            <Column field="referenceType" header="Reference Type" body={ReferenceTypeTemplate}/>
            <Column field="referenceNumber" header="Reference Number"/>
            <Column field="is_Submit" header="Stock Submitted" body={IssuedStatusTemplate}/>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
            </DataTable>
        </div>
        </div>
  )
}

export default SubmitStockReqBlock