import React from 'react'
import ListWarehouselocations from '../../Blocks/ListWarehouselocations'
import InventoryHeader from '../../Blocks/InventoryHeader'

const WarehouseLocations = () => {
  return (
    <>
    <InventoryHeader/>
    <div className='p-3'>
        <ListWarehouselocations />
    </div>
  
</>
  )
}

export default WarehouseLocations