import React, { useEffect, useRef, useState } from 'react'
import AddCompUser from './AddCompUser';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import moment from 'moment';
import EditCompanyUser from './EditCompanyUser';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const ListCompUsers = () => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const [visible, setVisible] = useState({
      open: false,
      mode: "",
      id: ""
    });
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [UItotalRecords, setUITotalRecords] = useState(0);
    const [CompanyUsers, setCompanyUsers] = useState([]);
    const [UserInvites, setUserInvites] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    let networkTimeout1 = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
        supName: { value: '', matchMode: 'contains' },

      }
  });


    
  
    useEffect(() => {
      loadLazyData();
      loadUserInvites();
    }, [lazyState, visible, reload]);
    useEffect (() => {
      loadUserInvites();
      loadLazyData();
      
    }, [])

    const loadUserInvites = () => {
      setLoading(true);
      if (networkTimeout1) {
          clearTimeout(networkTimeout1);
      }

      //imitate delay of a backend call
    networkTimeout1 = setTimeout(async () => {
    var MyRes = await GetAPICallAsync("ListCompanywiseAllUserInvites", { lazyEvent: lazyState }, "");
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setUITotalRecords(MyData.total);
        setUserInvites(MyData.data);
    }
        
    setLoading(false);
      }, Math.random() * 1000 + 250);
    };


    const loadLazyData = () => {
      console.log("In")
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }

      //imitate delay of a backend call
    networkTimeout = setTimeout(async () => {
    var MyRes = await GetAPICallAsync("ListCompanywiseAllUsers", { lazyEvent: lazyState }, "");
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setTotalRecords(MyData.total);
        setCompanyUsers(MyData.data);
    }
        
    setLoading(false);
      }, Math.random() * 1000 + 250);
    };
    const onPage = (event) => {
        setlazyState(event);
    };

    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        console.log(event);
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setSelectedCustomers(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = async (event) => {
        const selectAll = event.checked;

        if (selectAll) {
                setSelectAll(true);
                setSelectedCustomers(CompanyUsers);
            
        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const statusTemplate = (prop) => {
      var MyDate = new Date().toISOString();
        return (prop.expiryDate >= MyDate) ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Expired</span>
    }
    const DateTemplate = (prop) => {
      return moment(prop.date).format("DD/MM/yyyy");
    }
    const ActionTemplate = (row) => {
       return<>
       <button className='btn btn-primary' onClick={() => handleRevokeInvite(row.id)}>Revoke</button>
    
       </> 
    }
    const ActionTemplate1 = (row) => {
      return(
        <>
          <i className='pi pi-pencil ActionIcon' data-toggle="tooltip" title='Get In' onClick={() => setVisible({"open": true, "mode": "edit", "id": row.id})}></i>
          <i className="pi pi-trash ms-3 ActionIcon" data-toggle="tooltip" title='Edit' onClick={() => handleDeleteUser(row.id)}></i>
        </>
      )
      

   }


   const handleRevokeInvite = async (id) => {
    let MyRes = await  POSTAPICallAsync("DeleteUserInvite", {Id : id});
    console.log(MyRes);
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        if (MyData.success == true ){
          alert(MyData.message);
          setReload(!reload);
        }
        else if (MyData.success == false){
      
        }
        
    }
}
   const handleDeleteUser = async (id) => {
    let MyRes = await  POSTAPICallAsync("DeleteCompanyUser", {Id : id});
    console.log(MyRes);
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        if (MyData.success == true ){
          alert(MyData.message);
          setReload(!reload);
        }
        else if (MyData.success == false){
      
        }
        
    }
}
  return (
    <div>
    <div className='mb-3 row'>
    <div className='col-6'>
        <span className='ListMainHeading'>Company Users</span>
    </div>
    <div className='col-6 d-flex justify-content-end'>
        <button className='btn btn-outline-primary ms-auto' onClick={() => setVisible({"open": true, "mode": "add"})} >Add New</button>
    </div>
    </div>
    <div className='row'>
        <DataTable value={CompanyUsers} lazy dataKey="id" size='small' showGridlines  resizableColumns columnResizeMode="expand"
                first={lazyState.first} rows={5} totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
        <Column field="user.name" header="Name"/>
        <Column field="user.userName" header="User Name"></Column>
        <Column field="profile" header="Access Level"></Column>
        <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate1}></Column>
        </DataTable>
    </div>
    <div className='row mt-5'>
      <div className='col-6'>
          <span className='ListMainHeading'>User Invited</span>
      </div>
    </div>

    <div className='row'>
        <DataTable value={UserInvites} lazy dataKey="id"  size='small' showGridlines  resizableColumns columnResizeMode="expand"
                first={lazyState.first} rows={5} totalRecords={UItotalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
        <Column field="emailId" header="User Name"></Column>
        <Column field="invitedOn" header="Invited On" body={DateTemplate}></Column>
        <Column field="expiryDate" header="Status" body={statusTemplate} ></Column>
        <Column field="profile.name" header="Profile"></Column>
        <Column field="" header="Action" body={ActionTemplate}></Column>
        </DataTable>
    </div>
    <Dialog header={visible.mode === "edit" ? "Edit Company User" : "Add Company User"} visible={visible.open} style={{ width: '70vw' }} onHide={() => setVisible({"open": false})}>
        {visible.mode === "edit" ? <EditCompanyUser MyVisible = {visible} setVisible={setVisible}/> : <AddCompUser setVisible={setVisible}/>}
    </Dialog>
</div>
  )
}

export default ListCompUsers