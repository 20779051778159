import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const AddCompUser = (props) => {
const Company = JSON.parse(localStorage.getItem('Company'));
const navigate = useNavigate();
const [Message, setMessage] = useState();
const [ShowForm, setShowForm] = useState(true);
const [UserRegistered, setUserRegistered] = useState(false);
const [AlreadyUser, setAlreadyUser] = useState(true);
const [EmailId, setEmailId] = useState();
const [AllProfiles, setAllProfiles] = useState();
const [state, setState] = useState({
    CompanyId : Company.id,
    UserId : "",
    ProfileId : "",
    Name : "",
    UserName : ""
});
const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 0,
    page: 1,
    sortField: "",
    sortOrder: 0,
});
const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
    ...prevProps,
    [name]: value
    }));
};

const GetAllprofiles = async () => {
    var MyRes = await GetAPICallAsync("GetListProfiles", { lazyEvent: lazyState }, "");
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setAllProfiles(MyData.data);
    }
}

useEffect(() => {
    GetAllprofiles();
}, [])

const GetUserByEmailId = async () => {
    var MyRes = await GetAPICallAsync("GetUserbyEmailId", { lazyEvent: "" }, {Email: EmailId});
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        if(MyData.success){
            setState((prevProps) => ({
                ...prevProps,
                "UserId": MyData.data.user.id,
                "Name": MyData.data.user.name,
                "UserName": MyData.data.user.userName,
            }));
            setUserRegistered(true);
            setMessage("");
            setAlreadyUser(false);
        }
        else if (MyData.success == false && MyData.data.alreadyPresent){
            setMessage(MyData.message);
            setAlreadyUser(true);
            setUserRegistered(true);
        }
        else{
            setMessage(MyData.message);
            setUserRegistered(false);
            setAlreadyUser(false);
            setState((prevProps) => ({
                ...prevProps,
                "UserId": "",
                "Name": "",
                "UserName": EmailId,
            }));
        }
        
        // setTotalRecords(MyData.total);
        // setCompanyUsers(MyData.data);
    }
}

const handleInputchange = (event) => {
    const {name, value} = event.target;
    setState((prevProps) => ({
        ...prevProps,
        [name] : value,
    }))

}

const handleInviteUser = async(event) => {
    event.preventDefault();
    console.log(state);
    let MyRes = await  POSTAPICallAsync("AddNewUserInvite", state);
    console.log(MyRes);
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        if (MyData.success == true ){
            setMessage(MyData.message);
            props.setVisible({open: false});
        }
        else if (MyData.success == false){
            setMessage(MyData.message);
        }
        
    }
}
const handleSubmit = async (event) => {
event.preventDefault();
let MyRes = await  POSTAPICallAsync("AddCompanyUser", state);
console.log(MyRes);
if (MyRes.status === 401){
    localStorage.removeItem("User_Obj");
    localStorage.removeItem("Company");
    localStorage.removeItem("token");
    navigate("/login");
}
else{
    var MyData = await MyRes.json();
    console.log(MyData);
    if (MyData.success == true ){
        setMessage(MyData.message);
        props.setVisible({open: false});
    }
    else if (MyData.success == false){
        setMessage(MyData.message);
    }
    
}
}
  return (
    <>
    {Message ? <div className='col mb-3'>
            <span className='Error'>{Message}</span>

        </div> : "" }
        <>    
            <div className='col-6'>        
                <div className="input-group mb-3">
                    
                    <input type="text" required name="TaxName" className="form-control" id="TaxName" value={EmailId} onChange={(e) => {setEmailId(e.target.value)}} placeholder="Search by Email Id"/>
                
                <div className='input-group-append'>
                    <button type="button" onClick={GetUserByEmailId} className="btn btn-primary">Submit</button>
                </div>
                </div>
            </div>
            {!AlreadyUser ? 
            <form className='mt-3' onSubmit={!UserRegistered ? handleInviteUser : handleSubmit }>
                <div className='table table-responsive'>
          
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                            {UserRegistered && <th>Name</th>}
                            <th>User Name</th>
                            <th>Access Level</th>
                            <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            {UserRegistered && <td>
                                    {state.Name}
                                </td> }
                                <td>
                                    {state.UserName}
                                </td>
                                <td>
                                    <select name='ProfileId' required value={state.ProfileId} onChange={handleInputchange}>
                                        <option value="">Select One</option>
                                        {AllProfiles.map((item, index) => (<option key={index} value={item.id}>{item.name}</option>))}
                                    </select>
                                    {state.AccessLevel}
                                </td>
                                <td>
                                <button type="submit" className="btn btn-primary"> {!UserRegistered ? "Invite" : "Add" }</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
            : ""
            }
        </>
    </>
  )
}

export default AddCompUser