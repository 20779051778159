import React from 'react'
import TopNavigation from '../../Components/TopNavigation'
import AddCompForm from '../../Blocks/AddCompForm'

const AddNewCompany = () => {
  return (
    <>
     <TopNavigation/>
        <div className=''>
            <div className='col-12 p-3'>
                <h1 className='ListMainHeading'>Add New Company</h1>
            </div>
            <div className='col-12 p-3'>
                <AddCompForm />
            </div>
        </div> 
    </>
  )
}

export default AddNewCompany
