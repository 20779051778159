import React from 'react'
import PublicHeader from '../../Blocks/PublicHeader'
import { Link } from 'react-router-dom'
import CompanyList from '../../Blocks/CompanyList'

const Company = () => {
  return (
    <>
        <PublicHeader/>
        <div>
          <div className='container'>
        <div className='row g-0'>
          <div className='col-6 py-3'>
          <h1 className='ListMainHeading'>My Companies</h1>
          </div>
          <div className='col-6 text-end py-3'>
            <Link className='btn btn-outline-primary' to="/setup/companies/addnew">Add New Company</Link>
          </div>
        </div>
        <div className='col-12'>
            <CompanyList/>
        </div>
        </div>
        </div>
        
    </>
  )
}

export default Company
