import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import WareHouseList from '../../Blocks/WareHouseList'

const Warehouses = () => {
  return (
    <>
        <InventoryHeader/>
        <div className='p-3'>
            <WareHouseList QuckSetupMode={false} />
        </div>
      
    </>
  )
}

export default Warehouses
