import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import Addnewarinvoice from '../../Blocks/Addnewarinvoice'

const Addarinvoice = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
          <Addnewarinvoice />
        </div>
    </div>
  )
}

export default Addarinvoice