import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import LineChartSales from './Charts/LineChartSales';
import LineChartCummPurchase from './Charts/LineChartCummPurchase';
import StockValuationTable from '../Components/StockValuationTable';
import { Stop } from '@react-pdf/renderer';

const DashboardBlock = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let networkTimeout = null;
    const [state, setState] = useState({
      ACSales : [],
      ACPurchase: [],
      StockVList: [],
      
    });
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
          org_name: { value: '', matchMode: 'contains' },
      }
  });

  const GetAccumSales = async ()=> {
      var MyRes = await GetAPICallAsync("AccumulatedandMonthlySales", { lazyEvent: "" }, "");
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setState((PrevProps) => ({
              ...PrevProps,
              ACSales: MyData.data.annualCummSales,
            }))
          }
  }
  const GetAccumPurchase = async () => {
    var MyRes = await GetAPICallAsync("AccumulatedandMonthlyPurchase", { lazyEvent: "" }, "");
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setState((PrevProps) => ({
              ...PrevProps,
              ACPurchase : MyData.data.annualCummPurchase,
            }))
          }
  }

    const loadLazyData = () => {
        setLoading(true);
        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
    
        //imitate delay of a backend call
        networkTimeout = setTimeout(async () => {
          var MyRes = await GetAPICallAsync("StockValuation", { lazyEvent: lazyState }, "");
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setState((PrevProps) => ({
              ...PrevProps,
              StockVList : MyData.data.stockValuationList,
            }))
          }
          
        setLoading(false);
        }, Math.random() * 1000 + 250);
    };

    useEffect (() => {
        loadLazyData();
        GetAccumPurchase();
        GetAccumSales();
    }, [])

  return (
    <>
      <div>Dashboard</div>
      <div className='row'>
        <div className='col-6'>
            {state.ACPurchase.length > 0 && <LineChartCummPurchase purchases={state.ACPurchase}/>}
        </div>
        <div className='col-6'>
            {state.ACSales.length > 0 && <LineChartSales sales={state.ACSales}/>}
        </div>
      </div>
      <div className='row'>
      {state.StockVList.length > 0 && <StockValuationTable stockValuationList={state.StockVList}/>}
      </div>
    </>
  )
}

export default DashboardBlock