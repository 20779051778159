import React, { useState } from 'react'; 
import { useNavigate} from 'react-router-dom'

const InvSideMenu = () => {

    const navigate = useNavigate();
    const UserObj = JSON.parse(localStorage.getItem('User_Obj'));
    const CompanyObj = JSON.parse(localStorage.getItem('Company'));
    const CompanyName = CompanyObj && CompanyObj.companyName.split(" ")[0];
    const [visible, setVisible] = useState({
        Sales: true,
        Purchase: false,
    })

    const Logout = () => {
        localStorage.removeItem('User_Obj');
        localStorage.removeItem('token');
        localStorage.removeItem('Company');
        navigate("/login");
              
    }

    const handleCollapse = (e) => {
        e.stopPropagation();
        console.log("Hi");
        const name = e.target.getAttribute("name");
        setVisible((prevProps) => ({
            ...prevProps, 
            [name] : !prevProps[name],
        }))
    }

    return (
        <div className="flex justify-content-center">
           <ul class="nav flex-column">
                <li class="nav-item"><a class="NavigationHeading nav-link" aria-current="page" href="/reports/snapshot">Home</a></li>
                <li className='NavigationHeading d-flex justify-content-between align-items-center'  href="#SalesItems" role="button" name="Sales" aria-expanded="true" onClick={handleCollapse} aria-controls="SalesItems"><span name="Sales"> <i className="pi pi-dollar" name="Sales"></i> Sales</span> {visible.Sales ? <i className="pi pi-minus me-1" name="Sales"></i> : <i className="pi pi-plus me-1" name="Sales"></i> } </li>
                <ul class=" Level1Nav" id="SalesItems" style={{ display: visible.Sales ? "block" : "none"}}>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Sales Snapshot</a></li>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Sales Register</a></li>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Itemised Sales Bills</a></li>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Sales Orders List</a></li>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Sales Report</a></li>
                </ul>
                <li className='NavigationHeading d-flex justify-content-between align-items-center'  href="#PurchaseItems" role="button" name="Purchase" aria-expanded="true" onClick={handleCollapse} aria-controls="PurchaseItems"><span name="Purchase"> <i className="pi pi-shopping-cart" name="Purchase"></i> Purchase</span> {visible.Purchase ? <i className="pi pi-minus me-1" name="Purchase"></i> : <i className="pi pi-plus me-1" name="Purchase"></i>}</li>
                <ul class=" Level1Nav" id="PurchaseItems" style={{ display: visible.Purchase ? "block" : "none"}}>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="/reports/purchasesnapshot">Purchase Snapshot</a></li>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="/reports/purchaseregister">Purchase Register</a></li>
                    <li class="nav-item"><a class="nav-link" aria-current="page" href="/reports/itemisedpurchaseregister">Itemised Purchase Bills</a></li>
                </ul>
              
                

           </ul>
        </div>
    )
}

export default InvSideMenu