import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import AddProdOrderBlock from '../../Blocks/AddProdOrderBlock'

const AddProdOrderPage = () => {
  return (
    <div>
      <InventoryHeader/>
        <div className='p-3'>
          <AddProdOrderBlock/>
        </div>
    </div>
  )
}

export default AddProdOrderPage