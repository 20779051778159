import React from 'react'
import ListCurrencies from '../../Blocks/ListCurrencies'
import InventoryHeader from '../../Blocks/InventoryHeader'

const Currencies = () => {
  return (
    <>
        <InventoryHeader/>
        <div className='p-3'>
            <ListCurrencies QuckSetupMode={false}/>
        </div>
    </>
  )
}

export default Currencies