import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const EditItemCategory = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const navigate = useNavigate();
  const [Message, setMessage] = useState();
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 0,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {}
  });
  const [state, setState] = useState({
      Id: props.Id,
      PCatId : "",
      CatName : "",
      Description : "",
      CompanyId : props.CompId,
      
  });
  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
  };

  const GetWHbyId = async() => {
    var MyRes = await GetAPICallAsync("GetItemCatbyId", { lazyEvent: lazyState }, {ID: props.Id});
    if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
    }
    else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setState((prevProps) => ({
            ...prevProps,
            PCatId : MyData.data.pCatId,
            CatName : MyData.data.catName,
            Description : MyData.data.description
            
        }))
    }
};

useEffect (() => {  
  GetWHbyId();
}, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(state);
    await fetch(process.env.REACT_APP_API_URL + 'setup/EditItemCat', {
     method: 'POST',
     body: JSON.stringify(state),
     headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + tokenString
      },
    })
    .then((res) => 
    { if(res.status === 401){ navigate("/login"); }        
    return res.json()})
    .then((data) => {
        setMessage("Category Added Successfully")
    })
    .catch((err) => {
        console.log(err.message);
    });
  }
  return (
    <>
      {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible({"open": false})}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Category Name<span className='color-red'>*</span></label>
                <input type="text" required name="CatName" className="form-control" id="CatName" value={state.CatName} onChange={handleInputChange} placeholder="Enter Category name"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea type="text" rows="4" name="Description" className="form-control" id="Description" value={state.Description} onChange={handleInputChange} placeholder="Enter Description"/>
            </div>           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </>
  )
}

export default EditItemCategory
