import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import APInvoiceDetailBlock from '../../Blocks/APInvoiceDetailBlock'

const APInvoiceDetailPage = () => {
  return (
    <div>
        <InventoryHeader/>
        <div className='p-3'>
            <APInvoiceDetailBlock />
        </div>
    </div>
  )
}

export default APInvoiceDetailPage