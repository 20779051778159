import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import Listrfq from '../../Blocks/Listrfq'

const Rfq = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
          <Listrfq />
        </div>
    </div>
  )
}

export default Rfq
