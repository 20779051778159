import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall';

const EditCurrency = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
        filters: {
        }
    });
    const [state, setState] = useState({
      CompanyId : Company.id,
      Id: props.Id,
      Name : "",
      ShortCode : "",
      isDefaultCurrency : false,
      ConversionRate : ""
    });
    const handleInputChange = (event) => {
        var { name, value } = event.target;
        console.log(name);
        console.log(value);
        console.log(event.target.checked);
        if(name == "isDefaultCurrency"){
            value = event.target.checked
        }
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
    };
    const GetTaxbyId = async() => {
        var MyRes = await GetAPICallAsync("GetCurrencybyId", { lazyEvent: lazyState }, {ID: props.Id});
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setState((prevProps) => ({
                ...prevProps,
                Name : MyData.data.name,
                ShortCode : MyData.data.shortCode,    
                isDefaultCurrency : MyData.data.isDefaultCurrency,
                ConversionRate : MyData.data.conversionRate,
            }))
        }
    };

    useEffect (() => {  
        GetTaxbyId();
    }, []);

    const handleSubmit = async (event) => {
      event.preventDefault();
              console.log(state);
              let MyRes = await  POSTAPICallAsync("EditCurrency", state);
              console.log(MyRes);
              if (MyRes.status === 401){
                  localStorage.removeItem("User_Obj");
                  localStorage.removeItem("Company");
                  localStorage.removeItem("token");
                  navigate("/login");
              }
              else{
                  var MyData = await MyRes.json();
                  console.log(MyData);
                  if (MyData.success == true ){
                      setMessage(MyData.message);
                      setShowForm(false);
                  }
                  else{
                      setMessage(MyData.message);
                  }
                  
              }
    }
    return (
      <>
        {Message ? <div className='col'>
              <span>{Message}</span>
              <button onClick={() => props.setVisible({"open": false})}>Close</button>
          </div> :
        <form onSubmit={handleSubmit}>
              <div className="mb-3">
                  <label className="form-label">Name<span className='color-red'>*</span></label>
                  <input type="text" required name="Name" className="form-control" id="TaxName" value={state.Name} onChange={handleInputChange} placeholder="Enter name"/>
              </div>
              <div className="mb-3">
                  <label className="form-label">Short Code<span className='color-red'>*</span></label>
                  <input type="text" required name="ShortCode" className="form-control" id="ShortCode" value={state.ShortCode} onChange={handleInputChange} placeholder="Enter Short Code"/>
              </div>
              <div className="form-check">
                <input type="checkbox" checked={state.isDefaultCurrency} className="form-check-input" name='isDefaultCurrency'  onChange={handleInputChange} id="IsDefaultCurrency"/>
                <label className="form-check-label" for="exampleCheck1">Is Default Currency</label>
             </div>

              <div className="mb-3">
                  <label className="form-label">Conversion Rate</label>
                  <input type="text" required name="ConversionRate" className="form-control" id="ConversionRate" value={state.ConversionRate} onChange={handleInputChange} placeholder="Enter Conversion Rate"/>
              </div>           
              <div className='mt-4'>
                  <button type="submit" className="btn btn-primary">Submit</button>
              </div>
          </form>}
      </>
    )
}

export default EditCurrency