import React from 'react'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import logo from '../../images/logo.png'

const PurchaseOrderPdf = ({ purchaseOrder }) => {
  const Company = JSON.parse(localStorage.getItem('Company'));
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
    // Create styles
const styles = StyleSheet.create({
    page: { padding: 30 },
    section: { marginBottom: 20 },
    POSection: { width: "38%", marginBottom: 20, paddingLeft: 20 },
    CompanySection: { width: "58%", marginBottom: 20, paddingRight: 20 },
    TotalsSection: { width: "50%", marginBottom: 20 },
    header: { fontSize: 24, fontWeight: 'bold', textAlign: 'center' },
    POheader: { fontSize: 24, fontWeight: 'bold', marginBottom: 8 },
    CompanyName: { fontSize: 16, fontWeight: 'bold', marginBottom: 8 },
    VendorName: { fontSize: 14, fontWeight: 'bold', marginBottom: 8 },
    subHeader: { fontSize: 14, marginBottom: 8 },
    text: { fontSize: 12, marginBottom: 5 },
    POtext: { width: "25%", fontSize: 12 },
    TotalHeadtext: { width: "60%", fontSize: 12, textAlign: "right", paddingRight: 10, paddingTop: 3, margin: 0 },
    TotalValuetext: { width: "40%", fontSize: 12, paddingRight: 10, paddingTop: 3, textAlign: "right"},
    tableHeader: { flexDirection: 'row', borderWidth: 1, paddingBottom: 5, fontWeight: 'bold' },
    tablerow: { flexDirection: 'row', borderWidth: 0.5, paddingBottom: 5 },
    sectionrow: { flexDirection: 'row', paddingBottom: 5 },
    column: { width: '20%' , borderWidth: 0.5, textAlign: 'right' },
    itemColumn: { width: '20%', textAlign: 'center', fontSize: 12 },
    logo: {width: 100, height: "auto", backgroundColor: "red"}
  });
  return (
    <Document>
    <Page style={styles.page}>
      {/* Purchase Order Header */}
      <View style={styles.sectionrow}>
        <View style={styles.POSection}>
          <Image src="https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg" style={styles.logo} />
        </View>

      <View style={styles.CompanySection}>
        <Text style={styles.CompanyName}>{Company.companyName}</Text>
        <Text style={styles.text}>{purchaseOrder.poNo}</Text>
        <Text style={styles.text}>{purchaseOrder.poDate}</Text>
        <Text style={styles.text}>{purchaseOrder.totalPrice + purchaseOrder.totalTaxAmount} {purchaseOrder.currencyName}</Text>
      </View>
      
      </View>

      <View style={styles.section}>
        <Text style={styles.header}>{purchaseOrder.heading}</Text>
      </View>

       {/* Order Details */}
       
       <View style={styles.sectionrow}>
      {/* Vendor Information */}
      <View style={styles.CompanySection}>
        {/* <Text style={styles.subHeader}>Vendor Information</Text> */}
        <Text style={styles.VendorName}>{purchaseOrder.vendorInfo.supName}</Text>
        <Text style={styles.text}>{purchaseOrder.vendorInfo.localAddress}, {purchaseOrder.vendorInfo.cityName}, {purchaseOrder.vendorInfo.stateName}, {purchaseOrder.vendorInfo.countryName}, {purchaseOrder.vendorInfo.zipCode}</Text>
        <Text style={styles.text}>Email: {purchaseOrder.vendorInfo.email}</Text>
        <Text style={styles.text}>GST: {purchaseOrder.vendorInfo.gst}</Text>
        <Text style={styles.text}>TAN: {purchaseOrder.vendorInfo.tan}</Text>
      </View>
      <View style={styles.POSection}>
        {/* <Text style={styles.POheader}>Purchase Order</Text> */}
        <View style={styles.sectionrow}>
        <Text style={styles.POtext}>Date </Text>
        <Text style={styles.text}>: {moment(purchaseOrder.poDate).format("DD/MM/yyyy")}</Text>
        </View>
        <View style={styles.sectionrow}>
        <Text style={styles.POtext}>P.O. No </Text>
        <Text style={styles.text}>: {purchaseOrder.poNo}</Text>
        </View>
      </View>
      </View>

        {/* Notes and Comments */}
    <View style={styles.section}>
    <Text style={styles.text}>Subject: {purchaseOrder.subject || "No comments to show."}</Text>
    <Text style={styles.subHeader}>Dear {purchaseOrder.vendorInfo.contactPersonName}</Text>
    <Text style={styles.text}>{stripHtmlTags(purchaseOrder.welcomeText) || "No comments to show."}</Text>
    </View>

     

      {/* Itemized Details Table */}
      <View style={styles.section}>
        <Text style={styles.subHeader}>Itemized Details</Text>
        <View style={styles.tableHeader}>
          <Text style={styles.itemColumn}>Item Code</Text>
          <Text style={styles.itemColumn}>Name</Text>
          <Text style={styles.itemColumn}>Quantity</Text>
          <Text style={styles.itemColumn}>Unit Price</Text>
          <Text style={styles.itemColumn}>Tax Amount</Text>
          <Text style={styles.itemColumn}>Total Amount</Text>
        </View>
        {purchaseOrder.items.map((item, index) => (
          <View key={index} style={styles.tablerow}>
            <Text style={styles.itemColumn}>{item.itemCode}</Text>
            <Text style={styles.itemColumn}>{item.foreignName}</Text>
            <Text style={styles.itemColumn}>{item.rQty} {item.unitName}</Text>
            <Text style={styles.itemColumn}>{item.unitPrice} {purchaseOrder.currencyName}</Text>
            <Text style={styles.itemColumn}>{item.totalTax} {purchaseOrder.currencyName}</Text>
            <Text style={styles.itemColumn}>{item.taxableAmount + item.totalTax} {purchaseOrder.currencyName}</Text>
          </View>
        ))}
      </View>
      <View style={styles.sectionrow}>
      <View style={styles.TotalsSection}>

      </View>
      <View style={styles.TotalsSection}>
        <View style={styles.tablerow}>
          <Text style={styles.TotalHeadtext}>Taxable Amount</Text>
          <Text style={styles.TotalValuetext}>{purchaseOrder.totalPrice} {purchaseOrder.currencyName}</Text>
        </View>
        <View style={styles.tablerow}>
          <Text style={styles.TotalHeadtext}>Tax</Text>
          <Text style={styles.TotalValuetext}>{purchaseOrder.totalTaxAmount} {purchaseOrder.currencyName}</Text>
        </View>
        <View style={styles.tablerow}>
          <Text style={styles.TotalHeadtext}>Total Payable</Text>
          <Text style={styles.TotalValuetext}>{purchaseOrder.totalPrice + purchaseOrder.totalTaxAmount} {purchaseOrder.currencyName}</Text>
        </View>
      </View>
      </View>

      {/* Notes and Comments */}
      <View style={styles.section}>
        <Text style={styles.subHeader}>Notes & Comments</Text>
        <Text style={styles.text}>{stripHtmlTags(purchaseOrder.termsandConditions) || "No comments to show."}</Text>
      </View>
    </Page>
  </Document>
  )
}

export default PurchaseOrderPdf