import React from 'react'
import AddDynamicTextField from '../Components/AddDynamicTextField'
import AddDSelectField from '../Components/AddDSelectField'

const AddOtherField = (props) => {
   
    const ChooseForm = (params) => {
        switch (params) {
            case 'text' : return  <AddDynamicTextField FieldType ={props.FieldType} FormName = {props.FormName} setVisible={props.setVisible} />
            case 'select': return <AddDSelectField FieldType ={props.FieldType} FormName = {props.FormName} setVisible={props.setVisible} />
        }
    }
    
  return (
    <>
       {ChooseForm(props.FieldType)}
    </>
  )
}

export default AddOtherField
