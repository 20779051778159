import React from 'react';

const StockValuationTable = ({ stockValuationList }) => {
  const GetMyMethod = (MyMethod) => {
    switch (MyMethod){
      case "FIFO": return "FIFO"
      case "specific_identification": return "Specific Identification"
    }
  }
  return (
    <div>
      <h3>Stock Valuation</h3>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Warehouse Name</th>
            <th>Valuation Method</th>
            <th className='text-end'>Available Quantity</th>
            <th>Unit</th>
            {Object.keys(stockValuationList[0]?.currencyValues || {}).map((currency) => (
              <th className='text-end' key={currency}>{currency}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {stockValuationList.map((item) => (
            <tr key={item.itemId}>
              <td>{item.itemName}</td>
              <td>{item.warehouseName}</td>
              <td>{GetMyMethod(item.valuationMethod)}</td>
              <td className='text-end'>{item.availableQuantity.toFixed(2)} </td>
              <td>{item.unitShortCode}</td>
              {Object.entries(item.currencyValues).map(([currency, value]) => (
                <td key={currency} className='text-end'>
                  {value.toFixed(2)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StockValuationTable;
