import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ItemUnitsList from '../../Blocks/ItemUnitsList'

const UnitsList = () => {
  return (
    <>
     <InventoryHeader />
     <div className='p-3'>
        <ItemUnitsList QuckSetupMode={false}/>
      </div>
    </>
  )
}

export default UnitsList
