import React from 'react'
import InvSideMenu from '../../Components/InvSideMenu'
import InventoryHeader from '../../Blocks/InventoryHeader'
import PurchaseRegisterReportBlock from '../../Blocks/Reports/PurchaseRegisterReportBlock'

const PurchaseRegisterReportPage = () => {
  return (
    <div>
    <InventoryHeader/>
    <div className=' g-0'>
        <div className='w-200 Sidebar'>
            <InvSideMenu/>
        </div>
        <div className='ReportArea'>
            <PurchaseRegisterReportBlock/>
        </div>
    </div>
        
</div>
  )
}

export default PurchaseRegisterReportPage