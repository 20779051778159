import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const AddRFQ = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [Items, setItems] = useState([]); 
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const navigate = useNavigate();
    const [state, setState] = useState ({
        RFQName: "",
        RFQNo : "",
        RFQDate : "",
        CompanyId : Company.id,
        Heading : "To Whomsoever it may concern",
        Subject : "Request for quotation",
        WelcomeText : "",
        TermsandConditions : "",
        SigningAuthority : "",
        RFQItems : [{
            ItemId: "",
            SpecialRemarks: "",
            Qty: "",
            UnitId: "",
            UnitName: "",
            ItemIndex: "",
           
        }]

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
    const handleWlcomeEditorChange = (event) => {
        setState((prevProps) => ({
          ...prevProps,
          "WelcomeText": event.htmlValue,
        }));
      };
    const handleTndCChange = (event) => {
        setState((prevProps) => ({
          ...prevProps,
          "TermsandConditions": event.htmlValue,
        }));
      };

      
      const handleInputL2Change = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.RFQItems]
        if(name === "ItemId"){
            let Myitem = Items.find(x => x.id === value);
            values[index]["UnitName"] = Myitem.invItemUnit.unitName;
            values[index]["UnitId"] = Myitem.invItemUnit.id;
        }
        
        values[index][name] = value;
        values[index]["ItemIndex"] = index;
        setState((prevProps) => ({
            ...prevProps,
            RFQItems: values
          }));
      };

      const handleAddMoreItem = (index) => {
        const values = [...state.RFQItems, ]
        values.splice(index + 1, 0, {"ItemId": "", "SpecialRemarks": "", "Qty": "", "UnitId": "", "UnitName": "", "ItemIndex": "",} )
        setState((prevProps) => ({
            ...prevProps,
            RFQItems: values
          }));
      }

      const handleremoveitem = (index) => {
             
        const values = [...state.RFQItems]
        if(values.length > 1){
            values.splice(index, 1)
            setState((prevProps) => ({
                ...prevProps,
                RFQItems: values
              }));    
        }
      }
      
   
        const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/ListItemMasters?' + new URLSearchParams({
            CompanyId: Company && Company.id,
            }), {
            method: 'Get',
            headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer ' + tokenString
            },
          })
          .then(res => {
              
              if(res.status === 401){ navigate("/login"); }
              return res.json()
          } ))
        .then(data => {
            console.log(data)
            setItems(data.itemMasterDatas);
        })
        .catch((err) => {
        console.log(err.message);
        })

        useEffect (() => {   
            calltpAPI();
        }, []);
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        let MyRes = await  POSTAPICallAsync("CreateRFQ", state);
        console.log(MyRes);
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            if (MyData.success == true ){
                setMessage(MyData.message);
                setShowForm(false);
            }
            else{
                setMessage(MyData.message);
            }
            
        }
        
        };
  return (
    <>
    
       {!ShowForm ? <div className='col'>  
              <button onClick={() => props.setVisible(false)}>Close</button>
          </div> :
      <div className='p-5'>
        <form onSubmit={handleSubmit}>
        <div className='row'>
                <div className="col mb-3">
                    <label htmlFor="Heading" className="form-label">Document Name<span className='color-red'>*</span></label>
                    <input type="text" required name="RFQName" className="form-control" id="RFQName" value={state.RFQName} onChange={handleInputChange} placeholder="Enter document name"/>
                </div>
            </div>
            <div className='row'>
                <div className="col mb-3">
                    <label htmlFor="Heading" className="form-label">Heading<span className='color-red'>*</span></label>
                    <input type="text" required name="Heading" className="form-control" id="Heading" value={state.Heading} onChange={handleInputChange} placeholder="Enter company name"/>
                </div>
            </div>
            <div className='row'>
                <div className="col-3 mb-3">
                    <label htmlFor="RFQNo" className="form-label">RFQ No<span className='color-red'>*</span></label>
                    <input type="text" required name="RFQNo" className="form-control" id="RFQNo" value={state.RFQNo} onChange={handleInputChange} placeholder="Enter company name"/>
                </div>
                <div className="col-3 mb-3">
                    <label htmlFor="RFQDate" className="form-label">RFQ Date<span className='color-red'>*</span></label>
                    
                    <Calendar required name="RFQDate"  dateFormat="dd/mm/yy"  value={state.RFQDate} onChange={handleInputChange} showButtonBar showIcon ></Calendar>

                </div>
            </div>
            <div className='row'>
                <div className="col mb-3">
                    <label htmlFor="Subject" className="form-label">Subject<span className='color-red'>*</span></label>
                    <input type="text" required name="Subject" className="form-control" id="Subject" value={state.Subject} onChange={handleInputChange} placeholder="Enter company name"/>
                </div>
            </div>
           
            
            <div className="mb-3">
                <label htmlFor="WelcomeText" className="form-label">Welcome Text<span className='color-red'>*</span></label>
                <Editor value={state.WelcomeText} onTextChange={handleWlcomeEditorChange} style={{ height: '100px' }} />
            </div>
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th> Select Item</th>
                        <th colSpan={2}> Qty</th>
                        <th> Remarks</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
            {state.RFQItems.map((Item, index) => (
                <tr key={index}>
                    <td>
                        <select name="ItemId" className="form-select" value={Item.ItemId} onChange={event => handleInputL2Change(index, event)} aria-label="Default select example">
                            <option>Select Any</option>
                            {Items.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.name}</option>))}
                        </select>
                    </td>
                    <td>
                        <input type="text"  name="Qty" className="form-control" id="Qty" value={Item.Qty} onChange={event => handleInputL2Change(index, event)} placeholder="Quantity"/>  
                    </td>
                    <td> <span>{Item.UnitName}</span></td>
                    <td>
                        <input type="text"  name="SpecialRemarks" className="form-control" id="SpecialRemarks" value={Item.SpecialRemarks} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Local Address"/>
                    </td>
                    <td>
                        <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index)}></i>
                        <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
                    </td>
                </tr>

            ))}
            {/* <tr>
                <td colSpan={3}>
                    <button className='btn btn-primary' onClick={() => AddMoreItem()}>Add</button>
                </td>
            </tr> */}
            </tbody>
            </table>

            <div className="mb-3">
                <label htmlFor="TermsandConditions" className="form-label">Terms and Conditions<span className='color-red'>*</span></label>
                <Editor value={state.TermsandConditions} onTextChange={handleTndCChange} style={{ height: '150px' }} />
            </div>
            <div className='row'>
                <div className="col mb-3">
                    <label htmlFor="SigningAuthority" className="form-label">SignIn Authority Name<span className='color-red'>*</span></label>
                    <input type="text" required name="SigningAuthority" className="form-control" id="Subject" value={state.SigningAuthority} onChange={handleInputChange} placeholder="Enter Signin Authority Name"/>
                </div>
            </div>
            
            

            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>}
    <span>{Message}</span>
    </>
  )
}

export default AddRFQ
