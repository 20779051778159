import React from 'react'
import ProdOrderDetailBlock from '../../Blocks/ProdOrderDetailBlock'
import InventoryHeader from '../../Blocks/InventoryHeader'

const ProdOrderDetailsPage = () => {
  return (
    <div>
    <InventoryHeader/>
      <div className='p-3'>
        <ProdOrderDetailBlock/>
      </div>
  </div>
  )
}

export default ProdOrderDetailsPage