import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Home from '../Pages/Public/Home'
import Signup from '../Pages/Public/Signup';
import Login from '../Pages/Public/Login';
import Dashboard from '../Pages/Inventory/Dashboard';
import Company from '../Pages/Setup/Company';
import AddNewCompany from '../Pages/Setup/AddNewCompany';
import CompanyDetails from '../Pages/Setup/CompanyDetails';
import BankAccounts from '../Pages/Setup/BankAccounts';
import Warehouses from '../Pages/Setup/Warehouses';
import ItemMasterList from '../Pages/Setup/ItemMasterList';
import ItemCategory from '../Pages/Setup/ItemCategory';
import DynamicForms from '../Pages/Setup/DynamicForms';
import ManageFields from '../Pages/Setup/ManageFields';
import UnitsList from '../Pages/Setup/UnitsList';
import Rfq from '../Pages/Purchase/Rfq';
import PurchaseOrder from '../Pages/Purchase/PurchaseOrder';
import Suppliers from '../Pages/Setup/Suppliers';
import AddPurchaseOrder from '../Pages/Purchase/AddPurchaseOrder';
import Taxes from '../Pages/Setup/Taxes';
import Receivings from '../Pages/Purchase/Receivings';
import ReceiveItems from '../Pages/Purchase/ReceiveItems';
import ExcelFileReader from '../ServiceLayer/ExcelFileReader';
import ExcelDownload from '../ServiceLayer/ExcelDownload';
import Currencies from '../Pages/Setup/Currencies';
import Stock from '../Pages/Inventory/Stock';
import Apinvoice from '../Pages/Purchase/Apinvoice';
import Addapinvoice from '../Pages/Purchase/Addapinvoice';
import Dealers from '../Pages/Sales/Dealers';
import SalesOrders from '../Pages/Sales/SalesOrders';
import ARInvoices from '../Pages/Sales/ARinvoice';
import AddSalesOrder from '../Pages/Sales/AddSalesOrder';
import Addarinvoice from '../Pages/Sales/Addarinvoice';
import StockTransferOrder from '../Pages/Inventory/StockTransferOrder';
import AddSTOrder from '../Pages/Inventory/AddSTOrder';
import WarehouseLocations from '../Pages/Setup/WarehouseLocations';
import TransferRequests from '../Pages/Warehouse/TransferRequests';
import Warehousedetails from '../Pages/Setup/Warehousedetails';
import UsersList from '../Pages/Setup/UsersList';
import STODetails from '../Pages/Warehouse/STODetails';
import FindItem from '../Pages/Inventory/FindItem';
import BulkUpload from '../Pages/Setup/Bulkupload';
import BOMPage from '../Pages/Production/BOMPage';
import AddBOMPage from '../Pages/Production/AddBOMPage';
import ProductionOrderPage from '../Pages/Production/ProductionOrderPage';
import AddProdOrderPage from '../Pages/Production/AddProdOrderPage';
import ProdOrderDetailsPage from '../Pages/Production/ProdOrderDetailsPage';
import StockIssuePage from '../Pages/Inventory/StockIssuePage';
import StockIssueNewReqPage from '../Pages/Inventory/StockIssueNewReqPage';
import SubmitStockNewReqPage from '../Pages/Inventory/SubmitStockNewReqPage';
import SubmitStockReqPage from '../Pages/Inventory/SubmitStockReqPage';
import IssueRequests from '../Pages/Warehouse/IssueRequests';
import SIReqDetailPage from '../Pages/Warehouse/SIReqDetailPage';
import SubmitStockDetailPage from '../Pages/Warehouse/SubmitStockDetailPage';
import StockAdjustmentPage from '../Pages/Inventory/StockAdjustmentPage';
import StockAdjNewReqPage from '../Pages/Inventory/StockAdjNewReqPage';
import SAdjReqDetailPage from '../Pages/Warehouse/SAdjReqDetailPage';
import ProfilesPage from '../Pages/Setup/ProfilesPage';
import PermissionsPage from '../Pages/Setup/PermissionsPage';
import BillingDashboard from '../Pages/Billing/BillingDashboard';
import VerifyOTPPage from '../Pages/Setup/VerifyOTPPage';
import QuickSetupPage from '../Pages/Setup/QuickSetupPage';
import SupplierDetail from '../Pages/Purchase/SupplierDetail';
import APInvoiceDetailPage from '../Pages/Purchase/APInvoiceDetailPage';
import PurchaseOrderDetailPage from '../Pages/Purchase/PurchaseOrderDetailPage';
import ReceivingDetailPage from '../Pages/Purchase/ReceivingDetailPage';
import DealerDetailPage from '../Pages/Sales/DealerDetailPage';
import SalesOrderDetailPage from '../Pages/Sales/SalesOrderDetailPage';
import ItemtoReorderPage from '../Pages/Inventory/ItemtoReorderPage';
import ApprovalSetupPage from '../Pages/Setup/ApprovalSetupPage';
import ApprovalProcessPage from '../Pages/Setup/ApprovalProcessPage';
import ApprovalListPage from '../Pages/Others/ApprovalListPage';
import EditCompanyPage from '../Pages/Setup/EditCompanyPage';
import ProtectedRoutes from './ProtectedRoutes';
import ReportSnapshot from '../Pages/Reports/ReportSnapshot';
import PurchaseRegisterReportPage from '../Pages/Reports/PurchaseRegisterReportPage';
import ItemisedPurchaseBillsPage from '../Pages/Reports/ItemisedPurchaseBillsPage';
import PurchaseSnapshotReportPage from '../Pages/Reports/PurchaseSnapshotReportPage';
import StockSubmitRequest from '../Pages/Warehouse/StockSubmitRequest';
import StockAdjustmentRequests from '../Pages/Warehouse/StockAdjustmentRequests';

const RouteList = () => {
  return (
    <Routes>
        <Route exact path='/inventory/dashboard' element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/stocktransferorders' element={<ProtectedRoutes><StockTransferOrder /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/addstocktransferorder' element={<ProtectedRoutes><AddSTOrder /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/finditem' element={<ProtectedRoutes><FindItem /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/issuerequests' element={<ProtectedRoutes><StockIssuePage /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/newissuerequest' element={<ProtectedRoutes><StockIssueNewReqPage /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/submitstockrequest' element={<ProtectedRoutes><SubmitStockReqPage /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/submitstocknewrequest' element={<ProtectedRoutes><SubmitStockNewReqPage /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/stockadjustments' element={<ProtectedRoutes><StockAdjustmentPage /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/stockadjustmentnewrequest' element={<ProtectedRoutes><StockAdjNewReqPage /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/itemstoreorder' element={<ProtectedRoutes><ItemtoReorderPage /></ProtectedRoutes>}></Route>
        <Route exact path='/inventory/stock' element={<ProtectedRoutes><Stock/></ProtectedRoutes>}></Route>

        <Route exact path='/warehouse/transferrequests' element={<ProtectedRoutes><TransferRequests /></ProtectedRoutes>}></Route>
        <Route exact path='/warehouse/issuerequests' element={<ProtectedRoutes><IssueRequests /></ProtectedRoutes>}></Route>
        <Route exact path='/warehouse/submitstockrequest' element={<ProtectedRoutes><StockSubmitRequest /></ProtectedRoutes>}></Route>
        <Route exact path='/warehouse/stockadjustments' element={<ProtectedRoutes><StockAdjustmentRequests /></ProtectedRoutes>}></Route>
        <Route exact path='/warehouse/stodetails/:Id' element={<ProtectedRoutes><STODetails /></ProtectedRoutes>}></Route>
        <Route exact path='/warehouse/stockissuereqdetail/:Id' element={<ProtectedRoutes><SIReqDetailPage /></ProtectedRoutes>}></Route>
        <Route exact path='/warehouse/submitstockreqdetail/:Id' element={<ProtectedRoutes><SubmitStockDetailPage /></ProtectedRoutes>}></Route>
        <Route exact path='/warehouse/stockadjustmentredetail/:Id' element={<ProtectedRoutes><SAdjReqDetailPage /></ProtectedRoutes>}></Route>

        <Route exact path='/setup/bankaccounts' element={<ProtectedRoutes><BankAccounts /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/warehouses' element={<ProtectedRoutes><Warehouses /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/itemmaster' element={<ProtectedRoutes><ItemMasterList /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/itemcategory' element={<ProtectedRoutes><ItemCategory/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/itemcategory/:PCatId' element={<ProtectedRoutes><ItemCategory/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/managefields/:Id' element={<ProtectedRoutes><ManageFields/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/companies' element={<ProtectedRoutes><Company /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/companies/addnew' element={<ProtectedRoutes><AddNewCompany /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/company/Details' element={<ProtectedRoutes><CompanyDetails/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/editcompany/:Id' element={<ProtectedRoutes><EditCompanyPage/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/DynamicForms' element={<ProtectedRoutes><DynamicForms/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/itemunits' element={<ProtectedRoutes><UnitsList/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/taxes' element={<ProtectedRoutes><Taxes/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/warehouselocations/:Id' element={<ProtectedRoutes><WarehouseLocations /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/warehousedetails/:Id' element={<ProtectedRoutes><Warehousedetails /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/setpermissions/:Id' element={<ProtectedRoutes><PermissionsPage /></ProtectedRoutes>}></Route>
        <Route exact path='/setup/currencies' element={<ProtectedRoutes><Currencies/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/bulkupload' element={<ProtectedRoutes><BulkUpload/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/users' element={<ProtectedRoutes><UsersList/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/profiles' element={<ProtectedRoutes><ProfilesPage/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/quicksetup' element={<ProtectedRoutes><QuickSetupPage/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/approvalprocesses' element={<ProtectedRoutes><ApprovalProcessPage/></ProtectedRoutes>}></Route>
        <Route exact path='/setup/approvalsetup/:PName' element={<ProtectedRoutes><ApprovalSetupPage/></ProtectedRoutes>}></Route>
        
        <Route exact path='/production/bom' element={<ProtectedRoutes><BOMPage/></ProtectedRoutes>}></Route>
        <Route exact path='/production/addbom' element={<ProtectedRoutes><AddBOMPage/></ProtectedRoutes>}></Route>
        <Route exact path='/production/productionorder' element={<ProtectedRoutes><ProductionOrderPage/></ProtectedRoutes>}></Route>
        <Route exact path='/production/addproductionorder' element={<ProtectedRoutes><AddProdOrderPage/></ProtectedRoutes>}></Route>
        <Route exact path='/production/productionorderdetail/:Id' element={<ProtectedRoutes><ProdOrderDetailsPage/></ProtectedRoutes>}></Route>
        
        <Route exact path='/purchase/suppliers' element={<ProtectedRoutes><Suppliers/></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/supplier/:Id' element={<ProtectedRoutes><SupplierDetail/></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/rfqs' element={<ProtectedRoutes><Rfq /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/purchaseorders' element={<ProtectedRoutes><PurchaseOrder /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/purchaseorder/:Id' element={<ProtectedRoutes><PurchaseOrderDetailPage /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/addnewpo' element={<ProtectedRoutes><AddPurchaseOrder /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/receivings' element={<ProtectedRoutes><Receivings /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/receiving/:Id' element={<ProtectedRoutes><ReceivingDetailPage /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/receiveitems' element={<ProtectedRoutes><ReceiveItems /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/uploadexcel' element={<ProtectedRoutes><ExcelFileReader /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/ExcelDownload' element={<ProtectedRoutes><ExcelDownload /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/apinvoices' element={<ProtectedRoutes><Apinvoice /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/apinvoice/:Id' element={<ProtectedRoutes><APInvoiceDetailPage /></ProtectedRoutes>}></Route>
        <Route exact path='/purchase/addapinvoice' element={<ProtectedRoutes><Addapinvoice /></ProtectedRoutes>}></Route>
        
        <Route exact path='/sales/dealers' element={<ProtectedRoutes><Dealers/></ProtectedRoutes>}></Route>
        <Route exact path='/sales/dealer/:Id' element={<ProtectedRoutes><DealerDetailPage/></ProtectedRoutes>}></Route>
        <Route exact path='/sales/salesorders' element={<ProtectedRoutes><SalesOrders/></ProtectedRoutes>}></Route>
        <Route exact path='/sales/salesorder/:Id' element={<ProtectedRoutes><SalesOrderDetailPage/></ProtectedRoutes>}></Route>
        <Route exact path='/sales/addsalesorder' element={<ProtectedRoutes><AddSalesOrder/></ProtectedRoutes>}></Route>
        <Route exact path='/sales/arinvoices' element={<ProtectedRoutes><ARInvoices/></ProtectedRoutes>}></Route>
        <Route exact path='/sales/addarinvoice' element={<ProtectedRoutes><Addarinvoice/></ProtectedRoutes>}></Route>
        
        
        <Route exact path='/reports/snapshot' element={<ProtectedRoutes><ReportSnapshot/></ProtectedRoutes>}></Route>
        <Route exact path='/reports/purchaseregister' element={<ProtectedRoutes><PurchaseRegisterReportPage/></ProtectedRoutes>}></Route>
        <Route path='/reports/itemisedpurchaseregister' element={<ProtectedRoutes><ItemisedPurchaseBillsPage/></ProtectedRoutes>}></Route>
        <Route path='/reports/purchasesnapshot' element={<ProtectedRoutes><PurchaseSnapshotReportPage/></ProtectedRoutes>}></Route>
      
        <Route exact path='/admin/billingdashboard' element={<ProtectedRoutes><BillingDashboard/></ProtectedRoutes>}></Route>
        
        <Route exact path='/completesignup/verifyotp' element={<ProtectedRoutes><VerifyOTPPage/></ProtectedRoutes>}></Route>
        <Route exact path='/others/approvalrequests' element={<ProtectedRoutes><ApprovalListPage/></ProtectedRoutes>}></Route>
        
        <Route exact path='/login' element={<Login />}></Route>
        <Route path='/signup/:InviteToken' element={<Signup />}></Route>
        <Route path='/signup' element={<Signup />}></Route>
        <Route exact path='/' element={<Home/>}></Route>
        
</Routes>
  )
}

export default RouteList
