import React from 'react'
import AddStockTransferOrder from '../Components/AddStockTransferOrder'


const AddNewSTOrder = () => {
  return (
    <>
     <div className='mb-3 row'>
        <div className='col-6'>
            <h1 className='ListMainHeading'>Add Stock Transfer Order</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
           
        </div>
        </div>
        <div className='row'>
            <AddStockTransferOrder /> 
        </div>
    </>
  )
}

export default AddNewSTOrder