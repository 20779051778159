import React from 'react'
import ApprovalListBlock from '../../Blocks/ApprovalListBlock'
import InventoryHeader from '../../Blocks/InventoryHeader'

const ApprovalListPage = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
           <ApprovalListBlock/>
     </div>
   </>
  )
}

export default ApprovalListPage