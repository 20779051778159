import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import moment from 'moment';

const ListFromTransferRequests = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [OTRList, setOTRList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const [AppliedFilters, setAppliedFilters] = useState([]);
    const [filterHeight, setfilterHeight] = useState("0px");
    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
          org_name: { value: '', matchMode: 'contains' },
  
      }
  });
  const [Filters, setFilters] = useState({
    PONO: "",
    PODate: "",
    DocumentName: "",
    Supplier: "",
    status: "all"

});
  
  const items = [
    {
      label: 'Details',
      icon: 'pi pi-refresh',
      url: "/warehouse/stodetails/" + selectedRow.id + ""
  },
  {
      label: 'Edit',
      icon: 'pi pi-times',
      command: (row) => {
        console.log(row);
          toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
      }
  },
  {
    label: 'React Website',
    icon: 'pi pi-external-link',
    url: 'https://reactjs.org/'
  },
  ]; 
    
  
    useEffect(() => {
      loadLazyData();
    }, [lazyState, visible, props.WHID]);
    useEffect (() => {
      loadLazyData();
    }, [])
  
  
    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }
  
      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        var MyRes = await GetAPICallAsync("GetListOutTransferRequests", { lazyEvent: lazyState }, {Filters: JSON.stringify(Filters), WHId : props.WHID});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setOTRList(MyData.data);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };
  
  const onSort = (event) => {
      setlazyState(event);
  };
  
  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };
  
  const onSelectionChange = (event) => {
      const value = event.value;
  
      setSelectedCustomers(value);
      setSelectAll(value.length === totalRecords);
  };
  
  const onSelectAllChange = async (event) => {
      const selectAll = event.checked;
  
      if (selectAll) {
        var MyRes = await GetAPICallAsync("GetPOs", { lazyEvent: lazyState }, "");
              setSelectAll(true);
              setOTRList(MyRes.data);
        
      } else {
          setSelectAll(false);
          setSelectedCustomers([]);
      }
  };

  const handleFiltersChange = (event) => {
    let { name, value } = event.target;
    setFilters((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  
  const handleFilterSubmit = async (event) => {
    event.preventDefault();
    ManageAppliedFilters(Filters);
    FilterSubmitMethod(Filters);
  }
  
  const ManageAppliedFilters = (AppFilters) => {
      let MyFilters = [];
      if(AppFilters.PONO !== null && AppFilters.PONO !== ""){
        MyFilters = [...MyFilters, {name: "PONO", Filter: "PO No", Value: AppFilters.PONO}] 
      }
      if (Filters.PODate !== null && Filters.PODate !== ""){
        console.log(Filters.PODate);
        let FromDate = moment(Filters.PODate[0]).format("DD/MM/yyyy");
        let ToDate = new moment(Filters.PODate[1]).format("DD/MM/yyyy");
        MyFilters = [...MyFilters, {name: "PODate", Filter: "PO Date", Value: FromDate + "-" + ToDate}] 
      }
      if(AppFilters.Supplier !== null && AppFilters.Supplier !== ""){
        // let MySupplier= Suppliers.find(x => x.id == AppFilters.Supplier);
        // MyFilters = [...MyFilters, {name: "Supplier", Filter: "Supplier", Value: MySupplier.supName}] 
      }
      if(AppFilters.DocumentName !== null && AppFilters.DocumentName !== ""){
        MyFilters = [...MyFilters, {name: "DocumentName", Filter: "Document Name", Value: AppFilters.DocumentName}] 
      }
      setAppliedFilters(MyFilters);
  }
  
  const FilterSubmitMethod = async (AppFilters) => {
    var MyRes = await GetAPICallAsync("GetPOs", { lazyEvent: lazyState }, {Filters: AppFilters});
    if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
    }
    else{
      var MyData = await MyRes.json();
      console.log(MyData);
      setTotalRecords(MyData.total);
      setOTRList(MyData.data);
      setfilterHeight("0px")
    }
  
  }
  
  
  const RemoveFilter = (item, index) => {
    let MyAppliedFilter = [...AppliedFilters];
    MyAppliedFilter.splice(index, 1)
    setAppliedFilters(MyAppliedFilter);
    const name = item.name;
    let MyFilterz = {...Filters, [name] : ""}
    console.log(MyFilterz);
    setFilters((prevProps) => ({
      ...prevProps,
      [name] : "",
    }) )
    FilterSubmitMethod(MyFilterz);
  }
  
  const Reset = () => {
    setFilters({
      PONO: "",
      PODate: "",
      DocumentName: "",
      Supplier: "",
  })
  }
  const DateTemplate = (prop) => {
    return moment(prop.stoDate).format("DD/MM/yyyy");
  }
  
    const ActionTemplate = (row) => {
       return<>
       <div className="flex justify-content-center">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
            {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
       </div>
       </> 
    }
  return (
    <div>
    <div className=' row'>
    <div className='col-6'>
    <h1 className='ListMainHeading'>Out Transfer Requests</h1>
    </div>
    <div className='col-6 d-flex justify-content-end text-end'>
    <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
   <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight === "0px" ? "200px" : "0px")}></i>
    
    <Link className='btn btn-outline-primary' to="/inventory/addstocktransferorder"><i className="pi pi-plus"></i> Add New</Link>
    </div>
    </div>
    <div className='row'>
    <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
    </div>
    {AppliedFilters.length > 0 ? <div className='row mb-3'>
      <div className='col-12'>
        {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter}: {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}} onClick={() => RemoveFilter(item, index) }></i></span>))}
      </div>
    </div>: ""}
    <div className='filterContainer' style={{ height: filterHeight, transition: "height 1s"}}>
    <form onSubmit={handleFilterSubmit} style={{height: filterHeight, transition: "height 1s", overflow: "hidden" }}> 
      <div class="row g-2">
        <h2 className='FiltersHeading'>Filters</h2>
        <div class="col-3 mb-3">
          <label htmlFor="PONO" class="form-label">PO No</label>
          <input type="text" className="form-control" name="PONO" id="PONO" value={Filters.PONO} onChange={handleFiltersChange} placeholder="Purchase order number."/>
        </div>
        <div class="col-3 mb-3">
          <label htmlFor="BusinessType" class="form-label">Supplier</label>
          <select name="Supplier" className="form-select" value={Filters.Supplier} onChange={handleFiltersChange} aria-label="">
                <option value={""}>Select Any</option>
                {/* {Suppliers.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.supName}</option>))} */}
            </select>
        </div>
        <div class="col-3 mb-3">
          <label htmlFor="DocumentName" class="form-label">Document Name</label>
          <input type="text" className="form-control" name="DocumentName" id="DocumentName" value={Filters.DocumentName} onChange={handleFiltersChange} placeholder="Document Name"/>
        </div>
        <div class="col-3 mb-3">
          <label htmlFor="PODate" class="form-label">PO Date</label>
          <Calendar name="PODate" id="PODate"  dateFormat="dd/mm/yy" className="form-control custdate" selectionMode="range"  value={Filters.PODate} onChange={handleFiltersChange} showButtonBar showIcon placeholder='Select Date Range' ></Calendar>
        </div>              
        </div>
        <div className='row'>
          <div className='col-12'>
            <button type='submit' className='btn btn-outline-primary mt-4'>Apply <i className="pi pi-angle-double-right"></i> </button>
            <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => Reset()}>Reset <i className="pi pi-undo"></i> </button>
            <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => setfilterHeight("0px")}>Cancel <i className="pi pi-times"></i></button>
            
            </div>
        </div>
      </form>
    </div>
    <div className='row'>
        <DataTable value={OTRList} lazy dataKey="id" paginator removableSort size='small' showGridlines  resizableColumns columnResizeMode="expand"
                first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage} selectionMode="checkbox"
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column field="stoDate" header="STO Date" body={DateTemplate} sortable></Column>
        <Column field="stoName" header="Document Name" sortable />
        
        <Column field="toWarehouse.warehouseName" header="To Warehouse"></Column>
        <Column field="status" header="Status" ></Column>
        <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
        </DataTable>
    </div>
    </div>
  )
}

export default ListFromTransferRequests