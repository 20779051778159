import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';

const SignupForm = () => {
    const navigate = useNavigate();
    const [ErrorList, setErrorList] = useState([]);
    const Params = useParams(); 
    const [visible, setVisible] = useState(false);
    const [Message, setMessage] = useState(false);
    const [state, setState] = useState ({
        EmailId:"",
        yourname : "",
        password: "",
        countryCode:"",
        Mobile: "",
        InviteToken: ""
    });


    const CheckInvite = async () => {
        const { InviteToken } = Params;
        if(InviteToken){

            fetch(process.env.REACT_APP_API_URL + 'auth/InviteCheck?' + new URLSearchParams({
                InviteToken :  InviteToken}), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                },
            })
            .then(res => {
                if(res.status === 401){ 
                    localStorage.removeItem("User_Obj");
                    localStorage.removeItem("Company");
                    localStorage.removeItem("token");
                    navigate("/login");
                 }
                return res.json()
            } )
            .then(data => {
                console.log(data);
                if(data.success){
                    sessionStorage.setItem('inviteToken', InviteToken);
                    sessionStorage.setItem('inviteEmail', data.data.emailId);
                    setState((prevProps) => ({
                        ...prevProps,
                        InviteToken : InviteToken,
                        EmailId : data.data.emailId
                    }))
                    console.log(data.data.emailId);
                }
                else{
                    setMessage(data.message)
                    setVisible(true);
                }
            })
            .catch((err) => {
               console.log(err.message);
            })

        // 
        }
        else
        {
            const token = sessionStorage.getItem('inviteToken');
            const Email = sessionStorage.getItem('inviteEmail');
            if(token !== "" && token !== null && Email !== "" && Email !== null ){
                setState((prevProps) => ({
                    ...prevProps,
                    InviteToken : token,
                    EmailId : Email
                }))
            }
            else{

            }
        }
    }
    const footerContent = (
        <button className='btn btn-primary' icon="pi pi-check" onClick={() => setVisible(false)} autoFocus>Close</button>
    );

    useEffect(() => {
        CheckInvite();
    }, [])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
      const handleSubmit = (event) => {
        event.preventDefault();
        console.log(state);
        fetch(process.env.REACT_APP_API_URL + 'Auth/register', {
         method: 'POST',
         body: JSON.stringify(state),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
         },
      })
         .then((res) => res.json())
         .then((data) => {

            console.log(data);
            if(data.succeed){
                // localStorage.setItem("User_Obj", JSON.stringify(data.user) );
                localStorage.setItem("token", JSON.stringify(data.token) );
                navigate("/completesignup/verifyotp");
            }
            else{
                let values = [...ErrorList];
                data.errors.map((item) => {
                    values = [...values, item.errorMessage ]
                })
                setErrorList(values);                
            }


         })
         .catch((err) => {
            console.log(err.message);
         });
      };
      const handlePhone = (value, data) => {
        setState((prevProps) => ({
            ...prevProps,
            countryCode: data.dialCode,
            Mobile: value,
          }));
      };
  return (
    <div className='ps-lg-5'>
        {ErrorList.length > 0 && 
            <ul>
                {ErrorList.map((item, index) => (
                    <li className='Error' key={index}>{item}</li>
                ))}
                
            </ul>
        }
        <form onSubmit={handleSubmit}>
        <h1 className='MainHeading mb-2'>Signup</h1>
            <div className="mb-3">
                <label htmlFor="Email" className="form-label">Your name</label>
                <input type="text" required name="yourname" className="form-control" id="YourName" value={state.yourname} onChange={handleInputChange} placeholder="Enter your name"/>
                {/* <small id="emailHelp" className"form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div className='row'>
                <div className="col-12 mb-3">
                    <label htmlFor="Email" className="form-label">Mobile</label>
                    <PhoneInput inputProps={{required: true}} country={'in'} inputClass='inputClass' name="Mobile" value={state.Mobile} onChange={handlePhone} placeholder="Enter mobile with country code"/>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="Email" className="form-label">Email address</label>
                <input required disabled={state.InviteToken !== ""? true : false} type="email" className="form-control" id="EmailId" name="EmailId" value={state.EmailId} onChange={handleInputChange}  placeholder="Enter email"/>
                {/* <small id="emailHelp" className"form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div className="mb-3">
                <label htmlFor="" className="form-label">Password</label>
                <input required type="password" className="form-control" name="password" value={state.password} onChange={handleInputChange}  id="Password" placeholder="Password"/>
            </div>
            <div className="form-check">
                <input required type="checkbox" className="form-check-input" id="exampleCheck1"/>
                <label className="form-check-label" htmlFor="exampleCheck1">Agree to company policies.</label>
            </div>
            <div className='mt-4 mb-3'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
        <Dialog
                header="Token Error"
                visible={visible}
                style={{ width: '400px' }}
                footer={footerContent}
                onHide={() => setVisible(false)}
            >
                <p>{Message}</p>
            </Dialog>
    </div>
  )
}

export default SignupForm
