const PermissionGroups =  {
    PURSHASE: 'gJ7x2hF4',
    INVENTORY: 'a8F6zK1q',
    WAREHOUSE: 'bR2yT4mL',
    PRODUCTION: 'qW3p9N5j',
    SALES: 'xE7rY2sA',
    REPORTS: 'jL8kD5hZ',
    SETUP	:'vQ1tB8xC'
 
}
export default PermissionGroups
