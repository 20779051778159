import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const AddSupplier = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Cities, setCitiess] = useState([]);
    const [state, setState] = useState ({
        CompanyId: Company.id,
        SupName : "",
        BusinessType: "",
        ContactPersonName: "",
        ContactDesignation: "",
        Email: "",
        phone: "",
        GST : "",
        TAN : "",
        PAN : "", 
        SupplierAddresses: [{
            AddressName: "",
            AddressType : "register",
            StreetAddress : "",
            CityId : "",
            StateId : "",
            CountryId : "",
            ZipCode : "",
            ContactPersonName : "",
            ContactPhone : "",
            Email: ""
        }]

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
      const handleInputL2Change = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.SupplierAddresses]
        values[index][name] = value;
        setState((prevProps) => ({
            ...prevProps,
            SupplierAddresses: values
          }));
      };
      const handlePhone = (index, phone) => {
        const values = [...state.SupplierAddresses]
        values[index]["PhoneNumber"] = phone;
        console.log(values);
        setState((prevProps) => ({
            ...prevProps,
            SupplierAddresses: values
          }));
      };
      const handlePhoneL1 = (phone) => {
        setState((prevProps) => ({
            ...prevProps,
            phone: phone
          }));
      };
      const UpdateStates = (index, event) => {
        handleInputL2Change(index, event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/states?' + new URLSearchParams({
                CountryId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                setStates(data.lstStates);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      };
      const UpdateCities= (index, event) => {
        handleInputL2Change(index, event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/Cities?' + new URLSearchParams({
                StateId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                console.log(data);
                setCitiess(data.lstCities);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      }

      useEffect (() => {
            const calltpAPI = () => fetch(process.env.REACT_APP_API_URL + 'setup/countries', {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                setCountries(data.lstCountries);
            })
            .catch((err) => {
               console.log(err.message);
            })
            calltpAPI();
        });
      
      const handleSubmit = async (event) => {
            event.preventDefault();
            console.log(state);
            let MyRes = await  POSTAPICallAsync("CreateSupplier", state);
            console.log(MyRes);
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                var MyData = await MyRes.json();
                console.log(MyData);
                if (MyData.success == true ){
                    setMessage(MyData.message);
                    setShowForm(false);
                }
                else{
                    setMessage(MyData.message);
                }
                
            }
        };
  return (
    <>
    {!ShowForm ? <div className='col'>  
    <button onClick={() => props.setVisible({"open": false})}>Close</button>
</div> :
    <div className=''>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Supplier Name<span className='color-red'>*</span></label>
                <input type="text" required name="SupName" className="form-control" id="SupName" value={state.SupName} onChange={handleInputChange} placeholder="Enter Supplier name"/>
                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div className='row mb-3'>
                <div className="col-4">
                    <label htmlFor="BusinessType" className="form-label">Business Type<span className='color-red'>*</span></label>
                    <select name="BusinessType" required className="form-select" value={state.BusinessType} onChange={handleInputChange} aria-label="">
                        <option>Select Any</option>
                        <option value="manufacturer">Manufacturer</option>
                        <option value="trader">Trader</option>
                        <option value="wholesaler">Wholesaler</option>
                        <option value="retailer">Retailer</option>
                    </select>
                </div>
              
                <div className="col-4 mb-3">
                    <label htmlFor="ContactPersonName" className="form-label">Business Contact Person<span className='color-red'>*</span></label>
                    <input type="text" required  name="ContactPersonName" className="form-control" id="ContactPersonName" value={state.ContactPersonName} onChange={handleInputChange} placeholder="Enter Person Name"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
     
                <div className="col-4 mb-3">
                    <label htmlFor="ContactDesignation" className="form-label">Designation</label>
                    <input type="text"  name="ContactDesignation" className="form-control" id="ContactDesignation" value={state.ContactDesignation} onChange={handleInputChange} placeholder="Enter Designation"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="Email" className="form-label">Business Email<span className='color-red'>*</span></label>
                    <input type="email" required  name="Email" className="form-control" id="Email" value={state.Email} onChange={handleInputChange} placeholder="Enter Email"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="phone" className="form-label">Business Phone<span className='color-red'>*</span></label>
                    <PhoneInput inputProps={{required: true}} country={'in'} inputClass='inputClass' name="phone" value={state.phone} onChange={Phone => handlePhoneL1(Phone)} placeholder="Enter mobile with country code"/>
                </div>
    
                <div className="col-4 mb-3">
                    <label htmlFor="GST" className="form-label">GST</label>
                    <input type="text"  name="GST" className="form-control" id="GST" value={state.GST} onChange={handleInputChange} placeholder="Enter GST Number"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="TAN" className="form-label">TAN</label>
                    <input type="text"  name="TAN" className="form-control" id="TAN" value={state.TAN} onChange={handleInputChange} placeholder="Enter TAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
     
                <div className="col-4 mb-3">
                    <label htmlFor="PAN" className="form-label">PAN</label>
                    <input type="text"  name="PAN" className="form-control" id="PAN" value={state.PAN} onChange={handleInputChange} placeholder="Enter PAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>

            </div>
            <div className='row'>
                <div className='col-12 formheadingCont'>
                    <h2 className='formheading'>Address</h2>
                </div>
            </div>
            {state.SupplierAddresses.map((address, index) => (
                <div key={index}>
                    <div className='row'>
                    <div className="col-4 mb-3">
                            <label htmlFor="AddressName" className="form-label">Address Name<span className='color-red'>*</span></label>
                            <input type="text" required  name="AddressName" className="form-control" id="AddressName" value={address.AddressName} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Address Name"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="AddressType" className="form-label">Address Type<span className='color-red'>*</span></label>
                            <select disabled name="AddressType" required className="form-select" value={address.AddressType} onChange={event => handleInputL2Change(index, event)} aria-label="Default select example">
                                <option>Select Any</option>
                                <option value="register">Register Address</option>
                                <option value="headoffice">Head Office</option>
                                <option value="branchoffice">Branch Office</option>
                            </select>
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="ZipCode" className="form-label">Pin code<span className='color-red'>*</span></label>
                            <input type="text" required  name="ZipCode" className="form-control" id="ZipCode" value={address.ZipCode} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Pincode"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-4 mb-3">
                            <label htmlFor="CountryId" className="form-label">Country<span className='color-red'>*</span></label>
                            <select name="CountryId" required className="form-select" value={address.CountryId} onChange={event => UpdateStates(index, event)} >
                                <option value="">Select Country</option>
                                {Countries.map((item) => (<option key={item.id} value={item.id}>{item.countryName}</option>))}
                            </select>
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="StateId" className="form-label">States<span className='color-red'>*</span></label>
                            <select name="StateId" required id="StateId" className="form-select" value={address.StateId} onChange={event => UpdateCities(index, event)}>
                                <option value="">Select State</option>
                                {States.map((item) => (<option key={item.id} value={item.id}>{item.stateName}</option>))}
                            </select>
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="CityId" className="form-label">City<span className='color-red'>*</span></label>
                            <select name="CityId" required id="CityId" className="form-select" value={address.CityId} onChange={event => handleInputL2Change(index, event)}>
                                <option value="">Select City</option>
                                {Cities.map((item) => (<option key={item.id} value={item.id}>{item.cityName}</option>))}
                            </select>
                        </div>
                 
                        <div className="col-12 mb-3">
                            <label htmlFor="StreetAddress" className="form-label">Local Address<span className='color-red'>*</span></label>
                            <input type="text" required  name="StreetAddress" className="form-control" id="StreetAddress" value={address.StreetAddress} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Local Address"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        
                     
                    </div>
            </div>

            ))}

            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>
    }
    <span>{Message}</span>
    </>
  )
}

export default AddSupplier
