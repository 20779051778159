import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const CompanyList = () => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const navigate = useNavigate();
    const [Companies, setCompanies] = useState([]);
    const [ACompanies, setACompanies] = useState([]);
    const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/CompaniesList', {
        method: 'Get',
        headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + tokenString
        },
    })
    .then(res => {
        if(res.status === 401){ 
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login"); }
        return res.json()
    } )
    .then(data => {
       setCompanies(data.data.myComp);
       setACompanies(data.data.associatedComp);
    })
    .catch((err) => {
       console.log(err.message);
    }))
    useEffect (() => {
        calltpAPI();
    }, []);

    const LinkTemplate = (props) => {
        return <btn className='tablelink' onClick={() => CompanyDetailsbtn(props)}>{props.companyName}</btn>
    }
    const CompanyDetailsbtn = async (Company) => {
        localStorage.setItem("Company", JSON.stringify(Company) );
            var MyRes = await GetAPICallAsync("GetPermissionbyCompanyId", "", "");
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                var MyData = await MyRes.json();
                let Mypermissions = [];
                console.log(MyData);
                MyData.data.forEach(x => {
                    x.permissions.forEach( a => {
                        Mypermissions = [...Mypermissions, a.slug]
                    })

                });
                localStorage.setItem("gsxkjm", JSON.stringify( MyData.data.map(x => x.slug)) );
                localStorage.setItem("psxkjm", JSON.stringify(Mypermissions ))
            }
        if(Company.setupCompleted){
            navigate('/inventory/dashboard')
        }
        else{
            navigate('/setup/quicksetup')
        }
        
    }

    const ActionTemplate = (Company) => {
        return (
        <>
        <i className='pi pi-sign-in ActionIcon' data-toggle="tooltip" title='Get In' onClick={() => CompanyDetailsbtn(Company)}></i>
        <i className="pi pi-pencil ms-3 ActionIcon" data-toggle="tooltip" title='Edit' onClick={() => navigate('/setup/editcompany/'+ Company.id)}></i>
        </>
        )
    }
    const ActionTemplate1 = (Company) => {
        return (
        <>
        <i className='pi pi-sign-in ActionIcon' data-toggle="tooltip" title='Get In' onClick={() => CompanyDetailsbtn(Company)}></i>
        </>
        )
    }
  return (
    <>
     <DataTable value={Companies} tableStyle={{ minWidth: '50rem' }} size='small' showGridlines  resizableColumns columnResizeMode="expand">
        <Column field="companyName" header="Name" body={LinkTemplate}></Column>
        <Column field="gst" header="GST"></Column>
        <Column field="pan" header="PAN"></Column>
        <Column field="cin" header="CIN"></Column>
        <Column field='' header="Action" body={ActionTemplate}></Column>
    </DataTable>
    <div className="row mt-4 mb-2">
        <h1 className='ListMainHeading'>Associated Companies</h1>
    </div>
    <DataTable value={ACompanies} tableStyle={{ minWidth: '50rem' }} size='small' showGridlines  resizableColumns columnResizeMode="expand">
        <Column field="companyName" header="Name" body={LinkTemplate}></Column>
        <Column field="gst" header="GST"></Column>
        <Column field="pan" header="PAN"></Column>
        <Column field="cin" header="CIN"></Column>
        <Column field='' header="Action" body={ActionTemplate1}></Column>
    </DataTable>
    </>
  )
}

export default CompanyList
