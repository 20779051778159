import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GetAPICallAsync } from '../ServiceLayer/GetAPICall';
import { useNavigate } from 'react-router-dom';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

const ListStock = () => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [WhList, setWHList] = useState([]);
  const [Stock, setStock] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [CategoryList, setCategoryList] = useState([]);
  const [filterHeight, setfilterHeight] = useState("0px");
  const [AppliedFilters, setAppliedFilters] = useState([]);
  let networkTimeout = null;
  const [Filters, setFilters] = useState({
    ItemCode: "",
    ItemName: "",
    CategoryId: "",
    OnlyItemstoReorder: false,
});
const [CatlazyState, setCatlazyState] = useState({
  first: 0,
  rows: 0,
  page: 1,
  sortField: null,
  sortOrder: 0,
});
  
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {}
  });

  useEffect(() => {
    loadLazyData();
  }, [lazyState]);

  useEffect(() => {
    GetCategoriesList();
    loadLazyData();
  }, []);


  const GetCategoriesList = async() => {
    const MyRes = await GetAPICallAsync("GetListItemallCategories", { lazyEvent: CatlazyState }, {MYFirstNodeOnly: false});
    
    if (MyRes.status === 401) {
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
    } else {
      const MyData = await MyRes.json();
      console.log(MyData.data);
      setCategoryList(MyData.data);
    }
  }


  const loadLazyData = async () => {
    setLoading(true);
    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(async () => {
      const MyRes = await GetAPICallAsync("GetStock", { lazyEvent: lazyState }, {Filters: JSON.stringify(Filters)});
      
      if (MyRes.status === 401) {
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        const MyData = await MyRes.json();
        setTotalRecords(MyData.total);
        setWHList(MyData.data.warehouseList);
        console.log(MyData.data.itemStock);
        setStock(MyData.data.itemStock);
      }
      setLoading(false);
    }, Math.random() * 1000 + 250);
};

const onPage = (event) => {
  setlazyState(event);
};

const onSort = (event) => {
  setlazyState(event);
};

const onFilter = (event) => {
console.log(event);
  event['first'] = 0;
  setlazyState(event);
};

const onSelectionChange = (event) => {
  const value = event.value;

  setSelectedCustomers(value);
  setSelectAll(value.length === totalRecords);
};

const onSelectAllChange = async (event) => {
  const selectAll = event.checked;

  if (selectAll) {
          setSelectAll(true);
          setSelectedCustomers(Stock);
    
  } else {
      setSelectAll(false);
      setSelectedCustomers([]);
  }
};

const handleFiltersChange = (event) => {
const { name, value } = event.target;
setFilters((prevProps) => ({
  ...prevProps,
  [name]: value
}));
};

const handleShowOnlyReorderItems = () => {
  let values = {...Filters}
  console.log( values["OnlyItemstoReorder"]);
  values["OnlyItemstoReorder"] = !values["OnlyItemstoReorder"]
  setFilters(values);
  ManageAppliedFilters(values);
  FilterSubmitMethod(values);
}

const handleFilterSubmit = async (event) => {
event.preventDefault();
ManageAppliedFilters(Filters);
FilterSubmitMethod(Filters);
}

const FilterSubmitMethod = async (AppFilters) => {
var MyRes = await GetAPICallAsync("GetStock", { lazyEvent: lazyState }, {Filters: JSON.stringify(AppFilters)});
if (MyRes.status === 401){
  localStorage.removeItem("User_Obj");
  localStorage.removeItem("Company");
  localStorage.removeItem("token");
  navigate("/login");
}
else{
  var MyData = await MyRes.json();
  setTotalRecords(MyData.total);
  setWHList(MyData.data.warehouseList);
  console.log(MyData);
  setStock(MyData.data.itemStock);
  setfilterHeight("0px")
}

}

const ManageAppliedFilters = async (AppFilters) => {

  let MyFilters = [];
  
  if(AppFilters.ItemCode !== null && AppFilters.ItemCode !== ""){
    MyFilters = [...MyFilters, {name: "ItemCode", Filter: "Item Code:", Value: AppFilters.ItemCode}] 
  }
  if (AppFilters.ItemName !== null && AppFilters.ItemName !== ""){
    MyFilters = [...MyFilters, {name: "ItemName", Filter: "Item Name:", Value: AppFilters.ItemName}] 
  }
  if (AppFilters.OnlyItemstoReorder){
    MyFilters = [...MyFilters, {name: "OnlyItemstoReorder", Filter: "Items to reorder", Value: ""}] 
  }
  if (AppFilters.CategoryId !== null && AppFilters.CategoryId !== ""){
    let MyCat = CategoryList.find(x => x.id == AppFilters.CategoryId);
    MyFilters = [...MyFilters, {name: "CategoryId", Filter: "Category:", Value: MyCat.catName}] 
  }
  
  setAppliedFilters(MyFilters);

}

const RemoveFilter = (item, index) => {
let MyAppliedFilter = [...AppliedFilters];
MyAppliedFilter.splice(index, 1)
setAppliedFilters(MyAppliedFilter);
const name = item.name;
let MyFilterz = {...Filters, [name] : ""}
setFilters((prevProps) => ({
  ...prevProps,
  [name] : "",
}) )
FilterSubmitMethod(MyFilterz);
}

const Reset = () => {
setFilters({
  ItemCode: "",
  ItemName: "",
  CategoryId: "",
})
}


  // Double Row Header
  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Item Code" rowSpan={2} />
        <Column header="Item Name" rowSpan={2} />
        <Column header="Unit" rowSpan={2} />
        <Column header="Commited" rowSpan={2} />
        <Column header="Available" colSpan={WhList.length} className='text-center'/>
        <Column header="In Transit" colSpan={WhList.length} className='text-center'/>
      </Row>
      <Row>
        {WhList.map((wh) => (
          <Column key={`${wh.warehouseName}-available`} header={wh.warehouseName} />
        ))}
        {WhList.map((wh) => (
          <Column key={`${wh.warehouseName}-in-transition`} header={wh.warehouseName} />
        ))}
      </Row>
    </ColumnGroup>
  );

  return (
    <>
      <div className='row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Stock</h1>
        </div>
        <div className='col-6 d-flex justify-content-end text-end'>
          <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
          <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "230px" : "0px")}></i>
          <button className='btn btn-outline-primary' onClick={() => handleShowOnlyReorderItems()}> {Filters.OnlyItemstoReorder ? "Show All Items" : "Items to Reorder" }</button>
        </div>
        </div>
        <div className='row'>
        <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
        </div>
        {AppliedFilters.length > 0 ? <div className='row mb-3'>
          <div className='col-12'>
            {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter} {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}} onClick={() => RemoveFilter(item, index) }></i></span>))}
          </div>
        </div> : ""}
        <div className='filterContainer' style={{ height: filterHeight, transition: "height 1s"}}>
        <form onSubmit={handleFilterSubmit} style={{height: filterHeight, transition: "height 1s", overflow: "hidden" }}> 
          <div class="row g-2">
            <h2 className='FiltersHeading'>Filters</h2>
            <div class="col-3 mb-3">
              <label htmlFor="SupplierName" class="form-label">Item Code</label>
              <input type="text" className="form-control" name="ItemCode" id="SupplierName" value={Filters.ItemCode} onChange={handleFiltersChange} placeholder="Item Code"/>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="ItemName" class="form-label">Item Name</label>
              <input type="text" className="form-control" name="ItemName" id="ItemName" value={Filters.ItemName} onChange={handleFiltersChange} placeholder="Item Name"/>
            </div>
            <div className="col-3 mb-3">
              <label htmlFor="CategoryId" className="form-label">Category</label>
              <select name="CategoryId" className="form-select" value={Filters.CategoryId} onChange={handleFiltersChange} >
                  <option value="">Select Category</option>
                  {CategoryList.map((item) => (<option key={item.id} value={item.id}>{item.catName}</option>))}
              </select>
          
            </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <button type='submit' className='btn btn-outline-primary mt-4'>Apply <i className="pi pi-angle-double-right"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => Reset()}>Reset <i className="pi pi-undo"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => setfilterHeight("0px")}>Cancel <i className="pi pi-times"></i></button>
                
                </div>
            </div>
          </form>
        </div>

      <div className='row' style={{ marginTop: filterHeight === "0px" ? "-15px": "" }}>
          <DataTable value={Stock} headerColumnGroup={headerGroup} showGridlines lazy removableSort dataKey="id" paginator selectionMode="checkbox"
          first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage} scrollable scrollHeight="430px"
          onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
          onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}>
            <Column field="itemCode" header="Item Code" body={(item) => item.itemCode || 'N/A'} />
            <Column field="itemName" header="Item Name"  body={(item) => item.itemName || 'N/A'} />
            <Column field="unit" header="Unit" body={(item) => item.unit || 'N/A'} />
            <Column field="commited" header="Commited" body={(item) => item.commited || 0} />
            
            {WhList.map((wh) => (
              <Column 
                key={`${wh.warehouseName}-available`} 
                header={`${wh.warehouseName} Available`} 
                body={(item) => {
                  const warehouseStock = item.whWiseStock.find(w => w.whid === wh.id) || {};
                  return warehouseStock.availableQty || 0; // Display Available Qty
                }} 
              />
            ))}

            {WhList.map((wh) => (
              <Column 
                key={`${wh}-in-transition`} 
                header={`${wh.warehouseName} In Transition`} 
                body={(item) => {
                  const warehouseStock = item.whWiseStock.find(w => w.whid === wh.id) || {};
                  return warehouseStock.inTransition || 0; // Display In Transition Qty
                }} 
              />
            ))}
          </DataTable>
        
      </div>
    </>
  );
}

export default ListStock;
