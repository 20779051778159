import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const EditSupplier = (props) => {
    console.log(props)
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [ShowForm, setShowForm] = useState(true);
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Cities, setCitiess] = useState([]);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 0,
        page: 1,
        sortField: null,
        sortOrder: 0,
        filters: {
          warehouseName: { value: '', matchMode: 'contains' },
          cityName: {value: '', matchMode: 'contains'},
          countryName : {value: '', matchMode: 'contains'},
          status: {value: '', matchMode: 'equals'}
        }
      });
    const [state, setState] = useState ({
        CompanyId: Company.id,
        Id : props.Id,
        SupName : "",
        BusinessType: "",
        ContactPersonName: "",
        ContactDesignation: "",
        Email: "",
        phone: "",
        GST : "",
        TAN : "",
        PAN : "", 
        SupplierAddresses: []

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
      const handleInputL2Change = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.SupplierAddresses]
        values[index][name] = value;
        setState((prevProps) => ({
            ...prevProps,
            SupplierAddresses: values
          }));
      };
      const handlePhone = (index, phone) => {
        const values = [...state.SupplierAddresses]
        values[index]["PhoneNumber"] = phone;
        console.log(values);
        setState((prevProps) => ({
            ...prevProps,
            SupplierAddresses: values
          }));
      };
      const handlePhoneL1 = (phone) => {
        setState((prevProps) => ({
            ...prevProps,
            phone: phone
          }));
      };
         

      const GetSupplierbyId = async() => {
        var MyRes = await GetAPICallAsync("GetSupplierbyId", { lazyEvent: lazyState }, {ID: props.Id});
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            console.log(MyData);
            setState((prevProps) => ({
                ...prevProps,
                SupName : MyData.data.supName,
                BusinessType : MyData.data.businessType,
                ContactPersonName : MyData.data.contactPersonName,
                ContactDesignation : MyData.data.contactDesignation,
                Email : MyData.data.email,    
                phone : MyData.data.phone,
                GST : MyData.data.gst,
                TAN : MyData.data.tan,
                PAN : MyData.data.pan,
                
            }));
        }
    };

    useEffect (() => {  
        GetSupplierbyId();
    }, []);
            
        
      
      const handleSubmit = async (event) => {
            event.preventDefault();
            console.log(state);
            let MyRes = await  POSTAPICallAsync("EditSupplier", state);
            console.log(MyRes);
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                var MyData = await MyRes.json();
                console.log(MyData);
                if (MyData.success == true ){
                    setMessage(MyData.message);
                    setShowForm(false);
                }
                else{
                    setMessage(MyData.message);
                }
                
            }
        };
  return (
    <>
    {!ShowForm ? <div className='col'>  
    <button onClick={() => props.setVisible({"open": false})}>Close</button>
</div> :
    <div className=''>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Supplier Name<span className='color-red'>*</span></label>
                <input type="text" required name="SupName" className="form-control" id="SupName" value={state.SupName} onChange={handleInputChange} placeholder="Enter Supplier name"/>
                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div className='row mb-3'>
                <div className="col-4">
                    <label htmlFor="BusinessType" className="form-label">Business Type<span className='color-red'>*</span></label>
                    <select name="BusinessType" required className="form-select" value={state.BusinessType} onChange={handleInputChange} aria-label="">
                        <option>Select Any</option>
                        <option value="manufacturer">Manufacturer</option>
                        <option value="trader">Trader</option>
                        <option value="wholesaler">Wholesaler</option>
                        <option value="retailer">Retailer</option>
                    </select>
                </div>
              
                <div className="col-4 mb-3">
                    <label htmlFor="ContactPersonName" className="form-label">Business Conatct<span className='color-red'>*</span></label>
                    <input type="text" required  name="ContactPersonName" className="form-control" id="ContactPersonName" value={state.ContactPersonName} onChange={handleInputChange} placeholder="Enter Person Name"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
     
                <div className="col-4 mb-3">
                    <label htmlFor="ContactDesignation" className="form-label">Designation</label>
                    <input type="text"  name="ContactDesignation" className="form-control" id="ContactDesignation" value={state.ContactDesignation} onChange={handleInputChange} placeholder="Enter Designation"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="Email" className="form-label">Business Email<span className='color-red'>*</span></label>
                    <input type="email" required  name="Email" className="form-control" id="Email" value={state.Email} onChange={handleInputChange} placeholder="Enter Email"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="phone" className="form-label">Business Phone<span className='color-red'>*</span></label>
                    <PhoneInput inputProps={{required: true}} country={'in'} inputClass='inputClass' name="phone" value={state.phone} onChange={Phone => handlePhoneL1(Phone)} placeholder="Enter mobile with country code"/>
                </div>
    
                <div className="col-4 mb-3">
                    <label htmlFor="GST" className="form-label">GST</label>
                    <input type="text"  name="GST" className="form-control" id="GST" value={state.GST} onChange={handleInputChange} placeholder="Enter GST Number"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="TAN" className="form-label">TAN</label>
                    <input type="text"  name="TAN" className="form-control" id="TAN" value={state.TAN} onChange={handleInputChange} placeholder="Enter TAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
     
                <div className="col-4 mb-3">
                    <label htmlFor="PAN" className="form-label">PAN</label>
                    <input type="text"  name="PAN" className="form-control" id="PAN" value={state.PAN} onChange={handleInputChange} placeholder="Enter PAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>

            </div>
           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>
    }
    <span>{Message}</span>
    </>
  )
}

export default EditSupplier
