import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import SubmitStockNewReqBlock from '../../Blocks/SubmitStockNewReqBlock'

const SubmitStockNewReqPage = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
           <SubmitStockNewReqBlock/>
     </div>
   </>
  )
}

export default SubmitStockNewReqPage