import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddItemUnit = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [Message, setMessage] = useState();
  const [state, setState] = useState({
    CompanyId : Company.id,
    UnitName : "",
    UnitDescription : "",
    UnitShortCode : "",
  });
  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(Company.id)
    console.log(state);
    await fetch(process.env.REACT_APP_API_URL + 'setup/AddItemUnit', {
     method: 'POST',
     body: JSON.stringify(state),
     headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + tokenString
      },
    })
    .then((res) => 
    { if(res.status === 401){ navigate("/login"); }        
    return res.json()})
    .then((data) => {
        setMessage("Unit Added Successfully")
    })
    .catch((err) => {
        console.log(err.message);
    });
  }
  return (
    <>
      {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible({open: false})}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Unit Name<span className='color-red'>*</span></label>
                <input type="text" required name="UnitName" className="form-control" id="CatName" value={state.UnitName} onChange={handleInputChange} placeholder="Enter Unit name"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Short Code<span className='color-red'>*</span></label>
                <input type="text" required name="UnitShortCode" className="form-control" id="UnitShortCode" value={state.UnitShortCode} onChange={handleInputChange} placeholder="Enter Unit Short Code"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea type="text" rows="4" name="UnitDescription" className="form-control" id="UnitDescription" value={state.UnitDescription} onChange={handleInputChange} placeholder="Enter Description"/>
            </div>           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </>
  )
}

export default AddItemUnit
