import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListSuppliers from '../../Blocks/ListSuppliers'

const Suppliers = () => {
  return (
    <>
     <InventoryHeader />
     <div className='p-3'>
        <ListSuppliers/>
      </div>
    </>
  )
}

export default Suppliers
