// Function to generate a random color
export const generateRandomColor = () => {
    // const letters = '0123456789ABCDEF';
    // let color = '#';
    // for (let i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
    // Use HSL color model to generate good colors
    const hue = Math.floor(Math.random() * 360); // Random hue
    const saturation = 70 + Math.floor(Math.random() * 20); // Keep saturation high
    const lightness = 40 + Math.floor(Math.random() * 20); // Keep lightness medium

    // Return the color as an HSL value
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };