import React, { useEffect, useRef, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate, useParams } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputSwitch } from 'primereact/inputswitch';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const PermissionsBlock = () => {
  const [AllPermissions, setAllPermissions] = useState();
  const navigate = useNavigate();
  const Params = useParams();
  const [state, setState] = useState({
    ProfileId: Params.Id,
    PermissionIds: [],
  });
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {}
  });
  const GetallPermissions = async () => {
    var MyRes = await GetAPICallAsync("GetPermissionbyCompanyId", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData.data);
          setAllPermissions(MyData.data);
        }
  }

  const GetthisProfilePermissions = async () => {
    var MyRes = await GetAPICallAsync("ListPemissionsbyProfileId", { lazyEvent: lazyState }, {MyProfileId: Params.Id});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          const MyPermissionIds = MyData.data.map(permission => permission.permissionId);
          setState((prevProps) => ({
            ...prevProps, PermissionIds : MyPermissionIds
          }));
        }
  }
  useEffect(() => {
    GetallPermissions();
    GetthisProfilePermissions();
  }, []);

  const handlePerissionChange = (PermissionId) => {
    let selectedPermissions = [...state.PermissionIds];
    if(selectedPermissions.includes(PermissionId)){
      selectedPermissions = selectedPermissions.filter(x => x != PermissionId);
    }
    else{
      selectedPermissions = [...selectedPermissions, PermissionId];
      
    }
    setState((prevProps) => ({
      ...prevProps, PermissionIds : selectedPermissions
    }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(state.PermissionIds);
      let MyRes = await  POSTAPICallAsync("UpdatePermissions", state);
      console.log(MyRes);
      if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
      }
      else{
          var MyData = await MyRes.json();
          if (MyData.success === true ){
              alert("Profile has been updated.")
              navigate("/setup/profiles");
          }
          else{
              //setMessage(MyData.message);
          } 
      }
              
    
  }

  return (
    <>
    <div className='row'>
      <div className='col-6'>
      <h1 className='ListMainHeading'>Permissions</h1>
      </div>
      <div className='col-6 text-end'>
        <button className='btn btn-outline-primary' onClick={handleSubmit}>Update Permissions</button>
      </div>
    </div>
      <TabView>
      {AllPermissions && AllPermissions.map((item, index) => (
        <TabPanel key={index} header={item.name}>
          {item.permissions.map((tabdata, index1) => (
            <div key={index1} className='row mb-1'>
              <div className='col-3'>
                <span>{tabdata.name}</span>
              </div>
              <div className='col-3'>
                <InputSwitch checked={state.PermissionIds.includes(tabdata.id)} onChange={() => handlePerissionChange(tabdata.id)} />
              </div>
            </div>
          ))}
        </TabPanel>
      ))}
          
        </TabView>

    </>
  )
}

export default PermissionsBlock