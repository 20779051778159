import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import Addnewapinvoice from '../../Blocks/Addnewapinvoice'

const Addapinvoice = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
          <Addnewapinvoice />
        </div>
    </div>
  )
}

export default Addapinvoice