import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ProductionOrderBlock from '../../Blocks/ProductionOrderBlock'

const ProductionOrderPage = () => {
  return (
    <div>
    <InventoryHeader/>
      <div className='p-3'>
        <ProductionOrderBlock/>
      </div>
  </div>
  )
}

export default ProductionOrderPage