import React, { useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate } from 'react-router-dom';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'
import { Dialog } from 'primereact/dialog';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const Importdatablock = () => {
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [upload, setUpload] = useState("");
    const [Headers, setHeaders] = useState([]);
    const [ExcelHeaders, setExcelHeaders] = useState([]);
    const [DisUploadBtn, setDisUploadBtn] = useState(true);
    const [DisVerifyBtn, setDisVerifyBtn] = useState(true);
    const [DisFileUpload, setDisFileUpload] = useState(true);
    const [FetchbyPOMessage, setFetchbyPOMessage] = useState("");
    const [CategoryList, setCategoryList] = useState([]);
    const [Warehouses, setWarehouses] = useState([]);
    const [DynamicFields, setDynamicFields] = useState([]);
    const [ItemUnits, setItemUnits] = useState([]);
    const [ErrorList, setErrorList] = useState([]);
    const [ErrorListVisible, setErrorListVisible] = useState(false)
    // const [SampleItemData, setSampleItemData] = useState([
    //     {
    //         "ItemCode": "ABC001", "Category" : "", "Is_SubItem": false, "ItemGroup": "", "Name": "Item 1", "ForeignName": "Item 1",
    //         "Description": "This is test Item", "HSNCode": "00998", "DefaultWareHouse": "", "ItemType": "", "BuyingUnit": "",
    //         "BToIConversion": 1, "IToSConversion": 1, "Is_Sellable": true, "SellingUnit": "", "InventoryUnit": "", "IssueMethod": "FIFO",
    //         "TrackItem": true, "TrackBy": "", "CompanyId": Company.id,
    //     }
    // ]);

    const [ItemData, setItemData] = useState([
        {
            "ItemCode": "ABC001", "Category" : "", "Is_SubItem": false, "ItemGroup": "", "Name": "Item 1", "ForeignName": "Item 1",
            "Description": "This is test Item", "HSNCode": "00998", "DefaultWareHouse": "", "ItemType": "", "BuyingUnit": "", "InventoryUnit": "",
            "BToIConversion": 1, "Is_Sellable": true, "SellingUnit": "",  "IToSConversion": 1,"OpeningBalance": 0,"ReorderValue": 10, "IssueMethod": "FIFO",
            "TrackItem": false, "TrackBy": ""
        }
    ]);
    const navigate = useNavigate();
 

    const PrepareData = async (e) => {
        let value = e.target.value;
        console.log(value);
        setUpload(value);
        if(value === "item-master"){
            let ItemDataC = [];            
            var MyRes = await GetAPICallAsync("GetItemDDOptions", "", "");
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                setFetchbyPOMessage("");
                var MyData = await MyRes.json();
                setCategoryList(MyData.prodCategories);
                setWarehouses(MyData.warehouses);
                setItemUnits(MyData.itemUnits)
            }

            let MyHeaders = ["ItemCode", "Category","Is_SubItem", "ItemGroup","Name","ForeignName","Description",
                "HSNCode","DefaultWareHouse","ItemType","BuyingUnit","InventoryUnit","BToIConversion","Is_Sellable","SellingUnit","IToSConversion",
                "OpeningBalance","ReorderValue","IssueMethod","TrackItem","TrackBy"];
            var MyRes1 = await GetAPICallAsync("GetDynamicFields", "", {"FormName" : value});
            if (MyRes1.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
            }
            else{
            setFetchbyPOMessage("");
            let MyData = await MyRes1.json();
            ItemDataC = [...ItemData];
            console.log(MyData.listDynamicFields);
            setDynamicFields(MyData.listDynamicFields);
            MyData.listDynamicFields.forEach((item) => (
                MyHeaders = [...MyHeaders, item.fieldName],
                ItemDataC =  ItemDataC.map((obj) => ({...obj, [item.fieldName] : ""  }))
            ))
            setItemData(ItemDataC);
            setHeaders(MyHeaders);
            
            }
            // ExcelDownload(MyHeaders, ItemDataC, MyDynamicFields, ProductCategories, MyWarehouses, MyItemUnits);
            
        }
        

    }

    const DownloadTemplate = async () => {

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
    
        // Dynamic Headers
        let DHeaders = Headers;
        console.log(DHeaders);
    
        // Add Headers
        worksheet.addRow(DHeaders);
    
        // Sample Data
        let data = ItemData;
        // Add Data Rows
        data.forEach(row => {
            worksheet.addRow(Object.values(row));
        });
        DHeaders.forEach(item => {
            let optionsString = "";
            let targetColumnIndex = 0;
            let IfDynamicField = DynamicFields.find( x => x.fieldName === item);
            if( item === "Category"){
                let CatOptions = [];
                CategoryList.forEach((pitem) => {
                    CatOptions = [...CatOptions, pitem.catName];
                })
                optionsString = `"${CatOptions.join(",")}"`;

                targetColumnIndex = DHeaders.indexOf("Category") + 1; // Adjust this based on the header you want to target
                
            }
            else if (item === "DefaultWareHouse")
            {
                let WHOptions = [];
                Warehouses.forEach((Whitem) => {
                    WHOptions = [...WHOptions, Whitem.warehouseName];
                })
                optionsString = `"${WHOptions.join(",")}"`;
                targetColumnIndex = DHeaders.indexOf("DefaultWareHouse") + 1; // Adjust this based on the header you want to target
            }
            else if(item === "ItemType"){
                let MyOptions = ['Raw Material', 'Consumable', 'Packaging Material', 'Semi Finished Good', 'Finished Good' ];
                optionsString = `"${MyOptions.join(",")}"`;

                targetColumnIndex = DHeaders.indexOf("ItemType") + 1; // Adjust this based on the header you want to target
            }
            else if (item === "BuyingUnit")
            {
                let MyOptions = [];
                ItemUnits.forEach((Myitem) => {
                    MyOptions = [...MyOptions, Myitem.unitName];
                })

                optionsString = `"${MyOptions.join(",")}"`;
                targetColumnIndex = DHeaders.indexOf("BuyingUnit") + 1; // Adjust this based on the header you want to target
            }
            else if (item === "SellingUnit")
            {
                let MyOptions = [];
                ItemUnits.forEach((Myitem) => {
                    MyOptions = [...MyOptions, Myitem.unitName];
                })
                optionsString = `"${MyOptions.join(",")}"`;
                targetColumnIndex = DHeaders.indexOf("SellingUnit") + 1; // Adjust this based on the header you want to target
            }
            else if (item === "InventoryUnit")
            {
                let MyOptions = [];
                ItemUnits.forEach((Myitem) => {
                    MyOptions = [...MyOptions, Myitem.unitName];
                })
                optionsString = `"${MyOptions.join(",")}"`;
                targetColumnIndex = DHeaders.indexOf("InventoryUnit") + 1; // Adjust this based on the header you want to target
            }
            else if(item === "TrackBy"){
                let MyOptions = ['batch', 'serialno' ];
                optionsString = `"${MyOptions.join(",")}"`;
                targetColumnIndex = DHeaders.indexOf("TrackBy") + 1; // Adjust this based on the header you want to target
            }
            else if(IfDynamicField !== null && IfDynamicField !== undefined && IfDynamicField.fieldType === "select"){
                
                let MyOptions = [];
                IfDynamicField.formFieldOptions.forEach((Myitem) => {
                    MyOptions = [...MyOptions, Myitem.optionName];
                })
                optionsString = `"${MyOptions.join(",")}"`;
                targetColumnIndex = DHeaders.indexOf(IfDynamicField.fieldName) + 1; // Adjust this based on the header you want to target
            }

            if (targetColumnIndex > 0) {
                // Apply Data Validation (Dropdown) to the target column
                const dropdownColumn = worksheet.getColumn(targetColumnIndex);
                dropdownColumn.eachCell((cell, rowNumber) => {
                    if (rowNumber > 1) { // Skip header row
                        cell.dataValidation = {
                            type: 'list',
                            allowBlank: true,
                            formulae: [optionsString], // Dropdown options
                            showErrorMessage: true,
                            errorTitle: 'Invalid Selection',
                            error: 'Please select a value from the dropdown.'
                        };
                    }
                });
            }

        })
        
    
        // Write to a buffer
        const buffer = await workbook.xlsx.writeBuffer();
    
        // Save the file
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Data.xlsx');
    }

    // Function to compare Excel headers with predefined headers
    const compareHeaders = (excelHeaders, predefinedHeaders) => {
        if (excelHeaders.length !== predefinedHeaders.length) return false;
        for (let i = 0; i < excelHeaders.length; i++) {
            if (excelHeaders[i].trim() !== predefinedHeaders[i].trim()) {
                return false;
            }
        }
        return true;
    };

    const handleFileRead =  async (e) => {
       if(Headers.length > 0){
      
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
            let MyjsonData = [...jsonData];

            const excelHeaders = jsonData[0];
            setExcelHeaders(excelHeaders);
            // Compare Excel headers with predefined headers


            MyjsonData.map ((item, MyIndex) => {
                let MyItem = [...item];
                let MyHeading = Headers
                var result = MyIndex > 0 &&  MyItem.reduce((a, v, index) => ({ 
                    
                    ...a, [MyHeading[index]]: v
                
                }), {})
                if (MyIndex > 0){
                    MyjsonData[MyIndex - 1] = result;
                }
                console.log(result);
                
            })
            MyjsonData.pop();
            setItemData(MyjsonData);
        };
    
        reader.readAsArrayBuffer(file);
        setErrorList([]);
        setDisUploadBtn(true);
        setDisVerifyBtn(false);
    }
    else{
        alert("Please select 'what do you want to upload?'")
    }      
    }

    const Verify = () => {
        console.log(ItemData);
        let EL = [];
        if (!compareHeaders(ExcelHeaders, Headers)) {
            alert('The uploaded file do not match the expected format.');
            return; // Stop further processing if headers do not match
        }
        let MyIData = [...ItemData];
        MyIData = MyIData.map((item, index) => {
            
            if(item.Category === "" || item.Category === undefined ){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Category is required")
            }
            else
            {
                let category = CategoryList.find(x => x.catName === item.Category);
                console.log(category);
                if(category === "" || category === undefined){
                   EL.push("Row no "+ (parseInt(index) + 2) + ": Category is not correct")
                }
                else
                {
                    item = { ...item, CatID: category.id };
                }
            }
            if (item.Name === "" || item.Name === undefined){
                
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Name is required")
            }
            if(item.ForeignName === "" || item.ForeignName === undefined){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Foreign Name is required")
            }
            if(item.ItemCode === "" || item.ItemCode === undefined){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Item Code is required")
            }
            if(item.HSNCode === "" || item.HSNCode === undefined){     
                    EL.push("Row no "+ (parseInt(index) + 2) + ": HSN Code is required")
            }
            if(item.DefaultWareHouse === "" || item.DefaultWareHouse === undefined){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Default warehouse is required")
            }
            else{
                let Warehouse = Warehouses.find(x => x.warehouseName === item.DefaultWareHouse);
                console.log(Warehouse);
                if(Warehouse === "" || Warehouse === undefined){
                   EL.push("Row no "+ (parseInt(index) + 2) + ": Deafult Warehouse is not correct")
                }
                else
                {
                    item = { ...item, DefaultWareHouse: Warehouse.id };
                }
            }
            if(item.BuyingUnit === "" || item.BuyingUnit === undefined){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Buying Unit is required")
            }
            else{
                let Unit = ItemUnits.find(x => x.unitName === item.BuyingUnit);
                console.log(Unit);
                if(Unit === "" || Unit === undefined){
                   EL.push("Row no "+ (parseInt(index) + 2) + ": Buying unit is not correct")
                }
                else
                {
                    item = { ...item, BuyingUnit: Unit.id };
                }
            }
            if(item.InventoryUnit === "" || item.InventoryUnit === undefined){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Inventory Unit is required")
            }
            else{
                let Unit = ItemUnits.find(x => x.unitName === item.InventoryUnit);
                console.log(Unit);
                if(Unit === "" || Unit === undefined){
                   EL.push("Row no "+ (parseInt(index) + 2) + ": Inventory unit is not correct")
                }
                else
                {
                    item = { ...item, InventoryUnit: Unit.id };
                }
            }
            if(item.Is_Sellable === "" || item.Is_Sellable === undefined ){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Is_Sellable is required")
            }
            if(item.Is_Sellable === true && (item.SellingUnit === "" || item.SellingUnit === undefined)){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Selling Unit is required")
            }
            else{
                let Unit = ItemUnits.find(x => x.unitName === item.SellingUnit);
                console.log(Unit);
                if(Unit === "" || Unit === undefined){
                   EL.push("Row no "+ (parseInt(index) + 2) + ": Selling unit is not correct")
                }
                else
                {
                    item = { ...item, SellingUnit: Unit.id };
                }
            }
            if(item.TrackItem === "" || item.TrackItem === undefined ){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Track Item is required")
            }
            if(item.TrackItem === true && (item.TrackBy === "" || item.TrackBy === undefined)){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Track By is required")
            }
            else if (item.TrackItem === true && (item.TrackBy !== "batch" && item.TrackBy !== "serialno")){
               
                   EL.push("Row no "+ (parseInt(index) + 2) + ": Trackby is not correct")
            }
            if(item.Description === "" || item.Description === undefined){
                    EL.push("Row no "+ (parseInt(index) + 2) + ": Description is required")
            }

            let MyDF = [];
            DynamicFields.forEach((DF) => {
                console.log(DF);
                if(DF.isRequired){
                    if(item[DF.fieldName] === "" || item[DF.fieldName] === undefined){
                            EL.push("Row no "+ (parseInt(index) + 2) + ": " + DF.fieldName + " is required")
                    }
                }
                if(DF.fieldType === "select"){
                    let MyOption = DF.formFieldOptions.find(x => x.optValue === item[DF.fieldName])
                    if(MyOption === "" || MyOption === undefined){
                        EL.push("Row no "+ (parseInt(index) + 2) + ": " + DF.fieldName + " is not correct")
                     }
                }
                MyDF = [...MyDF, {"FormDynamicFieldId": DF.id, "Value": item[DF.fieldName]}]
            })
            item = { ...item, CompanyId: Company.id };
            item = { ...item, DynamicFields: MyDF };
            console.log(item);
            return item;
        })
        console.log(MyIData);
        setItemData(MyIData);
        if (EL.length > 0){
            setErrorList(EL);
        }
        else{
            setDisUploadBtn(false);
        }
        setErrorListVisible(true);
      }

      const Upload = async () => {
        console.log(ItemData);
        // let batchSize = 2000;
            if(ItemData.length <= 2000)
            {
                // for (let i = 0; i < ItemData.length; i += batchSize) {
                //     const batch = ItemData.slice(i, i + batchSize);
                let MyRes = await  POSTAPICallAsync("BulkItemMaster", ItemData);
                console.log(MyRes);
                if (MyRes.status === 401){
                    localStorage.removeItem("User_Obj");
                    localStorage.removeItem("Company");
                    localStorage.removeItem("token");
                    navigate("/login");
                }
                else{

                    var MyData = await MyRes.json();
                    if (MyData.success === true ){
                    alert("File uploded successfully")
                    }
                    else{
                        // setMessage(MyData.message);
                    }
                    
                }
                
            }
        }
    // }

  return (
    <>
        <div className='col-12'>

         
            <div className='row justify-content-center mt-2'>
            
                <div className="mb-3 col-6">
                <label className="form-label">What do you want to upload? Select One.<span className='color-red'>*</span></label>
                    
                        <select className='form-select col-4' name="upload" onChange={PrepareData}>
                        <option value={""}>Select Any</option>
                            <option value={"item-master"}>Item Master</option>
                        </select>
                    
                </div>      
                
          </div>
          <div className='row justify-content-center mt-2'>

            <div className='col-6 ' style={{border: "1px solid #e7e7e7", padding: "30px", borderRadius: "10px", backgroundColor: "#f8f8f8"}}>
        
          <div className='row'>
            <p>You can upload up to 2,000 records per file. To download template. <button disabled={upload !== "" ? false : true} className='btn btn-link' onClick={() => DownloadTemplate()}>click here.</button>  </p>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
            <input type="file" disabled={upload === "" ? true : false} className='form-control col-6' accept='.xls, .xlsx' onChange={handleFileRead} />
            </div>
          </div>
          <button className='btn btn-info' disabled={DisVerifyBtn} onClick={Verify}> <i className="pi pi-verified"></i>  Verify</button>
          <button className='btn btn-primary ms-1' disabled={DisUploadBtn} onClick={Upload}> <i className="pi pi-upload"></i>  Upload</button>
          {ErrorList.length > 0 ? <button type='button' className='btn btn-danger ms-1' onClick={() => setErrorListVisible(true)}> <i className="pi pi-ban"></i>  Error Log</button>: ""}
          </div>
          </div>
          </div>
          <Dialog header={"Message"}  visible={ErrorListVisible} style={{ width: '70vw' }} onHide={() => setErrorListVisible(false)}>
          {ErrorList.length > 0 ? <> 
          <h4>Total {ErrorList.length} errors found.</h4>         
          {ErrorList.map((ELItem) => (
            <><span>{ELItem}</span><br/></>
          ))}</>
         : "File has been verified, no error found. Please upload."}
        </Dialog>
        
    </>
  )
}

export default Importdatablock