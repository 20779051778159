import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import AddOtherField from './AddOtherField';

const ManagefieldsList = () => {
  const MyParams = useParams();
  console.log(MyParams);
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [FieldType, setFieldType] = useState();
  const [DynamicFields, setDynamicFields] = useState([]);
  const menuLeft = useRef(null);
  const toast = useRef(null);


  const items = [
    {
        items: [
            {
                label: 'Text Field',
                icon: 'pi pi-refresh',
                command: () => {
                    setFieldType("text");
                    setVisible(true);
                    // toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                }
            },
            {
                label: 'Select Field',
                icon: 'pi pi-times',
                command: () => {
                  setFieldType("select");
                  setVisible(true);
                }
            }
        ]
    }
  ];


  const calltpAPI = () => (fetch(process.env.REACT_APP_API_URL + 'setup/Listdynamicfields?' + new URLSearchParams({
    CompanyId: Company && Company.id,
    form: MyParams.Id
    }), {
    method: 'Get',
    headers: {
    'Content-type': 'application/json',
    'Authorization': 'Bearer ' + tokenString
    },
  })
  .then(res => {
      console.log(res)
      if(res.status === 401){ navigate("/login"); }
      return res.json()
  } )
  .then(data => {
    console.log(data);
    setDynamicFields(data.listDynamicFields);
  })
  .catch((err) => {
    console.log(err.message);
  }))
  useEffect (() => {
    calltpAPI();
  }, [])
  useEffect (() => {
    calltpAPI();
  }, [visible])
  return (
    <>
    <div className='mb-3 row'>
        <div className='col-6'>
          <span>Other Fields</span>
        </div>
        <div className='col-6 d-flex justify-content-end'>
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
            <button className='btn btn-outline-primary ms-auto' onClick={(event) => menuLeft.current.toggle(event)}>Add New</button>
        </div>
       </div>
       <div className='row'>
          <DataTable value={DynamicFields} tableStyle={{ minWidth: '50rem' }}>
            <Column field="fieldName" header="Field Name"></Column>
            <Column field="fieldType" header="Field Type"></Column>
            <Column field="isRequired" header="Mandatory"></Column>
            <Column field="status" header="Status"></Column>
          </DataTable>
       </div>
       <Dialog header="Add Other Field" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
         <AddOtherField FieldType = {FieldType} FormName = {MyParams.Id} setVisible={setVisible} />
      </Dialog>
      
    </>
  )
}

export default ManagefieldsList
