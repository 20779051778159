import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddItemUnit from './AddItemUnit';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const ListSalesOrders = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [SOList, setSOList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const [AppliedFilters, setAppliedFilters] = useState([]);
    const [filterHeight, setfilterHeight] = useState("0px");
    const [Dealers, setDealers] = useState([]);
    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
  });

  const [Filters, setFilters] = useState({
    SONO: "",
    SODate: "",
    DocumentName: "",
    DealerId: "",
    SOFromDate: "",
    SOToDate: "",
});
  
  const items = [
    {
      label: 'Details',
      icon: 'pi pi-info-circle',
      url: "/sales/salesorder/" + selectedRow.id + ""
  },
  {
      label: 'Edit',
      icon: 'pi pi-times',
      command: (row) => {
        console.log(row);
          toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
      }
  },
  {
    label: 'React Website',
    icon: 'pi pi-external-link',
    url: 'https://reactjs.org/'
  },
  ]; 
    
  
    useEffect(() => {
      loadLazyData();
    }, [lazyState, visible]);
    useEffect (() => {
      loadLazyData();
      GetListDealers();
    }, [])

    const GetListDealers = async () => {
      var MyRes = await GetAPICallAsync("GetListDealers", { lazyEvent: lazyState }, {MyFilters: JSON.stringify(Filters)});
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setDealers(MyData.data);
      }
    }
  
  
    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }
  
      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        var MyRes = await GetAPICallAsync("GetListSO", { lazyEvent: lazyState }, {Filters: JSON.stringify(Filters)});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setSOList(MyData.data);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };
  
  const onSort = (event) => {
      setlazyState(event);
  };
  
  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };
  
  const onSelectionChange = (event) => {
      const value = event.value;
  
      setSelectedCustomers(value);
      setSelectAll(value.length === totalRecords);
  };
  
  const onSelectAllChange = async (event) => {
      const selectAll = event.checked;
  
      if (selectAll) {
              setSelectAll(true);
              setSOList(SOList);
        
      } else {
          setSelectAll(false);
          setSelectedCustomers([]);
      }
  };

  const handleFiltersChange = (event) => {
    let { name, value } = event.target;
    setFilters((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
  
  const handleFilterSubmit = async (event) => {
    event.preventDefault();
    const FinalFilters = ManageAppliedFilters(Filters);
    FilterSubmitMethod(FinalFilters);
  }
  
  const ManageAppliedFilters = (AppFilters) => {
      let MyFilters = [];
      let ModifiedFilters = {...Filters};
      if(AppFilters.SONO !== null && AppFilters.SONO !== ""){
        MyFilters = [...MyFilters, {name: "SONO", Filter: "SO No", Value: AppFilters.SONO}] 
      }
      if (Filters.SODate !== null && Filters.SODate !== ""){
        let FromDate = moment(Filters.SODate[0]).format("DD/MM/yyyy");
        let ToDate = new moment(Filters.SODate[1]).format("DD/MM/yyyy");
        MyFilters = [...MyFilters, {name: "SODate", Filter: "SO Date", Value: FromDate + "-" + ToDate}] 
        ModifiedFilters.SOFromDate = Filters.SODate[0];
        ModifiedFilters.SOToDate = Filters.SODate[1];
      }
      if(AppFilters.DealerId !== null && AppFilters.DealerId !== ""){
        let MyDealer= Dealers.find(x => x.id == AppFilters.DealerId);
        MyFilters = [...MyFilters, {name: "DealerId", Filter: "Dealer", Value: MyDealer.dealerName}] 
      }
      if(AppFilters.DocumentName !== null && AppFilters.DocumentName !== ""){
        MyFilters = [...MyFilters, {name: "DocumentName", Filter: "Document Name", Value: AppFilters.DocumentName}] 
      }
      setAppliedFilters(MyFilters);
      setFilters(ModifiedFilters);
      return ModifiedFilters;
  }
  
  const FilterSubmitMethod = async (AppFilters) => {
    var MyRes = await GetAPICallAsync("GetListSO", { lazyEvent: lazyState }, {Filters: JSON.stringify(AppFilters)});
    if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
    }
    else{
      var MyData = await MyRes.json();
      console.log(MyData);
      setTotalRecords(MyData.total);
      setSOList(MyData.data);
      setfilterHeight("0px")
    }
  
  }
  
  
  const RemoveFilter = (item, index) => {
    let MyAppliedFilter = [...AppliedFilters];
    MyAppliedFilter.splice(index, 1)
    setAppliedFilters(MyAppliedFilter);
    const name = item.name;
    let MyFilterz = {...Filters, [name] : ""}
    console.log(MyFilterz);
    setFilters((prevProps) => ({
      ...prevProps,
      [name] : "",
    }) )
    FilterSubmitMethod(MyFilterz);
  }

  const Reset = () => {
    setFilters({
      SONO: "",
      SODate: "",
      DocumentName: "",
      DealerId: "",
  })
  }
  
  
    const ActiveTemplate = (prop) => {
        return prop.status === true ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Inactive</span>
    }
    const ActionTemplate = (row) => {
       return<>
       <div className="flex justify-content-center">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
            {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
       </div>
       </> 
    }
  return (
    <div>
       
       {!props.HideButtons && <div className=' row'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Sales Orders</h1>
        </div>
        <div className='col-6 d-flex justify-content-end text-end'>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
       <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "200px" : "0px")}></i>
        
        <Link className='btn btn-outline-primary' to="/sales/addsalesorder"><i className="pi pi-plus"></i> Add New</Link>
        </div>
        </div>}
        <div className='row'>
        <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
        </div>
        {AppliedFilters.length > 0 ? <div className='row mb-3'>
          <div className='col-12'>
            {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter}: {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}} onClick={() => RemoveFilter(item, index) }></i></span>))}
          </div>
        </div>: ""}
        <div className='filterContainer' style={{ height: filterHeight, transition: "height 1s"}}>
        <form onSubmit={handleFilterSubmit} style={{height: filterHeight, transition: "height 1s", overflow: "hidden" }}> 
          <div className="row g-2">
            <h2 className='FiltersHeading'>Filters</h2>
            <div className="col-3 mb-3">
              <label htmlFor="PONO" className="form-label">SO No</label>
              <input type="text" className="form-control" name="SONO" id="PONO" value={Filters.SONO} onChange={handleFiltersChange} placeholder="Purchase order number."/>
            </div>
            {/* <div className="col-3 mb-3">
              <label htmlFor="BusinessType" className="form-label">Supplier</label>
              <select name="Supplier" className="form-select" value={Filters.Supplier} onChange={handleFiltersChange} aria-label="">
                    <option value={""}>Select Any</option>
                    {Suppliers.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.supName}</option>))}
                </select>
            </div> */}
            <div className="col-3 mb-3">
              <label htmlFor="DocumentName" className="form-label">Document Name</label>
              <input type="text" className="form-control" name="DocumentName" id="DocumentName" value={Filters.DocumentName} onChange={handleFiltersChange} placeholder="Document Name"/>
            </div>
            <div className="col-3 mb-3">
              <label htmlFor="SODate" className="form-label">PO Date</label>
              <Calendar name="SODate" id="SODate"  dateFormat="dd/mm/yy" className="form-control custdate" selectionMode="range"  value={Filters.SODate} onChange={handleFiltersChange} showButtonBar placeholder='Select Date Range' hideOnRangeSelection ></Calendar>
            </div>
            <div className="col-3 mb-3">
                <label for="DealerId" className="form-label">Dealer</label>
                <select name="DealerId" className="form-select" value={Filters.DealerId} onChange={handleFiltersChange} >
                    <option value="">Select Dealer</option>
                    {Dealers.map((item) => (<option key={item.id} value={item.id}>{item.dealerName}</option>))}
                </select>
            </div>          
            </div>
            <div className='row'>
              <div className='col-12'>
                <button type='submit' className='btn btn-outline-primary mt-4'>Apply <i className="pi pi-angle-double-right"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => Reset()}>Reset <i className="pi pi-undo"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => setfilterHeight("0px")}>Cancel <i className="pi pi-times"></i></button>
                
                </div>
            </div>
          </form>
        </div>
        <div className='row' style={{ marginTop: filterHeight === "0px" ? "-15px": "" }}>
            <DataTable value={SOList} lazy dataKey="id" paginator removableSort size='small' showGridlines  resizableColumns columnResizeMode="expand"
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage} selectionMode="checkbox"
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                    selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column field="soNo" header="SO No"></Column>
            <Column field="soName" header="Document Name"/>
            <Column field="dealer.dealerName" header="Dealer"></Column>
            <Column field="isApproved" header="Approval"></Column>
            <Column field="orderStatus" header="Status"></Column>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
            </DataTable>
        </div>

    </div>
  )
}

export default ListSalesOrders