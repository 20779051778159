import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import { TabView, TabPanel } from 'primereact/tabview';
import CompanyBanksList from '../../Blocks/CompanyBanksList';
import WareHouseList from '../../Blocks/WareHouseList';

const CompanyDetails = () => {
  const CompanyId = JSON.parse(localStorage.getItem('CompanyId'));
    // let params = useParams();
    console.log(CompanyId);
  return (
    <>
      <InventoryHeader/>
      <div className='col-12 p-3'>
                <h1>Company Details</h1>
            </div>
      <div className='col-12'>
      <TabView>
            <TabPanel header="Basic Details">
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </TabPanel>
            <TabPanel header="Bank Accounts">
                <CompanyBanksList CompId = {CompanyId}/>
            </TabPanel>
            <TabPanel header="Warehouses">
               <WareHouseList CompId = {CompanyId} />
            </TabPanel>
        </TabView>
      </div>

    </>
  )
}

export default CompanyDetails
