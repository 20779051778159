import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import StockIssueBlock from '../../Blocks/StockIssueBlock'

const StockIssuePage = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
           <StockIssueBlock/>
     </div>
   </>
  )
}

export default StockIssuePage