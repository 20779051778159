import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import SSReqDetailsblock from '../../Blocks/SSReqDetailsblock'

const SubmitStockDetailPage = () => {
  return (
    <>
    <InventoryHeader />
    <div className='p-3'>
        <SSReqDetailsblock/>
    </div>
</>
  )
}

export default SubmitStockDetailPage