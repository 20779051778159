import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    if(!tokenString){
        localStorage.removeItem('User_Obj');
        localStorage.removeItem('token');
        localStorage.removeItem('Company');
    }
    return tokenString ? children : <Navigate to="/login" replace />;
}

export default ProtectedRoutes