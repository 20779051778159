import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import PurchaseOrderDetailBlock from '../../Blocks/PurchaseOrderDetailBlock'

const PurchaseOrderDetailPage = () => {
  return (
    <div>
        <InventoryHeader/>
        <div className='p-3'>
            <PurchaseOrderDetailBlock />
        </div>
    </div>
  )
}

export default PurchaseOrderDetailPage