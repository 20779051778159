import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddItemUnit from './AddItemUnit';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import EditItemUnit from './EditItemUnit';

const ItemUnitsList = (props) => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [UnitList, setUnitList] = useState([]);
    const [visible, setVisible] = useState({
      open: false,
      mode: "",
      id: "", 
  
    });
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedrows] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const [SelectedCat, setSelectedCat] = useState();
    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 5,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
        warehouseName: { value: '', matchMode: 'contains' },
        cityName: {value: '', matchMode: 'contains'},
        countryName : {value: '', matchMode: 'contains'},
        status: {value: '', matchMode: 'equals'}
      }
    });
  
    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }
  
      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        console.log(lazyState);
        var MyRes = await GetAPICallAsync("GetListUnits", { lazyEvent: lazyState },"");
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setUnitList(MyData.data);
          props.QuckSetupMode && props.MyItemTotalCallBack(MyData.total);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };
  
  const onSort = (event) => {
    
      setlazyState(event);
  };
  
  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };
  
  const onSelectionChange = (event) => {
      const value = event.value;
  
      setSelectedrows(value);
      setSelectAll(value.length === totalRecords);
  };
  
  const onSelectAllChange = async (event) => {
      const selectAll = event.checked;
  
      if (selectAll) {
        //var MyRes = await GetAPICallAsync("GetListItemCategories", { lazyEvent: lazyState }, {PCatID: params.PCatId ? params.PCatId : ""});
              setSelectAll(true);
              // var MyData = await MyRes.json();
              setSelectedrows(UnitList);
        
      } else {
          setSelectAll(false);
          setSelectedrows([]);
      }
  };
  
  useEffect(() => {
    loadLazyData();
  }, [lazyState, visible.open]);
  
  useEffect (() => {
    loadLazyData();
  }, [])
  
    const ActionTemplate = (cat) =>{
      let url = '/setup/itemcategory/' + cat.id;
      return <>
        
        <i className="pi pi-info-circle ms-3 ActionIcon" data-toggle="tooltip" title='Info'></i>
        <i className="pi pi-pencil ms-3 ActionIcon" data-toggle="tooltip" title='Edit' onClick={() => setVisible({open: true, mode: "edit", id : cat.id})}></i>
        <i className="pi pi-times ms-3 ActionIcon" data-toggle="tooltip" title='Status'></i>
      </>
    }

  const StatusTemplate = (status) => {
    return status.status ? <span>Active</span> : <span>Inactive</span>
  }
  return (
    <div>
      <div className='mb-3 row g-0'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Units List</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
          <button className='btn btn-outline-primary ms-auto' onClick={() => setVisible({open: true, mode: "add"})} > <i className="pi pi-plus" ></i> Add New</button>
        </div>
       </div>
       <div className='row g-0'>
          <DataTable value={UnitList} removableSort lazy dataKey="id" paginator 
                  first={lazyState.first} rows={5} selectionMode="checkbox" totalRecords={totalRecords} onPage={onPage}
                  onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                  onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                  selection={selectedRows} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column field="unitName" header="Unit Name"></Column>
            <Column field="unitShortCode" header="Short Code"></Column>
            <Column field="unitDescription" header="Description"></Column>
            <Column field="status" header="Status" body={StatusTemplate}></Column>
            <Column field="" header="Action" body={ActionTemplate}></Column>
          </DataTable>
       </div>
       <Dialog header={visible.mode === "add" ? "Add New Unit" : "Edit Unit"} visible={visible.open} style={{ width: '50vw' }} onHide={() => setVisible({"open": false})}>
       {visible.mode === "add" && <AddItemUnit CompId = {props.CompId} setVisible={setVisible} />}
         {visible.mode === "edit" && <EditItemUnit CompId = {props.CompId} Id={visible.id} setVisible={setVisible}/>}
      </Dialog>
    </div>
  )
}

export default ItemUnitsList
