import React from 'react'
import VerifyOTPBlock from '../../Blocks/VerifyOTPBlock'

const VerifyOTPPage = () => {
  return (
    <div>
        <VerifyOTPBlock/>
    </div>
  )
}

export default VerifyOTPPage
