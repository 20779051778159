import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListStock from '../../Blocks/ListStock'

const Stock = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
          <ListStock />
        </div>
    </div>
  )
}

export default Stock