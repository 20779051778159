import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import AddNewPurchaseOrder from '../../Blocks/AddNewPurchaseOrder'

const AddPurchaseOrder = () => {
  return (
    <div>
      <InventoryHeader/>
      <div className='p-3'>
          <AddNewPurchaseOrder />
        </div>
    </div>
  )
}

export default AddPurchaseOrder
