import React from 'react'
import { useNavigate } from 'react-router-dom';

const ApprovalProcessBlock = () => {
    let navigate = useNavigate();
  return (
    <div>
    <table className='table table-bordered mt-5'>
        <thead>
            <tr>
                <th>
                    Process Name
                </th>
                <th>
                    Set-up Status
                </th>
                <th>
                    No of Levels
                </th>
                <th>
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            
            <tr>
                <td>
                    Purchase Order
                </td>
                <td>

                </td>
                <td>

                </td>
                <td>
                   <span className='pi pi-cog ActionIcon' onClick={() => {navigate("/setup/approvalsetup/purchaseorder")}}></span>
                </td>
            </tr>
            <tr>
                <td>
                    Sales Order
                </td>
                <td>

                </td>
                <td>

                </td>
                <td>
                <span className='pi pi-cog ActionIcon' onClick={() => {navigate("/setup/approvalsetup/salesorder")}}></span>
                </td>
            </tr>
            <tr>
                <td>
                    AP Invoice Order
                </td>
                <td>

                </td>
                <td>

                </td>
                <td>
                <span className='pi pi-cog ActionIcon' onClick={() => {navigate("/setup/approvalsetup/apinvoice")}}></span>
                </td>
            </tr>
            <tr>
                <td>
                    AR Invoice Order
                </td>
                <td>

                </td>
                <td>

                </td>
                <td>
                <span className='pi pi-cog ActionIcon' onClick={() => {navigate("/setup/approvalsetup/arinvoice")}}></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
  )
}

export default ApprovalProcessBlock