import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AddItemMaster from './AddItemMaster';
import { Dialog } from 'primereact/dialog';
import EditItemMaster from './EditItemMaster';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import Importdatablock from './Importdatablock';

const ListItemMaster = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
  const navigate = useNavigate();
  const [visible, setVisible] = useState({
    open: false,
    mode: ""
  });
  const [Items, setItems] = useState([]);
  const [SelectedItem, setSelectedItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [selectedRow, setselectedRow] = useState([]);
  const [AppliedFilters, setAppliedFilters] = useState([]);
  const [filterHeight, setfilterHeight] = useState("0px");
  const [QuckSetupMode, setQuckSetupMode] = useState(props.QuckSetupMode)

  let networkTimeout = null;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {}
  });
  const [Filters, setFilters] = useState({
    PONO: "",
    PODate: "",
    DocumentName: "",
    Supplier: "",
});


  const StatusTemplate = (status) => {
    return status.status ? <span>Active</span> : <span>Inactive</span>
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyState, visible]);

  useEffect (() => {
    loadLazyData();
  }, [])


  const loadLazyData = () => {
    setLoading(true);
    if (networkTimeout) {
        clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(async () => {
      console.log(lazyState);
      var MyRes = await GetAPICallAsync("GetListItems", { lazyEvent: lazyState }, "");
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setTotalRecords(MyData.total);
        setItems(MyData.data);
        props.QuckSetupMode && props.MyItemTotalCallBack(MyData.total);
      }
      
    setLoading(false);
    }, Math.random() * 1000 + 250);
};
  const onPage = (event) => {
    setlazyState(event);
};

const onSort = (event) => {
  
    setlazyState(event);
};

const onFilter = (event) => {
  console.log(event);
    event['first'] = 0;
    setlazyState(event);
};

const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedCustomers(value);
    setSelectAll(value.length === totalRecords);
};

const onSelectAllChange = async (event) => {
    const selectAll = event.checked;

    if (selectAll) {
            setSelectAll(true);
            setSelectedCustomers(Items);
      
    } else {
        setSelectAll(false);
        setSelectedCustomers([]);
    }
};

const handleFiltersChange = (event) => {
  let { name, value } = event.target;
  setFilters((prevProps) => ({
    ...prevProps,
    [name]: value
  }));
};

const handleFilterSubmit = async (event) => {
  event.preventDefault();
  ManageAppliedFilters(Filters);
  FilterSubmitMethod(Filters);
}

const ManageAppliedFilters = (AppFilters) => {
    let MyFilters = [];
    if(AppFilters.PONO !== null && AppFilters.PONO !== ""){
      MyFilters = [...MyFilters, {name: "PONO", Filter: "PO No", Value: AppFilters.PONO}] 
    }
    if(AppFilters.DocumentName !== null && AppFilters.DocumentName !== ""){
      MyFilters = [...MyFilters, {name: "DocumentName", Filter: "Document Name", Value: AppFilters.DocumentName}] 
    }
    setAppliedFilters(MyFilters);
}

const FilterSubmitMethod = async (AppFilters) => {
  var MyRes = await GetAPICallAsync("GetPOs", { lazyEvent: lazyState }, {Filters: AppFilters});
  if (MyRes.status === 401){
    localStorage.removeItem("User_Obj");
    localStorage.removeItem("Company");
    localStorage.removeItem("token");
    navigate("/login");
  }
  else{
    var MyData = await MyRes.json();
    console.log(MyData);
    setTotalRecords(MyData.total);
    setItems(MyData.data);
    setfilterHeight("0px")
 
  }

}


const RemoveFilter = (item, index) => {
  let MyAppliedFilter = [...AppliedFilters];
  MyAppliedFilter.splice(index, 1)
  setAppliedFilters(MyAppliedFilter);
  const name = item.name;
  let MyFilterz = {...Filters, [name] : ""}
  console.log(MyFilterz);
  setFilters((prevProps) => ({
    ...prevProps,
    [name] : "",
  }) )
  FilterSubmitMethod(MyFilterz);
}

const Reset = () => {
  setFilters({
    PONO: "",
    PODate: "",
    DocumentName: "",
    Supplier: "",
})
}

  const handleEdit = (item) => {
    setVisible({open: true, mode: "edit"})
    console.log(item)
    setSelectedItem(item.id)
  }

  const ActionTemplate = (item) => {
    return <>
      <i className="pi pi-info-circle ActionIcon"></i>
      <i className="pi pi-pencil ms-3 ActionIcon" onClick={() => handleEdit(item)}></i>
      <i className="pi pi-times ms-3 ActionIcon"></i>
    </>
  }

  const GetDialogHeader= () => {
    switch(visible.mode){
      case "add" : return <span>Add Item Master</span>
      case "edit" : return <span>Edit Item Master</span>
      case "import" : return <span>Import</span>
      default : return <span>Add Item Master</span>
    }
  }
  return (
    <div>
      <div className=' row g-0'>
        <div className='col-6'>
        <h1 className='ListMainHeading'>Item Master List</h1>
        </div>
        <div className='col-6 d-flex justify-content-end text-end'>
        {props.QuckSetupMode === false && <>
        <i className="pi pi-download p-2 me-2" style={{ fontSize: '1.3rem' }}></i>
        <i className="pi pi-sliders-h p-2 me-2" data-toggle="tooltip" title="filters" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={() => setfilterHeight(filterHeight == "0px" ? "200px" : "0px")}></i>
        </>}
       <button className='btn btn-outline-primary' onClick={() => setVisible({open: true, mode: "import"})} > <i className="pi pi-plus" ></i> Import</button>
        <button className='btn btn-outline-primary ms-2' onClick={() => setVisible({open: true, mode: "add"})}><i className="pi pi-plus"></i> Add New</button>
        </div>
        </div>
        <div className='row g-0'>
        <span>Total {totalRecords} { (totalRecords > 1) ?  "records"  : "record"}</span>
        </div>
        {AppliedFilters.length > 0 ? <div className='row mb-3 g-0'>
          <div className='col-12'>
            {AppliedFilters.map((item, index) => (<span className='FilterTag' key={index}>{item.Filter}: {item.Value} <i className="pi pi-times" style={{fontSize: "11px"}} onClick={() => RemoveFilter(item, index) }></i></span>))}
          </div>
        </div>: ""}
        <div className='filterContainer' style={{ height: filterHeight, transition: "height 1s"}}>
        <form onSubmit={handleFilterSubmit} style={{height: filterHeight, transition: "height 1s", overflow: "hidden" }}> 
          <div class="row g-2">
            <h2 className='FiltersHeading'>Filters</h2>
            <div class="col-3 mb-3">
              <label htmlFor="PONO" class="form-label">PO No</label>
              <input type="text" className="form-control" name="PONO" id="PONO" value={Filters.PONO} onChange={handleFiltersChange} placeholder="Purchase order number."/>
            </div>
            {/* <div class="col-3 mb-3">
              <label htmlFor="BusinessType" class="form-label">Supplier</label>
              <select name="Supplier" className="form-select" value={Filters.Supplier} onChange={handleFiltersChange} aria-label="">
                    <option value={""}>Select Any</option>
                    {Suppliers.map((Myitem) => (<option key={Myitem.id} value={Myitem.id}>{Myitem.supName}</option>))}
                </select>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="DocumentName" class="form-label">Document Name</label>
              <input type="text" className="form-control" name="DocumentName" id="DocumentName" value={Filters.DocumentName} onChange={handleFiltersChange} placeholder="Document Name"/>
            </div>
            <div class="col-3 mb-3">
              <label htmlFor="PODate" class="form-label">PO Date</label>
              <Calendar name="PODate" id="PODate"  dateFormat="dd/mm/yy" className="form-control custdate" selectionMode="range"  value={Filters.PODate} onChange={handleFiltersChange} showButtonBar showIcon placeholder='Select Date Range' ></Calendar>
            </div>               */}
            </div>
            <div className='row'>
              <div className='col-12'>
                <button type='submit' className='btn btn-outline-primary mt-4'>Apply <i className="pi pi-angle-double-right"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => Reset()}>Reset <i className="pi pi-undo"></i> </button>
                <button type='button' className='btn btn-outline-primary mt-4 ms-2' onClick={() => setfilterHeight("0px")}>Cancel <i className="pi pi-times"></i></button>
                
                </div>
            </div>
          </form>
        </div>
       <div className='row g-0' style={{ marginTop: filterHeight === "0px" ? "-15px": "" }}>
       <DataTable value={Items} removableSort lazy dataKey="id" paginator 
                  first={lazyState.first} rows={lazyState.rows} selectionMode="checkbox" totalRecords={totalRecords} onPage={onPage}
                  onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                  onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                  selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column className='Datatablecol' field="itemCode" header="Item Code" sortable  ></Column>
            <Column className='Datatablecol' field="name" header="Name"></Column>
            <Column className='Datatablecol' field="foreignName" header="Foreign Name" ></Column>
            <Column className='Datatablecol' field="hsnCode" header="HSN"></Column>
            <Column className='Datatablecol' field="itemType" header="Item Type"></Column>
            <Column className='Datatablecol' field="status" header="Status" body={StatusTemplate} ></Column>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
          </DataTable>
       </div>
       <Dialog header={GetDialogHeader()} visible={visible.open} style={{ width: '70vw' }} onHide={() => setVisible({open: false})}>
       {visible.mode === "edit" && <EditItemMaster Id={SelectedItem} setVisible={setVisible} />} 
       {visible.mode == "add" && <AddItemMaster  setVisible={setVisible} />}
       {visible.mode == "import" && <Importdatablock />}
      </Dialog>
     
    </div>
  )
}

export default ListItemMaster
