import React, { useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate } from 'react-router-dom';

const FindItemBlock = () => {
    const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [MyResult, setMyResult] = useState([]);
    const [state, setState] = useState({
        CompanyId : Company.id,
        Parameter: "batch",
        FindReq : "",
    })

    const handleInputChange = (event) => {
        const { name, value } = event.target;        
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
    const handleFindItems = async (e) => {
        e.preventDefault();
        console.log(state);
        var MyRes = await GetAPICallAsync("FindItem", "", {"CompId": state.CompanyId, "MyReq": state.FindReq, "Parameter": state.Parameter});
        if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
        }
        else{
        var MyData = await MyRes.json();
        console.log(MyData.data);
        setMyResult(MyData.data);
        }
    };
  return (
    <div>
        <form onSubmit={handleFindItems} class="">
            <div className='row row-cols-lg-auto g-3 align-items-center'>

            
            <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="Parameter" id="Parameter" value="item" onChange={handleInputChange}/>
            <label class="form-check-label" for="inlineRadio1">Item Code/ Name</label>
            </div>
            <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="Parameter" id="Parameter" value="batch" onChange={handleInputChange}/>
            <label class="form-check-label" for="inlineRadio2">Batch No</label>
            </div>
            <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="Parameter" id="Parameter" value="serialno" onChange={handleInputChange} />
            <label class="form-check-label" for="inlineRadio3">SrNo</label>
            </div>
            </div>
            <div className='row row-cols-lg-auto g-3 align-items-center mt-3'>
            <div className="col-12">
                <label className="visually-hidden" htmlFor="FindReq">Username</label>
                
                
                <input type="text" className="form-control" id="FindReq" name='FindReq' value={state.FindReq} onChange={handleInputChange} placeholder="Search"/>
                
            </div>
            

            {/*  */}

            {/* <div class="col-12">
                <div class="form-check">
                <input class="form-check-input" type="checkbox" id="inlineFormCheck"/>
                <label class="form-check-label" for="inlineFormCheck">
                    Remember me
                </label>
                </div>
            </div> */}

            <div className="col-12">
                <button type="submit" className="btn btn-primary">Find</button>
            </div>
            </div>
        </form>
        {MyResult.length > 0 ? 
        <table className='table table-bordered mt-5'>
            <thead>
                <tr>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Warehouse Name</th>
                    <th>Location Name</th>
                    <th>Qty</th>
                </tr>
            </thead>
            <tbody>
            {MyResult.map((item, index) => (
                item.findItemWarehouses.map((item1, index1) => (
                    item1.findItemWarehouseLocations.map((item2, index2) => (
                        <tr key={index2}>
                            <td>{state.Parameter === "item"? item.itemCode : item.myItem.itemCode}</td>
                            <td>{state.Parameter === "item"? item.itemName : item.myItem.name}</td>
                            <td>{item1.warehouseName}</td>
                            <td>{item2.locationName}</td>
                            <td>{item2.qty}</td>
                        </tr>
                    ))
                ))
                
            ))
                
            }
            </tbody>
        </table> : ""
        }
    </div>
  )
}

export default FindItemBlock