import React from 'react'
import AddSalesOrder from '../Components/AddSalesOrder'

const AddNewSalesOrder = () => {
  return (
    <>
     <div className='mb-3 row'>
     <div className='col-6'>
            <h1 className='ListMainHeading'>Add New Sales Order</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
           
        </div>
        </div>
        <div className='row'>
            <AddSalesOrder /> 
        </div>
    </>
  )
}

export default AddNewSalesOrder
