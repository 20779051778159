import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import AddWareHouse from './AddWareHouse';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { Dropdown } from 'primereact/dropdown';
import EditWareHouse from './EditWarehouse';




const WareHouseList = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
  const [visible, setVisible] = useState({
    open: false,
    mode: "",
    id: ""
  });
  const [WareHouseList, setWareHouseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [selectedRow, setselectedRow] = useState([]);
  const menuRight = useRef(null);
  //const router = useRouter();
  const toast = useRef(null);
  let networkTimeout = null;
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: 0,
    filters: {
      warehouseName: { value: '', matchMode: 'contains' },
      cityName: {value: '', matchMode: 'contains'},
      countryName : {value: '', matchMode: 'contains'},
      status: {value: '', matchMode: 'equals'}
    }
  });
  const items = [
    {
      label: 'Details',
      icon: 'pi pi-info-circle',
      url: "/setup/warehousedetails/" + selectedRow.id + ""
  },
  {
    label: 'Locations',
    icon: 'pi pi-map-marker',
    url: "/setup/warehouselocations/" + selectedRow.id + ""
},
  {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: (row) => {
        console.log(row);
          // toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
          setVisible({open: true, mode: "edit", id : selectedRow.id})
      }
  },
  // {
  //   label: 'React Website',
  //   icon: 'pi pi-external-link',
  //   url: 'https://reactjs.org/'
  // },
  ]; 

  useEffect(() => {
    loadLazyData();
  }, [lazyState, visible]);
  useEffect (() => {
    loadLazyData();
  }, [])


  const loadLazyData = () => {
    setLoading(true);
    if (networkTimeout) {
        clearTimeout(networkTimeout);
    }

    //imitate delay of a backend call
    networkTimeout = setTimeout(async () => {
      console.log(lazyState);
      var MyRes = await GetAPICallAsync("GetListCompanyWarehouses", { lazyEvent: lazyState }, "");
      if (MyRes.status === 401){
        localStorage.removeItem("User_Obj");
        localStorage.removeItem("Company");
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        var MyData = await MyRes.json();
        console.log(MyData);
        setTotalRecords(MyData.total);
        setWareHouseList(MyData.data);
        props.QuckSetupMode && props.MyItemTotalCallBack(MyData.total);
      }
      
    setLoading(false);
    }, Math.random() * 1000 + 250);
};
  const onPage = (event) => {
    setlazyState(event);
};

const onSort = (event) => {
  
    setlazyState(event);
};

const onFilter = (event) => {
  console.log(event);
    event['first'] = 0;
    setlazyState(event);
};

const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedCustomers(value);
    setSelectAll(value.length === totalRecords);
};

const onSelectAllChange = async (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      var MyRes = await GetAPICallAsync("GetListCompanyWarehouses", { lazyEvent: lazyState }, "");
            setSelectAll(true);
            var MyData = await MyRes.json();
            setSelectedCustomers(MyData.data);
      
    } else {
        setSelectAll(false);
        setSelectedCustomers([]);
    }
};


  
  const StatusTemplate = (status) => {
    return status.status ? <span>Active</span> : <span>Inactive</span>
  }
  const [statuses] = useState(['Active', 'Inactive']);

  const statusFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)}  placeholder="Select One" className="p-column-filter" showClear />;
  }


  const ActionTemplate = (row) => {
    console.log(row.id);
    return <>
     
        <div className="flex justify-content-center">
              <Toast ref={toast}></Toast>
              <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
              <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
              {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
        </div>
    
    </>
  }

  return (
    <div>
      <div className='mb-3 row g-0'>
        <div className='col-6'>
          <h1 className='ListMainHeading'>Warehouses' List</h1>
        </div>
        <div className='col-6 d-flex justify-content-end'>
          <button className='btn btn-outline-primary ms-auto' onClick={() => setVisible({open: true, mode: "add"})} > <i className="pi pi-plus" ></i> Add New</button>
        </div>
       </div>

       <div className='row'>
          <DataTable value={WareHouseList} removableSort lazy dataKey="id" paginator 
                  first={lazyState.first} rows={5} selectionMode="checkbox" totalRecords={totalRecords} onPage={onPage}
                  onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                  onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                  selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column className='Datatablecol' field="warehouseName" filterField="warehouseName" header="Warehouse Name" sortable  ></Column>
            <Column className='Datatablecol' field="pStoreName" header="Parent Warehouse (If any)"></Column>
            <Column className='Datatablecol' field="cityName" header="City" ></Column>
            <Column className='Datatablecol' field="stateName" header="State"></Column>
            <Column className='Datatablecol' field="countryName" id="countryMy" header="Country"></Column>
            <Column className='Datatablecol' field="pinCode" header="Pin Code" ></Column>
            <Column className='Datatablecol' field="status" header="Status" body={StatusTemplate} ></Column>
            <Column className='Datatablecol' field="" header="Actions" body={ActionTemplate}></Column>
          </DataTable>
       </div>
       <Dialog header={visible.mode === "add" ? "Add New Warehouse" : "Edit Warehouse"} visible={visible.open} style={{ width: '50vw' }} onHide={() => setVisible({"open": false})}>
          {visible.mode === "add" ? <AddWareHouse CompId = {props.CompId} setVisible={setVisible} WareHouseList = {WareHouseList} /> : 
                          <EditWareHouse Id={visible.id} setVisible={setVisible}/>
          }
      </Dialog>
    </div>
  )
}

export default WareHouseList
