import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { InputOtp } from 'primereact/inputotp';
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const VerifyOTPBlock = () => {
  const [Message, setMessage] = useState();
    const [state, setState] = useState({
      OTP: "",
    });
    useEffect(() => {
    })
    const navigate = useNavigate();
    const handleResend = async () => {
        let MyRes = await  POSTAPICallAsync("ResendVerifyCode", "");
        if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
        }
        else{
            var MyData = await MyRes.json();
            if (MyData.success === true ){
                  alert("Your verification code has been resent to your email. Please check your inbox (and spam/junk folder) to complete the verification process.")       
            }
            else{
                setMessage(MyData.message);
            }
        }

    }
    const VerifyCode = async () => {
      let MyRes = await  POSTAPICallAsync("VerifyCode", state);
      if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
      }
      else{
          var MyData = await MyRes.json();
          console.log(MyData);
          if (MyData.success === true ){
            localStorage.setItem("User_Obj", JSON.stringify(MyData.data) );
            navigate("/setup/companies");
          }
          else{
              setMessage(MyData.message);
          }
      }

    }
  return (
    <>
    <div className='row justify-content-center align-items-center g-0' style={{height: "100vh", backgroundColor: "#f8f8f8"}}>

        <div className='col-4 text-center'>
        <span className='LogoText' style={{fontSize: "42px"}}>AQLOB</span>
            <p>Please check your email id to get the verifcation code. Didn't get the verification code? <br/> <button className="btn btn-link" onClick={handleResend}> Resend</button></p>
            {/* <input type="text" style={{width: "250px"}}  name="UnitPrice" className="d-inline form-control mx-2 text-center" id="UnitPrice" value={""} onChange={"handleInputChange"}/> */}
            <div className='row justify-content-center'>
              <div className='w-200'>
              <InputOtp name='OTP' value={state.OTP} onChange={(e) => setState({OTP : e.value})} />
              </div>
              </div>
            
            <div className='row justify-content-center' >
                    <button className='d-block btn btn-primary mt-3 col-3' onClick={VerifyCode}>Verify</button>
            </div>
        </div>

    </div>
    </>
  )
}

export default VerifyOTPBlock