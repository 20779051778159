import React, { useEffect, useState } from 'react'
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import PurchaseOrderPdf from '../Components/PDFComponents/PurchaseOrderPdf';

const PurchaseOrderDetailBlock = () => {
  const Params = useParams();
    const [PurchaseOrder, setPurchaseOrder] = useState();
    console.log(Params);
    const navigate = useNavigate();
    const GetPurchaseOrder = async () => {
        var MyRes = await GetAPICallAsync("GetPurchaseOrderById", { lazyEvent: "" }, {POId : Params.Id});
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setPurchaseOrder(MyData.data);
        }
    }
    useEffect(()=> {
        GetPurchaseOrder();
    }, [])
  return (
    PurchaseOrder && <>
    <div className='row mt-3'>
    <div className='col-6'><h1 className='ListMainHeading'> Purchase Order: {PurchaseOrder.poName}</h1></div>
       {/* Action Buttons */}
       <div className="col-6 text-end">
        {/* <button className='btn btn-outline-primary me-2'>Edit Invoice</button>
        <button className='btn btn-outline-primary me-2'>Approve</button>
        <button className='btn btn-outline-primary me-2'>Reject</button> */}
        <PDFDownloadLink document={<PurchaseOrderPdf purchaseOrder={PurchaseOrder} />} fileName="PurchaseOrder.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : <i className="pi pi-file-pdf" style={{ fontSize: '2.5rem', color: "red" }}></i>
      }
    </PDFDownloadLink>
        {/* <button className='btn btn-outline-primary me-2'>Print</button> */}
        {/* <button className='btn btn-outline-primary me-2'>Make Payment</button> */}
      </div>
    </div>
    

      <div className='row mt-3'>

      {/* Vendor Information */}
    
      <div className="col-6" style={{padding: "30px 30px", border: "1px solid #e7e7e7" }}>
        <h2 className='ListMainHeading text-center'>Vendor Information</h2>
        {/* <p className='mt-2'><strong>Vendor ID:</strong> V001</p> */}
        <p className='mt-3'><strong>Vendor Name:</strong> {PurchaseOrder.vendorInfo.supName}</p>
        <p><strong>Vendor Address:</strong> {PurchaseOrder.vendorInfo.localAddress} {PurchaseOrder.vendorInfo.cityName} {PurchaseOrder.vendorInfo.stateName} {PurchaseOrder.vendorInfo.countryName} {PurchaseOrder.vendorInfo.zipCode}</p>
        {/* <p><strong>Payment Terms:</strong> Net 30</p> */}
        <p><strong>Contact Person:</strong> {PurchaseOrder.vendorInfo.contactPersonName} ({PurchaseOrder.vendorInfo.email})</p>
        <p><strong>GST:</strong> {PurchaseOrder.vendorInfo.gst}</p>
        <p><strong>TAN:</strong> {PurchaseOrder.vendorInfo.tan}</p>
      </div>


      {/* Invoice Details */}
      <div className="col-6" style={{ padding: "30px 30px", border: "1px solid #e7e7e7", marginLeft: "-1px" }}>
      <h2 className='ListMainHeading text-center'>Order Details</h2>
      <div className="row">
        <div className='col-6 mt-2'>
        <p><strong>PO No:</strong>  {PurchaseOrder.poNo}</p>
        <p><strong>PO Date:</strong>  {moment(PurchaseOrder.poDate).format("DD/MM/yyyy")}</p>

        {/* <p><strong>Due Date:</strong> 31/10/2024</p> */}
        {/* <p><strong>PO No:</strong> {PurchaseOrder.poNo}</p> */}
        </div>
        <div className='col-6 text-center mt-2'>
        <p> <span style={{fontSize: "26px"}}>{PurchaseOrder.totalPrice + PurchaseOrder.totalTaxAmount} {PurchaseOrder.currencyName} </span> <br/><strong>Total Amount</strong> </p>
        <p><span style={{fontSize: "18px"}}> {PurchaseOrder.totalTaxAmount} {PurchaseOrder.currencyName}</span> <br/><strong>Tax</strong> </p>
        </div>
        </div>
      </div>
      <div className='col-12 mt-5'>
      <p><strong>Subject:</strong> {PurchaseOrder.subject}</p>
      <p>Dear Sir/ Ma'am</p>
      <div dangerouslySetInnerHTML={{ __html: PurchaseOrder.welcomeText }}/>
      </div>


      {/* Itemized Details */}
      <div className="col-12 mt-5">
        <h2 className='ListMainHeading'>Itemized Details</h2>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Name</th>
              <th className='text-end'>Quantity</th>
              <th className='text-end'>Unit Price</th>
              <th className='text-end'>Discount</th>
              <th className='text-end'>Taxable Amount</th>
              <th className='text-end'>Tax Amount</th>
              <th className='text-end'>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {PurchaseOrder.items.map((item, index) => (
              <tr key={index}>
              <td>{item.itemCode}</td>
              <td>{item.foreignName}</td>
              <td className='text-end'>{item.qty} {item.unitName}</td>
              <td className='text-end'>{item.unitPrice} {PurchaseOrder.currencyName}</td>
              <td className='text-end'>{item.discount} {PurchaseOrder.currencyName}</td>
              <td className='text-end'>{item.taxableAmount} {PurchaseOrder.currencyName}</td>
              <td className='text-end'>{item.totalTax} {PurchaseOrder.currencyName}</td>
              <td className='text-end'>{item.taxableAmount + item.totalTax} {PurchaseOrder.currencyName}</td>
            </tr>
            ))}
            
     
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
      {/* Approval Workflow */}
      <div className="col-12 mt-3">
      <h2  className='ListMainHeading'>Notes & Comments</h2>
        {PurchaseOrder.termsandConditions === ""? "No comments to show." : <div dangerouslySetInnerHTML={{ __html: PurchaseOrder.termsandConditions }}/> }
        {/* <h2  className='ListMainHeading'>Attachments</h2>
        <p><a href="#">View Original Invoice</a></p> */}
       
      </div>

      {/* Notes and Comments */}
      <div className="col-12 mt-3">
      <h2  className='ListMainHeading'>Approval Workflow</h2>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Approval Status</th>
            <th>Approved By</th>
            <th>Approved On</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>{PurchaseOrder.isApproved ? "Approved" : "Pending"}</td>
          <td>{(PurchaseOrder.isApproved && PurchaseOrder.approvedBy  === "") ? "Auto approved" : PurchaseOrder.approvedBy}</td>
          <td>{moment(PurchaseOrder.approvedOn).format("DD/MM/yyyy")}</td>
        
        </tr>
        </tbody>
      </table>
      </div>

      {/* Payment Information */}
      {/* <div className="col-12 mt-3">
        <h2  className='ListMainHeading'>Payment Information</h2>
        <table className='table table-bordered mt-3'>
        <thead>
          <tr>
            <th>Payment Date</th>
            <th>Paid Amount</th>
            <th>Outstanding Amount</th>
            <th>Payment Mode</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>Approved</td>
          <td>Jane Smith (Manager)</td>
          <td>03/10/2024</td>
          <td>Bank Transfer</td>
        
        </tr>
        </tbody>
      </table>
      </div> */}

    </div>

    </>
  )
}

export default PurchaseOrderDetailBlock