import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import InvSideMenu from '../../Components/InvSideMenu'
import PurchaseSnapshotReportBlock from '../../Blocks/Reports/PurchaseSnapshotReportBlock'

const PurchaseSnapshotReportPage = () => {
  return (
    <div>
    <InventoryHeader/>
    <div className=' g-0'>
        <div className='w-200 Sidebar'>
            <InvSideMenu/>
        </div>
        <div className='ReportArea'>
          <PurchaseSnapshotReportBlock/>
        </div>
    </div>
    </div>
  )
}

export default PurchaseSnapshotReportPage