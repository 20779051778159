import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddNewItemCategory = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const navigate = useNavigate();
  const [Message, setMessage] = useState();
  const [state, setState] = useState({
      
      PCatId : props.PCatId ? props.PCatId : "",
      CatName : "",
      Description : "",
      CompanyId : props.CompId,
      
  });
  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setState((prevProps) => ({
        ...prevProps,
        [name]: value
      }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(state);
    await fetch(process.env.REACT_APP_API_URL + 'setup/addnewitemcategory', {
     method: 'POST',
     body: JSON.stringify(state),
     headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + tokenString
      },
    })
    .then((res) => 
    { if(res.status === 401){ navigate("/login"); }        
    return res.json()})
    .then((data) => {
        setMessage("Category Added Successfully")
    })
    .catch((err) => {
        console.log(err.message);
    });
  }
  return (
    <>
      {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible({"open": false})}>Close</button>
        </div> :
      <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className="form-label">Category Name<span className='color-red'>*</span></label>
                <input type="text" required name="CatName" className="form-control" id="CatName" value={state.CatName} onChange={handleInputChange} placeholder="Enter Category name"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea type="text" rows="4" name="Description" className="form-control" id="Description" value={state.Description} onChange={handleInputChange} placeholder="Enter Description"/>
            </div>           
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>}
    </>
  )
}

export default AddNewItemCategory
