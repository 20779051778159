import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'

const AddCompForm = () => {
    const tokenString = JSON.parse(localStorage.getItem('token'));
    const navigate = useNavigate();
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Cities, setCitiess] = useState([]);
    const [state, setState] = useState ({
        CompanyName : "",
        Description : "",
        GST : "",
        TAN : "",
        PAN : "", 
        CIN : "",
        Addresses: [{

            AddressType : "register",
            StreetAddress : "",
            City : "",
            StateId : "",
            CountryId : "",
            PinCode : "",
            PhoneNumber : ""
        }]

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };
      const handleInputL2Change = ( index, event) => {
        const { name, value } = event.target;
        const values = [...state.Addresses]
        values[index][name] = value;
        setState((prevProps) => ({
            ...prevProps,
            Addresses: values
          }));
      };
      const handlePhone = (index, phone) => {
        const values = [...state.Addresses]
        values[index]["PhoneNumber"] = phone;
        console.log(values);
        setState((prevProps) => ({
            ...prevProps,
            Addresses: values
          }));
      };
      const UpdateStates = (index, event) => {
        handleInputL2Change(index, event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/states?' + new URLSearchParams({
                CountryId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                setStates(data.lstStates);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      };
      const UpdateCities= (index, event) => {
        handleInputL2Change(index, event);
        if(event.target.value !== ""){

            fetch(process.env.REACT_APP_API_URL + 'setup/Cities?' + new URLSearchParams({
                StateId: event.target.value,
                }), {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                console.log(data);
                setCitiess(data.lstCities);
            })
            .catch((err) => {
               console.log(err.message);
            })
        }
      }

      useEffect (() => {
            const calltpAPI = () => fetch(process.env.REACT_APP_API_URL + 'setup/countries', {
                method: 'Get',
                headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenString.toString()
                },
            })
            .then(res => {
                if(res.status === 401){ navigate("/login"); }
                return res.json()
            } )
            .then(data => {
                setCountries(data.lstCountries);
            })
            .catch((err) => {
               console.log(err.message);
            })
            calltpAPI();
        }, []);

        const GetCompPermissions = async () => {
            var MyRes = await GetAPICallAsync("GetPermissionbyCompanyId", "", "");
            if (MyRes.status === 401){
                localStorage.removeItem("User_Obj");
                localStorage.removeItem("Company");
                localStorage.removeItem("token");
                navigate("/login");
            }
            else{
                var MyData = await MyRes.json();
                let Mypermissions = [];
                console.log(MyData);
                MyData.data.forEach(x => {
                    x.permissions.forEach( a => {
                        Mypermissions = [...Mypermissions, a.slug]
                    })

                });
                localStorage.setItem("gsxkjm", JSON.stringify( MyData.data.map(x => x.slug)) );
                localStorage.setItem("psxkjm", JSON.stringify(Mypermissions ))
            }
        }
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        await fetch(process.env.REACT_APP_API_URL + 'setup/addcompany', {
         method: 'POST',
         body: JSON.stringify(state),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + tokenString.toString()
          },
        })
        .then((res) => 
        { if(res.status === 401){ navigate("/login"); }        
        return res.json()})
        .then((data) => {
            alert("Company Added Successfully")
            localStorage.setItem("Company", JSON.stringify(data.company) );
            GetCompPermissions();
            if(data.success){navigate("/setup/quicksetup")}
        })
        .catch((err) => {
            console.log(err.message);
        });
        };
     
      
  return (
      <div className=''>
        <form onSubmit={handleSubmit}>
        <div className='row'>
            <div className="mb-3">
                <label for="CompanyName" className="form-label">Company name<span className='color-red'>*</span></label>
                <input type="text" required name="CompanyName" className="form-control" id="CompanyName" value={state.CompanyName} onChange={handleInputChange} placeholder="Enter company name"/>
                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
           
            
            <div className="mb-3">
                <label for="Description" className="form-label">Description<span className='color-red'>*</span></label>
                <textarea required type="text" className="form-control" id="Description" rows="5" name="Description" value={state.Description} onChange={handleInputChange}  placeholder="Enter description"/>
                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            
                <div className="col-12 col-md-3 mb-3">
                    <label for="GST" className="form-label">GST</label>
                    <input type="text"  name="GST" className="form-control" id="GST" value={state.GST} onChange={handleInputChange} placeholder="Enter GST Number"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <label for="TAN" className="form-label">TAN</label>
                    <input type="text"  name="TAN" className="form-control" id="TAN" value={state.TAN} onChange={handleInputChange} placeholder="Enter TAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
     
                <div className="col-12 col-md-3 mb-3">
                    <label for="PAN" className="form-label">PAN</label>
                    <input type="text"  name="PAN" className="form-control" id="PAN" value={state.PAN} onChange={handleInputChange} placeholder="Enter PAN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <label for="CIN" className="form-label">CIN</label>
                    <input type="text"  name="CIN" className="form-control" id="CIN" value={state.CIN} onChange={handleInputChange} placeholder="Enter CIN"/>
                    {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
            </div>
            <div className='row'>
                <div className='col-12 formheading my-3'>
                    <h3 className='ListMainHeading'>Address</h3>
                </div>
            </div>
            {state.Addresses.map((address, index) => (
                <div key={index}>
                    <div className='row'>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="GST" className="form-label">Address Type</label>
                            <select disabled name="AddressType" className="form-select" value={address.AddressType} onChange={event => handleInputL2Change(index, event)} aria-label="Default select example">
                                <option>Select Any</option>
                                <option value="register">Registered Office</option>
                                <option value="headoffice">Head Office</option>
                                <option value="branchoffice">Branch Office</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="TAN" className="form-label">Country</label>
                            <select name="CountryId" className="form-select" value={address.CountryId} onChange={event => UpdateStates(index, event)} >
                                <option value="">Select Country</option>
                                {Countries.map((item) => (<option key={item.id} value={item.id}>{item.countryName}</option>))}
                            </select>
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="StateId" className="form-label">States</label>
                            <select name="StateId" id="StateId" className="form-select" value={address.StateId} onChange={event => UpdateCities(index, event)}>
                                <option value="">Select State</option>
                                {States.map((item) => (<option key={item.id} value={item.id}>{item.stateName}</option>))}
                            </select>
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="CIN" className="form-label">City</label>
                            <select name="City" id="City" className="form-select" value={address.City} onChange={event => handleInputL2Change(index, event)}>
                                <option value="">Select City</option>
                                {Cities.map((item) => (<option key={item.id} value={item.id}>{item.cityName}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="StreetAddress" className="form-label">Local Address</label>
                            <input type="text"  name="StreetAddress" className="form-control" id="StreetAddress" value={address.StreetAddress} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Local Address"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="PinCode" className="form-label">Pin code</label>
                            <input type="text"  name="PinCode" className="form-control" id="PinCode" value={address.PinCode} onChange={event => handleInputL2Change(index, event)} placeholder="Enter Pincode"/>
                            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        <div className="col-12 col-md-3 mb-3">
                            <label for="Email" className="form-label">Mobile</label>
                            <PhoneInput inputProps={{required: true}} country={'in'} inputClass='inputClass' name="PhoneNumber" value={address.PhoneNumber} onChange={phone => handlePhone(index, phone)} placeholder="Enter mobile with country code"/>
                        </div>
                    </div>
            </div>

            ))}

            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
    </div>
  )
}

export default AddCompForm
