import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import SIReqDetailBlock from '../../Blocks/SIReqDetailBlock'

const SIReqDetailPage = () => {
  return (
    <>
        <InventoryHeader />
        <div className='p-3'>
            <SIReqDetailBlock/>
        </div>
   </>
  )
}

export default SIReqDetailPage