import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListAPInvoices from '../../Blocks/ListAPInvoices'

const Apinvoice = () => {
  return (
    <div>
    <InventoryHeader/>
    <div className='p-3'>
        <ListAPInvoices />
      </div>
  </div>
  )
}

export default Apinvoice