export const IssueTo = ()  => {
    
    
   return [{
        name : "Production",
        value: "production"
    },
    {
        name: "Other",
        value: "other"
    }
    ]
}

export const ReferenceType = () => {
    return [{
        name: "Production Order No",
        value: "prodono"
    }]
}
