import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import SupplierDetailBlock from '../../Blocks/SupplierDetailBlock'

const SupplierDetail = () => {
  return (
    <div>
    <InventoryHeader/>
    <div className='p-3'>
        <SupplierDetailBlock />
      </div>
  </div>
  )
}

export default SupplierDetail