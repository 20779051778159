import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { POSTAPICallAsync } from '../ServiceLayer/POSTAPICall';

const DetailSTO = () => {
    const navigate = useNavigate();
    const [MySrNos, setMySrNos] = useState("");
    const [WrongSrNos, setWrongSrNos] = useState([]);
    const [confTracking, setConfirmTracking] = useState([]);
    const [FindBatch, setFindBatch] = useState("");
    const Params = useParams();
    const Company = JSON.parse(localStorage.getItem('Company'));
    const [STODetail, setSTODetail] = useState({
      Id: "",
      stoNo: "",
      stoDate: "",
      Name: "",
      TimeZone: "",
      CompanyId: "",
      FromWh: "",
      ToWH: "",
      Status: "",
      Subject: "",
      WelcomeText: "",
      termsandConditions: "",
      stockTransferOrderItems: [],
    });
    const [itemMatadata, setItemMetaData] = useState({
      visible : "",
      itemIndex: "",
      trackBy: "",
    });
    const [Batches, setBatches] = useState ([
      {
        BatchNo: "",
        Qty: "",
      }
  ])
    const GetSTO = async () => {
        var MyRes = await GetAPICallAsync("GetSTObyId", { lazyEvent: "" }, {Id : Params.Id});
        setConfirmTracking([])
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setSTODetail((prevProps) => ({
            ...prevProps,
        
            Id: MyData.data.id,
            stoDate: MyData.data.stoDate,
            stoNo: MyData.data.stoNo,
            Name: MyData.data.stoName,
            TimeZone: MyData.data.timeZone,
            CompanyId: MyData.data.companyId,
            FromWh: MyData.data.fromWarehouse.warehouseName,
            ToWH: MyData.data.toWarehouse.warehouseName,
            Status: MyData.data.status,
            Subject: MyData.data.subject,
            WelcomeText: MyData.data.welcomeText,
            termsandConditions: MyData.data.termsandConditions,
            stockTransferOrderItems: MyData.data.stockTransferOrderItems,
          }));

          MyData.data.stockTransferOrderItems.map((item) => {
            if(item.item.trackItem === true){
              let IsTracked = false;
              switch (item.item.trackBy){
                case "batch"  : {
                  if(item.stoItemBatches.length > 0 ){
                    IsTracked = true
                  }}
                case "serialno"   : {

                  if(item.stoItemSrNos.length === item.qty){
                    IsTracked = true
                  }
                  
                }
                
              }
              setConfirmTracking((prevProps) => ([
                ...prevProps, {"STOItemId": item.id, "Tracked" : IsTracked}
              ]))
            }
          })
        }
    }
    const handleAddMoreItem = (index) => {
      const values = [...Batches ]
      values.splice(index + 1, 0, {"BatchNo": "", "Qty": ""} )
      setBatches(values);
    }
  
    const handleremoveitem = (index) => {
      const values = [...Batches]
      if(values.length > 1){
          values.splice(index, 1)
          setBatches(values);
      }
    }
  
    const getTimeZone = () => {
                          
      const MytimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // setState((prevProps) => ({
      //     ...prevProps,
      //     "TimeZone": MytimeZone
      //   }));
  
    };

    const handleBatchInputChange = (Index, event) => {
      const { name, value } = event.target;
     let values = [...Batches];
     values[Index][name] = value;
      setBatches(values);
    };
  

    useEffect(() => {
        GetSTO();
    }, [])
    useEffect(() => {
      GetSTO();
    }, [itemMatadata.visible === false])

    const AddItemMetadata = (Index) => {
      let MyPOItem = STODetail.stockTransferOrderItems[Index]; 
      console.log(MyPOItem);
      setItemMetaData((prevProps) => ({
        ...prevProps,
        visible : true,
        itemIndex : Index,
        trackBy: MyPOItem.item.trackBy,
      }))
      if(MyPOItem.item.trackBy === "batch"){
        
        console.log(MyPOItem.stoItemBatches)
        if(MyPOItem.stoItemBatches.length > 0)
        {
          var Values = [];
          MyPOItem.stoItemBatches.map((batchitem) => {
            Values = [...Values, {"BatchNo": batchitem.batches.batchNo, "Qty": batchitem.qty}]
          })
          setBatches(Values);
        } 
      }
      else if(MyPOItem.item.trackBy === "serialno"){
        let SrNos = "";
        MyPOItem.stoItemSrNos.map((Sno, index) => {
          if(index < MyPOItem.stoItemSrNos.length - 1){
            SrNos += Sno.srNo.itemSrNo + ",";
          }
          else
          {
            SrNos += Sno.srNo.itemSrNo
          }
          
        })
        setMySrNos(SrNos);
        setWrongSrNos("")
      }
      
    }

    const SubmitItemMetaData = async (e) => {
      e.preventDefault();
      setWrongSrNos([]);
      let ItemsNumber = 0
      let values = [...STODetail.stockTransferOrderItems]
      let TrackBy = values[itemMatadata.itemIndex]["item"]["trackBy"];
      let OrderedQty = parseFloat(values[itemMatadata.itemIndex]["qty"])
      let ItemId = values[itemMatadata.itemIndex]["itemId"]
      let STOItemId = values[itemMatadata.itemIndex]["id"]
      let TackingValues = [...confTracking]

      
      if (TrackBy === "batch")
      {
          Batches.map ((item) => (
            ItemsNumber += parseInt(item.Qty)
          ))
        
          if(ItemsNumber === OrderedQty){
          var MyRes = await POSTAPICallAsync("VerifyandAddSTOBatch", {"STOItemId": STOItemId, "CompanyId": STODetail.CompanyId, "ItemId": ItemId, Batches: Batches});
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            if(MyData.success === false){
              setWrongSrNos(MyData.data);
            }
            else if(MyData.success === true){
              setItemMetaData((prevProps) => ({
                ...prevProps,
                "visible" : false
              }));
              let StoItemId = values[itemMatadata.itemIndex]["id"];
              let MyValue = TackingValues.find(x => x.STOItemId === StoItemId);
              MyValue.Tracked = true;
              setConfirmTracking(TackingValues);
              setBatches([{"BatchNo": "",  "Qty": ""}])
            }
          }
        
          }
          else{
            alert("Qty doesn't match with recieved qty.")
          }
      }
      else if (TrackBy === "serialno")
      {
        let TSrNos = MySrNos.split(",");
        ItemsNumber = TSrNos.length;
        if(parseInt(ItemsNumber) === parseInt(OrderedQty)){
          var MyRes = await POSTAPICallAsync("VerifyandAddSTOSrNo", {STOItemId: STOItemId, SrNos: MySrNos, WHID: STODetail.FromWhId, ItemId : ItemId});
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            if(MyData.success === false){
              setWrongSrNos(MyData.data);
            }
            else if(MyData.success === true){
              setItemMetaData((prevProps) => ({
                ...prevProps,
                "visible" : false
              }));
              let StoItemId = values[itemMatadata.itemIndex]["id"];
              let MyValue = TackingValues.find(x => x.STOItemId === StoItemId);
              MyValue.Tracked = true;
              setConfirmTracking(TackingValues);
            }
          }
          
        }
        else{
          alert("Qty doesn't match with recieved qty.")
        }
      }
        

    }

    const ChangeStatus = async () => {
      let MyStatus = ""
      if(STODetail.Status === "pending" ){
        MyStatus = "intransit"
      }
      else if(STODetail.Status === "intransit"){
        MyStatus = "completed";
      }
      var MyRes = await POSTAPICallAsync("UpdateSTOStatus", {STOId: Params.Id, Status: MyStatus});
          if (MyRes.status === 401){
            localStorage.removeItem("User_Obj");
            localStorage.removeItem("Company");
            localStorage.removeItem("token");
            navigate("/login");
          }
          else{
            var MyData = await MyRes.json();
            console.log(MyData)
            if(MyData.success){
              alert("Updated")
            }
            }
          
    }

    const GetButton = () => {
      if(STODetail.Status === "pending" ){
        let Validation  = true;
      confTracking.map((item) => {
        if(item.Tracked === false){
          Validation = false
        }
      
      })
      return Validation === true ?  <button className='btn btn-primary' onClick={() => ChangeStatus()}>Mark in Transit</button> : ""
    
      }
      else if(STODetail.Status === "intransit"){
        let Validation  = true;
        confTracking.map((item) => {
        if(item.Tracked === false){
          Validation = false
        }
      
        })
        return Validation === true ?  <button className='btn btn-primary' onClick={() => ChangeStatus()}>Mark Complete</button> : ""
    
      }
      
      
    }

    const GetBatch = () => {
      alert("All Good")
    }

    const GetTick = (Id) => {
      if(confTracking.length> 0){
        let MyTrack = confTracking.find(x => x.STOItemId === Id)
        return MyTrack.Tracked ? <span> <i className="pi pi-verified" style={{ color: 'green' }}></i></span> : <span> <i className="pi pi-ban" style={{ color: 'red' }}></i> </span>;
      }
      
    }

  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <h1 className='ListMainHeading'>Stock Transfer Order</h1>
        </div>
        <div className='col-6 text-end'>
        {GetButton()}
         
        </div>
      </div>
      <div className='row my-3 g-0'>
        <table className='table table-bordered'>
          <tbody>
          <tr>
            <td className='HeaderTd'>
              STO No
            </td>
            <td className='ValueTD'>
               {STODetail.stoNo}
            </td>
            <td className='HeaderTd'>
              STO Name
            </td>
            <td className='ValueTD'>
               {STODetail.Name}
            </td>
          </tr>
          <tr>
            <td className='HeaderTd'>
              STO Date
            </td>
            <td className='ValueTD'>
               {STODetail.stoDate}
            </td>
            <td className='HeaderTd'>
              Status
            </td>
            <td className='ValueTD'>
              {STODetail.Status}
            </td>
          </tr>
          <tr>
            <td className='HeaderTd'>
              Transfer From
            </td>
            <td className='ValueTD'>
               {STODetail.FromWh}
            </td>
            <td className='HeaderTd'>
              Transfer To
            </td>
            <td className='ValueTD'>
               {STODetail.ToWH}
            </td>
          </tr>
          <tr>
            <td className='HeaderTd'>
              Subject
              </td>
            <td colSpan={3}> 
            {STODetail.Subject === ""? "No Subject." : STODetail.Subject }
            </td>
          </tr>
          <tr>
            <td className='HeaderTd'>
              Welcome Message
              </td>
            <td colSpan={3}> 
            {STODetail.WelcomeText === ""? "No  Welcome Message." : <div dangerouslySetInnerHTML={{ __html: STODetail.WelcomeText }}/> }
            </td>
          </tr>
          <tr>
            <td className='HeaderTd'>
              Terms and Conditions
              </td>
            <td colSpan={3}> 
            {STODetail.termsandConditions === ""? "No terms and consditions to show." : <div dangerouslySetInnerHTML={{ __html: STODetail.termsandConditions }}/> }
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <h2  className='ListMainHeading'>Items to transfer</h2>
      

      <div className='row g-0'>
      <table  className='table table-bordered'>
          <thead>
            <tr>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Qty</th>
            <th>Unit</th>
            <th>Action</th>
            </tr>
          </thead>
        <tbody>
          {STODetail.stockTransferOrderItems.map((item, index ) =>(
            <tr key={index}>
             
              <td>{item.item.itemCode}</td>
              <td>{item.item.name}</td>
              <td>{item.qty}</td>
              <td>{item.itemUnit.unitShortCode}</td>
              <td>{(item.item.trackItem) ? <span className='btn btn-sm btn-link ms-3' onClick = {() => AddItemMetadata(index)}>{item.item.trackBy === "serialno" ? <span>Add Sr. No </span>: <span>Add Batch</span> }</span> : "No Action Required" }
                {GetTick(item.id)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <Dialog header="Add Info" visible={itemMatadata.visible} maximizable  style={{  width: '90vw',}} onHide={() => setItemMetaData({visible: false})}>
      {WrongSrNos.length > 0 ? <>
      <span className='Error'>Following data is wrong. Please check </span><br/>
      {WrongSrNos.map((item, index) => (
         <span className='Error' key={index}> {item},</span>
      )) }</> : ""
      }
      
      <form onSubmit={SubmitItemMetaData} className='mt-3'>
            {itemMatadata.trackBy === "batch"?
            <>
            {/* <div className='row'>
            <div className="col-6 mb-3">
                <input type="text" required name="BatchNo" className="form-control" id="TaxName" value={FindBatch} onChange={event => setFindBatch(event.target.value)} placeholder="Search by Batch / Manufacturer Batch No Number"/>
            </div>
            <div className='col-2'>
              <button type="button" className="btn btn-primary" onClick={() => GetBatch()}>Add</button>
            </div>
            </div> */}

            {Batches.map ((item, index) => (          
            <div key={index} className='row mt-3'>
            <div className="col-4 mb-3">
                <label className="form-label">Batch No<span className='color-red'>*</span></label>
                <input type="text" required name="BatchNo" className="form-control" id="TaxName" value={item.BatchNo} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Batch Number"/>
            </div>
             <div className="col-4 mb-3">
                <label className="form-label">Qty<span className='color-red'>*</span></label>
                <input type="text" required name="Qty" className="form-control" id="Qty" value={item.Qty} onChange={event => handleBatchInputChange(index, event)} placeholder="Enter Qty"/>
            </div>
            <div className='col-2'>
            <i className="pi pi-plus ActionIconsDefault" onClick={() => handleAddMoreItem(index)}></i>
                    <i className="pi pi-times mx-2 ActionIconsDefault" onClick={() => handleremoveitem(index)}></i>
            </div>
            
            </div> 
            ))}
            </>
            :
            <div className="mb-3">
                <label className="form-label">Add Serial Numbers ("," Comma Separated)</label>
                <textarea type="text" rows="4" name="MySrNos" className="form-control" id="MySrNos" value={MySrNos} onChange={(e) => setMySrNos(e.target.value)} placeholder="Enter Serial Numbers"/>
            </div> }          
            <div className='mt-4'>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
      </Dialog>
    </div>
  )
}

export default DetailSTO