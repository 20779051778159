// src/App.js
import React from 'react';
import PublicHeader from '../../Blocks/PublicHeader';
import { LinearGradient } from '@react-pdf/renderer';
import bannerAnalytics from '../../images/bannerAnalytics.jpg'

const Home = () => {
  const styles = {
    navbar: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '1rem 2rem',
      backgroundColor: '#2C3E50',
      position: 'fixed',
      width: '100%',
      top: '0',
      zIndex: '1000',
    },
    navbarLink: {
      color: 'white',
      fontSize: '16px',
      textDecoration: 'none',
      padding: '10px 20px',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
    },
    navbarLinkHover: {
      backgroundColor: '#2980B9',
    },
    
    ctaButton: {
      padding: '15px 40px',
      backgroundColor: '#E74C3C',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '18px',
      marginTop: '20px',
      transition: 'background-color 0.3s',
    },
    ctaButtonHover: {
      backgroundColor: '#C0392B',
    },
    section: {
      padding: '60px 20px',
      textAlign: 'left',
    },
    featureCard: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    },
    testimonialCard: {
      margin: '20px auto',
      padding: '20px',
      backgroundColor: '#ECF0F1',
      borderRadius: '10px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
      width: '80%',
      textAlign: 'center',
    },
    pricingCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    },
    footer: {
      padding: '20px',
      backgroundColor: '#34495E',
      color: 'white',
      textAlign: 'center',
    },
  };

  return (
    <>
      {/* Navbar */}
      <PublicHeader />
    

    <section>
      <div className='HomeBanner '>
      <div className='container' style={{height: '100%', boxSizing: 'border-box'}}>
          <div className='row g-0 justify-content-center align-items-center' style={{height: '100%'}}>
            <div className='col-12 col-lg-6 pe-sm-5'>
              <span className='BannerMainText1'>Advanced & Quality </span> <br/> <span className='BannerMainText2'>Line of Business</span>
              <p className='Banner-SubText'>Simple yet powerful AI-enabled platform designed to swiftly create compelling proposals, 
                impress your prospects, win more deals, and ensure you get paid on time.</p>
                <button className='btn btn-outline-primary cust-big-btn mt-3'>Try it Now  <i className="pi pi-angle-double-right"></i></button>
            </div>
            <div className='col-12 col-lg-6 text-end ps-0 mt-5 mt-lg-0'>
                <img width="100%" src={bannerAnalytics} style={{borderRadius: "50% 50% 50% 0px", backgroundColor: '#f8f8f8'}} alt='Banner Image'/>
            </div>
          </div>
        </div>
      </div>
    </section>

      {/* Features Section */}
      <section id="features" style={styles.section}>
        <div className='text-center'>
        <h2>Key Features</h2>
        </div>
        <div className='container mt-5'>
          <div className='row'>
          <div className='col-12 col-sm-4 mb-4' >
            <div className='col p-5' style={styles.featureCard}>
              <h3>Real-Time Stock Tracking</h3>
              <p>Monitor stock levels in real-time and get notified on low stock items automatically.</p>
            </div>
            
          </div>
          <div className='col-12 col-sm-4 mb-4' >
            <div className='col p-5' style={styles.featureCard}>
            <h3>Analytics & Reporting</h3>
            <p>Generate detailed reports on sales, inventory levels, and product trends.</p>
            </div>
            
          </div>
          <div className='col-12 col-sm-4 mb-4' >
            <div className='col p-5' style={styles.featureCard}>
            <h3>Multi-Location Support</h3>
            <p>Manage inventory across multiple warehouses or retail locations seamlessly.</p>
            </div>
            
          </div>
          <div className='col-12 col-sm-4 mb-3' >
            <div className='col p-5' style={styles.featureCard}>
              <h3>Real-Time Stock Tracking</h3>
              <p>Monitor stock levels in real-time and get notified on low stock items automatically.</p>
            </div>
            
          </div>
          <div className='col-12 col-sm-4 mb-3' >
            <div className='col p-5' style={styles.featureCard}>
            <h3>Analytics & Reporting</h3>
            <p>Generate detailed reports on sales, inventory levels, and product trends.</p>
            </div>
            
          </div>
          <div className='col-12 col-sm-4 mb-3' >
            <div className='col p-5' style={styles.featureCard}>
            <h3>Multi-Location Support</h3>
            <p>Manage inventory across multiple warehouses or retail locations seamlessly.</p>
            </div>
            
          </div>
          
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="howItWorks" style={styles.section}>
        <div className='HowitWorksImage'>

        </div>
        <div className='HowitWorksText'>

        </div>
        <div className='container' style={{ position: 'relative',zIndex: "99", color: "#fff"}}>
        <div className='row'>
          <div className='col-12 col-sm-6'>

          </div>
          <div className='col-12 col-sm-6 p-5'>
          
            <h2>How It Works</h2>
            <p>Our Inventory Management System is designed to be user-friendly, powerful, and efficient.</p>
            <div>
              <h3>Step 1: Set Up Your Inventory</h3>
              <p>Quickly add your products, set stock levels, and assign categories.</p>
            </div>
            <div>
              <h3>Step 2: Automate Stock Tracking</h3>
              <p>Our system will track stock in real-time and notify you when it’s time to reorder.</p>
            </div>
            <div>
              <h3>Step 3: Analyze Data</h3>
              <p>Access detailed reports to help with forecasting, product sourcing, and making informed decisions.</p>
            </div>
          
          </div>
          </div>
        </div>
      </section>

    

      {/* Pricing Section */}
      <section id="pricing" style={styles.section}>
        <div className='text-center'>
          <h2>Pricing Plans</h2>
        </div>
        <div className='container'>
        <div className='row mt-5'>
          <div className='col-12 col-sm-4 mb-3'>
            <div className='p-5 text-center' style={styles.pricingCard}>
              <h3>Basic Plan</h3>
              <p>$19/month</p>
              <p>Perfect for small businesses with basic inventory needs.</p>
              <button className='btn btn-primary cust-big-btn'>Sign Up</button>
            </div>
          </div>
          <div className='col-12 col-sm-4 mb-3'>
          <div className='p-5 text-center' style={styles.pricingCard}>
            <h3>Advanced Plan</h3>
            <p>$49/month</p>
            <p>Includes advanced features like reporting and multi-location management.</p>
            <button className='btn btn-primary cust-big-btn'>Sign Up</button>
          </div>
          </div>
          <div className='col-12 col-sm-4 mb-3'>
          <div className='p-5 text-center' style={styles.pricingCard}>
            <h3>Enterprise Plan</h3>
            <p>$99/month</p>
            <p>Custom features and support for large businesses.</p>
            <button className='btn btn-primary cust-big-btn'>Contact Us</button>
          </div>
          </div>
        </div>
        </div>
      </section>

     

      {/* Footer */}
      <footer style={styles.footer}>
        <p>&copy; 2024 Inventory Management System. All Rights Reserved.</p>
      </footer>
    </>
  );
};

export default Home;
