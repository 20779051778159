import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import AddItemUnit from './AddItemUnit';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import moment from 'moment';

const ListARInvoices = (props) => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [ARInvoices, setARInvoices] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [selectedRow, setselectedRow] = useState([]);
    const menuRight = useRef(null);
    //const router = useRouter();
    const toast = useRef(null);
    let networkTimeout = null;
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1,
      sortField: null,
      sortOrder: 0,
      filters: {
          org_name: { value: '', matchMode: 'contains' },
  
      }
  });
  
  const items = [
    {
      label: 'Details',
      icon: 'pi pi-refresh',
      url: "/customers/details/" + selectedRow.org_id + ""
  },
  {
      label: 'Edit',
      icon: 'pi pi-times',
      command: (row) => {
        console.log(row);
          toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
      }
  },
  {
    label: 'React Website',
    icon: 'pi pi-external-link',
    url: 'https://reactjs.org/'
  },
  ]; 
    
  
    useEffect(() => {
      loadLazyData();
    }, [lazyState, visible]);
    useEffect (() => {
      loadLazyData();
    }, [])
  
  
    const loadLazyData = () => {
      setLoading(true);
      if (networkTimeout) {
          clearTimeout(networkTimeout);
      }
  
      //imitate delay of a backend call
      networkTimeout = setTimeout(async () => {
        var MyRes = await GetAPICallAsync("GetListARInvoices", { lazyEvent: lazyState }, "");
        if (MyRes.status === 401){
          localStorage.removeItem("User_Obj");
          localStorage.removeItem("Company");
          localStorage.removeItem("token");
          navigate("/login");
        }
        else{
          var MyData = await MyRes.json();
          console.log(MyData);
          setTotalRecords(MyData.total);
          setARInvoices(MyData.data);
        }
        
      setLoading(false);
      }, Math.random() * 1000 + 250);
  };
    const onPage = (event) => {
      setlazyState(event);
  };
  
  const onSort = (event) => {
      setlazyState(event);
  };
  
  const onFilter = (event) => {
    console.log(event);
      event['first'] = 0;
      setlazyState(event);
  };
  
  const onSelectionChange = (event) => {
      const value = event.value;
  
      setSelectedCustomers(value);
      setSelectAll(value.length === totalRecords);
  };
  
  const onSelectAllChange = async (event) => {
      const selectAll = event.checked;
  
      if (selectAll) {
        var MyRes = await GetAPICallAsync("GetPOs", { lazyEvent: lazyState }, "");
              setSelectAll(true);
              setARInvoices(MyRes.data);
        
      } else {
          setSelectAll(false);
          setSelectedCustomers([]);
      }
  };
  
  const DateTemplate = (prop) => {
    return moment(prop.date).format("DD/MM/yyyy");
  }

  const ApprovalTemplate = (prop) => {
    return prop.isApproved === true ? <span className='ActiveClass'>Approved</span> : <span className='InActiveClass'>Pending</span>
}
  
    const ActiveTemplate = (prop) => {
        return prop.status === true ? <span className='ActiveClass'>Active</span> : <span className='InActiveClass'>Inactive</span>
    }
    const ActionTemplate = (row) => {
       return<>
       <div className="flex justify-content-center">
            <Toast ref={toast}></Toast>
            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Button  icon="pi pi-ellipsis-v" className="mr-2 MenuIcon" onClick={(event) => {menuRight.current.toggle(event); setselectedRow(row)}} aria-controls="popup_menu_right" aria-haspopup />
            {/* <span className='MenuIcon' onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup><i className="pi pi-ellipsis-v"></i></span> */}
       </div>
       </> 
    }
  return (
    <div>
       {!props.HideButtons &&  <div className='mb-3 row'>
        <div className='col-6'>
            <span>Account Payables</span>
        </div>
        <div className='col-6 d-flex justify-content-end'>
        <Link className='btn btn-primary' to="/sales/addarinvoice">Add New</Link>
        </div>
        </div>}
        <div className='row'>
            <DataTable value={ARInvoices} lazy dataKey="id" paginator removableSort size='small' showGridlines  resizableColumns columnResizeMode="expand"
                    first={lazyState.first} rows={10} totalRecords={totalRecords} onPage={onPage} selectionMode="checkbox"
                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} filters={lazyState.filters} loading={loading} tableStyle={{ minWidth: '75rem' }}
                    selection={selectedCustomers} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
            <Column field="date" header="Date" body={DateTemplate}  />
            <Column field="reqId" header="Request Id"></Column>
            <Column field="dealer.dealerName" header="Dealer"></Column>
            <Column field="warehouse.warehouseName" header="Approval Status" body={ApprovalTemplate}></Column>
            <Column field="status" header="Status" body={ActiveTemplate}></Column>
            </DataTable>
        </div>
        {/* <Dialog header="Create Purchase Order" visible={visible} maximizable  style={{  width: '90vw',}} onHide={() => setVisible(false)}>
            <AddPurchaseOrder setVisible={setVisible} />
        </Dialog> */}
    </div>
  )
}

export default ListARInvoices