import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddItemMaster = (props) => {
  const tokenString = JSON.parse(localStorage.getItem('token'));
  const Company = JSON.parse(localStorage.getItem('Company'));
    const navigate = useNavigate();
    const [Message, setMessage] = useState();
    const [DynamicFields, setDynamicFields] = useState([]);
    const [CategoryList, setCategoryList] = useState({
      Enable: "",
      data: [],
      value: "",
    });
    const [Subcategory, setSubcategories] = useState([]);
    const [Warehouses, setWarehouses] = useState([]);
    const [ItemUnits, setItemUnits] = useState([]);
    const [state, setState] = useState ({
      ItemCode : "",
      CatID : "",
      CompanyId : Company.id,
      Is_SubItem: false,
      ItemGroup: "",
      Name : "",
      ForeignName : "",
      Description : "",
      HSNCode : "",
      DefaultWareHouse : "",
      ItemType : "",
      BuyingUnit : "",
      BToIConversion: 1,
      IToSConversion: 1,
      Is_Sellable: true,
      SellingUnit : "",
      InventoryUnit : "",
      IssueMethod : "",
      TrackItem: false,
      TrackBy: "",
      OpeningBalance: "",
      ReorderValue: "",
      DynamicFields: []
    });

    const DynamicFieldsFunction = (listDynamicFields) => {
      let values = [...state.DynamicFields];
      listDynamicFields.map((field) => {
        return values = [...values, {"FormDynamicFieldId" : field.id, "Value" : "" }];
      })

      setState((prevProps) => ({
        ...prevProps,
        DynamicFields : values
      }))
      
    }

    const GetDynamicFields = () => (fetch(process.env.REACT_APP_API_URL + 'setup/Listdynamicfields?' + new URLSearchParams({
      CompanyId: Company && Company.id,
      form: "item-master"
      }), {
      method: 'Get',
      headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + tokenString
      },
    })
    .then(res => {
        if(res.status === 401){ navigate("/login"); }
        return res.json()
    } )
    .then(data => {
      setDynamicFields(data.listDynamicFields);
      DynamicFieldsFunction(data.listDynamicFields)
    })
    .catch((err) => {
      console.log(err.message);
    }))

    const GetFormFields = () => (fetch(process.env.REACT_APP_API_URL + 'setup/GetItemMasterForm?' + new URLSearchParams({
      CompanyId: Company && Company.id,
      }), {
      method: 'Get',
      headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + tokenString
      },
    })
    .then(res => {
        if(res.status === 401){ navigate("/login"); }
        return res.json()
    } )
    .then(data => {
      setCategoryList({data: data.prodCategories});
      setWarehouses(data.warehouses);
      setItemUnits(data.itemUnits)
    })
    .catch((err) => {
      console.log(err.message);
    }))



    const handleInputChange = (event) => {
        let { name, value, type } = event.target;
        if(type == "checkbox"){
          value = event.target.checked
        }
        setState((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
    };

    const handleDynamicInputChange = (index, event) => {
      const { name, value } = event.target;
        let values = [...state.DynamicFields]
        values[index][name] = value;
        setState((prevProps) => ({
            ...prevProps,
            DynamicFields: values
          }));
    }

      useEffect(() => {
        GetDynamicFields();
        GetFormFields();
      }, []);

      const GetField = (field, index) => {
        
        switch(field.fieldType){
          case "text" : {
            return  (
            <>
              <label htmlFor="AccountName" className="form-label">{field.fieldName}{field.isRequired ? <span className='color-red'> * </span> : ""}</label>
              <input required={field.isRequired} type="text" name="Value" className="form-control" id={field.id} value={state.DynamicFields.Value} onChange={event => handleDynamicInputChange(index, event)} />
            </>
          )};
          case "select": {
            return (
              <>
                  <label htmlFor={field.id} className="form-label">{field.fieldName}{field.isRequired ? <span className='color-red'> * </span> : ""}</label>
                  <select required={field.isRequired} name="Value"  id={field.id}  className="form-select" value={state.DynamicFields.Value} onChange={event => handleDynamicInputChange(index, event)}>
                      <option value="">Select {field.fieldName}</option>
                      {field.formFieldOptions.map((item) => (<option key={item.id} value={item.optValue}>{item.optionName}</option>))}
                  </select>
              </>
            )} 
        }

      }

      const CheckCategory = (event, index, catType) => {
        var MyList = CategoryList.data.filter(x => x.pCatId === event.target.value);
         setState((prevProps) => ({
          ...prevProps,
          CatID : event.target.value
        }))
        if(catType === "maincat"){
          const { name, value } = event.target;
         setCategoryList((prevProps) => ({
            ...prevProps,
            [name]: value
          }));
          MyList.length > 0 && setSubcategories((prevProps) => ([...prevProps, {value:"", Enable: "" ,"Categories" : MyList, removeActive: true}]));
          MyList.length > 0 && setCategoryList((prevProps) => ({...prevProps, "Enable" : "disabled"}));
        }
        else{
          //handleCategoryChange(event, "subcat", index)
          let SubCatList = [...Subcategory]
          var CurrentList = {...SubCatList[index]};
          
          if(MyList.length > 0)
          {
            CurrentList.Enable = "diabled";
            CurrentList.removeActive = false;
          }
            CurrentList.value = event.target.value;
      
          SubCatList[index] = CurrentList;
          setSubcategories(SubCatList);
          MyList.length > 0 && setSubcategories((prevProps) => ([...prevProps, {value:"", Enable: "" ,"Categories" : MyList, removeActive: true}]));

        }
          
      }

      const removeCategory = (index) => {
        let subcat = [...Subcategory];
        subcat.splice(index, 1);
        if (index === 0){
          setCategoryList((prevProps) => ({...prevProps, "Enable" : ""}));
          setState((prevProps) => ({
            ...prevProps,
            CatID : CategoryList.value
          }))
        }
        else if (index > 0){
          let previtem = {...subcat[index -1]}
          previtem.removeActive = true;
          previtem.Enable = "";
          subcat[index - 1] = previtem;
          setState((prevProps) => ({
            ...prevProps,
            CatID : previtem.value
          }))
        }
        
        
        setSubcategories(subcat);
      }
      

      const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        await fetch(process.env.REACT_APP_API_URL + 'setup/AddNewItemMaster', {
          method: 'POST',
          body: JSON.stringify(state),
          headers: {
             'Content-type': 'application/json; charset=UTF-8',
             'Authorization': 'Bearer ' + tokenString.toString()
           },
         })
         .then((res) => 
         { if(res.status === 401){ navigate("/login"); console.log(res); }        
         return res.json()})
         .then((data) => {
             setMessage("Account Added Successfully")
         })
         .catch((err) => {
             console.log(err.message);
         });
      }


  return (
    <>
    {Message ? <div className='col'>
            <span>{Message}</span>
            <button onClick={() => props.setVisible(false)}>Close</button>
        </div> :
    <form onSubmit={handleSubmit}>
      <div className='row mb-3'>
        <div className="col-4">
            <label htmlFor="CatID" className="form-label">Category</label>
            <select name="value" disabled={CategoryList.Enable} className="form-select" value={CategoryList.value} onChange={event => CheckCategory(event, 0, "maincat")} >
                <option value="">Select Category</option>
                {CategoryList.data.filter(x => x.pCatId === "00000000-0000-0000-0000-000000000000").map((item) => (<option key={item.id} value={item.id}>{item.catName}</option>))}
            </select>
          
        </div>
        { Subcategory.length > 0 ?
          Subcategory.map((cat, index) => (
            <div key={index} className="col-4">
              <label htmlFor="CatID" className="form-label">Sub-category</label>
              <select name="value" disabled={cat.Enable} className="form-select" value={cat.value} onChange={event => CheckCategory(event, index, "subcat")} >
                  <option value="">Select Sub-category</option>
                  {cat.Categories.map((item) => (<option key={item.id} value={item.id}>{item.catName}</option>))}
              </select>
              {cat.removeActive && <span className='removeBtn' onClick={() => removeCategory(index)}>Remove</span>}
           
          </div> 
          ))
          
        : " "
        }
      </div>
      <div className='row p-2'>
      <div className="col-4 mb-3 form-check">
                <input type="checkbox" className="form-check-input" name='Is_SubItem'  onChange={handleInputChange} id="Is_SubItem"/>
                <label className="form-check-label" htmlFor="Is_SubItem">Is Sub Item</label>
        </div>
        { state.Is_SubItem && <div className="col-4 mb-3">
            <label htmlFor='ItemCode' className="form-label">Group Name<span className='color-red'>*</span></label>
            <input type="text" required name="ItemGroup" className="form-control" id="ItemGroup" value={state.ItemGroup} onChange={handleInputChange} placeholder="Enter Item Code"/>
            
        </div>
        }
        </div>
        <div className='row'>
        <div className="col-4 mb-3">
        <label htmlFor='Name' className="form-label">Item name<span className='color-red'>*</span></label>
            <input type="text" required name="Name" className="form-control" id="Name" value={state.Name} onChange={handleInputChange} placeholder="Enter Item Name"/>
        </div>
        <div className="col-4 mb-3">
            <label htmlFor='ForeignName' className="form-label">Foreign Name<span className='color-red'>*</span></label>
            <input type="text" required name="ForeignName" className="form-control" id="ForeignName" value={state.ForeignName} onChange={handleInputChange} placeholder="Enter Foreign Name"/>
        </div>
        <div className="col-4 mb-3">
            <label htmlFor='ItemCode' className="form-label">Item Code<span className='color-red'>*</span></label>
            <input type="text" required name="ItemCode" className="form-control" id="ItemCode" value={state.ItemCode} onChange={handleInputChange} placeholder="Enter Item Code"/>
            
        </div>
        <div className="col-4 mb-3">
            <label htmlFor='ItemType' className="form-label">Item Type<span className='color-red'>*</span></label>
            <select name="ItemType"  className="form-select" value={state.ItemType} onChange={handleInputChange} >
                  <option value="">Select Item Type</option>
                  <option value="raw-material">Raw Material</option>
                  <option value="consumable">consumable</option>
                  <option value="packing-material">Packaging Material</option>
                  <option value="Semi-finished-good">Semi Finished Good</option>
                  <option value="finished-good">Finished Good</option>
                  
              </select>
        </div>
        <div className="col-4 mb-3">
            <label htmlFor='HSNCode' className="form-label">HSN Code<span className='color-red'>*</span></label>
            <input type="text" required name="HSNCode" className="form-control" id="HSNCode" value={state.HSNCode} onChange={handleInputChange} placeholder="Enter HSN Code"/>
        </div>
       
        <div className="col-4 mb-3">
            <label htmlFor='DefaultWareHouse' className="form-label">Default WareHouse<span className='color-red'>*</span></label>
            <select name="DefaultWareHouse"  className="form-select" value={state.DefaultWareHouse} onChange={handleInputChange} >
                <option value="">Select WareHouse</option>
                {Warehouses.map((item) => (<option key={item.id} value={item.id}>{item.warehouseName} {item.isSubstore && " (Sub Store)"}</option>))}
            </select>
        </div>
        {/* <div className="col-4 mb-3">
            <label htmlFor='IssueMethod' className="form-label">Issue Method<span className='color-red'>*</span></label>
            <select name="IssueMethod"  className="form-select" value={state.IssueMethod} onChange={handleInputChange} >
                  <option value="">Select Issue Method</option>
                  <option value="FIFO">First In - First Out (FIFO)</option>
                  <option value="LIFO">Last In - First out (LIFO)</option>                  
              </select>
        </div>
   */}
        <div className="col-4 mb-3">
            <label htmlFor='BuyingUnit' className="form-label">Buying Unit<span className='color-red'>*</span></label>
            <select name="BuyingUnit" required className="form-select" value={state.BuyingUnit} onChange={handleInputChange} >
                <option value="">Select Unit</option>
                {ItemUnits.map((item) => (<option key={item.id} value={item.id}>{item.unitName} ({item.unitShortCode})</option>))}
            </select>
        </div>
        <div className="col-4 mb-3">
            <label htmlFor='InventoryUnit' className="form-label">Inventory Unit<span className='color-red'>*</span></label>
            <select name="InventoryUnit" id="InventoryUnit" required className="form-select" value={state.InventoryUnit} onChange={handleInputChange} >
                <option value="">Select Unit</option>
                {ItemUnits.map((item) => (<option key={item.id} value={item.id}>{item.unitName} ({item.unitShortCode})</option>))}
            </select>
        </div>
        {state.BuyingUnit !== state.InventoryUnit ? 
        <div className="col-4 mb-3">
            <label htmlFor='BToIConversion' className="form-label">Conversion Rate (B to I)<span className='color-red'>*</span></label>
            <input type="text" required name="BToIConversion" className="form-control" id="BToIConversion" value={state.BToIConversion} onChange={handleInputChange} placeholder="Enter HSN Code"/>
        </div>
         : ""}
         <div className="col-4 mb-3">
            <label htmlFor='OpeningBalance' className="form-label">Opening Balance<span className='color-red'>*</span></label>
            <input type="text" required name="OpeningBalance" className="form-control" id="HSNCode" value={state.OpeningBalance} onChange={handleInputChange} placeholder="Enter Opening  balance"/>
        </div>
        <div className="col-4 mb-3">
            <label htmlFor='ReorderValue' className="form-label">Reorder Value<span className='color-red'>*</span></label>
            <input type="text" required name="ReorderValue" className="form-control" id="HSNCode" value={state.ReorderValue} onChange={handleInputChange} placeholder="Enter HSN Code"/>
        </div>

        </div>
        <div className='row bg-grey p-2'>
        <div className="col-4 mb-3">
          <div className='form-check p-3 '>
                <input type="checkbox" className="form-check-input" checked={state.Is_Sellable} name='Is_Sellable'  onChange={handleInputChange} id="Is_Sellable"/>
                <label className="form-check-label" htmlFor="Is_Sellable">Is Sellable</label>
          </div>
        </div>
        { state.Is_Sellable && <div className="col-4 mb-3">
            <label htmlFor='SellingUnit' className="form-label">Selling Unit<span className='color-red'>*</span></label>
            <select name="SellingUnit" required className="form-select" value={state.SellingUnit} onChange={handleInputChange} >
                <option value="">Select Unit</option>
                {ItemUnits.map((item) => (<option key={item.id} value={item.id}>{item.unitName} ({item.unitShortCode})</option>))}
            </select>
        </div>
        }
        {state.SellingUnit !== state.InventoryUnit ? <div className="col-4 mb-3">
            <label htmlFor='IToSConversion' className="form-label">Conversion Rate (I to S)<span className='color-red'>*</span></label>
            <input type="text" required name="IToSConversion" className="form-control" id="IToSConversion" value={state.IToSConversion} onChange={handleInputChange} placeholder="Enter HSN Code"/>
        </div> : ""}
        </div>
        <div className='row bg-grey p-2'>
        <div className="col-4 mb-3">
          <div className='form-check pt-3'>
                <input type="checkbox" className="form-check-input" name='TrackItem'  onChange={handleInputChange} id="TrackItem"/>
                <label className="form-check-label" htmlFor="TrackItem">Is Trackable</label>
                </div>
        </div>
        {state.TrackItem && <div className="col-4 mb-3">
            <label htmlFor='TrackBy' className="form-label">Track By<span className='color-red'>*</span></label>
            <select name="TrackBy"  className="form-select" value={state.TrackBy} onChange={handleInputChange} >
                  <option value="">Select Track By</option>
                  <option value="batch">Batches</option>
                  <option value="serialno">Serial No</option>                  
              </select>
        </div>
        }

        </div>
        <div className='row'>
       
        {DynamicFields.map((field, index) => (
            <div className='col-4 mb-3' key={index}>
              
              {GetField(field, index)}
            </div>
            
          ))}
        </div>
        
      <div className='row mb-3'>
        <div className="col">
            <label htmlFor='Description' className="form-label">Description<span className='color-red'>*</span></label>
            <textarea rows={4} type="text" required name="Description" className="form-control" id="Description" value={state.Description} onChange={handleInputChange} placeholder="Enter Description"/>
        </div>
     
      </div>
         <div className='mt-4'>
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
    </form>}
    </>
  )
}

export default AddItemMaster
