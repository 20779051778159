import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ApprovalSetupBlock from '../../Blocks/ApprovalSetupBlock'

const ApprovalSetupPage = () => {
  return (
    <>
        <InventoryHeader />
        <div className='p-3'>
            <ApprovalSetupBlock/>
        </div>
    </>
  )
}

export default ApprovalSetupPage