import React from 'react'
import LoginForm from '../../Blocks/LoginForm'
import Header from '../../Blocks/PublicHeader'

const Login = () => {
  return (
    <div>
     <Header />
     <div className='Banner pt-5'>
      <div className='container' style={{position: 'relative', zIndex: '99'}}>
        <div className='row g-0 align-items-center loginouter'>
            <div className='col-12 col-md-6'>
            <div className='row justify-content-center align-items-center MySignupDiv'>
                <div className='col-12'>
                  <h1 className='MainHeading'>Welcome to aqlob</h1>
                  <p className='Banner-SubText'>India's most valuable Inventory Management Solution</p>
                  <ul className='LoginUl'>
                    <li><i className="pi pi-check"></i> Easy Acces to your inventory.</li>
                    <li><i className="pi pi-check"></i> Avoid dead stock.</li>
                    <li> <i className="pi pi-check"></i> Real time tracking of items</li>
                    <li><i className="pi pi-check"></i> Accurate Stock Valuation</li>
                    <li><i className="pi pi-check"></i> Compliance Ready Solution</li>
                  </ul>
                  </div>

              </div>
            </div>
            <div className='col-12 col-md-6'>
                <LoginForm />
            </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Login
