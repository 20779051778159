import React, { useEffect, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import ListFromTransferRequests from '../Components/ListFromTransferRequests';
import ListToTransferRequests from '../Components/ListToTransferRequests';
import {GetAPICallAsync} from '../ServiceLayer/GetAPICall'
import { useNavigate } from 'react-router-dom';

const ListTransferRequests = () => {
  const navigate = useNavigate();
  const [MyWarehouses, setMYWarehouses] = useState([]);
  const [SelectedWHId, setSelectedWHId] = useState();

  const GetMyWarehouses = async () => {
    var MyRes = await GetAPICallAsync("GetMyWarehouses", {lazyEvent: ""} , "");
    if (MyRes.status === 401){
      localStorage.removeItem("User_Obj");
      localStorage.removeItem("Company");
      localStorage.removeItem("token");
      navigate("/login");
    }
    else{
      var MyData = await MyRes.json();
      console.log(MyData.data);
      setMYWarehouses(MyData.data);
    }
  };
  useEffect (() => {  
    GetMyWarehouses();
  }, []);

  return (
    <>

    <div className='row mb-3'>
      <div className='col-12'>
      <span className='ListMainHeading'> Transfer Requests </span>
      <span className='ms-2'>
      <select name="CurrencyId" className="" value={SelectedWHId} onChange={(e) => setSelectedWHId(e.target.value)} >
                  <option value="">Select Warehouse</option>
              {MyWarehouses.map((item) => (<option key={item.warehouse.id} value={item.warehouse.id}>{item.warehouse.warehouseName}</option>))} 
      </select>
      </span>
      </div>      
    </div>
       
        
        <TabView>
          <TabPanel header="Out Requests">
              <ListFromTransferRequests WHID={SelectedWHId} />
          </TabPanel>
          <TabPanel header="In Requests">
              <ListToTransferRequests WHID={SelectedWHId} />
          </TabPanel>
        
        </TabView>
    </>
    
  )
}

export default ListTransferRequests