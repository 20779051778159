import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import ListARInvoices from '../../Blocks/ListARInvoices'

const ARinvoice = () => {
  return (
    <div>
    <InventoryHeader/>
      <div className='p-3'>
        <ListARInvoices />
      </div>
  </div>
  )
}

export default ARinvoice