import React from 'react'
import InventoryHeader from '../../Blocks/InventoryHeader'
import WHStockIssueRequestBlock from '../../Blocks/WHStockIssueRequestBlock'

const IssueRequests = () => {
  return (
    <>
    <InventoryHeader />
        <div className='p-3'>
            <WHStockIssueRequestBlock/>
        </div>
   </>
  )
}

export default IssueRequests